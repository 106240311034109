import { Injectable } from '@angular/core';


// This is used to display the logs that you see on the home screen
// These are not the logs that get sent back to the server
@Injectable({
    providedIn: "root"
})
export class LoggerService {
    logs: string[] = [];

    constructor() {
    }

    logInfo(msg: any)  { this.log(`INFO: ${msg}`); }
    logDebug(msg: any) { this.log(`DEBUG: ${msg}`); }
    logError(msg: any) { this.log(`ERROR: ${msg}`); }

    private log(msg: any) {
        this.logs.push(msg);
        if(this.logs.length > 18) {
            this.logs = this.logs.slice(1);
        }
    }
}
