

export class BlockingCollection<T> {
    private availableValues = new Array<T>(0);
    private waitingPromises = new Array<(t:T) => void>(0);

    public reset(values: T[]) {
        this.waitingPromises = [];
        this.availableValues = values.slice();
    }

    public push(value: T) {
        if(this.waitingPromises.length) {
            const waitingPromise = this.waitingPromises.pop();
            waitingPromise(value);
        } else {
            if(this.availableValues.indexOf(value) === -1) {
                this.availableValues.push(value);
            }
        }
    }

    public async pop() : Promise<T> {
        return this.availableValues.length
            ? this.availableValues.pop()
            : new Promise<T>(
                (resolve) => {
                    this.waitingPromises.push(resolve);
                }
            );
    }
}