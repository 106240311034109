import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { parseClockFeedSettings, DisplaySection } from "./clockFeedSettings";
import { StyleSheetHandler } from 'src/styleSheetHandler';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { FileUtils } from 'src/fileUtils';
import { LoggerService } from "src/loggerService";
import { generateRenderInstructions, renderTextAtOptimumSizeAndPosition, renderTextBlock, renderBackgroundFill, renderToCanvas } from './clockFeedRendering';
import { IClock, LocalTimeZoneClock } from "src/clock";

// An interval function that syncs with the clock
// so it calls 'next' as close to the second changeover
// as possible
function onEverySecond(clock: LocalTimeZoneClock) : Observable<Date> {
    return new Observable<Date>(
        (subscriber) => {
            let timeoutHandle:number = undefined;
            let isSubscribed = true;
            const setNextTimer = () => {
                if(timeoutHandle) {
                    window.clearTimeout(timeoutHandle);
                }
                if(isSubscribed) {
                    const now = clock.now.getTime();
                    const next = (1000 - (now%1000)) || 1000;
                    timeoutHandle = window.setTimeout(
                        () => {
                            try {
                                subscriber.next(new Date(now+next));
                            } finally {
                                if(isSubscribed) {
                                    setNextTimer();
                                }
                            }
                        },
                        next
                    );
                }
            };
            setNextTimer();
            const clockLeapSubscription = clock.$timeLeap.subscribe({
                next: () => {
                    if(isSubscribed) {
                        subscriber.next(clock.now);
                    }
                    setNextTimer();
                }
            });
            return {
                unsubscribe: () => {
                    isSubscribed = false;
                    if(timeoutHandle) {
                        window.clearTimeout(timeoutHandle);
                    }
                    clockLeapSubscription.unsubscribe()
                }
            }

        }
    );
}


@Component({
    "templateUrl": "clock-feed.component.html",
    "selector": "clock-feed"
})
export class ClockFeedComponent extends VisualFeedBaseComponent implements AfterViewInit, OnDestroy {
    private stylesheetHandler: StyleSheetHandler;
    private clockFeedTextSubscription: Subscription = null;
    private fontFamilyWatchSubscriptions: Subscription[] = [];
    private clockFeedSettings: DisplaySection[];
    private fontFaceLookup: { [key: string]: string };
    private timerSubscription: Subscription = null;
    @ViewChild("clockCanvas", {read: ElementRef}) clockCanvas: ElementRef<HTMLCanvasElement>;

    constructor(public mediaPlayerFileSystem: MediaPlayerFileSystem, private hostElement: ElementRef, private readonly localTimeZoneClock: LocalTimeZoneClock) {
        super(mediaPlayerFileSystem);
    }

    ngAfterViewInit() {
        const clockFeedName = "ClockFeed.xml";
        const clockFeedFilePath = FileUtils.getFeedFilePath(clockFeedName, this.sourceFolder);
        this.clockFeedTextSubscription = this.mediaPlayerFileSystem.watchTextContent(
            clockFeedFilePath
        ).pipe(
            map(xmlText => parseClockFeedSettings(xmlText))
        ).subscribe(
            clockFeedSettings => {
                this.stylesheetHandler = new StyleSheetHandler(this.hostElement.nativeElement);
                this.clockFeedSettings = clockFeedSettings;
                this.resetDisplay();
            }
        );
    }

    ngOnDestroy() {
        if (this.clockFeedTextSubscription)
        {
            this.clockFeedTextSubscription.unsubscribe();
            this.clockFeedTextSubscription = null;
        }
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.clearFontFileListeners();
    }

    private clearFontFileListeners() {
        while (this.fontFamilyWatchSubscriptions.length) {
            const listenerHandle = this.fontFamilyWatchSubscriptions.pop();
            listenerHandle.unsubscribe();
        }
    }

    private resetDisplay() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        const renderInstructions = generateRenderInstructions(
            this.generateSourceRectanglePriorToRotation(),
            this.clockFeedSettings
        );
        const context = this.clockCanvas.nativeElement.getContext("2d");

        this.clearFontFileListeners();
        this.fontFaceLookup = {};
        const uniqueFontNames = [
            ...new Set(
                renderInstructions.textBlocks.map(
                    tb => renderInstructions.fontNameLookup[tb.fontName]
                )
            )
        ];
        uniqueFontNames.forEach(
            (fontName, index) => {
                this.applyFontFamily(
                    fontName,
                    index
                );
            }
        );
        
        renderToCanvas(
            context,
            renderInstructions,
            this.localTimeZoneClock.now,
            this.fontFaceLookup
        );
        this.timerSubscription = onEverySecond(this.localTimeZoneClock).subscribe({
            next: (dateTime) => {
                renderToCanvas(
                    context,
                    renderInstructions,
                    dateTime,
                    this.fontFaceLookup
                );
            }
        });
    }

    private applyFontFamily(fileName: string, index: number) {
        if (fileName && fileName.length) {
            const fontFilePath = this.getFeedFilePath(fileName);
            const mediaFileArrived$ = this.mediaPlayerFileSystem.watchMediaFileArrived(fontFilePath);

            const listenerSubscription = mediaFileArrived$
                .subscribe({
                    next: async (fileEntry: FileEntry) => {
                        const fileUrl = await FileUtils.getFileUrl(fileName, this.sourceFolder);
                        const existedFontfamily = this.stylesheetHandler.getFontFamily(fileUrl);
                        const fontFamilyName = existedFontfamily == null ? `font_family_${this.sourceFolder}_${index}` : existedFontfamily;
                        if (existedFontfamily == null) {
                            this.stylesheetHandler
                                .setFontFamily(fontFamilyName, fileUrl);
                            this.fontFaceLookup[fileName] = fontFamilyName;
                        } 
                    }
                });
            this.fontFamilyWatchSubscriptions.push(listenerSubscription);
        }
    }
}