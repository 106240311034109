import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Side, NavigatableComponent, FocusRelinquishedArgs } from '../../../../navigatableComponent';
import { asyncScheduler } from "rxjs";
import { Observable, BehaviorSubject } from "rxjs";
import { PagedDataSource } from 'src/pagedDataSource';
import { ApplicationsPageComponent } from '../applications-page/applications-page.component';
import { PagerComponent } from '../../pager/pager.component';


@Component({
    selector: 'installed-applications-view',
    templateUrl: './installed-applications-view.component.html'
})
export class InstalledApplicationsViewComponent extends NavigatableComponent implements OnInit {
    constructor() {
        super();
        console.log(`InstalledApplicationsViewComponent constructor invoked`);
    }

    private static getAppsInfo() : Promise<tizen.ApplicationInformation[]> {
        if(typeof(tizen) !== "undefined") {
            if(tizen.application) {
                return new Promise<tizen.ApplicationInformation[]>(
                    (resolve, reject) => {
                        tizen.application.getAppsInfo(
                            resolve,
                            reject
                        );
                    }
                );
            }
        }
        return Promise.resolve<tizen.ApplicationInformation[]>([{
            id: "test",
            name: "dummy application",
            categories: [],
            iconPath: "",
            installDate: new Date(),
            packageId: "",
            show: true,
            size: 0,
            version: ""
        }]);
    }

    public readonly dataSource: PagedDataSource<tizen.ApplicationInformation> = new PagedDataSource<tizen.ApplicationInformation>();


    async ngOnInit() {
        console.log(`InstalledApplicationsViewComponent.OnInit invoked`);
        super.ngOnInit();
        this.dataSource.data =  (
            await InstalledApplicationsViewComponent
                .getAppsInfo()
        ).sort(
            (l,r) =>
                l.name < r.name
                ? -1
                : l.name > r.name ? 1 : 0
        );
    }

    private _pageView: NavigatableComponent;
    private _pager: NavigatableComponent;
    
    public focus(from: Side) {
        console.log('installed-applications-view.focus invoked');
        if(this._pageView) {
            this._pageView.focus(from);
        } else {
            console.log('installedPackagesView focus. No _pageView, so scheduling this asap');
            asyncScheduler.schedule(() => {
                this.focus(from);
            });
        }
    }

    pagerFocusRelinquished(args: FocusRelinquishedArgs) {
        if(args.fromSide === "Left") {
            this.relinquishFocus(args.fromSide);
        } else if (args.fromSide === "Bottom") {
            this._pageView.focus("Top");
        } else {
            this._pager.focus(args.fromSide);
        }
    }

    applicationsPageFocusRelinquished(args: FocusRelinquishedArgs) {
        console.log(`InstalledPackagesViewComponent. packagesPageFocusRelinqushed invoked`);
        if(args.fromSide === "Left") {
            this.relinquishFocus(args.fromSide);
        } else if (args.fromSide === "Top") {
            this._pager.focus("Bottom");
        } else {
            // Give it back again
            this._pageView.focus(args.fromSide);
        }
    }

    @ViewChild(ApplicationsPageComponent)
    public set applicationsPage(v: NavigatableComponent) {
        this._pageView = v;
    }

    @ViewChild(PagerComponent)
    public set pager(p: NavigatableComponent) {
        this._pager = p;
    }

}

