import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { LaqorrFileEntry } from 'src/fileUtils';

export class PlaylistIterator {

	private entries: LaqorrFileEntry[] = [];

	private readonly subscription: Subscription;

	private index = -1;
	constructor(private readonly fileList$: Observable<LaqorrFileEntry[]>) {
		this.subscription = this.fileList$.subscribe(
			(entries) => {
				this.entries = entries;
				this.availabilitySubject.next(!!this.entries && !!this.entries.length);
			}
		);
	}

	dispose() {
		this.subscription.unsubscribe();
	}

	private readonly availabilitySubject: Subject<boolean> = new Subject<boolean>();

	public readonly availability$ = this.availabilitySubject.pipe(distinctUntilChanged());

	public next() : LaqorrFileEntry {
		if(!this.entries) {
			return undefined;
		}
		if(!this.entries.length) {
			return undefined;
		}
		this.index = (this.index+1)%this.entries.length;
		return this.entries[this.index];
	}

	public peekNext(): LaqorrFileEntry {
		if(!this.entries) {
			return undefined;
		}
		if(!this.entries.length) {
			return undefined;
		}
		const nextIndex = (this.index+1)%this.entries.length;
		return this.entries[nextIndex];
	}
}
