import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { NavigatableComponent, Side } from 'src/navigatableComponent';

@Component({
    selector: "package-tile",
    templateUrl: "package-tile.component.html"
})
export class PackageTileComponent extends NavigatableComponent {

    @Input('package')
    package: tizen.PackageInformation;

    private hasTakenFocus: boolean = false;
    @Input('takeFocus')
    set takeFocus(v: boolean) {

        if(v !== this.hasTakenFocus) {

            this.hasTakenFocus = v;
            if(v) {
                console.log(`PackageTileComponent ${this.package.id} takeFocus(${v}). Taking focus`);
                this.focus("Unknown");
            } else {
                console.log(`PackageTileComponent ${this.package.id} takeFocus(${v}). Relinquish focus`);
                this.relinquishFocus("Unknown");
            }
        }
    }

    get takeFocus() : boolean {
        return this.hasTakenFocus;
    }

    focus(from: Side) {
        super.focus(from);
        if(this.packageHyperlink && this.packageHyperlink.nativeElement) {
            this.packageHyperlink.nativeElement.focus();
        }
    }

    @ViewChild('packageHyperlink') packageHyperlink : ElementRef;
}