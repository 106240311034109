import { ServerApi, MediaPlayerComponent, PlayerStatusAndHistory, MediaFileExecutions } from './serverApi';
import { HtmlUtils} from './htmlUtils';
import { DeviceSettings } from './deviceSettings';
import { Injectable } from '@angular/core';
import { Logger } from "./logger";
import { LogDatabase, LogData } from "./logDatabase";
import { ScreenPowerManager } from "./screenActivation/screenPowerManager";
import { ActivationInfo } from "./screenActivation/activationInfo";
import { PlayHistoryDatabase, PlayHistory } from './playHistoryDatabase';
import { VersionInfoProvider } from './versionInfoProvider';
import { LocalTimeZoneClock } from './clock';

@Injectable({
    providedIn: "root"
})
export class ServerUpdater {
    constructor(
        private readonly serverApi: ServerApi,
        private readonly deviceSettings: DeviceSettings,
        private readonly screenPowerManager: ScreenPowerManager,
        private readonly logger: Logger,
        private readonly logDatabase: LogDatabase,
        private readonly playHistoryDatabase: PlayHistoryDatabase,
        private readonly versionInfoProvider: VersionInfoProvider,
        private readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
    }

    readonly ENTRY_TOO_LARGE_ERROR_CODE = 413;

    private postLogs(): any {
        const MAX_ROWS_TO_POST = 100;
        return this.logDatabase.fetchLogs()
            .then(
                (results: {
                    entries: LogData[],
                    keys: string[]
                }) => {
                    const postLogSlice = (start: any, maxRowsToPost: any) => {
                        const logSlice = {
                            entries: results.entries.slice(start, start + maxRowsToPost),
                            keys: results.keys.slice(start, start + maxRowsToPost)
                        };
                        return () => this.postLogsToServer(logSlice);
                    }
                    let postAllLogs = Promise.resolve();
                    for (let i = 0; i < results.entries.length; i += MAX_ROWS_TO_POST) {
                        postAllLogs = postAllLogs.then(postLogSlice(i, MAX_ROWS_TO_POST));
                    }
                    return postAllLogs;
                }
            );
    }

    private postLogsToServer(allLogs:{
        entries: LogData[],
        keys: string[]
    }) : Promise<any> {
        const bundle = {
            entries: allLogs.entries.slice(),
            keys: allLogs.keys.slice()
        };

        const logBundle = {
            PlayerId: this.deviceSettings.playerSettings.deviceId,
            Entries: bundle.entries
        };

        return this.serverApi.postPlayerLogs(logBundle).toPromise()
            .then(
                () => this.logDatabase.deleteLogs(bundle.keys), 
                (e:any) => {
                if (e == this.ENTRY_TOO_LARGE_ERROR_CODE) {
                    if (bundle.entries.length === 1) {
                        return this.logDatabase.deleteLogs(bundle.keys)
                            .then(() => {
                                const newLogMessage = "Cannot send log to server, event only just one log. The first 32 characters of the log: " +
                                    bundle.entries[0].Description.substring(0, 31);
                                const logData = {
                                    CreatedDate: new Date(),
                                    EntryType: 1,
                                    Source: "ServerUpdate.js",
                                    Description: newLogMessage
                                };
                                return this.serverApi.postPlayerLogs({
                                    PlayerId: this.deviceSettings.playerSettings.deviceId,
                                    Entries: [logData]
                                }).toPromise().catch(() => {

                                });
                            }, (e: any)  => {
                                // If you couldn't send this one, just eat it up!
                                return Promise.resolve();
                            });
                    } else {
                        // Break up this bundle into two smaller bundles
                        const leftBundleSize = Math.ceil(allLogs.entries.length / 2);
                        const firstLogSet = {
                            entries: allLogs.entries.slice(0, leftBundleSize),
                            keys: allLogs.keys.slice(0, leftBundleSize)
                        };
                        const secondLogSet = {
                            entries: allLogs.entries.slice(leftBundleSize),
                            keys: allLogs.keys.slice(leftBundleSize)
                        };
                        return this.postLogsToServer(firstLogSet)
                            .then(() => {
                                if (secondLogSet.entries.length) {
                                    return this.postLogsToServer(secondLogSet);
                                }
                            });
                    }
                } else {
                    console.log("Inside the postPlayerLogs reject handler");
                    this.logger.error("index.js", "cmsServer.postPlayerLogs failed. http error code is " + e, e);
                }
            });
    }

    private static convertActivationInfoForApi(activationInfo: ActivationInfo) : PlayerStatusAndHistory["ActivationInfo"]  {
        return {
            ActivationState: activationInfo.ActivationState,
            ChangeTime: HtmlUtils.dateToJsonString(activationInfo.ChangeTime),
            ChangeTrigger: activationInfo.ChangeTrigger,
            NextChangeTime: HtmlUtils.dateToJsonString(activationInfo.NextChangeTime)
        };
    }

    private static getSystemInfoBuild() : Promise<tizen.SystemInfoBuild> {
        if (tizen && tizen.systeminfo && tizen.systeminfo.getPropertyValue) {
            return new Promise<tizen.SystemInfoBuild>(
                (resolve, reject) => {
                    tizen.systeminfo.getPropertyValue(
                        "BUILD",
                        buildInfo => resolve(buildInfo),
                        errorCallback => reject(errorCallback)
                    );
                }
            );
        } else {
            return Promise.resolve<tizen.SystemInfoBuild>({
                "buildVersion": window.navigator.userAgent,
                "manufacturer": "",
                "model": ""
            }); 
        }
    }


    isSendingUpdate: boolean = false;
    // It would be better if we did not have to include the activation info provider
    // But this is to avoid a cyclic dependency
    public async sendUpdate() {
        console.log('serverUpdater.sendUpdate');
        if(this.isSendingUpdate) {
            return;
        } 
        this.isSendingUpdate = true;
        try {
            const playerSettings = await this.deviceSettings.getPlayerSettings();
            if (!playerSettings.deviceId) {
                return;
            }
            let systemInfoBuild: tizen.SystemInfoBuild = {
                "buildVersion": window.navigator.userAgent,
                "manufacturer": "",
                "model": ""
            };
            try {
                systemInfoBuild = await ServerUpdater.getSystemInfoBuild();
            } catch (error) {
                this.logger.error('ServerUpdater', `Error when querying the system info build: ${error}`);
            }
            const writePlayHistory = this.playHistoryDatabase
                .fetchPlayHistory()
                .then(
                    async (results:PlayHistory) => {
                        const playerStatusAndHistory:PlayerStatusAndHistory = {
                            ActivationInfo: ServerUpdater.convertActivationInfoForApi(await this.screenPowerManager.getActivationInfo()),
                            PlayerId: this.deviceSettings.playerSettings.deviceId,
                            IpAddress: '',
                            FreeDiskSpace: 0,
                            DiskSpacePercentageFree: 100,
                            CurrentSoftwareVersion: (await this.versionInfoProvider.getVersionInfo()).versionNumber,
                            OsVersionString: systemInfoBuild.buildVersion,
                            Model: systemInfoBuild.model,
                            CurrentVideoPlaylist: results.VideoPlaylist,
                            CurrentAudioPlaylist: results.AudioPlaylist,
                            PlayerLocalTime: HtmlUtils.dateToJsonString(this.localTimeZoneClock.now),
                            ProcessHealth: [
                                {
                                    MediaPlayerProcess: MediaPlayerComponent.Player,
                                    IsRunning: true
                                },
                                {
                                    MediaPlayerProcess: MediaPlayerComponent.Controller,
                                    IsRunning: true
                                }
                            ],
                            ExecutionHistory: <MediaFileExecutions[]><any>results.Entries,
                            ScreenMetrics: [{
                                Width: window.screen.availWidth || window.screen.width || window.outerWidth,
                                Height: window.screen.availHeight || window.screen.height || window.outerHeight
                            }]
                        };
                        if (results.Entries.length <= 0) {
                            return this.serverApi.updateStatusAndHistory(playerStatusAndHistory).toPromise();
                        } else {
                            return this.postPlayHistoryToServer(results, playerStatusAndHistory).then(() => {
                                return this.playHistoryDatabase.deletePlayHistory(results.ExecutionKeys, []); //use to delete some executions which do not link to any media files
                            });
                        }
                    }
                );
            const writeLogs = this.postLogs();
            //slice logs to 100 rows each and post them
            return await Promise.all([writePlayHistory, writeLogs]);
        }
        finally {
            this.isSendingUpdate = false;
        }
    };

    private convertPlayerHistory(playHistory:any) {
        const result:any[]  = [];

        playHistory.forEach((entry:any) => {
            result.push({
                MediaType : entry.MediaType,
                FileName : entry.FileName,
                ExecutionTimes : entry.ExecutionTimes
            });
        });

        return result;
    }

    private postPlayHistoryToServer(playHistory:any, playerStatusAndHistory:any) : Promise<any> {
        if (playHistory.Entries.length <= 0) {
            return Promise.resolve();
        }

        playerStatusAndHistory.ExecutionHistory = this.convertPlayerHistory(playHistory.Entries);

        return this.serverApi.updateStatusAndHistory(playerStatusAndHistory).toPromise()
            .then(() => {
                const executionKeys:any[] = [];
                const mediaFileKeys:any[] = [];
                playHistory.Entries.forEach((entry:any) => {
                    executionKeys.pushAll(entry.ExecutionTimeKeys);
                    mediaFileKeys.push(entry.Id);
                });

                return this.playHistoryDatabase.deletePlayHistory(executionKeys, mediaFileKeys).then(() => {
                    return Promise.resolve();
                });
            }, (e:any) => {
                if (e === this.ENTRY_TOO_LARGE_ERROR_CODE) {
                    if (playHistory.Entries.length === 1) {

                        const executionKeys:any[] = [];
                        const mediaFileKeys:any[] = [];
                        playHistory.Entries.forEach((entry:any) => {
                            executionKeys.pushAll(entry.ExecutionTimeKeys);
                            mediaFileKeys.push(entry.Id);
                        });

                        return this.playHistoryDatabase.deletePlayHistory(executionKeys, mediaFileKeys)
                            .then(() => {
                                const newLogMessage = "Cannot send player status and history to server, event only just one play history.";
                                const logData = {
                                    CreatedDate: new Date(),
                                    EntryType: 1,
                                    Source: "ServerUpdate.js",
                                    Description: newLogMessage
                                };
                                return this.serverApi.postPlayerLogs({
                                    PlayerId: this.deviceSettings.playerSettings.deviceId,
                                    Entries: [logData]
                                }).toPromise().catch(() => {
                                    return Promise.resolve();
                                });
                            }, (e:any) => {
                                // If you couldn't send this one, just eat it up!
                                return Promise.resolve();
                            });
                    } else {
                        // Break up this bundle into two smaller bundles
                        const leftBundle: any = {};
                        const rightBundle: any = {};
                        for (let key in playHistory) {
                            leftBundle[key] = playHistory[key];
                            rightBundle[key] = playHistory[key];
                        }

                        const leftBundleSize = Math.ceil(playHistory.Entries.length / 2);
                        leftBundle.Entries = playHistory.Entries.slice(0, leftBundleSize);
                        rightBundle.Entries = playHistory.Entries.slice(leftBundleSize);

                        return this.postPlayHistoryToServer(leftBundle, playerStatusAndHistory)
                            .then(() => {
                                return this.postPlayHistoryToServer(rightBundle, playerStatusAndHistory);
                            });
                    }

                } else {
                    this.logger.error("serverUpdater.js", "cmsServer.postPlayHistory failed, error code is " + e);
                }
            });
    }
}