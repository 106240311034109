import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { asyncScheduler } from "rxjs";
import { PagedDataSource } from 'src/pagedDataSource';
import { Side, NavigatableComponent, FocusRelinquishedArgs } from '../../../../navigatableComponent';
import { PackagesPageComponent } from '../packages-page/packages-page.component';
import { PagerComponent } from '../../pager/pager.component';

const baseTizenPackageInformation:tizen.PackageInformation[] = [
    {
        id: 'com.samsung.calendar-account-manager',
        name: 'reminder notification',
        description: '',
        version: '0.1.0',
        iconPath: '',
        author: '',
        dataSize: 0,
        totalSize: 53248,
        lastModified: new Date(2019, 4, 3, 21, 56)
    },
    {
        id: 'org.tizen.ADPlayer',
        name: 'org.tizen.ADPlayer',
        description: 'org.tizen.ADPlayer',
        version: '1.2',
        iconPath: '',
        author: '',
        dataSize: 0,
        totalSize: 843776,
        lastModified: new Date(2019, 4, 3, 21, 56)
    },
    {
        id: 'FFHjklmIO',
        name: 'Laqorr',
        description: 'Laqorr Media Player',
        version: '1.2',
        iconPath: './images/laqorr_title.png',
        author: '',
        dataSize: 3421,
        totalSize: 746192,
        lastModified: new Date(2020, 5, 7, 21, 56)
    }  
]

function initializeMockTizenPackages() {
    let installedPackages: tizen.PackageInformation[] = [];
    while(installedPackages.length < 140) {
        installedPackages = [...baseTizenPackageInformation, ...installedPackages];
    }
    for(let i = 0; i < installedPackages.length; ++i) {
        let p = installedPackages[i];

        installedPackages[i] = {
            id: `${i}_${p.id}`,
            author: p.author,
            dataSize: p.dataSize,
            description: p.description,
            iconPath: p.iconPath,
            lastModified: p.lastModified,
            name: p.name,
            totalSize: p.totalSize,
            version: p.version
        }; 
    }

    const packageManager: tizen.PackageManager = {
        getPackagesInfo: (successCallback) => {
            successCallback(installedPackages);
        },
        getPackageInfo: (id) => {
            return installedPackages.find(pi => pi.id === id);
        },
        uninstall: (id, progressCallback, errorCallback) => {
            const matchingPackageIndex = installedPackages.findIndex(m => m.id === id);
            if(matchingPackageIndex >= 0) {
                console.log(`Removing package at index ${matchingPackageIndex}`)
                installedPackages.splice(matchingPackageIndex, 1);
                progressCallback.onComplete(id);
            } else {
                if(errorCallback) {
                    errorCallback({
                        code: 0,
                        message: `Can not find a package matching ${id}`,
                        name: "PackageNotFound"
                    });
                }
            }
        }
    };
    (<any>window).tizen = {
        package: packageManager
    };
}

@Component({
    selector: 'installed-packages-view',
    templateUrl: 'installed-packages-view.component.html'
})
export class InstalledPackagesViewComponent extends NavigatableComponent implements OnInit {
    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    readonly dataSource = new PagedDataSource<tizen.PackageInformation>();

    public status: string;
    public packagesAreLoaded = false;

    readonly pageSize = 16;

    public focus(from: Side) {
        if(this._packagesPage) {
            this._packagesPage.focus(from);
        } else {
            console.log('installedPackagesView focus. No _packagesPages, so scheduling this asap');
            asyncScheduler.schedule(() => {
                this.focus(from);
            });
        }
    }

    private _packagesPage: NavigatableComponent;
    private _pager: NavigatableComponent;

    public pagerFocusRelinquished(args: FocusRelinquishedArgs) {
        if(args.fromSide === "Left") {
            this.relinquishFocus(args.fromSide);
        } else if (args.fromSide === "Bottom") {
            this._packagesPage.focus("Top");
        } else {
            this._pager.focus(args.fromSide);
        }
    }

    public packagesPageFocusRelinquished(args: FocusRelinquishedArgs) {
        console.log(`InstalledPackagesViewComponent. packagesPageFocusRelinqushed invoked`);
        if(args.fromSide === "Left") {
            this.relinquishFocus(args.fromSide);
        } else if (args.fromSide === "Top") {
            this._pager.focus("Bottom");
        } else {
            // Give it back again
            this._packagesPage.focus(args.fromSide);
        }
    }

    @ViewChild(PackagesPageComponent)
    public set packagesPage(v: NavigatableComponent) {
        console.log(`set packagesPage invoked - ${v}`);
        this._packagesPage = v;
    }

    @ViewChild(PagerComponent)
    public set pager(p: NavigatableComponent) {
        this._pager = p;
    }

    ngOnInit(): void {
        super.ngOnInit();
        console.log("InstalledPackagesView ngOnInit")
        if(typeof(tizen) === "undefined") {
            initializeMockTizenPackages();
        }
        if(typeof(tizen) !== "undefined")
        {
            this.status = "Querying for tizen packages";
            tizen.package.getPackagesInfo(
                info => {
                    console.log('received package info');
                    this.status = `${info.length} packages installed`;
                    this.dataSource.data = info; // .sort((l, r) => l.name < r.name ? -1 : ((l.name === r.name) ? 0 : 1));
                    this.packagesAreLoaded = true;
                    this.changeDetectorRef.detectChanges();
                },
                error => {
                    this.status = error.message;
                    this.changeDetectorRef.detectChanges();
                }
            );
        }
        else
        {
            console.log('tizen.package is falsy');
            this.status = "The tizen object is not available";
            this.packagesAreLoaded = false;
            this.changeDetectorRef.detectChanges();
        }
    }
}