import { Rectangle, BackgroundFill, TextBlock, RenderInstructions } from "./clockFeedRenderInstructions";
import { DisplaySection } from "./clockFeedSettings";
import { DateTimeFormatter } from './dateTimeFormatter';
import { calculateFontSize } from './canvasUtils';

export function generateRenderInstructions(
    rect: Rectangle,
    clockFeedSettings: DisplaySection[],  
): RenderInstructions {
    const feedWidth = rect.width;
    const feedHeight = rect.height;
    const result: RenderInstructions = {
        backgroundFills: [],
        textBlocks: [],
        fontNameLookup: {}
    };
    let weightSum: number = 0;
    clockFeedSettings.forEach((clockFeedSetting, index) => {
        if (clockFeedSetting.sizePortion > 0) {
            weightSum += clockFeedSetting.sizePortion;
        }
    });
    let sectionTop: number = 0;

    // Make it so that all of the heights are integers
    // and that it completely fills the section
    const heights = clockFeedSettings.map(setting => feedHeight * setting.sizePortion/weightSum);    
    let t:number = 0;
    for(let i = 0; i < heights.length -1; ++i) {
        heights[i] = Math.floor(heights[i]);
        t += heights[i];
    }
    heights[heights.length-1] = Math.ceil(feedHeight - t);

    clockFeedSettings.forEach((clockFeedSetting, index) => {
        if (clockFeedSetting.sizePortion <= 0) {
            return;
        }
     
        const sectionHeight: number = heights[index];
        const backgroundRectangle: Rectangle = {
            left: 0,
            top: sectionTop,
            width: feedWidth,
            height: sectionHeight
        };
        const backgroundFill: BackgroundFill = {
            rectangle: backgroundRectangle,
            fillColor: clockFeedSetting.backgroundColor
        };
        const textRectangle: Rectangle = {
            left: clockFeedSetting.paddingLeft * feedWidth,
            top: sectionTop + clockFeedSetting.paddingTop * sectionHeight,
            width: (1.0 - clockFeedSetting.paddingLeft -clockFeedSetting.paddingRight) * feedWidth,
            height: (1.0 - clockFeedSetting.paddingTop -clockFeedSetting.paddingBottom) * sectionHeight
        }
        const textBlock: TextBlock = {
            rectangle: textRectangle,
            generateText: (d: Date) => DateTimeFormatter.format(d, clockFeedSetting.format),
            measurementText: DateTimeFormatter.constructMeasurementText(clockFeedSetting.format),
            foregroundColor: clockFeedSetting.foregroundColor,
            isBold: clockFeedSetting.font.isBold,
            isItalic: clockFeedSetting.font.isItalic,
            fontName: clockFeedSetting.font.family.name,
            horizontalAlignment: clockFeedSetting.horizontalAlignment,
            verticalAlignment: clockFeedSetting.verticalAlignment
        };
        result.backgroundFills.push(backgroundFill);
        result.textBlocks.push(textBlock);
        
        let find: boolean = false;
        for (let fontName in result.fontNameLookup) {
            if (fontName === clockFeedSetting.font.family.name) {
                find = true;
                break;
            } 
        }
        if (!find) {
            result.fontNameLookup[clockFeedSetting.font.family.name] = clockFeedSetting.font.family.fileName;
        }
        sectionTop += sectionHeight;
    });

    return result;
}

export function renderBackgroundFill(
    context: CanvasRenderingContext2D,
    backgroundFill: BackgroundFill
) {
    // display text
    context.clearRect(
        backgroundFill.rectangle.left,
        backgroundFill.rectangle.top,
        backgroundFill.rectangle.width,
        backgroundFill.rectangle.height
    );
    context.fillStyle = backgroundFill.fillColor;
    context.fillRect(
        backgroundFill.rectangle.left,
        backgroundFill.rectangle.top,
        backgroundFill.rectangle.width,
        backgroundFill.rectangle.height
    );
}

export function renderTextBlock(
    context: CanvasRenderingContext2D,
    dateTime: Date,
    textBlock: TextBlock,
    fontFamilyName: string
) {
    const bold: string = textBlock.isBold ? "bold " : "";
    const italic: string = textBlock.isItalic ? "italic " : "";
    const fontStyle: string = `${bold}${italic}`;
    const fontHeight = calculateFontSize(
        context,
        textBlock.rectangle.width,
        textBlock.rectangle.height,
        fontFamilyName,
        fontStyle,
        textBlock.measurementText
    );
    context.font = `${fontStyle}${fontHeight}px ${fontFamilyName}`;
    context.fillStyle = textBlock.foregroundColor;
    const text: string = textBlock.generateText(
        dateTime
    );
    let xPos, yPos;
    const textMetrics = context.measureText(text);
    switch (textBlock.horizontalAlignment) {
        case "left":
            context.textAlign = "left";
            xPos = textBlock.rectangle.left;
            break;
        case "right":
            context.textAlign = "right";
            xPos = textBlock.rectangle.left + textBlock.rectangle.width;
            break;
        default:
            context.textAlign = "center";
            xPos = textBlock.rectangle.left + textBlock.rectangle.width / 2.0;
            break;
    }
    switch (textBlock.verticalAlignment) {

        default:
            context.textBaseline = "middle";
            yPos = textBlock.rectangle.top + textBlock.rectangle.height / 2.0;
            break;
    }
    context.fillText(text, xPos, yPos);
}

export function renderTextAtOptimumSizeAndPosition(
    containingElement: HTMLCanvasElement,
    renderInstructions: RenderInstructions,
    index: number,
    fontFamilyName: string
) : void {
    const context: CanvasRenderingContext2D = containingElement.getContext("2d");
    renderBackgroundFill(
        context,
        renderInstructions.backgroundFills[index]
    );
    renderTextBlock(
        context,
        new Date(),
        renderInstructions.textBlocks[index],
        fontFamilyName
    );
}

export function renderToCanvas(
    context: CanvasRenderingContext2D,
    renderInstructions: RenderInstructions,
    dateTime: Date,
    fontFaceLookup: { [key:string]:string}
) {
    renderInstructions.backgroundFills.forEach(
        backgroundFill => renderBackgroundFill(context, backgroundFill)
    );                
    renderInstructions.textBlocks.forEach(
        (textBlock) => {
            renderTextBlock(
                context,
                dateTime,
                textBlock,
                fontFaceLookup[renderInstructions.fontNameLookup[textBlock.fontName]]
            );
        }
    );
}