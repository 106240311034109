import "src/extensions/dateExtensions";

export interface MediaFile {
	Name: string;
	DateStart: Date;
	DateEndExclusive: Date
}

export interface PlaylistAssignment {
	assignmentIndex: number;
	PlaylistId: string;
	DateRangeStart: Date;
    DateRangeEndExclusive: Date;
	DayOfWeekFlags: string;
	TimeOfDayStart: string;
	TimeOfDayEnd: string;
}

export interface MediaFiles {
	[key: string]: MediaFile;
}

export interface MediaFileIds {
	MediaFiles: string[];
}

export interface Playlists {
	[key: string]: MediaFileIds;
}

export interface MultiPlaylists {
	playlistAssignments: PlaylistAssignment[];
	mediaFiles: MediaFiles;
	playlists: Playlists;
}

export const EmptyMultiPlaylists : MultiPlaylists = {
    playlistAssignments: [],
    mediaFiles: {},
    playlists: {}
}

export class PlaylistReader {
    static getPlaylistFromXml(xmlDocument: Document): MultiPlaylists {
        if(!xmlDocument) {
            return EmptyMultiPlaylists;
        }
        const playlistAssignments: PlaylistAssignment[] = [];
        const mediaFiles: MediaFiles = {};
        const playlists: Playlists = {};

        const assignments = xmlDocument.getElementsByTagName('PlaylistAssignment');
        const assignmentsArray: Element[] = Array.prototype.slice.call(assignments);
        assignmentsArray.forEach((element, index) => {
            const playlistId = element.getElementsByTagName('PlaylistId')[0].textContent;

            const dateRangeStart = element.getElementsByTagName('DateRangeStart')[0].textContent;
            let dateRangeStartDateFormat:Date = null;
            if (dateRangeStart !== "") {
            	dateRangeStartDateFormat = Date.parseDateString(dateRangeStart);
            }

            const dateRangeEndExclusive = element.getElementsByTagName('DateRangeEndExclusive')[0].textContent;
            let dateRangeEndExclusiveDateFormat:Date = null;
            if (dateRangeEndExclusive !== "") {
            	dateRangeEndExclusiveDateFormat = Date.parseDateString(dateRangeEndExclusive);
            }

            const dayOfWeekFlags = element.getElementsByTagName('DayOfWeekFlags')[0].textContent;
            const timeOfDayStart = element.getElementsByTagName('TimeOfDayStart')[0].textContent;
            const timeOfDayEnd = element.getElementsByTagName('TimeOfDayEnd')[0].textContent;
            const playlistAssignment = { assignmentIndex: index, PlaylistId: playlistId, DateRangeStart: dateRangeStartDateFormat, DateRangeEndExclusive: dateRangeEndExclusiveDateFormat, DayOfWeekFlags: dayOfWeekFlags, TimeOfDayStart: timeOfDayStart, TimeOfDayEnd: timeOfDayEnd };

            playlistAssignments.push(playlistAssignment);
        });

        const playlist = xmlDocument.getElementsByTagName('Playlist');
        const playlistArray: Element[] = Array.prototype.slice.call(playlist);
        playlistArray.forEach((element, index) => {
            const id = element.getElementsByTagName('Id')[0].textContent;
            const mediaFiles = element.getElementsByTagName('MediaFiles')[0];

            const mediaFileIdArray:string[] = [];

            const fileIds = mediaFiles.getElementsByTagName('MediaFileId');
            const fileIdsArray: Element[] = Array.prototype.slice.call(fileIds);
            fileIdsArray.forEach((element, index) => {
                mediaFileIdArray.push(element.textContent);
            });
            playlists[id] = { MediaFiles: mediaFileIdArray };
        });

        const files = xmlDocument.getElementsByTagName('MediaFile');
        const filesArray: Element[] = Array.prototype.slice.call(files);
        filesArray.forEach((element, index) => {
            const id = element.getElementsByTagName('Id')[0].textContent;
            const name = element.getElementsByTagName('Name')[0].textContent;
            const dateStart = element.getElementsByTagName('DateStart')[0].textContent;
            let dateStartDateFormat:Date = null;
            if (dateStart !== "") {
            	dateStartDateFormat = Date.parseDateString(dateStart);
            }

            const dateEndExclusive = element.getElementsByTagName('DateEndExclusive')[0].textContent;
            let dateEndExclusiveDateFormat:Date = null;
            if (dateEndExclusive !== "") {
            	dateEndExclusiveDateFormat = Date.parseDateString(dateEndExclusive);
            }

            mediaFiles[id] = { Name: name, DateStart: dateStartDateFormat, DateEndExclusive: dateEndExclusiveDateFormat };
        });
        return {
            playlistAssignments: playlistAssignments,
            mediaFiles: mediaFiles,
            playlists: playlists
        };
    }
}