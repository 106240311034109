import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DeviceSettings } from './deviceSettings';
import { HtmlUtils } from './htmlUtils';
import { DeviceReboot } from './deviceReboot';
import { LocalTimeZoneClock } from './clock';


@Injectable({
    providedIn: "root"
})
export class DailyReboot {
    private timerHandle: NodeJS.Timer = null;
    private enableDailyReboot: boolean;
    private dailyRebootTime: string;

    constructor (
        private readonly deviceSettings: DeviceSettings,
        private readonly deviceReboot: DeviceReboot,
        private readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
        this.listenToDailyRebootSettings();
        this.listenForLocalTimeZoneClockLeaps();
    }

    private listenToDailyRebootSettings() {
        this.deviceSettings.playerSettingsObservable
            .pipe(
                filter(playerSettings => playerSettings.dailyReboot != this.enableDailyReboot || playerSettings.dailyRebootTime != this.dailyRebootTime)
            ).subscribe({
                next:  (playerSettings) => {
                    this.enableDailyReboot = playerSettings.dailyReboot;
                    this.dailyRebootTime = playerSettings.dailyRebootTime;
                    if (this.enableDailyReboot) {
                        this.setRebootTime();
                    } else {
                        this.clearRebootTime();
                    }
                }
            });
    }

    private listenForLocalTimeZoneClockLeaps() {
        this.localTimeZoneClock.$timeLeap.subscribe({
           next: () => {
                if (this.enableDailyReboot) {
                    this.setRebootTime();
                } else {
                    this.clearRebootTime();
                }
            }
        });
    }

    private setRebootTime() {
        this.clearRebootTime();

        let time = HtmlUtils.getTime(this.dailyRebootTime);
        if (time) {
            let dateNow = this.localTimeZoneClock.now;
            dateNow.setHours(dateNow.getHours(), dateNow.getMinutes(), 0, 0);

            const different = time.getTime() - dateNow.getTime();
            console.log("different is " + different);

            const currentRebootTime = time;

            if (different <= 0) {
                time.setDate(time.getDate() + 1);
            }

            console.log("current reboot time is " + currentRebootTime.toString());
            const actualDifference = time.getTime() - this.localTimeZoneClock.now.getTime();
            this.timerHandle = setTimeout(
                () => this.deviceReboot.run(),
                actualDifference
            );
        }
    }

    private clearRebootTime() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
    }

    public async start() {
        try {
            const playerSettings = await this.deviceSettings.getPlayerSettings();
            this.enableDailyReboot = playerSettings.dailyReboot;
            this.dailyRebootTime = playerSettings.dailyRebootTime;
            if (this.enableDailyReboot) {
                this.setRebootTime();
            }
        } catch(error) {
            const newErrorMessage = `Exception thrown in deailyReboot.start: ${error}`;
            const newError = new Error(newErrorMessage);
            newError.name = error.name;
            console.log(newError);
        }

    }
}
