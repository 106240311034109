import { Component, ElementRef, ViewChild } from '@angular/core';
import { EnrolmentClient } from 'src/eneter/enrolment/enrolmentClient';
import { NavigatableComponent, Side } from 'src/navigatableComponent';

type EnrolmentClientViewState = "default"| "unEnrolConfirmation";

function hasFocus(elementRef: ElementRef<HTMLButtonElement>) {
    if(!elementRef) {
        return false;
    }
    return document.activeElement === elementRef.nativeElement;
}

@Component({
    selector: 'enrolment-client-view',
    templateUrl: './enrolment-client-view.component.html',
    styleUrls: ["./enrolment-client-view.component.scss"]
})
export class EnrolmentClientViewComponent extends NavigatableComponent {

    state: EnrolmentClientViewState = "default";

    constructor(
        readonly enrolmentClient: EnrolmentClient
    ) {
        super();
    }

    checkForFocusedElements(from:Side) {
        if(this.hasFocus) {
            switch(this.state) {
                case 'default':
                    if(this._enrolButton) {
                        this._enrolButton.nativeElement.focus();
                    } else if (this._unenrolButton) {
                        this._unenrolButton.nativeElement.focus();
                    }
                    break;
                case 'unEnrolConfirmation':
                    if(! (!!this._cancelUnenrolButton && !!this._confirmUnenrolButton)) {
                        return;
                    }
                    if(
                        hasFocus(this._cancelUnenrolButton) || hasFocus(this._confirmUnenrolButton)
                    ) {
                        break;
                    }
                    if((from === "Left") && (this._confirmUnenrolButton)) {
                        this._confirmUnenrolButton.nativeElement.focus();
                    } else if(this._cancelUnenrolButton) {
                        this._cancelUnenrolButton.nativeElement.focus();
                    } else if (this._confirmUnenrolButton) {
                        this._confirmUnenrolButton.nativeElement.focus();
                    }
            }
        }
    }

    moveFocusLeft() {
        if((this.state === 'unEnrolConfirmation') && hasFocus(this._cancelUnenrolButton)) {
            this._confirmUnenrolButton.nativeElement.focus();
            return true;
        }
        else {
            return super.moveFocusLeft();
        }
    }

    moveFocusRight() {
        if((this.state === 'unEnrolConfirmation') && hasFocus(this._confirmUnenrolButton)) {
            this._cancelUnenrolButton.nativeElement.focus();
            return true;
        }
        else {
            return super.moveFocusRight();
        }
    }
    

    focus(from: Side) {
        super.focus(from);
        this.checkForFocusedElements(from);
    }

    beginEnrolment() {
        this.enrolmentClient.beginEnrolment();
    }

    async unenrol() {
        this.state = "unEnrolConfirmation";
    }

    async confirmUnenrol() {
        this.state = "default";
        this.enrolmentClient.unenrol();
    }

    async cancelUnenrol() {
        this.state = "default";
    }

    private _enrolButton: ElementRef<HTMLButtonElement>;
    @ViewChild('enrolButton') set enrolButton(value: ElementRef<HTMLButtonElement>) {
        console.log('enrolment-client-view component enrolButton setter');
        this._enrolButton = value;
        this.checkForFocusedElements("Unknown");
    }
    private _unenrolButton: ElementRef<HTMLButtonElement>;
    @ViewChild('unenrolButton') set unenrolButton(value: ElementRef<HTMLButtonElement>) {
        console.log('enrolment-client-view component unenrolButton setter');
        this._unenrolButton = value;
        this.checkForFocusedElements("Unknown");
    }

    private _confirmUnenrolButton: ElementRef<HTMLButtonElement>;
    @ViewChild('confirmUnenrolButton') set confirmUnenrolButton(value: ElementRef<HTMLButtonElement>) {
        console.log('enrolment-client-view component confirmUnenrolButton setter');
        this._confirmUnenrolButton = value;
        this.checkForFocusedElements("Unknown");
    }

    private _cancelUnenrolButton: ElementRef<HTMLButtonElement>;
    @ViewChild('cancelUnenrolButton') set cancelUnenrolButton(value: ElementRef<HTMLButtonElement>) {
        console.log('enrolment-client-view component cancelUnenrolButton setter');
        this._cancelUnenrolButton = value;
        this.checkForFocusedElements("Unknown");
    }   
}