import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NavigatableComponent, Side } from 'src/navigatableComponent';
import { asyncScheduler } from "rxjs";


@Component({
    templateUrl: 'package-view.component.html',
    selector: 'package-view'
})
export class PackageViewComponent extends NavigatableComponent implements OnInit {
    constructor(private route: ActivatedRoute, private readonly location: Location) {
        super();
    }

    id: string;

    package: tizen.PackageInformation;

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.package = tizen.package.getPackageInfo(this.id);
    }

    focusOnUninstallButton() {
        if(this.uninstallButton && this.uninstallButton.nativeElement) {
            this.uninstallButton.nativeElement.focus();
        } else {
            asyncScheduler.schedule(() => this.focusOnUninstallButton());
        }
    }

    focus(from: Side) {
        super.focus(from);
        this.focusOnUninstallButton();
    }

    showUninstallProgress: boolean;
    uninstallProgress:number = 0;
    uninstallError: string;

    uninstallPackage() {
        console.log('Uninstall package invoked');
        this.showUninstallProgress = true;
        try {
            tizen.package.uninstall(
                this.id,
                {
                    onComplete: (id) => {
                        this.location.back();
                    },
                    onProgress: (id, v) => {
                        this.uninstallProgress = v;
                    }
                },
                (webApiError) => {
                    this.uninstallError = webApiError.message;
                }
            );
        } catch(exception) {
            if(typeof(exception) === 'string') {
                this.uninstallError = exception;
            } else if (typeof(exception.message) === 'string') {
                this.uninstallError = exception.message;
            } else {
                this.uninstallError = "An exception was thrown when attempting to uninstall";
            }
        }

    }

    @ViewChild('btnUninstall') uninstallButton : ElementRef;
}