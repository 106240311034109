import { map } from 'rxjs/operators';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { fileManager } from 'src/fileManager';

const FAILSAFE_PLAYLIST_FILENAME = "FailSafe.Playlist.xml";

function failSafePlaylistPath() {
    return "/" + fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VideoPlaylist) + "/" + FAILSAFE_PLAYLIST_FILENAME;
}

function readFailSafePlaylist(xmlText: string): string[] {
    if(!xmlText) {
        return [];
    }
    if(xmlText == '') {
        return [];
    }
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");
    const playlistFiles = xmlDoc.getElementsByTagName('PlaylistFile');
    const filesArray: Element[] = Array.prototype.slice.call(playlistFiles);
    const playlist: string[] = [];
    filesArray.forEach((element: Element, index: number) => {
        const name = element.getElementsByTagName('Name')[0].textContent;
        const md5 = element.getElementsByTagName('MD5')[0].textContent;

        const file = { Name: name, MD5: md5 };
        playlist.push(name);
    });
    console.log(`failsafePlaylistManager.getFailSafePlaylist length=${playlist.length}`);
    return playlist;
}

export function getMediaFileNamesOfFailsafePlaylist(mediaPlayerFileSystem: MediaPlayerFileSystem) {
    return mediaPlayerFileSystem.watchTextContent(
        failSafePlaylistPath()
    ).pipe(
        map(readFailSafePlaylist)
    );
}