import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { EnrolmentClient } from 'src/eneter/enrolment/enrolmentClient';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: "./root.component.html"
}) 
export class RootComponent implements OnInit {
    constructor(readonly router: Router, readonly enrolmentClient : EnrolmentClient) {
        console.log('RootComponent constructor');
    }

    async ngOnInit() {
        console.log('RootComponetn on init');

    }
}
