import { Subscription, timer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { EmptyMultiPlaylists, MultiPlaylists, PlaylistReader, PlaylistAssignment } from './playlistReader';
import { ContentScheduler } from './contentScheduler';
import { LocalTimeZoneClock } from '../../../../clock';

interface ActivePlaylistEntry {
    playlistId: string;
    applicableDateTime: Date;
}

const NoPlaylist : ActivePlaylistEntry = {
    playlistId: '',
    applicableDateTime: null
};

function getCurrentPlaylistFromMultiplaylist(multiPlaylist: MultiPlaylists, currentDateTime: Date) : { playlistId: string, nextCheckTime: Date } {
    const dateNowMiddleNight = new Date(currentDateTime);
    dateNowMiddleNight.setHours(0);
    dateNowMiddleNight.setMinutes(0);
    dateNowMiddleNight.setSeconds(0);

    const endNowMiddleNight = new Date(dateNowMiddleNight);
    endNowMiddleNight.setDate(dateNowMiddleNight.getDate() + 1);

    const realTimeNow = new Date(currentDateTime);
    let nextTimeToCheckWhenNoPlayContent = new Date(endNowMiddleNight);

    let currentAssignment: {
        playlistAssignment: PlaylistAssignment,
        nextTimeToCheck: Date
    };
    const playlistContentSchdule = ContentScheduler.generateSchedule(
        dateNowMiddleNight,
        endNowMiddleNight,
        multiPlaylist.playlistAssignments
    );
    for (let index = 0; index < playlistContentSchdule.length; index++) {
        const startTime = playlistContentSchdule[index].StartDateTime;
        const endTime = playlistContentSchdule[index].EndDateTime;

        if (endTime > realTimeNow && (startTime < realTimeNow || startTime.getTime() === realTimeNow.getTime())) {
            currentAssignment = {
                playlistAssignment: <PlaylistAssignment>playlistContentSchdule[index].Assignment,
                nextTimeToCheck: playlistContentSchdule[index].EndDateTime
            };
            break;
        }

        if (nextTimeToCheckWhenNoPlayContent > startTime && startTime > realTimeNow) {
            nextTimeToCheckWhenNoPlayContent =startTime;
        }
    }
    if (currentAssignment) {
        return {
            playlistId: currentAssignment.playlistAssignment.PlaylistId,
            nextCheckTime: currentAssignment.nextTimeToCheck
        };
    } else {
        return {
            playlistId: undefined,
            nextCheckTime: nextTimeToCheckWhenNoPlayContent
        }
    }
}

function watchForCurrentPlaylist(multiPlaylist: MultiPlaylists, localTimeZoneClock: LocalTimeZoneClock) : Observable<ActivePlaylistEntry> {
    return new Observable<ActivePlaylistEntry>(
        subscriber => {
            let timerSubscription: Subscription;

            function performCheck(dateTime: Date):void {
                console.log(`watchForCurrentPlaylist: now = ${dateTime}`)
                const result = getCurrentPlaylistFromMultiplaylist(multiPlaylist, dateTime);               
                const playlistEntry:ActivePlaylistEntry = result.playlistId
                    ? { playlistId : result.playlistId, applicableDateTime : dateTime }
                    : NoPlaylist;
                if(timerSubscription) {
                    timerSubscription.unsubscribe();
                }
                timerSubscription = timer(result.nextCheckTime.getTime() - localTimeZoneClock.now.getTime())
                    .subscribe(
                        () => performCheck(result.nextCheckTime)
                    );   
                subscriber.next(playlistEntry);
            }
            const localTimeZoneLeapSubscription: Subscription = localTimeZoneClock.$timeLeap.subscribe(
                () => performCheck(localTimeZoneClock.now)
            );
            performCheck(localTimeZoneClock.now);
            return {
                unsubscribe: () => {
                    if(timerSubscription) {
                        timerSubscription.unsubscribe();
                    }
                    if (localTimeZoneLeapSubscription) {
                        localTimeZoneLeapSubscription.unsubscribe();
                    }
                }
            }
        }
    );
}

function readMultiPlaylistFromXmlText(xmlText: string) : MultiPlaylists {
    if(!xmlText) {
        return EmptyMultiPlaylists;
    }
    if(xmlText == '') {
        return EmptyMultiPlaylists;
    }
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");
    return PlaylistReader.getPlaylistFromXml(xmlDoc);
}

// We could probably think of a better name for this interface
interface ScheduledPlaylistResult extends ActivePlaylistEntry {
    multiPlaylist: MultiPlaylists;
}

function getMediaFilesFromPlaylistPlayableOnDate(scheduledPlaylistResult: ScheduledPlaylistResult):string[] {
    if(!scheduledPlaylistResult) {
        return [];
    }
    const { playlistId, applicableDateTime, multiPlaylist } = scheduledPlaylistResult;
    if(!playlistId) {
        return [];
    }
    const referencedPlaylist = multiPlaylist.playlists[playlistId];
    if(!referencedPlaylist) {
        return [];
    }
    const mediaFileNames:string[] = [];
    for (const mediaFileId of referencedPlaylist.MediaFiles) {
        if (mediaFileId) {
            const mediaFile = multiPlaylist.mediaFiles[mediaFileId];
            if (mediaFile) {
                if ((mediaFile.DateEndExclusive !== null && mediaFile.DateEndExclusive < applicableDateTime) || (mediaFile.DateStart !== null && mediaFile.DateStart > applicableDateTime)) {
                    continue;
                }
                mediaFileNames.push(mediaFile.Name);
            }
        }
    }
    return mediaFileNames;
}

// An OperatorFunction, which can be passed into a pipe
function getScheduledPlaylist(localTimeZoneClock: LocalTimeZoneClock, multiPlaylists$: Observable<MultiPlaylists>) {
    return new Observable<ScheduledPlaylistResult>(
        (observer) => {
            let currentPlaylistSubscription:Subscription;
            const multiPlaylistSubscription = multiPlaylists$.subscribe({
                next: (multiPlaylist) => {
                    if(currentPlaylistSubscription) {
                        currentPlaylistSubscription.unsubscribe();
                    }
                    currentPlaylistSubscription = watchForCurrentPlaylist(multiPlaylist, localTimeZoneClock)
                        .subscribe({
                            next: (activePlaylist) => observer.next({
                                multiPlaylist: multiPlaylist,
                                applicableDateTime: activePlaylist.applicableDateTime,
                                playlistId: activePlaylist.playlistId
                            })
                        });
                }
            });
            return {
                unsubscribe: () => {
                    if(currentPlaylistSubscription) {
                        currentPlaylistSubscription.unsubscribe();
                    }
                    multiPlaylistSubscription.unsubscribe();  
                }
            };
        }
    );
}

export function getMediaFileNamesOfCurrentPlaylist(
    multiPlaylistPath : string,
    mediaPlayerFileSystem: MediaPlayerFileSystem,
    localTimeZoneClock: LocalTimeZoneClock
) {
    return mediaPlayerFileSystem.watchTextContent(multiPlaylistPath)
        .pipe(
            map(readMultiPlaylistFromXmlText),
            (multiPlaylist: Observable<MultiPlaylists>) => getScheduledPlaylist(localTimeZoneClock, multiPlaylist),
            map(getMediaFilesFromPlaylistPlayableOnDate) 
        );
}
