import * as LaqorrProtobuf from '../laqorrProtobuf';
import { ProtobufSerializer } from '../serializeProtobufferMessage';
import { ActivationInfo } from '../screenActivation/activationInfo';

export const Serializers = {
    Message : new ProtobufSerializer<LaqorrProtobuf.Message>(
        './protoBuf/Message.proto',
        'Message'
    ),
    PublicKey : new ProtobufSerializer<LaqorrProtobuf.PublicKey>(
        './protoBuf/PublicKey.proto',
        'PublicKey'
    ),
    Enrolment : {
        RequestContents: new ProtobufSerializer<LaqorrProtobuf.Enrolment.RequestContents>(
            './protoBuf/Enrolment/RequestContents.proto',
            'RequestContents'
        ),
        InitialRequest: new ProtobufSerializer<LaqorrProtobuf.Enrolment.InitialRequest>(
            './protoBuf/Enrolment/InitialRequest.proto',
            'InitialRequest'
        ),
        ResponseContents : new ProtobufSerializer<LaqorrProtobuf.Enrolment.ResponseContents>(
            './protoBuf/Enrolment/ResponseContents.proto',
            'ResponseContents'
        ),
        InitialResponse: new ProtobufSerializer<LaqorrProtobuf.Enrolment.InitialResponse>(
            './protoBuf/Enrolment/InitialResponse.proto',
            'InitialResponse'
        ),
        EnrolmentConfirmation: new ProtobufSerializer<LaqorrProtobuf.Enrolment.EnrolmentConfirmation>(
            './protoBuf/Enrolment/EnrolmentConfirmation.proto',
            'EnrolmentConfirmation'
        ),
        ConfirmationReceipt: new ProtobufSerializer<LaqorrProtobuf.Enrolment.ConfirmationReceipt>(
            './protoBuf/Enrolment/ConfirmationReceipt.proto',
            'ConfirmationReceipt'
        )
    },
    Session : {
        SessionRequest: new ProtobufSerializer<LaqorrProtobuf.Session.SessionRequest>(
            './protoBuf/Session/SessionRequest.proto',
            'SessionRequest'
        ),
        InitiateSession: new ProtobufSerializer<LaqorrProtobuf.Session.InitiateSession>(
            './protoBuf/Session/InitiateSession.proto',
            'InitiateSession'           
        ),
        SessionAcceptance: new ProtobufSerializer<LaqorrProtobuf.Session.SessionAcceptance>(
            './protoBuf/Session/SessionAcceptance.proto',
            'SessionAcceptance'
        ),
        SessionAcceptanceContents: new ProtobufSerializer<LaqorrProtobuf.Session.SessionAcceptanceContents>(
            './protoBuf/Session/SessionAcceptanceContents.proto',
            "SessionAcceptanceContents"
        ),
        SessionRejection: new ProtobufSerializer<LaqorrProtobuf.Session.SessionRejection>(
            './protoBuf/Session/SessionRejection.proto',
            'SessionRejection'
        ),
        SessionResponse: new ProtobufSerializer<LaqorrProtobuf.Session.SessionResponse>(
            './protoBuf/Session/SessionResponse.proto',
            'SessionResponse'
        )
    },
    MethodCall : {
        MethodInvocation: new ProtobufSerializer<LaqorrProtobuf.MethodCall.MethodInvocation>(
            './protoBuf/MethodCall/MethodInvocation.proto',
            'MethodInvocation'
        ),
        MethodResponse: new ProtobufSerializer<LaqorrProtobuf.MethodCall.MethodResponse>(
            './protoBuf/MethodCall/MethodResponse.proto',
            'MethodResponse'
        )
    },
    ClientInterface: {
        PlayerSettings: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.PlayerSettings>(
            './protoBuf/ClientInterface/PlayerSettings.proto',
            'PlayerSettings'
        ),
        DownloadParameters: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.DownloadParameters>(
            './protoBuf/ClientInterface/FileDownloadClient.proto',
            'DownloadParameters'
        ),
        ReceiveBytesParameters: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.ReceiveBytesParameters>(
            './protoBuf/ClientInterface/FileDownloadClient.proto',
            "ReceiveBytesParameters"
        ),
        QueryPath: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.QueryPath>(
            './protoBuf/ClientInterface/FolderQueryResults.proto',
            'QueryPath'
        ),
        FolderQueryResults: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.FolderQueryResults>(
            './protoBuf/ClientInterface/FolderQueryResults.proto',
            'FolderQueryResults'
        ),
        DeleteFileOrFolderParameter: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.DeleteFileOrFolderParameter>(
            './protoBuf/ClientInterface/FolderQueryResults.proto',
            'DeleteFileOrFolderParameter'
        ),
        ActivationInfo: new ProtobufSerializer<LaqorrProtobuf.ClientInterface.ActivationInfo>(
             './protoBuf/ClientInterface/ActivationInfo.proto',
            'ActivationInfo'
        )
    }
};