// This was originally copied from here: http://sourcebox.io/39cc83f38263adee5bc655f11fbcefab/html
// Since then it has been modified slightly to handle Uin8Arrays as well as strings


     export var md5 = (function(){
        	"use strict";
        
        	/*
        	 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
        	 * Digest Algorithm, as defined in RFC 1321.
        	 * Copyright (C) Paul Johnston 1999 - 2000.
        	 * Updated by Greg Holt 2000 - 2001.
        	 * See http://pajhome.org.uk/site/legal.html for details.
        	 */
        
        	/*
        	 * Convert a 32-bit number to a hex string with ls-byte first
        	 */
			var hex_chr = "0123456789abcdef";
			function rhex(num: number)
        	{
        		var str:string = "", j:number;
        		for(j = 0; j <= 3; j++)
        			str += hex_chr.charAt((num >> (j * 8 + 4)) & 0x0F) +
        			hex_chr.charAt((num >> (j * 8)) & 0x0F);
        		return str;
        	}
        
        	/*
        	 * Convert a string to a sequence of 16-word blocks, stored as an array.
        	 * Append padding bits and the length, as described in the MD5 standard.
        	 */
        	function str2blks_MD5(str:any)
        	{
                var getValueAt:(n:number) => number;
                if(str instanceof ArrayBuffer) {
                    str = new Uint8Array(str);
                }
                if(str instanceof Uint8Array) {
                    getValueAt = function(n:number) {
                        return str[n];
                    };
                } else if (str.charCodeAt) {
                    getValueAt = function(n:number) {
                        return str.charCodeAt(n);
                    };
                } else {
                    throw new TypeError("The md5 function only accepts inputs of type ArrayBuffer, Uint8Array or string");
                }
                
        	    var nblk = ((str.length + 8) >> 6) + 1, // str.length * 8 / 512 + 1  . divide to 512 bits per block
        			blks = new Array(nblk * 16),        // 16 32-bits blocks
        			i:number;
        		for (i = 0; i < nblk * 16; i++) {       // set to 0
        		    blks[i] = 0;
        		}

        		for (i = 0; i < str.length; i++) {
        		    blks[i >> 2] |= getValueAt(i) << ((i % 4) * 8);
        		}

        		var dd = i >> 2;
        		var fff = 0x80 << ((i % 4) * 8);
        		blks[i >> 2] |= 0x80 << ((i % 4) * 8);
        		blks[nblk * 16 - 2] = str.length * 8;
        		return blks;
        	}
        
        	/*
        	 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
        	 * to work around bugs in some JS interpreters.
        	 */
        	function add(x:number, y:number)
        	{
        		var lsw = (x & 0xFFFF) + (y & 0xFFFF);
        		var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        		return (msw << 16) | (lsw & 0xFFFF);
        	}
        
        	/*
        	 * Bitwise rotate a 32-bit number to the left
        	 */
			function rol(num: number, cnt:number)
        	{
        		return (num << cnt) | (num >>> (32 - cnt));
        	}
        
        	/*
        	 * These functions implement the basic operation for each round of the
        	 * algorithm.
        	 */
			function cmn(q: number, a: number, b: number, x: number, s: number, t: number)
        	{
        		return add(rol(add(add(a, q), add(x, t)), s), b);
        	}
			function ff(a: number, b: number, c: number, d: number, x: number, s: number, t: number)
        	{
        		return cmn((b & c) | ((~b) & d), a, b, x, s, t);
        	}
			function gg(a: number, b: number, c: number, d: number, x: number, s: number, t: number)
        	{
        		return cmn((b & d) | (c & (~d)), a, b, x, s, t);
        	}
			function hh(a: number, b: number, c: number, d: number, x: number, s: number, t: number)
        	{
        		return cmn(b ^ c ^ d, a, b, x, s, t);
        	}
			function ii(a: number, b: number, c: number, d: number, x: number, s: number, t: number)
        	{
        		return cmn(c ^ (b | (~d)), a, b, x, s, t);
        	}

        	/*
        	 * Take a string and return the hex representation of its MD5.
        	 */
        	function calcMD5(str:any)
        	{
        	    var x = str2blks_MD5(str);
        		var a =  1732584193;
        		var b = -271733879;
        		var c = -1732584194;
        		var d =  271733878;
        
        		var i:number, olda:number, oldb:number, oldc:number, oldd:number;

        		var forEachSixteenElements = function (f:any) {
        		    for (var i = 0; i < x.length; i += 16) {
        		        var chunk = x.slice(i, i + 16);
        		        f(chunk);
        		    }
        		};
        

        		var processChunk = function (chunk:number[]) {
        		    olda = a;
        		    oldb = b;
        		    oldc = c;
        		    oldd = d;

        		    a = ff(a, b, c, d, chunk[0], 7, -680876936);
        		    d = ff(d, a, b, c, chunk[1], 12, -389564586);
        		    c = ff(c, d, a, b, chunk[2], 17, 606105819);
        		    b = ff(b, c, d, a, chunk[3], 22, -1044525330);
        		    a = ff(a, b, c, d, chunk[4], 7, -176418897);
        		    d = ff(d, a, b, c, chunk[5], 12, 1200080426);
        		    c = ff(c, d, a, b, chunk[6], 17, -1473231341);
        		    b = ff(b, c, d, a, chunk[7], 22, -45705983);
        		    a = ff(a, b, c, d, chunk[8], 7, 1770035416);
        		    d = ff(d, a, b, c, chunk[9], 12, -1958414417);
        		    c = ff(c, d, a, b, chunk[10], 17, -42063);
        		    b = ff(b, c, d, a, chunk[11], 22, -1990404162);
        		    a = ff(a, b, c, d, chunk[12], 7, 1804603682);
        		    d = ff(d, a, b, c, chunk[13], 12, -40341101);
        		    c = ff(c, d, a, b, chunk[14], 17, -1502002290);
        		    b = ff(b, c, d, a, chunk[15], 22, 1236535329);

        		    a = gg(a, b, c, d, chunk[1], 5, -165796510);
        		    d = gg(d, a, b, c, chunk[6], 9, -1069501632);
        		    c = gg(c, d, a, b, chunk[11], 14, 643717713);
        		    b = gg(b, c, d, a, chunk[0], 20, -373897302);
        		    a = gg(a, b, c, d, chunk[5], 5, -701558691);
        		    d = gg(d, a, b, c, chunk[10], 9, 38016083);
        		    c = gg(c, d, a, b, chunk[15], 14, -660478335);
        		    b = gg(b, c, d, a, chunk[4], 20, -405537848);
        		    a = gg(a, b, c, d, chunk[9], 5, 568446438);
        		    d = gg(d, a, b, c, chunk[14], 9, -1019803690);
        		    c = gg(c, d, a, b, chunk[3], 14, -187363961);
        		    b = gg(b, c, d, a, chunk[8], 20, 1163531501);
        		    a = gg(a, b, c, d, chunk[13], 5, -1444681467);
        		    d = gg(d, a, b, c, chunk[2], 9, -51403784);
        		    c = gg(c, d, a, b, chunk[7], 14, 1735328473);
        		    b = gg(b, c, d, a, chunk[12], 20, -1926607734);

        		    a = hh(a, b, c, d, chunk[5], 4, -378558);
        		    d = hh(d, a, b, c, chunk[8], 11, -2022574463);
        		    c = hh(c, d, a, b, chunk[11], 16, 1839030562);
        		    b = hh(b, c, d, a, chunk[14], 23, -35309556);
        		    a = hh(a, b, c, d, chunk[1], 4, -1530992060);
        		    d = hh(d, a, b, c, chunk[4], 11, 1272893353);
        		    c = hh(c, d, a, b, chunk[7], 16, -155497632);
        		    b = hh(b, c, d, a, chunk[10], 23, -1094730640);
        		    a = hh(a, b, c, d, chunk[13], 4, 681279174);
        		    d = hh(d, a, b, c, chunk[0], 11, -358537222);
        		    c = hh(c, d, a, b, chunk[3], 16, -722521979);
        		    b = hh(b, c, d, a, chunk[6], 23, 76029189);
        		    a = hh(a, b, c, d, chunk[9], 4, -640364487);
        		    d = hh(d, a, b, c, chunk[12], 11, -421815835);
        		    c = hh(c, d, a, b, chunk[15], 16, 530742520);
        		    b = hh(b, c, d, a, chunk[2], 23, -995338651);

        		    a = ii(a, b, c, d, chunk[0], 6, -198630844);
        		    d = ii(d, a, b, c, chunk[7], 10, 1126891415);
        		    c = ii(c, d, a, b, chunk[14], 15, -1416354905);
        		    b = ii(b, c, d, a, chunk[5], 21, -57434055);
        		    a = ii(a, b, c, d, chunk[12], 6, 1700485571);
        		    d = ii(d, a, b, c, chunk[3], 10, -1894986606);
        		    c = ii(c, d, a, b, chunk[10], 15, -1051523);
        		    b = ii(b, c, d, a, chunk[1], 21, -2054922799);
        		    a = ii(a, b, c, d, chunk[8], 6, 1873313359);
        		    d = ii(d, a, b, c, chunk[15], 10, -30611744);
        		    c = ii(c, d, a, b, chunk[6], 15, -1560198380);
        		    b = ii(b, c, d, a, chunk[13], 21, 1309151649);
        		    a = ii(a, b, c, d, chunk[4], 6, -145523070);
        		    d = ii(d, a, b, c, chunk[11], 10, -1120210379);
        		    c = ii(c, d, a, b, chunk[2], 15, 718787259);
        		    b = ii(b, c, d, a, chunk[9], 21, -343485551);

        		    a = add(a, olda);
        		    b = add(b, oldb);
        		    c = add(c, oldc);
        		    d = add(d, oldd);
        		};
                
        		forEachSixteenElements(processChunk);

        		return rhex(a) + rhex(b) + rhex(c) + rhex(d);
        	}
        
        	return calcMD5;
        })();