import { Subscription, timer, Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { ContentScheduler } from '../video-feed/content/contentScheduler';
import { RssFeedGetter } from './rssGetter';
import { LocalTimeZoneClock } from 'src/clock';

interface FontFamily {
    name: string;
    fileName: string;
}

interface TextStyle {
    fontWeight: string;
    fontStyle: string;
    color: string;
    fontSize: string;
}

export interface Resource {
    key: string;
    textStyle: TextStyle;
    fontFamily: FontFamily;
}

interface PanelObject {
    contentType : string;
    rssUrl: string;
    defaultImageFile: string;
    backgroundColor: string;
    items: any[];
}

export interface TickerDisplay {
    tickerDisplayBackground: string;
    textStyleRef: string;
    tickerSpeed: string;
    leftPanel: PanelObject;
    mainPanel: PanelObject;
    rightPanel: PanelObject;
}

export interface TickerDisplayAssignment {
    assignmentIndex: number;
    DateRangeStart: Date;
    DateRangeEndExclusive: Date;
    DayOfWeekFlags: string;
    TimeOfDayStart: string;
    TimeOfDayEnd: string;
    tickerDisplay: TickerDisplay;
}

interface TickerContent {
    resources: Resource[];
    tickerDisplayAssignments: TickerDisplayAssignment[];
}

export interface ScheduledTickerResult {
    resources: Resource[];
    tickerDisplayAssignment: TickerDisplayAssignment;
}

function getCurrentTickerFromTickerAssignments(tickerDisplayAssignments: TickerDisplayAssignment[], currentDateTime: Date) : { assignment: TickerDisplayAssignment, nextCheckTime: Date } {
    const dateNowMiddleNight = new Date(currentDateTime);
    dateNowMiddleNight.setHours(0);
    dateNowMiddleNight.setMinutes(0);
    dateNowMiddleNight.setSeconds(0);

    const endNowMiddleNight = new Date(dateNowMiddleNight);
    endNowMiddleNight.setDate(dateNowMiddleNight.getDate() + 1);

    const realTimeNow = new Date(currentDateTime);
    let nextTimeToCheckWhenNoPlayContent = new Date(endNowMiddleNight);

    let currentAssignment: {
        tickerAssignment: TickerDisplayAssignment,
        nextTimeToCheck: Date
    };
    const tickerContentSchdule = ContentScheduler.generateSchedule(
        dateNowMiddleNight,
        endNowMiddleNight,
        tickerDisplayAssignments
    );
    for (let index = 0; index < tickerContentSchdule.length; index++) {
        const startTime = tickerContentSchdule[index].StartDateTime;
        const endTime = tickerContentSchdule[index].EndDateTime;

        if (endTime > realTimeNow && (startTime < realTimeNow || startTime.getTime() === realTimeNow.getTime())) {
            currentAssignment = {
                tickerAssignment: <TickerDisplayAssignment>tickerContentSchdule[index].Assignment,
                nextTimeToCheck: tickerContentSchdule[index].EndDateTime
            };
            break;
        }

        if (nextTimeToCheckWhenNoPlayContent > startTime && startTime > realTimeNow) {
            nextTimeToCheckWhenNoPlayContent = startTime;
        }
    }
    if (currentAssignment) {
        const assignment = currentAssignment.tickerAssignment;
        if (((assignment.tickerDisplay.leftPanel.rssUrl) && assignment.tickerDisplay.leftPanel.rssUrl !== "") ||
            ((assignment.tickerDisplay.mainPanel.rssUrl) && assignment.tickerDisplay.mainPanel.rssUrl !== "") ||
            ((assignment.tickerDisplay.rightPanel.rssUrl) && assignment.tickerDisplay.rightPanel.rssUrl !== "")) {
            const rssCheckTime = new Date();
            rssCheckTime.setMinutes(rssCheckTime.getMinutes() + 15);
            currentAssignment.nextTimeToCheck = min(rssCheckTime, currentAssignment.nextTimeToCheck);
        }
        return {
            assignment: currentAssignment.tickerAssignment,
            nextCheckTime: currentAssignment.nextTimeToCheck
        };
    } else {
        return {
            assignment: undefined,
            nextCheckTime: nextTimeToCheckWhenNoPlayContent
        }
    }
}

function min(l, r){
    return l < r ? l : r;
}

function watchForCurrentTicker(
    tickerDisplayAssignments: TickerDisplayAssignment[],
    localTimeZoneClock : LocalTimeZoneClock
) : Observable<TickerDisplayAssignment> {
    return new Observable<TickerDisplayAssignment>(
        subscriber => {
            let timerSubscription: Subscription;
            function performCheck(dateTime: Date):void {
                const result = getCurrentTickerFromTickerAssignments(tickerDisplayAssignments, dateTime);               
                if (timerSubscription) {
                    timerSubscription.unsubscribe();
                }
                timerSubscription = timer(result.nextCheckTime.getTime() - localTimeZoneClock.now.getTime())
                    .subscribe(
                        () => performCheck(result.nextCheckTime)
                    );   
                subscriber.next(result.assignment);
            }
            performCheck(localTimeZoneClock.now);
            const timeLeapSubscription = localTimeZoneClock.$timeLeap.subscribe({
                next: () => performCheck(localTimeZoneClock.now)
            });
            return {
                unsubscribe: () => {
                    if(timerSubscription) {
                        timerSubscription.unsubscribe();
                    }
                    timeLeapSubscription.unsubscribe();
                }
            }
        }
    );
}

// An OperatorFunction, which can be passed into a pipe
function getScheduledTicker(tickerContent$: Observable<TickerContent>, localTimeZoneClock: LocalTimeZoneClock) {
    return new Observable<ScheduledTickerResult>(
        (subscriber) => {
            let currentTickerSubscription: Subscription;
            const tickerContentSubscription = tickerContent$.subscribe({
                next: (tickerContent) => {
                    if (currentTickerSubscription) {
                        currentTickerSubscription.unsubscribe();
                    }
                    currentTickerSubscription = watchForCurrentTicker(tickerContent.tickerDisplayAssignments, localTimeZoneClock)
                        .subscribe({
                            next: (tickerDisplayAssignment) => subscriber.next({
                                resources: tickerContent.resources,
                                tickerDisplayAssignment: tickerDisplayAssignment
                            })
                        });
                }
            });
            return {
                unsubscribe: () => {
                    if(currentTickerSubscription) {
                        currentTickerSubscription.unsubscribe();
                    }
                    tickerContentSubscription.unsubscribe();  
                }
            };
        }
    );
}

function getTickerFromXml(xmlText: string) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");

    const resources: Resource[] = [];
    const tickerDisplayAssignments: TickerDisplayAssignment[] = [];
    const tickerResources = xmlDoc.getElementsByTagName('Resource');
    const resourcesArray: Element[] = Array.prototype.slice.call(tickerResources);
    resourcesArray.forEach((element, index) => {
        const key = element.getAttribute('Key');
        const textStyle = element.getElementsByTagName('TextStyle')[0];
        const fontWeight = textStyle.getAttribute('FontWeight');
        const fontSize = textStyle.getAttribute('FontSize');
        const fontStyle = textStyle.getAttribute('FontStyle');
        const color = textStyle.getAttribute('Color');
        const fontFamily = textStyle.getElementsByTagName('FontFamily')[0];
        let name, fileName;
        if (typeof fontFamily != 'undefined') {
            name = fontFamily.getAttribute('Name');
            fileName = fontFamily.getAttribute('FileName');
        }
        const resource = {
            key: key,
            textStyle: {
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                color: color,
                fontSize: fontSize
            },
            fontFamily: {
                name: name,
                fileName: fileName
            }
        };
        resources.push(resource);
    });

    let chainedPromise = Promise.resolve();
    const assignments = xmlDoc.getElementsByTagName('TickerDisplayAssignment');
    const assignmentsArray: Element[] = Array.prototype.slice.call(assignments);
    assignmentsArray.forEach((element, index) => {
        const daypartingConditions = element.getElementsByTagName('DayPartingConditions')[0];

        let dateRangeStart, dateRangeEndExclusive, dayOfWeekFlags, timeOfDayStart, timeOfDayEnd;
        if (typeof daypartingConditions != 'undefined') {
            const dateRangeStartString = daypartingConditions.getElementsByTagName('DateRangeStart')[0].textContent;
            if (dateRangeStartString !== "") {
                dateRangeStart = Date.parseDateString(dateRangeStartString);
            }

            const dateRangeEndExclusiveString = daypartingConditions.getElementsByTagName('DateRangeEndExclusive')[0].textContent;
            if (dateRangeEndExclusiveString !== "") {
                dateRangeEndExclusive = Date.parseDateString(dateRangeEndExclusiveString);
            }

            dayOfWeekFlags = daypartingConditions.getElementsByTagName('DayOfWeekFlags')[0].textContent;
            timeOfDayStart = daypartingConditions.getElementsByTagName('TimeOfDayStart')[0].textContent;
            timeOfDayEnd = daypartingConditions.getElementsByTagName('TimeOfDayEnd')[0].textContent;
        }

        const tickerDisplay = element.getElementsByTagName('TickerDisplay')[0];
        const tickerDisplayBackground = tickerDisplay.getAttribute('BackgroundColor');
        const textStyleRef = tickerDisplay.getAttribute('TextStyleRef');
        const tickerSpeed = tickerDisplay.getAttribute('TickerSpeed');

        const leftPanel = tickerDisplay.getElementsByTagName('LeftPanel')[0];
        const mainPanel = tickerDisplay.getElementsByTagName('MainPanel')[0];
        const rightPanel = tickerDisplay.getElementsByTagName('RightPanel')[0];


        chainedPromise = chainedPromise.then(() => {

            return new Promise((resolve, reject) => {
                readPanelInfo(leftPanel).then((leftPanel) => {
                    readPanelInfo(mainPanel).then((mainPanel) => {
                        readPanelInfo(rightPanel).then((rightPanel) => {
                            tickerDisplayAssignments.push({
                                assignmentIndex: index,
                                DateRangeStart: dateRangeStart,
                                DateRangeEndExclusive: dateRangeEndExclusive,
                                DayOfWeekFlags: dayOfWeekFlags,
                                TimeOfDayStart: timeOfDayStart,
                                TimeOfDayEnd: timeOfDayEnd,
                                tickerDisplay: {
                                    tickerDisplayBackground: tickerDisplayBackground,
                                    textStyleRef: textStyleRef,
                                    tickerSpeed: tickerSpeed,
                                    leftPanel: leftPanel,
                                    mainPanel: mainPanel,
                                    rightPanel: rightPanel
                                }
                            });
                            resolve();
                        });
                    });
                });
            });

        });

    });

    return from(chainedPromise.then(() => <TickerContent>{ resources: resources, tickerDisplayAssignments: tickerDisplayAssignments }));
 }

function readPanelInfo(Panel: Element): Promise<PanelObject> {
    if (typeof Panel == 'undefined') {
        return Promise.resolve({
            contentType: undefined,
            rssUrl: undefined,
            defaultImageFile: undefined,
            backgroundColor: undefined,
            items: []
        });
    } else {
        return new Promise((resolve, reject) => {
            const PanelDefaultImageFile = Panel.getAttribute('DefaultImageFile');
            const PanelContentType = Panel.getAttribute("ContentType");
            const PanelRssUrl = Panel.getAttribute("RssUrl");
            const PanelStyleRef = Panel.getAttribute("TextStyleRef");
            const PanelBackgroundColor = Panel.getAttribute('BackgroundColor');
            const panelObject = <PanelObject>{
                contentType: PanelContentType,
                rssUrl: PanelRssUrl,
                defaultImageFile: PanelDefaultImageFile,
                backgroundColor: PanelBackgroundColor,
                items: []
            };
            if ((PanelContentType) && PanelContentType === "Rss" && (PanelRssUrl) && PanelRssUrl !== "") {
                getRssItems(PanelRssUrl, PanelDefaultImageFile, PanelStyleRef).then((items) => {
                    panelObject.items = items;
                    resolve(panelObject);
                }, () => {
                    panelObject.items = getPanelItems(Panel, PanelStyleRef);
                    resolve(panelObject);
                });
            } else {
                panelObject.items = getPanelItems(Panel, PanelStyleRef);
                resolve(panelObject);
            }
        });
    }
}

function getRssItems(rssUrl: string, defaultIcon: string, styleRef: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
        const rssGetter = new RssFeedGetter();
        return rssGetter.retrieve(rssUrl).then((titles: string[]) => {
            const items: any[] = [];
            titles.forEach((title, index) => {
                items.push({Type : 'Text', Text : title,  TextStyleRef: styleRef});
                items.push({Type:'Image', FileName: defaultIcon});
            });

            resolve(items);
        }, () => {
            console.log("error when read rss");
            reject();
        });
    });
}

function getPanelItems(xmlElement: Element, parentStyle: string) {
    const items: any[] = [];
    const itemsArray: Element[] = Array.prototype.slice.call(xmlElement.children);
    itemsArray.forEach((element, index) => {
        if (element.nodeName === "TextItem"){
            let textStyleReference = element.getAttribute('TextStyleRef');
 
            if (!textStyleReference && parentStyle) {
                textStyleReference = parentStyle;
            }
 
            const text = element.getElementsByTagName('Text')[0].textContent;
            items.push({ Type:'Text', Text: text, TextStyleRef: textStyleReference });
        } else {
            const fileName = element.getAttribute('FileName');
            items.push({Type: 'Image', FileName: fileName});
        }
    });

    return items;
}

export function getAssignmentOfCurrentTicker(filePath : string, mediaPlayerFileSystem: MediaPlayerFileSystem, localTimeZoneClock: LocalTimeZoneClock) {
    return mediaPlayerFileSystem.watchTextContent(filePath)
        .pipe(
            mergeMap(getTickerFromXml),
            tickerContent => getScheduledTicker(tickerContent, localTimeZoneClock)
        );
}
