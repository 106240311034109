import { Component, Input } from '@angular/core';
import { TileComponentBase } from 'src/tileComponentBase';


@Component({
    selector: 'applications-page',
    templateUrl: './applications-page.component.html'
})
export class ApplicationsPageComponent  extends TileComponentBase<tizen.ApplicationInformation> {

    cloneItem(item: tizen.ApplicationInformation): tizen.ApplicationInformation {
        return {
            categories: item.categories,
            iconPath: item.iconPath,
            id: item.id,
            installDate: item.installDate,
            name: item.name,
            packageId: item.packageId,
            show: item.show,
            size: item.size,
            version: item.version
        };
    }
  
    @Input('items')
    public set items(itemsArray: tizen.ApplicationInformation[]) {
        super.setItems(itemsArray);
    }
}