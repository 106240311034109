import { DisplayHandler, ExecutionCompleteArgs, ExecutionId } from "./displayHandler";
import { Subject } from "rxjs";
import { LaqorrFileEntry } from "src/fileUtils";


// This class should never be instantiated
// It will only be instantiated if there is a media file
// with an unknown extension
export class NullDisplayHandler implements DisplayHandler {
    stopAll() {
    }

    readonly playCompleteSubject = new Subject<ExecutionCompleteArgs>();
    get mediaExecutionComplete$() {
        return this.playCompleteSubject.asObservable();
    }
    play(mediaFile: LaqorrFileEntry, executionId: ExecutionId) {
        const startTime = new Date();
        // There was some error which caused this to be invoked in the first place
        // To avoid this sending the application into a continuous busy loop
        // add a one second delay
        window.setTimeout(
            () => {
                this.playCompleteSubject.next({
                    executionId: executionId,
                    mediaFile: mediaFile,
                    startTime: startTime,
                    succeeded: false
                });
            },
            1000
        );
    }

    prepareNext(mediaFile: LaqorrFileEntry) {
    }
}