import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivationInfo, FunctionalityStatus, ActivationState, ChangeTrigger } from './activationInfo';
import { ITimerService, TimerService } from '../timerService';
import { FiniteStateMachineStateManager } from '../finiteStateMachineStateManager';
import { map, first, distinctUntilChanged } from 'rxjs/operators';
import { DeviceSettings, PlayerSettings } from '../deviceSettings';
import { ClientInterface } from './../laqorrProtobuf'
import { Logger } from '../logger'
import { LocalTimeZoneClock } from '../clock'
import { EnrolmentClient } from 'src/eneter/enrolment/enrolmentClient';


export interface IActivationInfoProvider {
    getActivationInfo(): Promise<ActivationInfo>;
}

export interface IScreenPowerManager {
    start() : void;
    getActivationInfo(): Promise<ActivationInfo>;
    activationInfoObservable: Observable<ActivationInfo>;
    activateManually(): Promise<ActivationInfo>;
    deactivateManually() : Promise<ActivationInfo>;
    getFunctionalityStatus(): FunctionalityStatus;
}

abstract class ScreenPowerManagerFsmState {
    constructor(protected readonly mStartDateTime: Date, protected readonly mExpiryDateTime: Date, protected timerService: ITimerService) {
    }

    protected buildActivationInfo(activationState: ActivationState, changeTrigger: ChangeTrigger, expiryDateTime: Date, startDateTime: Date): ActivationInfo {
        return {
            ActivationState: activationState,
            ChangeTime: startDateTime,
            ChangeTrigger: changeTrigger,
            NextChangeTime: expiryDateTime
        };
    }

    abstract getStateName(): string;
    abstract getActivationInfo(): ActivationInfo;
    abstract timerSaysOff(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState;
    abstract timerSaysOn(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState;
    abstract manuallyTurnOn(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState;
    abstract manuallyTurnOff(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState;
    abstract clockIsInvalid(clockValue: Date) : ScreenPowerManagerFsmState;
}

class SpmManuallyOn extends ScreenPowerManagerFsmState {
    getStateName(): string {
        return "SpmManuallyOn";
    }

    getActivationInfo(): ActivationInfo {
        return this.buildActivationInfo(ClientInterface.ActivationState.Active, ChangeTrigger.Manual, this.mExpiryDateTime, this.mStartDateTime);
    }

    timerSaysOff(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        let dateNow = this.timerService.getCurrentTime();
        if (this.mExpiryDateTime) {
            if ((+this.mExpiryDateTime) <= (+dateNow)) {
                return new SpmAutomaticallyOff(timerValue, nextTimedOnEvent, this.timerService);
            } else {
                return this;
            }
        } else {
            return new SpmAutomaticallyOff(timerValue, nextTimedOnEvent, this.timerService);
        }
    }

    timerSaysOn(timerValue: Date, nextTimedOffEvent: Date): ScreenPowerManagerFsmState {
        if(nextTimedOffEvent == this.mExpiryDateTime) {
            return this;
        } else {
            return new SpmManuallyOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOn(nextTimedOffEvent?: Date): ScreenPowerManagerFsmState {
        if(nextTimedOffEvent == this.mExpiryDateTime) {
            return this;
        } else {
            return new SpmManuallyOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmManuallyOff(this.timerService.getCurrentDateTime(), nextTimedOnEvent, this.timerService);
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return new SpmInvalidClock(clockValue, this.timerService);
    }
}

class SpmManuallyOff extends ScreenPowerManagerFsmState {
    getStateName(): string {
        return "SpmManuallyOff";
    }

    getActivationInfo(): ActivationInfo {
        return this.buildActivationInfo(ClientInterface.ActivationState.Inactive, ChangeTrigger.Manual, this.mExpiryDateTime, this.mStartDateTime);
    }

    timerSaysOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOnEvent) {
            return this;
        } else {
            return new SpmManuallyOff(this.mStartDateTime, nextTimedOnEvent, this.timerService);
        }
    }

    timerSaysOn(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        if(typeof(this.mExpiryDateTime) !== 'undefined') {
            if (this.mExpiryDateTime) {
                if (this.mExpiryDateTime < this.timerService.getCurrentTime() || this.mExpiryDateTime.getTime() === this.timerService.getCurrentTime().getTime()) {
                    return new SpmAutomaticallyOn(timerValue, nextTimedOnEvent, this.timerService);
                } else {
                    return this;
                }
            }
    
        }
        return new SpmAutomaticallyOn(timerValue, nextTimedOnEvent, this.timerService);
    }

    manuallyTurnOn(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return new SpmManuallyOn(this.timerService.getCurrentDateTime(), nextTimedOnEvent, this.timerService);
    }

    manuallyTurnOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOnEvent) {
            return this;
        } else {
            return new SpmManuallyOff(this.mStartDateTime, nextTimedOnEvent, this.timerService);
        }
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return new SpmInvalidClock(clockValue, this.timerService);
    }
}

class SpmAutomaticallyOn extends ScreenPowerManagerFsmState {

    getStateName(): string {
        return "SpmAutomaticallyOn";
    }

    getActivationInfo(): ActivationInfo {
        return this.buildActivationInfo(ClientInterface.ActivationState.Active, ChangeTrigger.Timer, this.mExpiryDateTime, this.mStartDateTime);
    }

    timerSaysOff(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOff(timerValue, nextTimedOnEvent, this.timerService);
    }

    timerSaysOn(timerValue: Date, nextTimedOffEvent: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOffEvent) {
            return this;
        } else {
            return new SpmAutomaticallyOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOn(nextTimedOffEvent?: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOffEvent) {
            return this;
        } else {
            return new SpmAutomaticallyOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmManuallyOff(this.timerService.getCurrentDateTime(), nextTimedOnEvent, this.timerService);
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return new SpmInvalidClock(clockValue, this.timerService);
    }
}

class SpmAutomaticallyOff extends ScreenPowerManagerFsmState {

    getStateName(): string {
        return "SpmAutomaticallyOff";
    }

    getActivationInfo(): ActivationInfo {
        return this.buildActivationInfo(ClientInterface.ActivationState.Inactive, ChangeTrigger.Timer, this.mExpiryDateTime, this.mStartDateTime);
    }

    timerSaysOff(timeValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOnEvent) {
            return this;
        } else {
            return new SpmAutomaticallyOff(this.mStartDateTime, nextTimedOnEvent, this.timerService);
        }
    }

    timerSaysOn(timerDateTime: Date, nextTimedOffEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOn(timerDateTime, nextTimedOffEvent, this.timerService);
    }

    manuallyTurnOn(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return new SpmManuallyOn(this.timerService.getCurrentDateTime(), nextTimedOnEvent, this.timerService);
    }

    manuallyTurnOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        if(nextTimedOnEvent == this.mExpiryDateTime) {
            return this;
        } else {
            return new SpmAutomaticallyOff(this.mStartDateTime, nextTimedOnEvent, this.timerService);
        }
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return new SpmInvalidClock(clockValue, this.timerService);
    }
}


class SpmInitialOn extends ScreenPowerManagerFsmState {
    constructor(startDateTime: Date, expiryDateTime: Date, timerService: ITimerService) {
        super(startDateTime, expiryDateTime, timerService);
    }

    getStateName(): string {
        return "SpmInitialOn";
    }

    getActivationInfo(): ActivationInfo {
        return this.buildActivationInfo(
            ClientInterface.ActivationState.Active,
            ChangeTrigger.ApplicationStart,
            this.mExpiryDateTime,
            this.mStartDateTime
        );
    }

    timerSaysOff(eventTime: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOff(eventTime, nextTimedOnEvent, this.timerService);
    }

    timerSaysOn(eventTime: Date, nextTimedOffEvent: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOffEvent) {
            return this;
        } else {
            return new SpmInitialOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOn(nextTimedOffEvent?: Date): ScreenPowerManagerFsmState {
        if(this.mExpiryDateTime == nextTimedOffEvent) {
            return this;
        } else {
            return new SpmInitialOn(this.mStartDateTime, nextTimedOffEvent, this.timerService);
        }
    }

    manuallyTurnOff(nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmManuallyOff(this.timerService.getCurrentDateTime(), nextTimedOnEvent, this.timerService);
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return new SpmInvalidClock(clockValue, this.timerService);
    }
}

class SpmInvalidClock extends ScreenPowerManagerFsmState {
    constructor(clockValue: Date, timerService: ITimerService) {
        super(clockValue, undefined, timerService)
    }

    getActivationInfo(): ActivationInfo {
        return {
            ActivationState: ClientInterface.ActivationState.InvalidClock,
            ChangeTrigger: ChangeTrigger.ApplicationStart,
            ChangeTime: this.mStartDateTime,
            NextChangeTime: undefined
        }
    }

    getStateName = () => "SpmInvalidClock"

    manuallyTurnOff(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return this; // Not until the clock gets fixed!
    }

    manuallyTurnOn(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return this; // Not until the clock gets fixed!
    }

    clockIsInvalid(clockValue: Date): ScreenPowerManagerFsmState {
        return this;
    }

    timerSaysOff(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOff(timerValue, nextTimedOnEvent, this.timerService);
    }

    timerSaysOn(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOn(timerValue, nextTimedOnEvent, this.timerService);
    }
}

class SpmUnenrolled extends ScreenPowerManagerFsmState {
    getStateName(): string {
        return "Unenrolled"
    }
    getActivationInfo(): ActivationInfo {
        return {
            ActivationState: ClientInterface.ActivationState.Configuration,
            ChangeTime: this.mStartDateTime,
            ChangeTrigger: ChangeTrigger.Manual,
            NextChangeTime: undefined
        };
    }
    // This will only happen once if moves into the enrolled state
    timerSaysOff(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOff(timerValue, nextTimedOnEvent, this.timerService);
    }
    timerSaysOn(timerValue: Date, nextTimedOnEvent: Date): ScreenPowerManagerFsmState {
        return new SpmAutomaticallyOn(timerValue, nextTimedOnEvent, this.timerService);
    }
    clockIsInvalid(clockValue: Date) : ScreenPowerManagerFsmState {
        return this;
    }
    manuallyTurnOn(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return this;
    }
    manuallyTurnOff(nextTimedOnEvent?: Date): ScreenPowerManagerFsmState {
        return this;
    }

}


type ScreenPowerState = "On" | "Off" | "Whatever";

interface DateTimeAndState {
    DateTime: Date;
    State: ScreenPowerState;
}

class ScreenStateScheduler {

    private static combineDateAndTime(date: Date, time: Date): Date {
        date.setHours(time.getHours(), time.getMinutes(), 0, 0);
        return date;
    }

    private static parseTimeString(input: string) : Date {
        if(!input){
            return null;
        }
    
        const times = input.split(":");

        if(times.length !== 2){
            return null;
        }
    
        const hours = parseFloat(times[0]);
        const minutes = parseFloat(times[1]);

        if(isNaN(hours) || isNaN(minutes) ){
            return  null;
        }
    
        if(hours%1 !== 0 || minutes%1 !== 0){
            return null;
        }
    
        if(hours > 23 || minutes > 59){
            return null;
        }
    
        const now = new Date();
        now.setHours(hours, minutes, 0, 0);
        return now;
    }

	private static addDateTimeAndStateToList(deviceSettings : PlayerSettings, list: DateTimeAndState[], day: Date) {
		let offTime = ScreenStateScheduler.combineDateAndTime(
            new Date(
                day.getTime()
            ),
            ScreenStateScheduler.parseTimeString(deviceSettings.screenActivationSettings.endTime)
        );
		let onTime = ScreenStateScheduler.combineDateAndTime(
            new Date(
                day.getTime()
            ),
            ScreenStateScheduler.parseTimeString(deviceSettings.screenActivationSettings.startTime)
        );

        list.push({
            DateTime: offTime,
            State: "Off"
        });

        list.push({
            DateTime: onTime,
            State: "On"
        });

        return true;
    }

    public static getPastAndFutureStateChanges(deviceSettings: PlayerSettings, timerService: TimerService) {
        let dateTimeAndSateList: DateTimeAndState[] = [];
        let today = timerService.getCurrentTime();
        let yesterday = new Date(today.getTime());
        yesterday.setDate(yesterday.getDate() - 1);

        let tomorrow = new Date(today.getTime());
        tomorrow.setDate(tomorrow.getDate() + 1);
        let days = [yesterday, today, tomorrow];
        days.every(
            (day) => {
                return ScreenStateScheduler.addDateTimeAndStateToList(
                    deviceSettings,
                    dateTimeAndSateList,
                    day
                );
            }
        );

        dateTimeAndSateList.sort(
            (d1, d2) => {
                if (d1.DateTime > d2.DateTime) {
                    return 1;
                } else if (d1.DateTime < d2.DateTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
        );

        return dateTimeAndSateList;
    }

    
    public static getTheLastDateTimeInList(referenceDateTime: Date, dateTimeAndStateList: DateTimeAndState[]): DateTimeAndState {
        let newList: DateTimeAndState[] = [];
        dateTimeAndStateList.forEach(function (data) {
            if (data.DateTime < referenceDateTime || data.DateTime.getTime() === referenceDateTime.getTime()) {
                newList.push(data);
            }
        });

        return newList[newList.length - 1];
    }

    public static getTheFirstDateTimeInList(referenceDataTime: Date, dateTimeAndStateList: DateTimeAndState[]): DateTimeAndState {
        var newList: DateTimeAndState[] = [];

        dateTimeAndStateList.forEach(function (data) {
            if (data.DateTime > referenceDataTime) {
                newList.push(data);
            }
        });

        return newList[0];
    }
}

interface TransitionResult
{ 
    changedToNewState: boolean;
    activationInfo: ActivationInfo;
}


@Injectable({
    providedIn: "root"
})
export class ScreenPowerManager implements IScreenPowerManager, IActivationInfoProvider {
    private readonly timerService: TimerService;
    private readonly stateMachineManager: FiniteStateMachineStateManager<ScreenPowerManagerFsmState>;
    private readonly activationInfoSubject: Subject<ActivationInfo> = new Subject<ActivationInfo>();

    constructor(
        private readonly deviceSettings: DeviceSettings, 
        private readonly logger: Logger, 
        private readonly localTimeZoneClock: LocalTimeZoneClock,
        private readonly enrolmentClient: EnrolmentClient
    ) {
        this.timerService = new TimerService(localTimeZoneClock);
        this.stateMachineManager = new FiniteStateMachineStateManager(
            new SpmInitialOn(
                this.timerService.getCurrentDateTime(),
                null,
                this.timerService
            )
        );
        // Should filter this to device settings we are interested in
        deviceSettings.playerSettingsObservable.subscribe({
            next: (properties) => this.deviceSettingsPropertiesChanged()
        });
        this.localTimeZoneClock.$timeLeap.subscribe({
            next: () => this.reset()
        });
        this.enrolmentClient.status.pipe(
            map(s => s.isEnrolled),
            distinctUntilChanged()
        ).subscribe({
            next: value => this.enrolmentStatusChanged(value)
        });
    }

    private readonly minimumValidDateTime = new Date("2023-09-20 01:10Z");


    private isInsideReset = false;
    private mustRepeatReset = false;
    private async reset() {
        if (this.isInsideReset) {
            this.mustRepeatReset = true;
            return;
        }
        this.isInsideReset = true;
        try {
            let transitionResult: TransitionResult;
        
            const playerSettings = await this.deviceSettings.getPlayerSettings();
            const isEnrolled = await (this.enrolmentClient.status.pipe( 
                map(s => s.isEnrolled),
                first()
            ).toPromise());
    
            const currentDateTime = new Date();
            if(currentDateTime < this.minimumValidDateTime) {
                transitionResult = await this.transition(
                    s => s.clockIsInvalid(currentDateTime)
                );
                if(transitionResult.changedToNewState) {
                    this.logger.error(
                        "screenPowerManager",
                        `The device clock is set at an invalid time of ${currentDateTime}`
                    );
                }
                this.timerService.setNextNotification(1000, () => this.reset());
            }  else if (!isEnrolled) {
                console.log("ScreenPowerManager Transitioning to the unenrolled state");
                transitionResult = await this.transition(s => new SpmUnenrolled(new Date(), undefined, this.timerService));
            } else if(!playerSettings.turnScreenOnAndOff) { // TODO: check it if is enrolled here as well! If it's not enrolled, then no screen activation!
                transitionResult = await this.transition(s => new SpmInitialOn(new Date(), null, this.timerService));
                this.turnScreenOn();
            } else {
                const dateTimeAndStateList = ScreenStateScheduler.getPastAndFutureStateChanges(
                    playerSettings,
                    this.timerService
                );
                const referenceDateTime = this.timerService.getCurrentTime();
                const lastOccurring = ScreenStateScheduler.getTheLastDateTimeInList(referenceDateTime, dateTimeAndStateList);
                const nextOccurring = ScreenStateScheduler.getTheFirstDateTimeInList(referenceDateTime, dateTimeAndStateList);
                transitionResult = await this.transition(
                    oldState =>
                        lastOccurring.State === "On"
                        ? oldState.timerSaysOn(lastOccurring.DateTime, nextOccurring.DateTime)
                        : oldState.timerSaysOff(lastOccurring.DateTime, nextOccurring.DateTime)
                );
                this.timerService.setNextNotification(nextOccurring.DateTime, () => this.reset());
            }
            if(transitionResult.changedToNewState) {
                this.broadcastScreenMessage(transitionResult.activationInfo);
                this.activationInfoSubject.next(transitionResult.activationInfo);
            }
        } finally {
            this.isInsideReset = false;
            if(this.mustRepeatReset) {
                this.mustRepeatReset = false;
                window.setTimeout(() => this.reset(), 0);
            }
        }
    }

    get activationInfoObservable() {
        return this.stateMachineManager
            .currentState
            .pipe(
                distinctUntilChanged(),
                map(s => s.getActivationInfo())
            );
    }

    private deviceSettingsPropertiesChanged() {
        this.reset();
        console.log('ScreenPowerManager.DeviceSettingsPropertiesChanged invoked');
    }

    private enrolmentStatusChanged(isEnrolled: boolean) {
        console.log('ScreenPowerManager.enrolmentStatusChanged invoked!');
        this.reset();
    }

    public getActivationInfo(): Promise<ActivationInfo> {
        const actionInfoObservable = this
            .stateMachineManager
            .currentState
            .pipe(
                map(s => {
                    const stateName = s.getStateName();
                    return s.getActivationInfo();
                }),
                first()
            );
        return new Promise<ActivationInfo>(
            (resolve) =>
            {
                actionInfoObservable.subscribe(
                    activationInfo => {
                        resolve(activationInfo);
                    }
                )
            }
        )
    }

    private async broadcastScreenMessage(activationInfo? : ActivationInfo): Promise<void> {
        try {
            activationInfo = activationInfo || await this.getActivationInfo();
            switch(activationInfo.ActivationState) {
                case ClientInterface.ActivationState.Active:
                case ClientInterface.ActivationState.Configuration:
                    this.turnScreenOn();
                    break;
                case ClientInterface.ActivationState.Inactive:
                    this.turnScreenOff();
            }
        } finally {

        }
    }

    private turnScreenOn() {
        // This will have to go thro the screen state maintainer
        // But for now...

        if(typeof(b2bapis) === 'undefined') {
            console.log('Invoking turnScreenOn');
            return;
        }
        try {
            b2bapis.b2bcontrol.setPanelMute(
                "OFF",
                (v:any) => {
                    console.log("Successfully turned the screen on");
                },
                (error) => {
                    console.error(`Failed to turn the screen on: ${error.name} "${error.message}"`);
                }
            );    
        } catch(e) {
            const error:Error = <Error>e;
            console.error(`Error! e.message = ${error.message}`);
        }
    }

    private turnScreenOff() {
        if(typeof(b2bapis) === 'undefined') {
            console.log('Invoking turnScreenOff');
            return;
        }
        b2bapis.b2bcontrol.setPanelMute(
            "ON",
            (v:any) => {
                console.log("Successfully turned the screen on");
            },
            (error) => {
                console.error(`Failed to turn the screen on: ${error.name} "${error.message}"`);
            }
        )
    }

    start(): void {
        this.reset();
    }

    private transition(transitionFunction: (s:ScreenPowerManagerFsmState) => ScreenPowerManagerFsmState) : Promise<TransitionResult> {
        return new Promise<TransitionResult>(
            (resolve) => {
                this.stateMachineManager.transition(
                    state => {
                        const newState = transitionFunction(state);
                        resolve({
                            changedToNewState: newState !== state,
                            activationInfo: newState.getActivationInfo()
                        });
                        return newState;
                    }
                );
            }
        );
    }

    async activateManually(): Promise<ActivationInfo> {
        console.log('screenPowerManager.activateManually being invoked');
        const playerSettings = await this.deviceSettings.getPlayerSettings();
        let nextTurnOffTime: Date;
        if(playerSettings.turnScreenOnAndOff) {
            const dateTimeAndStateList = ScreenStateScheduler.getPastAndFutureStateChanges(
                playerSettings,
                this.timerService
            );
            const referenceDateTime = this.timerService.getCurrentTime();
            const nextTurnOffEntry = dateTimeAndStateList.find(
                node => 
                    node.DateTime > referenceDateTime && node.State === "Off"
            );
            nextTurnOffTime = nextTurnOffEntry
                ? nextTurnOffEntry.DateTime
                : null;

        }
        const transitionResult = await this.transition(
            state => state.manuallyTurnOn(nextTurnOffTime)
        );
        this.broadcastScreenMessage(transitionResult.activationInfo);
        this.activationInfoSubject.next(transitionResult.activationInfo);
        return transitionResult.activationInfo;
    }

    async deactivateManually(): Promise<ActivationInfo> {
        console.log('screenPowerManager.activateManually being invoked');
        const playerSettings = await this.deviceSettings.getPlayerSettings();
        let nextTurnOnTime: Date;
        if(playerSettings.turnScreenOnAndOff) {
            const dateTimeAndStateList = ScreenStateScheduler.getPastAndFutureStateChanges(
                playerSettings,
                this.timerService
            );
            const referenceDateTime = this.timerService.getCurrentTime();
            const nextTurnOnEntry = dateTimeAndStateList.find(
                node => 
                    node.DateTime > referenceDateTime && node.State === "On"
            );
            nextTurnOnTime = nextTurnOnEntry
                ? nextTurnOnEntry.DateTime
                : null;

        }
        const transitionResult = await this.transition(
            state => state.manuallyTurnOff(nextTurnOnTime)
        );
        this.broadcastScreenMessage(transitionResult.activationInfo);
        this.activationInfoSubject.next(transitionResult.activationInfo);
        return transitionResult.activationInfo;
    }
    
    getFunctionalityStatus(): FunctionalityStatus {
        throw new Error('Method not implemented.');
    }

}