import { Subscription } from 'rxjs';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { ScheduledTickerResult, Resource, TickerDisplay } from './tickerScheduler';
import { HtmlUtils } from 'src/htmlUtils';
import { FontFamily } from '../clock-feed/clockFeedSettings';
import { fileManager } from 'src/fileManager';
import { FileUtils } from 'src/fileUtils';


namespace TickerStyleGeneration {
    export function writeToStyleAccordingToTickerDisplay(style: HTMLStyleElement, tickerId: string, tickerDisplay: TickerDisplay) {
        const backgroundColor = tickerDisplay.tickerDisplayBackground;
        if (backgroundColor) {
            style.append(`#${tickerId} {background-color:${backgroundColor};}`)
        }
    }
}

class TickerFontManager {

    private fontWatchers: Subscription[] = [];

    private nameLookup : {[key:string]:string} = {};

    public constructor(
        private readonly styleElementId: string,
        private readonly sourceFolder: string,
        private mediaPlayerFileSystem: MediaPlayerFileSystem
    ) {

    }

    public dispose() : void {
        this.fontWatchers.forEach(
            fontWatcher => fontWatcher.unsubscribe()
        );

        this.fontWatchers.splice(0, this.fontWatchers.length);
    }

    public getUniqueFamilyNameForFontFile(fontFileName: string) : string {
        if (fontFileName in this.nameLookup) {
            return this.nameLookup[fontFileName];
        }
        const generatedName = `ticker_font_${HtmlUtils.generateUniqueId()}`;
        this.nameLookup[fontFileName] = generatedName;
        const fontFilePath = FileUtils.getFeedFilePath(fontFileName, this.sourceFolder);

        const watch = this.mediaPlayerFileSystem.watchMediaFileArrived(fontFilePath)
            .subscribe({
                next: async (fileEntry: FileEntry) => {
                    const fileUrl = await FileUtils.getFileUrl(fontFileName, this.sourceFolder);
                    let fontFace = '';
                    fontFace += '@font-face{';
                    fontFace += `font-family: ${generatedName};`;
                    fontFace += "src:url('" + fileUrl + "');";
                    fontFace += '}';
                    document.getElementById(this.styleElementId).append(fontFace);
                }
            });

        this.fontWatchers.push(watch);
        return generatedName;
    }
}


export class TickerRenderer {
    private readonly tickerId = `divTicker_${HtmlUtils.generateUniqueId()}`;
    private readonly styleElementId = `style_${this.tickerId}`;

    private tickerImageWatchers: Subscription[] = [];

    private readonly tickerFontManager: TickerFontManager;

    constructor(
        private mediaPlayerFileSystem: MediaPlayerFileSystem,
        private sourceFolder: string,
        private element: HTMLElement,
        private scheduledTickerResult: ScheduledTickerResult
    ) {
        this.tickerFontManager = new TickerFontManager(this.styleElementId, sourceFolder, mediaPlayerFileSystem);
    }

    buildTickerHtml() {
        this.generateTickerStyleAndAddToDocument();

        const tickerElement = document.createElement("div");
        tickerElement.className = "divTicker";
        tickerElement.id = this.tickerId;

        const leftTickerPanel = document.createElement("div");
        leftTickerPanel.className = "divTickerLeftPanel";
        tickerElement.appendChild(leftTickerPanel);

        const rightTickerPanel = document.createElement("div");
        rightTickerPanel.className = "divTickerRightPanel";
        tickerElement.appendChild(rightTickerPanel);

        const mainTickerPanel = document.createElement("div");
        mainTickerPanel.className = "divTickerMainPanel";
        tickerElement.appendChild(mainTickerPanel);

        const mainPanelMarquee = document.createElement("div");
        mainPanelMarquee.className = "divMarquee";
        mainTickerPanel.appendChild(mainPanelMarquee);

        const marqueeChildren = document.createElement("div");
        marqueeChildren.className = "marqueeChild";
        mainPanelMarquee.appendChild(marqueeChildren);

        this.element.textContent = '';
        this.element.appendChild(tickerElement);

        const height = this.element.offsetHeight * 0.8;
        const parentWidth = this.element.offsetWidth;

        const leftPanelItems = this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.leftPanel.items;

        if (leftPanelItems.length !== 0){
            this.generateTickerPanelItems(leftTickerPanel, leftPanelItems, height);
        }

        const mainPanelItems = this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.mainPanel.items;
        let speed = 10;
        if (mainPanelItems.length !== 0) {

            if (this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.tickerSpeed) {
                try {
                    speed = parseInt(this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.tickerSpeed);
                } catch (error) {

                }
            }
            this.generateTickerPanelItems(marqueeChildren, mainPanelItems, height);
        }

        //use when the width of elements in main panel is less than width of screen
        const mainPanelWidth = marqueeChildren.offsetWidth;

        if (mainPanelWidth !== 0 && mainPanelWidth < parentWidth) {
            const count = parentWidth / mainPanelWidth + 1;
            const marqueeHtml = marqueeChildren.innerHTML;

            for (var i = 0; i < count; i++) {
                mainPanelMarquee.insertAdjacentHTML("beforeend", marqueeHtml);
            }
        }
        mainPanelMarquee.insertAdjacentHTML("beforeend", mainPanelMarquee.innerHTML);

        let moveSpeed = this.calculateSpeed(speed, marqueeChildren.offsetWidth, this.element.offsetWidth);
        //end

        this.generateTickerAnimationCss(marqueeChildren.offsetWidth, moveSpeed, mainPanelMarquee);


        //use to detect the size changed event of ticker cause by font or image loaded
        HtmlUtils.divSizeChanged(marqueeChildren, () => {
            mainPanelMarquee.style.animation = null;
            moveSpeed = this.calculateSpeed(speed, marqueeChildren.offsetWidth, this.element.offsetWidth);
            this.generateTickerAnimationCss(marqueeChildren.offsetWidth, moveSpeed, mainPanelMarquee);
        });

        const rightPanelItems = this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.rightPanel.items;

        if (rightPanelItems.length !== 0) {
            this.generateTickerPanelItems(rightTickerPanel, rightPanelItems, height);
        }
    }

    dispose() {
        this.clearTickerImageWatchers();
        this.tickerFontManager.dispose();
        let style = document.getElementById(this.styleElementId);
        if (style) {
            style.remove();
        }
        const styleId = 'tickerAnimationstyle' + this.tickerId;
        style = document.getElementById(styleId);
        if (style) {
            style.remove();
        }
    }

    calculateSpeed(speed: number, tickerMainPanelWidth: number, screenWidth: number) {
        return tickerMainPanelWidth / ((screenWidth * speed) / 60);
    }

    generateTickerAnimationCss(width: number, speed: number, marquee: HTMLElement) {
        const styleId = 'tickerAnimationstyle' + this.tickerId;
        let style = document.getElementById(styleId);
        if (style) {
            style.remove();
        }

        style = document.createElement("style");
        style.id = styleId;
        style.append('@-webkit-keyframes marquee' + this.tickerId + '{0% { -webkit-transform: translateX(0px);}100% { -webkit-transform: translateX(-' + width + 'px);}');

        document.head.appendChild(style);
        marquee.style.animation = 'marquee' + this.tickerId + ' ' + speed + 's linear infinite';
    }

    private generateTickerStyleAndAddToDocument() {
        const parentStyleRef = this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.textStyleRef;
        const existingStyle = document.getElementById(this.styleElementId);
        if (existingStyle) {
            existingStyle.remove();
        }

        const style = document.createElement("style");
        style.id = this.styleElementId;

        TickerStyleGeneration.writeToStyleAccordingToTickerDisplay(style, this.tickerId, this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay);
        
        this.scheduledTickerResult.resources.forEach((resource) => {

            style.append(
                TickerRenderer.generateResourceCss(
                    '#' + this.tickerId + ' span[name="' + resource.key + '"]', 
                    resource, 
                    null, 
                    this.tickerFontManager
                )
            );

            if (parentStyleRef && parentStyleRef !== '') {
                if (parentStyleRef === resource.key) {
                    const backgroundColor = this.scheduledTickerResult.tickerDisplayAssignment.tickerDisplay.tickerDisplayBackground;
                    style.append(
                        TickerRenderer.generateResourceCss(
                            '#' + this.tickerId,
                            resource,
                            backgroundColor, 
                            this.tickerFontManager
                        )
                    );
                }
            }
        });

        document.head.appendChild(style);
    }

    watchTickerImages(imageItems: string[]) {
        const imageNames: string[] = [];

        imageItems.forEach((name) => {
            if (imageNames.indexOf(name) == -1) {
                imageNames.push(name);
            }
        });

        imageNames.forEach((name) => {
            const visualFeedContentFolderName = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VisualFeedContent);
            const imageFilePath = `/${visualFeedContentFolderName}/${this.sourceFolder}/${name}`;
            const watch = this.mediaPlayerFileSystem.watchMediaFileArrived(imageFilePath)
                .subscribe({
                    next: async (fileEntry: FileEntry) => {
                        const fileUrl = await FileUtils.getFileUrl(name, this.sourceFolder);
                        const elements: Element[] = Array.prototype.slice.call(document.querySelectorAll('#' + this.tickerId + ' img[name="' + name + '"]'));
                        elements.forEach((element, index) => {
                           element.setAttribute('src', fileUrl + "?version=" + new Date().getTime());
                        });
                    }
                });

            this.tickerImageWatchers.push(watch);
        });
    }

    clearTickerImageWatchers() {
        this.tickerImageWatchers.forEach((watcher) => {
            watcher.unsubscribe();
        });

        this.tickerImageWatchers = [];
    }

    static generateResourceCss(
        selector: string,
        resource: Resource,
        backgroundColor: string,
        tickerFontManager: TickerFontManager
    ) : string {
        const nameAndValues: { [key:string]:string } = {};
        if (resource.textStyle.fontWeight) {
            nameAndValues['font-weight'] = resource.textStyle.fontWeight;
        }
        if (resource.textStyle.color) {
            nameAndValues['color'] = resource.textStyle.color;
        }
        if (resource.textStyle.fontStyle) {
            nameAndValues['font-style'] = resource.textStyle.fontStyle;
        }
        if (resource.textStyle.fontSize) {
            nameAndValues['font-size'] = `${resource.textStyle.fontSize}px`;
        }
        if (resource.fontFamily.name) {
            nameAndValues['font-family'] = tickerFontManager.getUniqueFamilyNameForFontFile(resource.fontFamily.fileName);
        }
        if (backgroundColor) {
            nameAndValues['background-color'] = backgroundColor;
        }

        let resourceCss: string = `${selector} {`;
        for(const name in nameAndValues) {
            resourceCss = `${resourceCss} ${name}:${nameAndValues[name]};`;
        }
        resourceCss = `${resourceCss} }`;
        return resourceCss;
    }

    generateTickerPanelItems(panel: HTMLElement, items: any[], height: number) {
        const images: string[] = [];

        let itemElement: HTMLElement;
        items.forEach((item) => {
            if (item.Type === 'Text') {
                itemElement = document.createElement("span");
                itemElement.className = "tickerText";
                itemElement.innerText = item.Text;
                if (item.TextStyleRef) {
                    itemElement.setAttribute("name", item.TextStyleRef);
                }
            } else {
                itemElement = document.createElement("img");
                itemElement.style.height = height + "px";
                itemElement.setAttribute("name", item.FileName);
                images.push(item.FileName);
            }
            panel.appendChild(itemElement);
        });

        if (images.length !== 0) {
            this.watchTickerImages(images);
        }
    }
}
