import { fileManager } from "./fileManager";

export class FileHierarchyNode {
    children: FileHierarchyNode[] = [];
    files: tizen.File[] = [];

	constructor(readonly directoryEntry: tizen.File | DirectoryEntry) {
	}

	findMatchingHierarchyNode(folders: string[]): FileHierarchyNode {
		var first = folders[0];
		var remaining = folders.slice(1);
		var foundNode:any;
		this.children.every(function (n) {
			if (n.directoryEntry.name === first) {
				foundNode = n;
				return false;
			} else {
				return true;
			}
		});
		if (!foundNode) {
			return;
		}
		if (remaining.length === 0) {
			return foundNode;
		} else {
			return foundNode.findMatchingHierarchyNode(remaining);
		}
	}

	findMatchingFileEntry(filePath:string) : tizen.File | FileEntry | void  {
		const relativePathSplit = filePath.split('\\');
		const folders = relativePathSplit.slice(0, relativePathSplit.length - 1);
		const fileName = relativePathSplit[relativePathSplit.length - 1];
		const matchingNode = this.findMatchingHierarchyNode(folders);
		if (matchingNode) {
			// Now, does it match?
			var fileEntry:tizen.File;
			matchingNode.files.every(function (fe) {
				if (fe.name === fileName) {
					fileEntry = fe;
					return false;
				} else {
					return true;
				}
			});
			return fileEntry;
		} else {
			console.log(`Unable to find a heirachy node matching ${matchingNode}`);
		}
	}

	findFileInNode(node: FileHierarchyNode, filePath: string) {
		let fileEntry: tizen.File;
		node.files.every(function (fe) {
			if (fe.name === filePath) {
				fileEntry = fe;
				return false;
			} else {
				return true;
			}
		});
		return fileEntry;
	}

	findMatchingMusicFileEntry(filePath: string, mediaFolder: number) {
		if (!this.directoryEntry) {
			return null;
		}

		var pathArray = (fileManager.getMediaPlayerFolderName(mediaFolder) + "/" + filePath.replace("\\", "/")).split("/");
		if (pathArray.length === 1) {
			return this.findFileInNode(this, filePath);
		} else {
			var folders = pathArray.slice(0, pathArray.length - 1);
			var matchedNode = this.findMatchingHierarchyNode(folders);

			if (matchedNode) {
				return this.findFileInNode(matchedNode, pathArray[pathArray.length - 1]);
			}
		}
	}
}




