import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { LocalTimeZoneClock } from 'src/clock';
import { FileUtils } from 'src/fileUtils';
import { LoggerService } from "src/loggerService";
import { getAssignmentOfCurrentTicker } from './tickerScheduler';
import { TickerRenderer } from './tickerRenderer';

@Component({
    "templateUrl": "ticker-feed.component.html",
    "selector": "ticker-feed"
})
export class TickerFeedComponent extends VisualFeedBaseComponent implements AfterViewInit, OnDestroy {
    private assignmentSubscription: Subscription = null;
    private tickerRenderer: TickerRenderer;
    
    @ViewChild("ticker", {read: ElementRef}) ticker: ElementRef<HTMLDivElement>;

    constructor(
        public readonly mediaPlayerFileSystem: MediaPlayerFileSystem, 
        private readonly logger: LoggerService,
        private readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
        super(mediaPlayerFileSystem);
    }

    ngAfterViewInit() {
        const tickerFeedName = "TickerContentEx.xml";
        const tickerFeedFilePath = FileUtils.getFeedFilePath(tickerFeedName, this.sourceFolder);
        this.logger.logInfo(`tickerFeedFilePath=${tickerFeedFilePath}`);
        this.assignmentSubscription = getAssignmentOfCurrentTicker(
            tickerFeedFilePath,
            this.mediaPlayerFileSystem,
            this.localTimeZoneClock
        ).subscribe(
            tickerResult => {
                if (tickerResult.tickerDisplayAssignment) {
                    const preTickerRenderer = this.tickerRenderer;
                    this.tickerRenderer = new TickerRenderer(
                        this.mediaPlayerFileSystem,
                        this.sourceFolder,
                        this.ticker.nativeElement,
                        tickerResult
                    );
                    this.tickerRenderer.buildTickerHtml();
                    if (preTickerRenderer) {
                        preTickerRenderer.dispose();
                    }
                }
            }
        );       
    }

    ngOnDestroy() {
        if (this.assignmentSubscription)
        {
            this.assignmentSubscription.unsubscribe();
            this.assignmentSubscription = null;
        }
        if (this.tickerRenderer) {
            this.tickerRenderer.dispose();
        }
    }
}