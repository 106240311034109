import { DynamicDataView } from './dynamicDataView';

export interface Encoding {
    stringFromBytes(dynamicDataView: DynamicDataView, beingIdx: number, size: number) : string;
    stringToBytes(dynamicDataView: DynamicDataView, beginIdx: number, str: string) : number;
}


class Utf16EncodingBase implements Encoding {
    readonly myIsLittleEndian : boolean;

    constructor(isLittleEndian : boolean) {
        this.myIsLittleEndian = isLittleEndian;
    }

    stringFromBytes(dynamicDataView : DynamicDataView, beginIdx: number, size:number) {
        let aResult = "";
        for (let i = 0; i < size; ++i)
        {
            aResult += String.fromCharCode(dynamicDataView.getUint16(beginIdx + i++, this.myIsLittleEndian));
        }
        return aResult;
    }

    stringToBytes(dynamicDataView : DynamicDataView, beginIdx : number, str : string)
    {
        var aLength = str.length;
        var ch;
        for (let i = 0; i < aLength; ++i)
        {
            ch = str.charCodeAt(i);
            dynamicDataView.setUint16(beginIdx + i * 2, ch, this.myIsLittleEndian);
        }
        
        var aResultSize = aLength * 2;
        return aResultSize;
    }
}




//API: no
// UTF16 Little Endian encoding convertor. 
export class Utf16LeEncoding implements Encoding
{
    // note: true means little-endian.
    readonly myUtf16EncodingBase = new Utf16EncodingBase(true);
    
    stringFromBytes(dynamicDataView :DynamicDataView, beginIdx:number, size : number)
    {
        return this.myUtf16EncodingBase.stringFromBytes(dynamicDataView, beginIdx, size);
    }
    
    stringToBytes(
        dynamicDataView : DynamicDataView,
        beginIdx : number,
        str :string
    ) : number
    {
        return this.myUtf16EncodingBase.stringToBytes(dynamicDataView, beginIdx, str);
    }
}

//API: no
// UTF16 Big Endian encoding convertor.
export class Utf16BeEncoding implements Encoding
{
    // note: false means big-endian.
    readonly myUtf16EncodingBase = new Utf16EncodingBase(false);
    
    stringFromBytes(dynamicDataView :DynamicDataView, beginIdx:number, size : number)
    {
        return this.myUtf16EncodingBase.stringFromBytes(dynamicDataView, beginIdx, size);
    }
    
    stringToBytes(
        dynamicDataView : DynamicDataView,
        beginIdx : number,
        str :string
    ) : number
    {
        return this.myUtf16EncodingBase.stringToBytes(dynamicDataView, beginIdx, str);
    }
}


// UTF8 encoding convertor.
export class Utf8Encoding implements Encoding
{
    stringFromBytes(dynamicDataView : DynamicDataView, beginIdx: number, size : number)
    {
        var aResult = "";
        var aCode;
        var i;
        var aValue;
        for (i = 0; i < size; i++)
        {
            aValue = dynamicDataView.getUint8(beginIdx + i);

            // If one byte character.
            if (aValue <= 0x7f)
            {
                aResult += String.fromCharCode(aValue);
            }
            // If mutlibyte character.
            else if (aValue >= 0xc0)
            {
                // 2 bytes.
                if (aValue < 0xe0)
                {
                    aCode = ((dynamicDataView.getUint8(beginIdx + i++) & 0x1f) << 6) |
                            (dynamicDataView.getUint8(beginIdx + i) & 0x3f);
                }
                // 3 bytes.
                else if (aValue < 0xf0)
                {
                    aCode = ((dynamicDataView.getUint8(beginIdx + i++) & 0x0f) << 12) |
                            ((dynamicDataView.getUint8(beginIdx + i++) & 0x3f) << 6) |
                            (dynamicDataView.getUint8(beginIdx + i) & 0x3f);
                }
                // 4 bytes.
                else
                {
                    // turned into two characters in JS as surrogate pair
                    aCode = (((dynamicDataView.getUint8(beginIdx + i++) & 0x07) << 18) |
                            ((dynamicDataView.getUint8(beginIdx + i++) & 0x3f) << 12) |
                            ((dynamicDataView.getUint8(beginIdx + i++) & 0x3f) << 6) |
                            (dynamicDataView.getUint8(beginIdx + i) & 0x3f)) - 0x10000;
                    // High surrogate
                    aResult += String.fromCharCode(((aCode & 0xffc00) >>> 10) + 0xd800);
                    // Low surrogate
                    aCode = (aCode & 0x3ff) + 0xdc00;
                }
                aResult += String.fromCharCode(aCode);
            } // Otherwise it's an invalid UTF-8, skipped.
        }
        
        return aResult;
    }
    
    stringToBytes(dynamicDataView : DynamicDataView, beginIdx : number, str : string)
    {
        var aLength = str.length;
        var aResultSize = 0;
        var aCode;
        var i;
        for (i = 0; i < aLength; i++)
        {
            aCode = str.charCodeAt(i);
            if (aCode <= 0x7f)
            {
                dynamicDataView.setUint8(beginIdx + aResultSize++, aCode);
            }
            // 2 bytes 
            else if (aCode <= 0x7ff)
            {
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0xc0 | (aCode >>> 6 & 0x1f));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode & 0x3f));
            }
            // 3 bytes
            else if (aCode <= 0xd700 || aCode >= 0xe000)
            {
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0xe0 | (aCode >>> 12 & 0x0f));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode >>> 6 & 0x3f));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode & 0x3f));
            }
            else
            // 4 bytes, surrogate pair
            {
                aCode = (((aCode - 0xd800) << 10) | (str.charCodeAt(++i) - 0xdc00)) + 0x10000;
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0xf0 | (aCode >>> 18 & 0x07));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode >>> 12 & 0x3f));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode >>> 6 & 0x3f));
                dynamicDataView.setUint8(beginIdx + aResultSize++, 0x80 | (aCode & 0x3f));
            }
        }
        
        return aResultSize;
    }
}



export class EncoderDecoder {
    readonly myDynamicDataView : DynamicDataView;
    myIdx = 0;
    constructor(dynamicDataView : DynamicDataView) {
        this.myDynamicDataView = dynamicDataView;
    }

    writeByte(value:number) {
        this.myDynamicDataView.setUint8(this.myIdx++, value);
    }

    readByte()
    {
        return this.myDynamicDataView.getUint8(this.myIdx++);
    };

    skipBytes(size:number) {
        this.myIdx += size;
    }

    readInt32(isLittleEndian : boolean)
    {
        var aResult = this.myDynamicDataView.getInt32(this.myIdx, isLittleEndian);
        this.myIdx += 4;
        return aResult;
    };

    writeInt32(value: number, isLittleEndian : boolean) {
        this.myDynamicDataView.setInt32(this.myIdx, value, isLittleEndian);
        this.myIdx += 4;
    }

    writePlainString(str : string, encoding : Utf16LeEncoding, isLittleEndian : boolean)
    {
        const aSize = encoding.stringToBytes(
            this.myDynamicDataView,
            this.myIdx + 4,
            str
        );
        this.myDynamicDataView.setInt32(
            this.myIdx,
            aSize, 
            isLittleEndian
        );
        this.myIdx += 4 + aSize;
    }

    writeBytes(arrayBuffer: ArrayBuffer) {
        this.myDynamicDataView.writeBytes(this.myIdx, arrayBuffer);
        this.myIdx += arrayBuffer.byteLength;
    }

    readBytes(size : number)
    {
        const anArrayBuffer = this.myDynamicDataView.readBytes(this.myIdx, size);
        this.myIdx += size;
        return anArrayBuffer;
    };

    readPlainByteArray(isLittleEndian : boolean)
    {
        const aLength = this.readInt32(isLittleEndian);
        const anArrayBuffer = this.readBytes(aLength);
        return anArrayBuffer;
    }

    writePlainByteArray(arrayBuffer : ArrayBuffer, isLittleEndian : boolean)
    {
        // Length of the array.
        this.writeInt32(arrayBuffer.byteLength, isLittleEndian);
        
        // Bytes.
        this.writeBytes(arrayBuffer);
    }

    readPlainString(stringEncoding:Encoding, isLittleEndian : boolean)
    {
        const aSize = this.readInt32(isLittleEndian);
        
        const aResult = stringEncoding.stringFromBytes(this.myDynamicDataView, this.myIdx, aSize);
        this.myIdx += aSize;
        
        return aResult;
    }
}