// This is a port of the eneter class DynamicDataView
// https://github.com/DATMedia/Laqorr-Media-Player/blob/master/Chrome/lib/eneter/eneter-messaging-6.5.0.js#L1518

import { LoggerService } from "src/loggerService";

export class DynamicDataView {

    myArrayBuffer : ArrayBuffer;
    mySize : number;
    myDataView : DataView;
    constructor(arrayBufferOrSize : number | ArrayBuffer, readonly loggerService: LoggerService) {
        if(typeof(arrayBufferOrSize) === 'number') {
            this.mySize = arrayBufferOrSize;
            this.myArrayBuffer = new ArrayBuffer(this.mySize);
        } else {
            this.mySize = arrayBufferOrSize.byteLength;
            this.myArrayBuffer = arrayBufferOrSize;
        }
        this.myDataView = new DataView(this.myArrayBuffer);
    }

    private expand(minimalSize:number) {
        if(minimalSize <= this.mySize) {
            // nothing to do
            return;
        }
        // Create new
        const aNewSize = minimalSize + 50;
        const aNewArrayBuffer = new ArrayBuffer(aNewSize);

        // Copy
        new Uint8Array(aNewArrayBuffer).set(new Uint8Array(this.myArrayBuffer));

        // Set new
        this.mySize = aNewSize;
        this.myArrayBuffer = aNewArrayBuffer;
        this.myDataView = new DataView(this.myArrayBuffer);
    }

    setUint8(idx:number, value: number) {
        this.expand(idx+1);
        this.myDataView.setUint8(idx, value);
    }

    getUint16(idx:number, isLittleEndian: boolean) {
        return this.myDataView.getUint16(idx, isLittleEndian);
    }

    setUint16(idx: number, value: number, isLittleEndian : boolean) {
        this.expand(idx + 2);
        this.myDataView.setUint16(idx, value, isLittleEndian);
    }

    setInt32(idx: number, value: number, isLittleEndian : boolean) {
        this.expand(idx + 4);
        this.myDataView.setInt32(idx, value, isLittleEndian);
    }

    getInt32(idx: number, isLittleEndian: boolean) {
        return this.myDataView.getInt32(idx, isLittleEndian);
    }

    getUint8(idx: number)
    {
        return this.myDataView.getUint8(idx);
    }

    writeBytes(idx: number, arrayBuffer: ArrayBuffer) {
        const aMinimalSize = idx + arrayBuffer.byteLength;
        this.expand(aMinimalSize);
        
        // Copy incoming array buffer.
        new Uint8Array(
            this.myArrayBuffer,
            idx
        ).set(new Uint8Array(arrayBuffer));
    }

    readBytes(idx: number, size : number)
    {
        var anArrayBuffer = this.myDataView.buffer;
        
        // Get bytes in ArrayBuffer.
        var aResult = anArrayBuffer.slice(idx, idx + size);
        return aResult;
    }

    getArrayBuffer() {
        return this.myArrayBuffer;
    }
}