import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy, IterableDiffers } from '@angular/core';
import { NavigatableComponent, Side } from 'src/navigatableComponent';
import { EnrolmentClient } from '../../../eneter/enrolment/enrolmentClient';
import { VersionInfoProvider, BuildVersionInfo } from 'src/versionInfoProvider';
import { map, distinctUntilChanged, filter, delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { arrayFromMostRecentValues } from 'src/inputListener';
import { isFileSystemAvailable } from 'src/fileManager';
import { Router } from '@angular/router';

@Component({
    templateUrl: "./customer-page.component.html",
    styleUrls: ["./customer-page.component.scss"]
})
export class CustomerPageComponent extends NavigatableComponent implements OnInit, AfterViewInit, OnDestroy  {
    constructor(
        readonly enrolmentClient: EnrolmentClient,
        private readonly versionInfoProvider: VersionInfoProvider,
        private readonly router: Router
    ) {
        super();
    }

    versionInfo: BuildVersionInfo;

    enrolmentClientStatusSubscription: Subscription;
    async ngOnInit() {
        this.versionInfo = await this.versionInfoProvider.getVersionInfo();
        // If we switch from unenrolled to enrolled, then we want to
        // automatically launch
        this.enrolmentClientStatusSubscription = this.enrolmentClient.status
            .pipe(
                map(status => status.isEnrolled),
                distinctUntilChanged(),
                arrayFromMostRecentValues(2),
                filter(values => !values[0] && values[1]),
                delay(0) // Pushes the handler to the end of the processing queue, allowing other controls to initialize
            ).subscribe({
                next: () => {
                    this.router.navigateByUrl('/display');
                }
            });
    }

    async ngOnDestroy() {
        if(this.enrolmentClientStatusSubscription) {
            this.enrolmentClientStatusSubscription.unsubscribe();
            this.enrolmentClientStatusSubscription = null;    
        }
    }

    ngAfterViewInit(): void {
    }

    get isFileSystemSupported() : boolean {
        return isFileSystemAvailable();
    }

    focus(from:Side) {
        if(this._enrolmentClientView) {
            this._enrolmentClientView.focus(from);
        } else {
            this.enrolmentClientViewFocusPending = from;
        }
    }

    private enrolmentClientViewFocusPending?: Side = undefined;
    private _enrolmentClientView: NavigatableComponent;

    @ViewChild('enrolmentClientView') set enrolmentClientView(value: NavigatableComponent) {
        this._enrolmentClientView = value;
        if(this.enrolmentClientViewFocusPending && this._enrolmentClientView) {
            this._enrolmentClientView.focus(this.enrolmentClientViewFocusPending);
            this.enrolmentClientViewFocusPending = undefined;
        }
    }
}