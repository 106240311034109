import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstalledApplicationsViewComponent } from '../components/applications/installed-applications-view/installed-applications-view.component';
import { AppComponent } from '../components/app/app.component';
import { HomeComponent } from '../components/home/home.component';
import { RootComponent } from '../components/root/root.component';
import { DisplayComponent } from '../components/display/display.component';
import { InstalledPackagesViewComponent } from '../components/packages/installed-packages-view/installed-packages-view.component';
import { PackageViewComponent } from '../components/packages/package-view/package-view.component';
import { ApplicationViewComponent } from '../components/applications/application-view/application-view.component';
import { LocalSettingsViewComponent } from '../components/local-settings-view/local-settings-view.component';
import { CustomerPageComponent } from '../components/customer-page/customer-page.component';

const routes: Routes = [
    {
        path: '',
        component: RootComponent
    },
    {
        path: 'display',
        component: DisplayComponent
    },
    { 
        path: 'setup',
        component: AppComponent,
        children: [
            { path: 'customer-component', component: CustomerPageComponent },
            { path: 'home-component', component: HomeComponent},
            { path: 'package-component', component: InstalledPackagesViewComponent },
            { path: 'package/:id', component: PackageViewComponent },
            { path: 'application-component', component: InstalledApplicationsViewComponent },
            { path: 'application/:id', component: ApplicationViewComponent },
            { path: 'local-settings-component', component: LocalSettingsViewComponent },
            { path: '', component: CustomerPageComponent}
        ]
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    
}