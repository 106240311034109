export {}
declare global {
    interface DateConstructor {
        new (value: Date): Date;
        parseDateString(s: string): Date;
    }

    interface Date{
        stringFormat: (f: string) => string;
    }
}

//MM month
//dd day
//yyyy year
//HH hours
//mm minutes
//ss seconds
Date.prototype.stringFormat = function (format:string):string {
    var day = this.getDate();
    var month = this.getMonth() + 1;
    var year = this.getFullYear();

    var hours = this.getHours();
    var minutes = this.getMinutes();
    var seconds = this.getSeconds();

    function convertToCorrectFormat(number:number):any {
        if (number < 10) {
            return "0" + number;
        }

        return number;
    }

    return format.replace("dd", convertToCorrectFormat(day))
                 .replace("MM", convertToCorrectFormat(month))
                 .replace("yyyy", year)
                 .replace("HH", convertToCorrectFormat(hours))
                 .replace("mm", convertToCorrectFormat(minutes))
                 .replace("ss", convertToCorrectFormat(seconds));
};

Date.parseDateString = function (s:string):Date {
	let tokens = s.split('-');
	let year = parseInt(tokens[0], 10);
	let month = parseInt(tokens[1], 10) - 1;
	let day = parseInt(tokens[2], 10);
	var date = new Date(year, month, day, 0, 0, 0, 0);
	return date;
};




