import { Component, Input } from '@angular/core';
import { Side, FocusRelinquishedArgs } from '../../../../navigatableComponent';
import { TileComponentBase } from 'src/tileComponentBase';

@Component({
    templateUrl: 'packages-page.component.html',
    selector: 'packages-page'
})
export class PackagesPageComponent extends TileComponentBase<tizen.PackageInformation>  {
 
    cloneItem(p: tizen.PackageInformation) : tizen.PackageInformation {
        return {
            author: p.author,
            dataSize: p.dataSize,
            description: p.description,
            iconPath: p.iconPath,
            id: p.id,
            lastModified: p.lastModified,
            totalSize: p.totalSize,
            name: p.name,
            version: p.version
        };
    }

    @Input('items')
    public set items(itemsArray: tizen.PackageInformation[]) {
        super.setItems(itemsArray);
    }
}