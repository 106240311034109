import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

export interface IPagedData {
    pageSize : number;
    canIncrement: Observable<boolean>;
    canDecrement: Observable<boolean>;
    page: number;
    readonly pageChange: Observable<number>;
}


export class PagedDataSource<T> implements IPagedData {

    private readonly pageSizeChangeSubject  = new BehaviorSubject(16); 
    public get pageSize() : number {
        return this.pageSizeChangeSubject.getValue();
    }

    public set pageSize(value: number) {
        if(value !== this.pageSizeChangeSubject.getValue()) {
            this.pageSizeChangeSubject.next(value);
        }
    }

    public get pageSizeChange() : Observable<number> {
        return this.pageSizeChangeSubject.asObservable();
    }

    private pageSubject = new BehaviorSubject(0);

    public get page(): number {
        return this.pageSubject.getValue();
    }
    public set page(value: number) {
        if(value !== this.pageSubject.getValue()) {
            console.log('pagedDataSource: page is changing');
            this.pageSubject.next(value);
        }
    }
    public get pageChange(): Observable<number> {
        return this.pageSubject.asObservable();
    }

    private readonly dataSubject = new BehaviorSubject<T[]>([]);

    public get data(): T[] {
        return this.dataSubject.getValue();
    }

    public set data(value: T[]) {
        console.log('pagedDataSource: data set method invoked');
        this.dataSubject.next(value);
    }

    private get dataChange(): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    public get canIncrement() : Observable<boolean> {
        return combineLatest(
            [this.pageChange, this.dataChange, this.pageSizeChange]
        ).pipe(
            map(
                ([page, data, pageSize]) => {
                    return ((page+1)*pageSize) < data.length;
                }
            )
        )
    }

    public get canDecrement() : Observable<boolean> {
        return this.pageChange.pipe(map(page => page > 0));
    }

    private _view: Observable<T[]>;
    public get view() : Observable<T[]> {
        this._view = this._view || combineLatest(
            [this.pageChange, this.dataChange, this.pageSizeChange]
        ).pipe(
            map(
                ([page, data, pageSize]) => {
                    console.log('Paged datasource: recalculating visible data');
                    return data.slice(page*pageSize, (page+1)*pageSize);
                }
            )
        );
        return this._view;
    }
}