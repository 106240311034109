/**
 * Event argument used to notify that duplex input channel received a message.
 * @class
 */
export class DuplexChannelMessageEventArgs
{
    constructor(
        /**
         * Returns the channel id identifying the receiver of request messages. (e.g. ws://127.0.0.1:8090/).
         */
        readonly ChannelId : string,
        /**
         * Returns the message.
         */
        readonly Message : string | ArrayBuffer,
        /**
         * Returns the unique logical id identifying the receiver of response messages.
         */
        readonly ResponseReceiverId : string
    ) {
    }
};