import { Injectable } from '@angular/core';
import * as LaqorrProtobuf from "./laqorrProtobuf";
import { FileManager, fileManager } from "./fileManager";
import { FileUtils } from "./fileUtils";
import { LoggerService } from "./loggerService";
import { MediaPlayerFileSystem } from "./mediaPlayerFileSystem";
import { MediaPlayerFileInfoManager } from "./mediaPlayerFileInfoManager";
import { Logger } from "./logger";

interface QueryFolderResults {
	files: (tizen.File |FileEntry)[];
	folders: (tizen.File | DirectoryEntry)[];
    path: string;
}

@Injectable({
    providedIn: "root"
})
export class ControllerService {
    constructor(
        private readonly mediaPlayerFileSystem: MediaPlayerFileSystem,
        private readonly mediaPlayerFileInfoManager: MediaPlayerFileInfoManager,
        private readonly loggerService: LoggerService,
        private readonly logger: Logger
    ) {
    }

    private async queryFolder(path: string): Promise<QueryFolderResults> {
        try {
            const root = await fileManager.fileSystemReady;
            const dirEntry = await FileUtils.getTizenDirectory(root, path, { create: false, exclusive: false });
            return await new Promise((resolve, reject) => {
                const files: (tizen.File | FileEntry)[] = [];
                const folders: (tizen.File | DirectoryEntry)[] = []; 
                fileManager.readAllDirectoryEntries(
                    dirEntry,
                    (entries) => {
                    entries.forEach((entry: tizen.File | Entry) => {
                        if (entry.isDirectory) {
                            folders.push(<tizen.File | DirectoryEntry>entry);
                        } else { // It's a file
                            files.push(<tizen.File | FileEntry>entry);
                        }
                    });
                }, () => {
                    resolve({
                        files: files,
                        folders: folders,
                        path: "/" + path
                    });
                });
            });
        } catch (e) {
            throw { error: e, path: "//" + path };
        }
    }

    private generateVirtualFolderDefinitions() {
        const virtualFolderDefinitions: LaqorrProtobuf.ClientInterface.VirtualFolderDefinition[] = [];

        for (let key in LaqorrProtobuf.ClientInterface.MediaPlayerFolder) {
            if (isNaN(Number(key))) {
                const mediaPlayerFolderKey = key;
                const virtualFolderDefinition:LaqorrProtobuf.ClientInterface.VirtualFolderDefinition = {
                    MediaPlayerFolder: LaqorrProtobuf.ClientInterface.MediaPlayerFolder[mediaPlayerFolderKey],
                    FullPath: "/" + key
                }
                virtualFolderDefinitions.push(virtualFolderDefinition);
            }
        }

        return virtualFolderDefinitions;
    }

    private async getMd5(file: tizen.File | FileEntry): Promise<string> {
        if (file.name.toLowerCase().endsWith("xml")) {
            return await FileUtils.getFileMd5ByChunks(file, this.loggerService);
        } else {
            return '';
        }
    }

    private async generateFileItems(files: (tizen.File | FileEntry)[]) {
        return await Promise.all(files.map(async (file) => {
            const md5 = await this.getMd5(file);
            const result = await FileUtils.getFileSizeAndModificationTime(file);
            return {
                FileName: file.name,
                FileSize: result.size,
                MD5: md5,
                LastModifiedDateTime: this.formatDate(result.modificationTime, "dd/MM/yyyy HH:mm:ss")
            };
        }));
    }

    private async generateFolderItems(folders: (tizen.File | DirectoryEntry)[]) {
        return await Promise.all(folders.map(async (folder) => {
            const result = await FileUtils.getFileSizeAndModificationTime(folder);
            return {
                FolderName: folder.name,
                LastModifiedDateTime: this.formatDate(result.modificationTime, "dd/MM/yyyy HH:mm:ss")
            };
        }));
    }

    private formatDate(date: Date, format: string): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const convertToCorrectFormat = (number: number) => {
            if (number < 10) {
                return "0" + number;
            }

            return number.toString();
        };

        return format.replace("dd", convertToCorrectFormat(day))
            .replace("MM", convertToCorrectFormat(month))
            .replace("yyyy", year.toString())
            .replace("HH", convertToCorrectFormat(hours))
            .replace("mm", convertToCorrectFormat(minutes))
            .replace("ss", convertToCorrectFormat(seconds));
    }

    async queryFolderContents(queryPath: LaqorrProtobuf.ClientInterface.QueryPath): Promise<LaqorrProtobuf.ClientInterface.FolderQueryResults> {
        const mediaFolder = queryPath.MediaPlayerFolder;
        const subFolder = queryPath.SubFolder;
        let path = fileManager.getMediaPlayerFolderName(mediaFolder);
        if (subFolder.length !== 0) {
            path += ("/" + subFolder);
        }
        try {
            const queryResults = await this.queryFolder(path);
            const fileItemsPromise = this.generateFileItems(queryResults.files);
            const folderItemsPromise = this.generateFolderItems(queryResults.folders);
            const results = await Promise.all([fileItemsPromise, folderItemsPromise]);
            return {
                VirtualFolderDefinitions: this.generateVirtualFolderDefinitions(),
                Status: LaqorrProtobuf.ClientInterface.FolderQueryResultsStatus.Success,
                FolderPath: queryResults.path,
                ChildFolders: <LaqorrProtobuf.ClientInterface.FolderItem[]>results[1],
                ChildFiles: <LaqorrProtobuf.ClientInterface.FileItem[]>results[0]
            };
        } catch (e) {
            const folderQueryResults = {
                VirtualFolderDefinitions: null,
                Status: LaqorrProtobuf.ClientInterface.FolderQueryResultsStatus.Success,
                FolderPath: path,
                ChildFolders: null,
                ChildFiles: null
            };
            if (e && e.error && e.error.name === "NotFoundError") {
                folderQueryResults.Status = LaqorrProtobuf.ClientInterface.FolderQueryResultsStatus.NotFound;
                folderQueryResults.FolderPath = e.path;
            }
            return folderQueryResults;
        }
    }

    async deleteFile(deleteFileParameters: LaqorrProtobuf.ClientInterface.DeleteFileOrFolderParameter): Promise<any> {
        let path = deleteFileParameters.Path.replace("\\", "/");
        if (path.length > 0 && path.startsWith("/")) {
            path = path.slice(1);
        }
        const root= await fileManager.fileSystemReady;
        const fileEntry = await FileUtils.getTizenFile(root, path, { create: false, exclusive: false });
        
        this.logger.debug("controllerService", `Deleting the file ${path}`);
        await FileUtils.deleteFileOrDirectory(fileEntry);
        this.mediaPlayerFileSystem.onFileDeleted.next(fileEntry);
        this.mediaPlayerFileInfoManager.notifyDeletedFilesToServer([fileEntry.fullPath]);
    }

    async deleteFolder(deleteFileParameters: LaqorrProtobuf.ClientInterface.DeleteFileOrFolderParameter): Promise<any> {
        console.log('controllService.deleteFolder invoked');
        let path = deleteFileParameters.Path.replace("\\", "/");
        if (path.length > 0 && path.startsWith("/")) {
            path = path.slice(1);
        }
        const root= await fileManager.fileSystemReady;
        const dirEntry = await FileUtils.getTizenDirectory(root, path, { create: false, exclusive: false });
        const files = await fileManager.getAllFiles(dirEntry);
        await FileUtils.deleteFileOrDirectory(dirEntry);

        const fullPaths:string[] = new Array<string>(files.length);
        for(let i = 0; i < files.length; ++i) {
            fullPaths[i] = files[i].fullPath;
        }
        this.mediaPlayerFileInfoManager.notifyDeletedFilesToServer(fullPaths);
    }
}
