import { Observable, fromEvent } from 'rxjs';
import { scan, map, filter } from 'rxjs/operators';


function appendToRollingArray<T>(arrayMaxLength: number) : (a : T[], item : T) => T[] {
    return (a : T[], item: T) =>  
        [
            ...(
                a.length < arrayMaxLength
                ? a 
                : a.slice(a.length - arrayMaxLength + 1)
            ), 
            item
        ];
}

export function arrayFromMostRecentValues<T>(maxLength: number) : (input: Observable<T>) => Observable<T[]> {
    return scan(appendToRollingArray(maxLength), []);
}

export function watchMostRecentKeydowns(length: number) : Observable<KeyboardEvent[]> {
    return fromEvent(window, "keydown")
        .pipe(
            arrayFromMostRecentValues<KeyboardEvent>(length)
        );
}

export function listenForEasterEgg(...values: string[]) : Observable<void> {
    return watchMostRecentKeydowns(values.length)
        .pipe(
            filter(
                (value:KeyboardEvent[]) => {
                    if(value.length != values.length) {
                        return false;
                    }
                    return value.every(
                        (v, i) => v.key === values[i]
                    );
                }
            ),
            map(() => {}) // Converts it to Observable<void>
        );
}