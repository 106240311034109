// This was originally copied from here: http://sourcebox.io/39cc83f38263adee5bc655f11fbcefab/html
// Since then it has been modified to take data incrementally

import { LoggerService } from "./loggerService";

let globalLoggerService: LoggerService;

// Uint8Array.slice polyfill
if(!Uint8Array.prototype.slice) {
    Uint8Array.prototype.slice = function(begin?: number, end?: number) {
        if(begin === void 0) {
            begin = 0;
        }
        if(end === void 0) {
            this.byteLength;
        }

        begin = Math.floor(begin);
        end = Math.floor(end);
        if(begin < 0) {
            begin += this.byteLength;
        }
        if(end < 0) {
            end += this.byteLength;
        }
        //The range specified by the `begin` and `end` values is clamped to the 
        //valid index range for the current array.
        begin = Math.min(Math.max(0, begin), this.byteLength);
        end = Math.min(Math.max(0, end), this.byteLength);
        
        //If the computed length of the new ArrayBuffer would be negative, it 
        //is clamped to zero.
        if (end - begin <= 0) {
            return new Uint8Array(0);
        }
        globalLoggerService.logDebug(`Uint8Array.prototype.slice. begin=${begin}, end=${end}, byteLength=${this.byteLength}`);
        const result = new ArrayBuffer(end - begin);
        const resultBytes = new Uint8Array(result);
        // Can I just return sourceBytes?
        globalLoggerService.logDebug(`Uint8Array.prototype.slice. Invoking new Uint8Array(this.buffer, ${begin}, ${end-begin})`);
        const sourceBytes = new Uint8Array(this.buffer, begin, end-begin);
        globalLoggerService.logDebug(`Uint8Array.prototype.slice. Invoking resultBytes.set(sourceBytes). sourceBytes.byteLength = ${sourceBytes.byteLength}, resultBytes.byteLength = ${resultBytes.byteLength}`);
        resultBytes.set(sourceBytes);
        globalLoggerService.logDebug(`Uint8Array.prototype.clice. Calling new Uint8Array(resultBytes)`);
        return new Uint8Array(resultBytes);
    }
}

export class Md5Chunk {
    public constructor(readonly loggerService: LoggerService) {
        globalLoggerService = loggerService;
    }
    /*
    * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
    * Digest Algorithm, as defined in RFC 1321.
    * Copyright (C) Paul Johnston 1999 - 2000.
    * Updated by Greg Holt 2000 - 2001.
    * See http://pajhome.org.uk/site/legal.html for details.
    */

    /*
    * Convert a 32-bit number to a hex string with ls-byte first
    */
    private static hex_chr:string = "0123456789abcdef";

    private static rhex(num:number):string {
        let str = "";
        for (let j = 0; j <= 3; j++) {
            str += Md5Chunk.hex_chr.charAt((num >> (j * 8 + 4)) & 0x0F) +
            Md5Chunk.hex_chr.charAt((num >> (j * 8)) & 0x0F);
        }
        return str;
    }

    /*
    * Add integers, wrapping at 2^32. This uses 16-bit operations internally
    * to work around bugs in some JS interpreters.
    */
    private static add(x:number, y:number):number {
        var lsw = (x & 0xFFFF) + (y & 0xFFFF);
        var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return (msw << 16) | (lsw & 0xFFFF);
    }

    /*
    * Bitwise rotate a 32-bit number to the left
    */
    private static rol(num:number, cnt:number):number {
        return (num << cnt) | (num >>> (32 - cnt));
    }

    /*
    * These functions implement the basic operation for each round of the
    * algorithm.
    */
    private static cmn(q:number, a:number, b:number, x:number, s:number, t:number): number {
        const add = Md5Chunk.add;
        const rol = Md5Chunk.rol;
        return add(rol(add(add(a, q), add(x, t)), s), b);
    }

    private static ff(a:number, b:number, c:number, d:number, x:number, s:number, t:number) : number {
        const cmn = Md5Chunk.cmn;
        return cmn((b & c) | ((~b) & d), a, b, x, s, t);
    }

    private static gg(a:number, b:number, c:number, d:number, x:number, s:number, t:number) : number {
        return Md5Chunk.cmn((b & d) | (c & (~d)), a, b, x, s, t);
    }

    private static hh(a:number, b:number, c:number, d:number, x:number, s:number, t:number) : number {
        return Md5Chunk.cmn(b ^ c ^ d, a, b, x, s, t);
    }

    private static ii(a:number, b:number, c:number, d:number, x:number, s:number, t:number) :number {
        return Md5Chunk.cmn(c ^ (b | (~d)), a, b, x, s, t);
    }


    private static sizeOfBlock = 16;

    private a = 1732584193;
    private b = -271733879;
    private c = -1732584194;
    private d = 271733878;

    private static sizeOfBuffer = 64;
    // Buffer will only take UInt32
    private buffer:Uint8Array = new Uint8Array(Md5Chunk.sizeOfBuffer);

    private i:number;
    private olda:number; 
    private oldb:number; 
    private oldc:number; 
    private oldd:number;

    private processBlock(block:Uint8Array|Uint32Array) {
        let uint32:Uint32Array = null;
        if (block instanceof Uint8Array) {
            uint32 = Md5Chunk.uint8ArrayToUint32Array(block, Md5Chunk.sizeOfBlock);
        } else {
            uint32 = block;
        }

        this.processChunk(uint32);
    }

    private totalMessageSize:number = 0;
    private writeIndex:number = 0;

    
    private bufferOffset:number = 0;
    private expectedEnd:number = 0;
    private actualEnd:number = 0;

    receiveBits(arrayBuffer: ArrayLike<number>) {
        let array = new Uint8Array(arrayBuffer);
        let arrayLength = array.length;
        this.writeIndex = 0;
        
        this.totalMessageSize += arrayBuffer.length * 8;
        let arrayBufferLength = arrayBuffer.length - 1;

        while (this.writeIndex <= arrayBufferLength) {
            if (this.bufferOffset !== 0) {
                this.expectedEnd = this.writeIndex + Md5Chunk.sizeOfBuffer - this.buffer.length;
                this.actualEnd = arrayLength >= this.expectedEnd ? this.expectedEnd : arrayLength;
                this.bufferOffset = Md5Chunk.appendBuffer(this.buffer, this.bufferOffset, array, this.writeIndex, this.actualEnd);
            } else {
                this.expectedEnd = this.writeIndex + Md5Chunk.sizeOfBuffer;
                this.actualEnd = arrayLength >= this.expectedEnd ? this.expectedEnd : arrayLength;
                this.bufferOffset = Md5Chunk.assignValues(this.buffer, array, this.writeIndex, this.actualEnd);
            }

            if (this.buffer.length === Md5Chunk.sizeOfBuffer && this.bufferOffset === 0) {
                this.processBlock(this.buffer);
            }

            this.writeIndex += this.buffer.length;
        }
    }

    static assignValues(buffer1:Uint8Array, buffer2:Uint8Array, start: number, end:number) :number {
        let length = end - start;
        for (var i = 0; i < length; i++) {
            buffer1[i] = buffer2[i + start];
        }

        return length % Md5Chunk.sizeOfBuffer;
    }

    private static appendBuffer(buffer1:Uint8Array, buffer1Offset:number, buffer2:Uint8Array, buffer2Start:number, buffer2End:number) {
        let length = buffer2End - buffer2Start;
        for (let i = 0; i < length; i++) {
            buffer1[i + buffer1Offset] = buffer2[i + buffer2Start];
        }
        return (buffer1.length + length) % Md5Chunk.sizeOfBuffer;
    }

    public complete() : string {
        if(!this.buffer.slice) {
            this.loggerService.logError(`typeof(this.buffer.slice) == ${typeof(this.buffer.slice)}`);
        }
        this.buffer = this.buffer.slice(0, this.bufferOffset);
        var theNumberOfBlocksLeft = ((this.buffer.length + 8) >> 6) + 1;
        for (var num = 0; num < theNumberOfBlocksLeft; num++) {
            var newBlock = new Uint32Array(16);
            if (num === 0) {
                newBlock = Md5Chunk.uint8ArrayToUint32Array(this.buffer, Md5Chunk.sizeOfBlock);
                newBlock[this.buffer.length >> 2 % 16] |= 0x80 << (((this.totalMessageSize / 8) % 4) * 8);
            }

            if (num === theNumberOfBlocksLeft - 1) {
                newBlock[14] = this.totalMessageSize;
                newBlock[15] = ((this.totalMessageSize - (this.totalMessageSize >>> 0)) / Math.pow(2, 32)) >> 0;
            }

            this.processBlock(newBlock);
        }
        return Md5Chunk.rhex(this.a) + Md5Chunk.rhex(this.b) + Md5Chunk.rhex(this.c) + Md5Chunk.rhex(this.d);
    }

    private static emptyUint32():Uint32Array {
        return new Uint32Array(32);
    }


    private static uint8ArrayToUint32Array(uint8Array:Uint8Array, sizeOfUint32Array:number):Uint32Array {
        if (uint8Array.length === 64) {
            return new Uint32Array(uint8Array.buffer);
        } else {
            var uint32Array = new Uint32Array(sizeOfUint32Array);
            for (var i = 0; i < sizeOfUint32Array; i++) {
                uint32Array[i] = (uint8Array[i * 4 + 3] << 24 | uint8Array[i * 4 + 2] << 16 | uint8Array[i * 4 + 1] << 8 | uint8Array[i * 4]);
            }
            return uint32Array;
        }
    }


    processChunk(chunk:Uint32Array):void {

    const ff = Md5Chunk.ff;
    const gg = Md5Chunk.gg;
    const add = Md5Chunk.add;
    const hh = Md5Chunk.hh;
    const ii = Md5Chunk.ii;

        this.olda = this.a;
        this.oldb = this.b;
        this.oldc = this.c;
        this.oldd = this.d;

        this.a = ff(this.a, this.b, this.c, this.d, chunk[0], 7, -680876936);
        this.d = ff(this.d, this.a, this.b, this.c, chunk[1], 12, -389564586);
        this.c = ff(this.c, this.d, this.a, this.b, chunk[2], 17, 606105819);
        this.b = ff(this.b, this.c, this.d, this.a, chunk[3], 22, -1044525330);
        this.a = ff(this.a, this.b, this.c, this.d, chunk[4], 7, -176418897);
        this.d = ff(this.d, this.a, this.b, this.c, chunk[5], 12, 1200080426);
        this.c = ff(this.c, this.d, this.a, this.b, chunk[6], 17, -1473231341);
        this.b = ff(this.b, this.c, this.d, this.a, chunk[7], 22, -45705983);
        this.a = ff(this.a, this.b, this.c,this. d, chunk[8], 7, 1770035416);
        this.d = ff(this.d, this.a, this.b, this.c, chunk[9], 12, -1958414417);
        this.c = ff(this.c, this.d, this.a, this.b, chunk[10], 17, -42063);
        this.b = ff(this.b, this.c, this.d, this.a, chunk[11], 22, -1990404162);
        this.a = ff(this.a, this.b, this.c, this.d, chunk[12], 7, 1804603682);
        this.d = ff(this.d, this.a, this.b, this.c, chunk[13], 12, -40341101);
        this.c = ff(this.c, this.d, this.a, this.b, chunk[14], 17, -1502002290);
        this.b = ff(this.b, this.c, this.d, this.a, chunk[15], 22, 1236535329);

        this.a = gg(this.a, this.b, this.c, this.d, chunk[1], 5, -165796510);
        this.d = gg(this.d, this.a, this.b, this.c, chunk[6], 9, -1069501632);
        this.c = gg(this.c, this.d, this.a, this.b, chunk[11], 14, 643717713);
        this.b = gg(this.b, this.c, this.d, this.a, chunk[0], 20, -373897302);
        this.a = gg(this.a, this.b, this.c, this.d, chunk[5], 5, -701558691);
        this.d = gg(this.d, this.a, this.b, this.c, chunk[10], 9, 38016083);
        this.c = gg(this.c, this.d, this.a, this.b, chunk[15], 14, -660478335);
        this.b = gg(this.b, this.c, this.d, this.a, chunk[4], 20, -405537848);
        this.a = gg(this.a, this.b, this.c, this.d, chunk[9], 5, 568446438);
        this.d = gg(this.d, this.a, this.b, this.c, chunk[14], 9, -1019803690);
        this.c = gg(this.c, this.d, this.a, this.b, chunk[3], 14, -187363961);
        this.b = gg(this.b, this.c, this.d, this.a, chunk[8], 20, 1163531501);
        this.a = gg(this.a, this.b, this.c, this.d, chunk[13], 5, -1444681467);
        this.d = gg(this.d, this.a, this.b, this.c, chunk[2], 9, -51403784);
        this.c = gg(this.c, this.d, this.a, this.b, chunk[7], 14, 1735328473);
        this.b = gg(this.b, this.c, this.d, this.a, chunk[12], 20, -1926607734);

        this.a = hh(this.a, this.b, this.c, this.d, chunk[5], 4, -378558);
        this.d = hh(this.d, this.a, this.b, this.c, chunk[8], 11, -2022574463);
        this.c = hh(this.c, this.d, this.a, this.b, chunk[11], 16, 1839030562);
        this.b = hh(this.b, this.c, this.d, this.a, chunk[14], 23, -35309556);
        this.a = hh(this.a, this.b, this.c, this.d, chunk[1], 4, -1530992060);
        this.d = hh(this.d, this.a, this.b, this.c, chunk[4], 11, 1272893353);
        this.c = hh(this.c, this.d, this.a, this.b, chunk[7], 16, -155497632);
        this.b = hh(this.b, this.c, this.d, this.a, chunk[10], 23, -1094730640);
        this.a = hh(this.a, this.b, this.c, this.d, chunk[13], 4, 681279174);
        this.d = hh(this.d, this.a, this.b, this.c, chunk[0], 11, -358537222);
        this.c = hh(this.c, this.d, this.a, this.b, chunk[3], 16, -722521979);
        this.b = hh(this.b, this.c, this.d, this.a, chunk[6], 23, 76029189);
        this.a = hh(this.a, this.b, this.c, this.d, chunk[9], 4, -640364487);
        this.d = hh(this.d, this.a, this.b, this.c, chunk[12], 11, -421815835);
        this.c = hh(this.c, this.d, this.a, this.b, chunk[15], 16, 530742520);
        this.b = hh(this.b, this.c, this.d, this.a, chunk[2], 23, -995338651);

        this.a = ii(this.a, this.b, this.c, this.d, chunk[0], 6, -198630844);
        this.d = ii(this.d, this.a, this.b, this.c, chunk[7], 10, 1126891415);
        this.c = ii(this.c, this.d, this.a, this.b, chunk[14], 15, -1416354905);
        this.b = ii(this.b, this.c, this.d, this.a, chunk[5], 21, -57434055);
        this.a = ii(this.a, this.b, this.c, this.d, chunk[12], 6, 1700485571);
        this.d = ii(this.d, this.a, this.b, this.c, chunk[3], 10, -1894986606);
        this.c = ii(this.c, this.d, this.a, this.b, chunk[10], 15, -1051523);
        this.b = ii(this.b, this.c, this.d, this.a, chunk[1], 21, -2054922799);
        this.a = ii(this.a, this.b, this.c, this.d, chunk[8], 6, 1873313359);
        this.d = ii(this.d, this.a, this.b, this.c, chunk[15], 10, -30611744);
        this.c = ii(this.c, this.d, this.a, this.b, chunk[6], 15, -1560198380);
        this.b = ii(this.b, this.c, this.d, this.a, chunk[13], 21, 1309151649);
        this.a = ii(this.a, this.b, this.c, this.d, chunk[4], 6, -145523070);
        this.d = ii(this.d, this.a, this.b, this.c, chunk[11], 10, -1120210379);
        this.c = ii(this.c, this.d, this.a, this.b, chunk[2], 15, 718787259);
        this.b = ii(this.b, this.c, this.d, this.a, chunk[9], 21, -343485551);

        this.a = add(this.a, this.olda);
        this.b = add(this.b, this.oldb);
        this.c = add(this.c, this.oldc);
        this.d = add(this.d, this.oldd);
    }
}
