import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { FocusRelinquishedArgs, NavigatableComponent, Side } from '../../../navigatableComponent';
import { DeveloperModeSetting } from '../../../developerModeSettingProvider';

class NavigationGraphNode {
  element: NavigatableComponent;
  edges: { [key in Side]? : { targetEdge: Side, target: NavigatableComponent, isRouterOutlet: boolean } };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  activeComponent : NavigatableComponent;

  constructor(readonly developerModeSetting: DeveloperModeSetting, private readonly router: Router, private readonly location: Location) {
  } 

  navigationGraph : NavigationGraphNode[] = [];

  private resetNavigationGraph() {
    this.navigationGraph = [];
    if(this._topBannerComponent) {
      const topBannerNode: NavigationGraphNode = {
        element: this._topBannerComponent,
        edges: {}
      };
      if(this._navigationSidebar) {
        topBannerNode.edges["Left"] = {
          target: this._navigationSidebar,
          targetEdge: "Right",
          isRouterOutlet: false
        };
      }
      if(this.activeComponent) {
        topBannerNode.edges["Bottom"] = {
          target: this.activeComponent,
          targetEdge: "Top",
          isRouterOutlet: true
        }
      }
      this.navigationGraph.push(topBannerNode);
    }

    if(this._navigationSidebar) {
      const navigationSidebarNode: NavigationGraphNode = {
        element: this._navigationSidebar,
        edges: {}
      };
      if(this._topBannerComponent) {
        navigationSidebarNode.edges.Top = {
          target: this._topBannerComponent,
          targetEdge: "Bottom",
          isRouterOutlet: false
        }
      }
      if(this.activeComponent) {
        navigationSidebarNode.edges.Right = {
          target: this.activeComponent,
          targetEdge: "Left",
          isRouterOutlet: true
        }
      }
      this.navigationGraph.push(navigationSidebarNode);
    }
    if(this.activeComponent) {
      const activeComponentNode: NavigationGraphNode = {
        element: this.activeComponent,
        edges: {}
      };
      if(this._topBannerComponent) {
        activeComponentNode.edges.Top = {
          target: this._topBannerComponent,
          targetEdge: "Bottom",
          isRouterOutlet: false
        }
      }
      if(this._navigationSidebar) {
        activeComponentNode.edges.Left = {
          target: this._navigationSidebar,
          targetEdge: "Right",
          isRouterOutlet: false
        }
      }
      this.navigationGraph.push(activeComponentNode);
    }
  }

  ngOnInit(): void {
    fromEvent(window, "keydown")
      .subscribe({
        next: (event:KeyboardEvent) => {
          if(event.key == "XF86Back" || event.key == "BrowserBack") {
            event.preventDefault();
            console.log(`Invoking this.location.back(). Current Path is ${this.location.path()}`);

            this.location.back();
            console.log(`Afterwards, Current Path is ${this.location.path()}`);
          }
        }
      });
  }

  focusingOnRouterOutlet = false;

  ngAfterViewInit() {
    this.router.navigate(["setup/customer-component"]);
    this._topBannerComponent.focus("Bottom");
  }

  navigationKeySubscription: Subscription;

 
  title = 'App Component';


  focusRelinquishedSubscription : Subscription;

  hasFocus = false;

  componentFocusRelinquished(args:FocusRelinquishedArgs) {
    console.log('appcomponent.componentFocusRelinquished invoked');
    // Find it in the graph
    const graphNode = this.navigationGraph.find(n => n.element === args.component);
    if(graphNode) {
      const matchingEdge = graphNode.edges[args.fromSide];
      if(matchingEdge) {
        matchingEdge.target.focus(matchingEdge.targetEdge);
        this.focusingOnRouterOutlet = matchingEdge.isRouterOutlet;
        return;
      }
    }
    args.component.focus(args.fromSide);
  }

  onRouteActivated(event) {
    console.log("app.component onRouteActivated");
    if(this.focusRelinquishedSubscription) {
      this.focusRelinquishedSubscription.unsubscribe();
      this.focusRelinquishedSubscription = null;
    }
    if(event instanceof NavigatableComponent)
    {
      console.log('event is an instance of NavigatableComponent');

      this.activeComponent = event;
      this.focusRelinquishedSubscription = this.activeComponent.focusRelinquished.subscribe(
        (args:FocusRelinquishedArgs) => {
          this.componentFocusRelinquished(args);
        }
      );
      if(this.focusingOnRouterOutlet) {
        this.activeComponent.focus("Unknown");
      }

    }
    else
    {
      this.activeComponent = undefined;
    }
    this.resetNavigationGraph();
  }

  onRouteDeactivated(event) {
    console.log('onRouteDeactivated');
  }

  private _topBannerComponent : NavigatableComponent;
  private _navigationSidebar: NavigatableComponent;
  @ViewChild('topBanner') set topBannerComponent(component: NavigatableComponent) {
    console.log('topBannerComponent set invoked');
    this._topBannerComponent = component;
    this.resetNavigationGraph();
  }

  @ViewChild('navigationSidebar') set navigationSidebarComponent(component: NavigatableComponent) {
    console.log('navigationSidebar set invoked');
    this._navigationSidebar = component;
    this.resetNavigationGraph();
  }
}