import { Component } from '@angular/core';
import { MethodCallDispatcher } from 'src/eneter/methodCall/methodCallDispatcher';


@Component({
    selector: 'method-call-dispatcher-view',
    templateUrl: 'method-call-dispatcher-view.component.html'
})
export class MethodCallDispatcherViewComponent {
    constructor(readonly methodCallDispatcher: MethodCallDispatcher) {
    }
}