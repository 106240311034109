import { Injectable } from '@angular/core';
import { ScreenPowerManager } from './screenPowerManager';
import { ServerReportingTimer } from '../serverReportingTimer';

// All this class does is watch the screen power manager, waiting for its state to change
// When the state does change it triggers a server update.
// Another possibelr efactor is for it to also turn the tizen screen on and off.
@Injectable({
    providedIn: "root"
})
export class ScreenPowerManagerWatcher {
    constructor(readonly screenPowerManager: ScreenPowerManager, readonly serverReportingTimer: ServerReportingTimer) {
    }

    startWatching() {
        console.log('ScreenPowerManagerWatcher start watching invoked');
        this.screenPowerManager
            .activationInfoObservable
            .subscribe(
                {
                    next: (activationInfo) => {
                        console.log('ScreenPowerManagerWatcher detected the activationInfo changed. Will now trigger update');
                        this.serverReportingTimer.manuallySendUpdate();
                    }
                }
            )
    }
}