import { Observable } from 'rxjs';
import { LaqorrFileEntry } from 'src/fileUtils';


export type HtmlElementDisplay = "block" | "none" | "inline-block" | "flex" | "inline-flex";

export type ExecutionId = number;

export type ExecutionCompleteArgs = {
    mediaFile: LaqorrFileEntry,
    executionId: ExecutionId,
    startTime: Date,
    succeeded: boolean
};

export interface DisplayHandler {
    stopAll() : void;

    play(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : void;
    prepareNext(mediaFile: LaqorrFileEntry) : void;

    readonly mediaExecutionComplete$: Observable<ExecutionCompleteArgs>; 
}

type PreparedElementHtmlElement = HTMLDivElement | HTMLVideoElement | HTMLObjectElement;


export interface PreparedElement<T extends PreparedElementHtmlElement> {
	file: LaqorrFileEntry;
    element: T; // HTMLDivElement | HTMLVideoElement | HTMLObjectElement;
    display: HtmlElementDisplay;
    zIndex: number;
}

export const zIndexes = {
    hiding: 1,
    pending: 8,
    // There will be a solid-coloured div acting as a "curtain" at z-index 9
    showing: 10,
    fading: 11
}


