import { analyzeAndValidateNgModules } from '@angular/compiler';

/**
 * Message decoded by the protocol formatter.<br/>
 * The protocol formatter is used for the internal communication between output and input channel.
 * When the channel receives a message it uses the protocol formatter to figure out if is is
 * 'Open Connection', 'Close Connection' or 'Data Message'.
 * Protocol formatter decodes the message and returns ProtocolMessage.
 * @class
 */
export class ProtocolMessage
{
    constructor(
        /**
        * Type of the message. This parameter is not used in Eneter for Javascript. 
        */
        MessageType:any,
         /**
         * Client id. This parameter is not used in Eneter for Javascript.
         */
        public readonly responseReceiverId : string,
            /**
         * Decoded message data.
        */
        public readonly Message:ArrayBuffer | string
    )
    {
    }
}