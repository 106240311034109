import { BehaviorSubject, Observable } from 'rxjs';


// We implement a number of components as Finite State Machines
// These handle events asynchronously
//
// There can be issues where a transitioning event happened
// while a different transitioning event was in process
//
// This ensures they all take place one at a time
//
export class FiniteStateMachineStateManager<T> {
    constructor(initialState: T) {
        this.currentStateSubject = new BehaviorSubject<T>(initialState);
    }
    private readonly currentStateSubject : BehaviorSubject<T>
    get currentState() : Observable<T> {
        return this.currentStateSubject.asObservable();
    }
    private isProcessingStateTransformations = false;
    private readonly stateTransformationQueue : Array<(oldState: T) => T | Promise<T>> = [];

    private async processStateTransformations() {
        if(this.isProcessingStateTransformations) {
            return;
        }
        this.isProcessingStateTransformations = true;
        try {
            while(this.stateTransformationQueue.length) {
                const method = this.stateTransformationQueue.splice(0, 1)[0];
                this.currentStateSubject.next(await method(this.currentStateSubject.getValue()));
            }
        } finally {
            this.isProcessingStateTransformations = false;
        }
    }

    public transition(transformation: (currentState: T) => T | Promise<T>) {
        this.stateTransformationQueue.push(transformation);
        this.processStateTransformations();
    } 
}