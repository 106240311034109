import { FileHierarchyNode } from "./fileHierarchyNode";
import { LaqorrDirectoryEntry, FileUtils } from "./fileUtils";
import { ClientInterface } from './laqorrProtobuf';
import "./extensions/arrayExtensions";


// This is code copied from the Chrome implementation
// which was written over several years before we discovered typescript modules
//
// There isn't a valid reason why it's so convoluted, and can be simplified
//
interface MediaPlayerFolder {
    [index: string]: number;
    Video: number;
    Music: number;
    VideoPlaylist: number;
    AudioPlaylist: number;
    VisualFeedContent: number;
    AudioMessage: number;
    AudioAdvert: number;
    MusicWhitelist: number;
    TemporaryFiles?: number;
}


function isTizenFileSystemAvailable() : boolean {
    return (
        (typeof(tizen) !== 'undefined') 
        && !!tizen 
        && (!!tizen.filesystem)
    );
}

function isWebkitRequestFileSystemAvailable() : boolean {
    return !!window.webkitRequestFileSystem;
}

export function isFileSystemAvailable() : boolean {
    return isTizenFileSystemAvailable() || isWebkitRequestFileSystemAvailable();
}

async function initializeFileSystem() : Promise<LaqorrDirectoryEntry> {
    if(isTizenFileSystemAvailable()) {
        return new Promise<tizen.File>(
            (resolve, reject) =>
            {
                tizen.filesystem.resolve('wgt-private', resolve, reject);
            }
        )
    }
    else if (isWebkitRequestFileSystemAvailable()) {
        return new Promise<DirectoryEntry>(
            (resolve, reject) =>
            {
                const requestedBytes = 1024*1024*1024; // 1GB
                const webkitPersistentStorage:any = (<any>navigator).webkitPersistentStorage;
                webkitPersistentStorage.requestQuota (
                    requestedBytes, 
                    (grantedBytes) => {  
                        console.log(`requestQuota returned ${grantedBytes} bytes`);
                        window.webkitRequestFileSystem(
                            window.PERSISTENT,
                            grantedBytes,
                            (fileSystem: FileSystem) => {
                                console.log(`Successfully retrieved file system. Name = ${fileSystem.name}`);
                                resolve(fileSystem.root);
                            },
                            reject
                        );
                    }, 
                    e => { 
                        console.log('requestQuota error', e);
                        reject(e); 
                    }
                );
            }
        );
    } else {
        throw "Could not detect the tizen or chrome filesystem";
    }
}

export class FileManager {
    readonly fileSystemReady: Promise<LaqorrDirectoryEntry> = initializeFileSystem();
    readonly temporaryFiles: string = "TemporaryFiles";
    readonly playerSettingsFile: string = "PlayerSettings.json";
    readonly mediaPlayerFolder: MediaPlayerFolder = {
        Video: ClientInterface.MediaPlayerFolder.Video,
        Music: ClientInterface.MediaPlayerFolder.Music,
        VideoPlaylist: ClientInterface.MediaPlayerFolder.VideoPlaylist,
        AudioPlaylist: ClientInterface.MediaPlayerFolder.AudioPlaylist,
        VisualFeedContent: ClientInterface.MediaPlayerFolder.VisualFeedContent,
        AudioMessage: ClientInterface.MediaPlayerFolder.AudioMessage,
        AudioAdvert: ClientInterface.MediaPlayerFolder.AudioAdvert,
        MusicWhitelist: ClientInterface.MediaPlayerFolder.MusicWhitelist,
        TemporaryFiles: ClientInterface.MediaPlayerFolder.TemporaryFiles
    };
    readonly audioFolderName = {
        Music: "Music",
        AudioMessage: "Music/AudioMessages",
        AudioAdvert: "Music/AudioAdverts"
    };
    readonly FILETYPE_IMAGE:string = 'FILETYPE_IMAGE';
    readonly FILETYPE_VIDEO:string = 'FILETYPE_VIDEO';
    readonly FILETYPE_TEXT:string = 'FILETYPE_TEXT';
    readonly FILETYPE_UNKNOWN:string = 'FILETYPE_UNKNOWN';
    readonly FILETYPE_MUSIC:string = 'FILETYPE_MUSIC';

    isAudio(folder: number): boolean {
        return folder === this.mediaPlayerFolder.AudioAdvert ||
			folder === this.mediaPlayerFolder.AudioMessage ||
			folder === this.mediaPlayerFolder.Music;
    }

    isAdvertOrMessage(folder: number): boolean {
        return folder === this.mediaPlayerFolder.AudioAdvert ||
			folder === this.mediaPlayerFolder.AudioMessage;
    }

    // TODO: Turn this into a promise
    readAllDirectoryEntries(
        directoryEntry: tizen.File | DirectoryEntry,
        handleEntries:(e:tizen.File[] | Entry[])=>void, 
        complete:()=>void
    ) {
        if(FileManager.isTizenFile(directoryEntry)) {
            directoryEntry.listFiles(
                entries => {
                    handleEntries(entries);
                    complete();
                }, function (e) {
                    console.error(e);
                }
            );
        } else {
            const reader = directoryEntry.createReader();
            reader.readEntries(
                entries => {
                    handleEntries(entries);
                    complete();
                },
                e => console.error(e)
            )
        }
    }

    getMediaPlayerFolderName(mediaPlayerFolder: any):string {
        if (mediaPlayerFolder === this.mediaPlayerFolder.AudioAdvert) {
            return "Music/AudioAdverts";
        } else if (mediaPlayerFolder === this.mediaPlayerFolder.AudioMessage) {
            return "Music/AudioMessages";
        } else {
            for (var k in this.mediaPlayerFolder) {
                if ((<any>this.mediaPlayerFolder)[k] == mediaPlayerFolder) {
					return k;
                }
            }
        }
    };

    public static isTizenFile(f: tizen.File | Entry) : f is tizen.File {
        return (
            (typeof(f.name) === 'string')
            && (typeof((<tizen.File>f).path) === 'string')
        );
    }

    async getMediaPlayerFolderEntry(mediaPlayerFolder: number): Promise<tizen.File | DirectoryEntry> {
        const folderName = this.getMediaPlayerFolderName(mediaPlayerFolder);
        const separatedByFolderPath = folderName.split(/[\/\\]+/);
        let folderReady = fileManager.fileSystemReady;
        for (let i = 0; i < separatedByFolderPath.length - 1; ++i) {
            folderReady = FileUtils.chainSubFolder(folderReady, separatedByFolderPath[i]);
        }
        const lastFolderName = separatedByFolderPath[separatedByFolderPath.length - 1];
        const fe = await folderReady;
        if (folderName) {
            return await FileUtils.getTizenDirectory(fe, lastFolderName, { exclusive: false, create: true });
        } else {
            throw `Unable to recognise media player folder id ${mediaPlayerFolder}`;
        }
    }

    async getFileHierarchy(directoryEntry: tizen.File | DirectoryEntry): Promise<FileHierarchyNode> {
        const node = new FileHierarchyNode(directoryEntry);
        return new Promise((resolve, reject) => {
            const allPromises:Promise<void>[] = [];
            this.readAllDirectoryEntries(
                directoryEntry,
                (entries:tizen.File[]) => {
                    for(let entry of entries) {
                        if(entry.isDirectory) {
                            const addToNodePromise: Promise<void> = (
                                async () => {
                                    const childNode = await this.getFileHierarchy(entry);
                                    node.children.push(childNode);
                                }
                            )();
                            allPromises.push(addToNodePromise);
                        } else {
                            node.files.push(entry);
                        }
                    }
                },
                async() => {
                    await Promise.all(allPromises);
                    resolve(node);
                }
            );
        });
    }

    async getExistingFileHierarchy(): Promise<FileHierarchyNode> {
        const root = await this.fileSystemReady;
        return await this.getFileHierarchy(root);
    }

    // This should be in FileUtils, not fileManager
    private static async recursivelyGetAllEntriesInFolder(folder: tizen.File | DirectoryEntry) : Promise<tizen.File[] | Entry[]> {
        if(FileManager.isTizenFile(folder)) {
            let children = await new Promise<tizen.File[]>(
                (resolve, reject) => {
                    folder.listFiles(
                        resolve,
                        reject
                    )
                }
            );
            for(let child of children.slice()) {
                if(child.isDirectory) {
                    children = [...children, ...<tizen.File[]>(await FileManager.recursivelyGetAllEntriesInFolder(child))]
                }
            }
            return children;
        } else {
            const reader = folder.createReader();
            let children = await new Promise<Entry[]>(
                (resolve, reject) => {
                    reader.readEntries(
                        resolve,
                        reject
                    );
                }
            );
            for(let child of children.slice()) {
                if(child.isDirectory) {
                    children = [...children, ...<Entry[]>(await FileManager.recursivelyGetAllEntriesInFolder(<DirectoryEntry>child))]
                }
            }
            return children;
        }
    } 

    // This should be in FileUtils, not fileManager
    async getAllFiles(dirEntry: tizen.File | DirectoryEntry): Promise<tizen.File[] | Entry[]> {
        return await FileManager.recursivelyGetAllEntriesInFolder(dirEntry);
    }

    async getAllFilesInDirectory(directory: string): Promise<tizen.File[] | Entry[]> {
        try {
            const root = await this.fileSystemReady;
            const dirEntry = await FileUtils.getTizenDirectory(root, directory, { create: false});
            return FileManager.recursivelyGetAllEntriesInFolder(dirEntry);
        } catch (e) {
            console.error("error when getSpecificDirectoryHierarchy " + e);
            return [];
        }
    }

    determineFileTypeFromName(fileName: string): string {
        if (!fileName) {
            return this.FILETYPE_UNKNOWN;
        }

        fileName = fileName.toLowerCase();

        const regexImage = new RegExp('\\.(png|jpg|jpeg|gif)$', 'i');
        if (regexImage.test(fileName)) {
            return this.FILETYPE_IMAGE;
        }
        const regexVideo = new RegExp('\\.(mp4|m4v|mov)$', 'i');
        if (regexVideo.test(fileName)) {
            return this.FILETYPE_VIDEO;
        }
        const regextText = new RegExp('\\.(txt|xml|Playlist)$', 'i');
        if (regextText.test(fileName)) {
            return this.FILETYPE_TEXT;

        }
        const regexMusic = new RegExp('\\.(mp3)$', 'i');

        if (regexMusic.test(fileName)) {
            return this.FILETYPE_MUSIC;
        }
        return this.FILETYPE_UNKNOWN;
    }
}

export const fileManager = new FileManager();
 