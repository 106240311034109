import { FileUtils, LaqorrFileEntry } from 'src/fileUtils';
import { fileManager, FileManager } from 'src/fileManager';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { getMediaFileNamesOfFailsafePlaylist } from './failSafePlaylistManager';
import { FileAvailabilityItem, watchFileSetForAvailabilityChanges } from 'src/fileSetWatcher';
import { getMediaFileNamesOfCurrentPlaylist } from './playlistScheduler';
import { combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LocalTimeZoneClock } from 'src/clock';

function extractAvailableFiles(contents: FileAvailabilityItem[]) : LaqorrFileEntry[] {
    if(!contents) {
        return [];
    }
    return contents
        .filter(f => f.IsReady)
        .map(f => f.File);
}

const MULTI_PLAYLIST_FILENAME = "MultiPlaylist.xml";

// Gets the list of video feed file entries to play, taking into account
// - The multiplaylist
// - The current date/time
// - The availability of files
// - The failsafe playlist
//
// This returns an observable which dynamically updates as the above parameters changes
//

export function getVideoFeedFileEntries(mediaPlayFileSystem: MediaPlayerFileSystem, localTimeZoneClock: LocalTimeZoneClock, sourceFolder: string) {
    const videoFolderName = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.Video);
    const feedContent = getMediaFileNamesOfCurrentPlaylist(
        FileUtils.getFeedFilePath(MULTI_PLAYLIST_FILENAME, sourceFolder),
        mediaPlayFileSystem,
        localTimeZoneClock
    ).pipe(
        mergeMap(fileList => watchFileSetForAvailabilityChanges(fileList, mediaPlayFileSystem, videoFolderName)),
        map(extractAvailableFiles)
    );
    const failsafeContent = getMediaFileNamesOfFailsafePlaylist(mediaPlayFileSystem)
        .pipe(
            mergeMap(fileList => watchFileSetForAvailabilityChanges(fileList, mediaPlayFileSystem, videoFolderName)),
            map(extractAvailableFiles)
        );
    return combineLatest(
        [feedContent, failsafeContent]
    ).pipe(
        map(
            (fileLists: LaqorrFileEntry[][]) => {
                for(let fileList of fileLists) {
                    if((fileList) && (fileList.length)) {
                        return fileList;
                    }
                }
                return [];
            }             
        )
    );
}


