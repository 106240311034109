import { Component, ViewChild, ElementRef } from "@angular/core";
import { NavigatableComponent, Side } from "src/navigatableComponent";
import { Router} from "@angular/router";


@Component({
    templateUrl: 'navigation-sidebar.component.html',
    selector: 'navigation-sidebar',
    styleUrls: ['navigation-sidebar.component.scss']
})
export class NavigationSidebar extends NavigatableComponent {

    constructor(private readonly router: Router) {
      super();
    }

    public readonly isRunningOnTizenDevice: boolean = (typeof(tizen) !== 'undefined');

    linksInVerticalOrder() {
      const onAll = [
        this.publicLink,
        this.homeLink,
        this.localSettingsLink
      ];

      if (this.isRunningOnTizenDevice) {
        return [
          ...onAll,
          this.packagesLink,
          this.applicationsLink,
        ];
      } else {
        return onAll
      }
    }

      lastFocusedHyperlinkIndex = 0;

      moveLinkFocus(transform: (index:number)=>number) {
        console.log('NavigationSidebar moveLinkFocus');
        const links = this.linksInVerticalOrder();
        let focusedLinkIndex = links.findIndex(v => document.activeElement == v.nativeElement); // v.nativeElement.focused);
        focusedLinkIndex = (focusedLinkIndex >= 0 ) ? focusedLinkIndex : this.lastFocusedHyperlinkIndex;

        const transformed = transform(focusedLinkIndex);
        if(transformed < 0) {
            return this.relinquishFocus("Top");
        } else if (transformed >= links.length) {
            return this.relinquishFocus("Bottom");
        } else {
          links[transformed].nativeElement.focus();
          this.lastFocusedHyperlinkIndex = transformed;
          const nativeElement = links[transformed].nativeElement;
          nativeElement.click();
//            const href = nativeElement.attributes['routerlink'];
//            console.log(`calling navigateByUrl to ${href.value}`);
//            this.router.navigateByUrl(href.value);
          return true;
        }
      }

    focus(from: Side) {
        super.focus(from);
        const element = this.linksInVerticalOrder()[this.lastFocusedHyperlinkIndex];
        element.nativeElement.focus();
    }

    moveFocusUp() {
      return this.moveLinkFocus(n => n-1);
    }

    moveFocusDown() {
      return this.moveLinkFocus(n => n+1);
    }
    

    @ViewChild('publicLink') publicLink: ElementRef<HTMLLinkElement>;
    @ViewChild('homeLink') homeLink: ElementRef<HTMLLinkElement>;
    @ViewChild('packagesLink') packagesLink: ElementRef<HTMLLinkElement>;
    @ViewChild('applicationsLink') applicationsLink: ElementRef<HTMLLinkElement>;
    @ViewChild('localSettingsLink') localSettingsLink: ElementRef<HTMLLinkElement>;
}