import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { NavigatableComponent } from 'src/navigatableComponent';
import { IPagedData } from 'src/pagedDataSource';
import { Observable } from 'rxjs';

enum PagerButton {
    decrement,
    increment
}

@Component({
    templateUrl: "pager.component.html",
    selector: "pager"
})
export class PagerComponent extends NavigatableComponent {

    @Input('dataSource')
    dataSource: IPagedData;

    incrementPage() {
        this.dataSource.page = this.dataSource.page + 1;
    }

    decrementPage() {
        this.dataSource.page = this.dataSource.page - 1;
    }

    focusedButton: PagerButton = PagerButton.decrement;


    private setFocusedButton(button: PagerButton) {
        this.focusedButton = button;
        switch(this.focusedButton) {
            case PagerButton.decrement:
                this.decrementButton.nativeElement.focus();
                break;
            case PagerButton.increment:
                this.incrementButton.nativeElement.focus();
                break;
        }
    }

    focus(from) {
        super.focus(from);
        let calledAlready = false;
        this.setFocusedButton(this.canIncrement ? PagerButton.increment : PagerButton.decrement);
    }

    private get canDecrement(): boolean {
        return this.decrementButton && !(this.decrementButton.nativeElement.disabled);
    } 

    private get canIncrement(): boolean {
        return this.incrementButton && !(this.incrementButton.nativeElement.disabled);
    }

    moveFocusLeft() {

        if(this.focusedButton === PagerButton.increment && this.canDecrement) {
            this.setFocusedButton(PagerButton.decrement);
            return true;
        } else {
            return super.moveFocusLeft();
        }
    }

    moveFocusRight() {
        if(this.focusedButton === PagerButton.decrement && this.canIncrement) {
            this.setFocusedButton(PagerButton.increment);
            return true;
        } else {
            return super.moveFocusRight();
        }
    }

    @ViewChild("incrementButton") incrementButton : ElementRef;
    @ViewChild("decrementButton") decrementButton: ElementRef;
}