export interface FontFamily {
    name: string;
    fileName: string;
}

export interface Font {
    isBold: boolean;
    isItalic: boolean;
    family: FontFamily;
}

export interface DisplaySection {
    font: Font;
    sizePortion: number;
    backgroundColor: string;
    foregroundColor: string;
    format: string;
    paddingLeft: number;
    paddingRight: number;
    paddingBottom: number;
    paddingTop: number;
    horizontalAlignment: string;
    verticalAlignment: string;
}

export function parseClockFeedSettings(xmlText: string): DisplaySection[] {
    const sections: DisplaySection[] = [];
    const parser: DOMParser = new DOMParser();
    const xmlDoc: Document = parser.parseFromString(xmlText, "text/xml");
    const clockSections = xmlDoc.getElementsByTagName('Section');
    const sectionsArray: Element[] = Array.prototype.slice.call(clockSections);
    sectionsArray.forEach((element: Element, index: number) => {
        const section: DisplaySection = <DisplaySection>{};
        const sizePosition = element.getAttribute('SizePortion');
        section.sizePortion = typeof sizePosition == 'undefined' ? 0 : parseInt(sizePosition);
        let backgroundColor = element.getAttribute('BackgroundColor');
        if (backgroundColor.startsWith('0x')) {
            backgroundColor = backgroundColor.replace('0x', '#');
        }
        section.backgroundColor = backgroundColor;
        let foregroundColor = element.getAttribute('ForegoundColor');
        if (foregroundColor.startsWith('0x')) {
            foregroundColor = foregroundColor.replace('0x', '#');
        }
        section.foregroundColor = foregroundColor;
        section.format = element.getAttribute('Format');
        section.paddingLeft = parseFloat(element.getAttribute('PaddingLeft'));
        section.paddingRight = parseFloat(element.getAttribute('PaddingRight'));
        section.paddingTop = parseFloat(element.getAttribute('PaddingTop'));
        section.paddingBottom = parseFloat(element.getAttribute('PaddingBottom'));
        section.horizontalAlignment = element.getAttribute('HorizontalAlignment');
        section.verticalAlignment = element.getAttribute('VerticalAlignment');
        const font = element.getElementsByTagName('Font')[0];
        section.font = <Font>{};
        section.font.isBold = font.getAttribute('IsBold') == 'true';
        section.font.isItalic = font.getAttribute('IsItalic') == 'true';
        const family = font.getElementsByTagName('Family')[0];
        section.font.family = <FontFamily>{};
        section.font.family.name = family.getAttribute('Name');
        section.font.family.fileName = family.getAttribute('FileName');
        sections.push(section);
    });
    return sections;
}





