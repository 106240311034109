import { Injectable } from '@angular/core'
import { PlayHistoryDatabase } from 'src/playHistoryDatabase';
import { ServerReportingTimer } from 'src/serverReportingTimer';


@Injectable({
    providedIn: "root"
})
export class VideoExecutionRecorder {

    private dateTimeOfLastReporting: Date;

    static readonly REPORTING_THRESHOLD = 60 * 60 * 1000;

    public constructor(private readonly playHistoryDatabase: PlayHistoryDatabase, private readonly serverReportingTimer: ServerReportingTimer) {
    }

    public async record(fileName: string, feedId: string, timeAtPlayStart: Date) {
        await this.playHistoryDatabase.recordPlayHistory(
            fileName,
            'Video',
            feedId,
            timeAtPlayStart
        );
        const mustSendUpdate = (!this.dateTimeOfLastReporting)
            || (new Date().getTime() - this.dateTimeOfLastReporting.getTime() > VideoExecutionRecorder.REPORTING_THRESHOLD);
        if(mustSendUpdate) {
            console.log('videoExecutionRecorder: manually sending the update');
            this.serverReportingTimer.manuallySendUpdate();
            this.dateTimeOfLastReporting = new Date();
        }
    }
}
