import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'server-selector',
    templateUrl: './server-selector.component.html'
})
export class ServerSelectorComponent implements OnInit {

    ngOnInit() {
        window.setTimeout(
            () => {
                this.webServerAddressElement.nativeElement.focus();
                this.webServerAddressElement.nativeElement.select();
            },
            0
        );
    }

    @Input() serverAddress : string;

    @Output() serverAddressSelected = new EventEmitter<string>();

    webServerInputKeyDown(args: KeyboardEvent) {
        if(args.keyCode === 65376) {
            this.focusOnButton();
        }
    }

    @ViewChild('webServerAddress') webServerAddressElement: ElementRef;
    @ViewChild('webServerAddressSubmitButton') webServerAddressSubmitButton: ElementRef;

    focusOnButton() {
        this.webServerAddressSubmitButton.nativeElement.focus();
    }
}