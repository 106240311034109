import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fileManager } from "../../../fileManager";
import { MediaPlayerFileSystem } from '../../../mediaPlayerFileSystem';

export type FeedTypeId = 
    "DATMedia.HtmlFeed" 
    | "DATMedia.ClockFeed"
    | "DATMedia.TickerFeed"
    | "Internal.NoContent"
    | "DATMedia.VideoFeed";

export interface ScreenLayoutContentFeed {
    id: string;
    typeId: FeedTypeId;
    sourceFolder: string;
    zIndex: number;
    top:number;
    left: number;
    width: number;
    height:number;
}

export interface ScreenLayoutContent {
    key: string;
    backgroundColor: string;
    expectedHeight: number;
    expectedWidth: number;
    feeds: ScreenLayoutContentFeed[];
}

export const emptyContent: ScreenLayoutContent = {
    key: 'empty',
    backgroundColor: 'black',
    expectedHeight: 1080,
    expectedWidth: 1920,
    feeds: [{
        id: "locallyGeneratedFeed",
        height: 1080,
        left: 0,
        top: 0,
        width: 1920,
        sourceFolder: "",
        typeId: "Internal.NoContent",
        zIndex: 1
    }]
}

export const SCREEN_LAYOUT_FILENAME = "ScreenLayouts.xml";

function parseScreenLayoutXml(xmlText: string) : ScreenLayoutContent {
    if(!xmlText) {
        return emptyContent;
    }
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, "text/xml");
    const feeds: {[key:string]: {id:string, typeId:FeedTypeId, sourceFolder:string}} = {};
    const visualFeeds = xmlDoc.getElementsByTagName('VisualFeed');
    const feedsArray: Element[] = Array.prototype.slice.call(visualFeeds);
    feedsArray.forEach((element, index) => {
        const id = element.getElementsByTagName('Id')[0].textContent;
        feeds[id] = {
            id: id,
            typeId: <FeedTypeId>element.getElementsByTagName('TypeId')[0].textContent,
            sourceFolder: element.getElementsByTagName('SourceFolder')[0].textContent
        };
    });
    // Assuming only one Screen Layout
    const screenLayout = xmlDoc.getElementsByTagName('ScreenLayout')[0];

    const screenHeightText = screenLayout.getElementsByTagName('ScreenHeight')[0].textContent;
    const screenWidthText = screenLayout.getElementsByTagName('ScreenWidth')[0].textContent;

    const content:ScreenLayoutContent = {
        key: xmlDoc.getElementsByTagName('Key')[0].textContent,
        backgroundColor: screenLayout.getElementsByTagName('BackgroundColor')[0].textContent,
        expectedHeight: parseInt(screenHeightText, 10),
        expectedWidth: parseInt(screenWidthText, 10),
        feeds: []
    };

    const feedAssignments = screenLayout.getElementsByTagName('FeedAssignment');
    const assignmentsArray: Element[] = Array.prototype.slice.call(feedAssignments);
    assignmentsArray.forEach((element, index) => {
        const top = parseInt(element.getElementsByTagName('Top')[0].textContent, 10);
        const left = parseInt(element.getElementsByTagName('Left')[0].textContent, 10);
        const width = parseInt(element.getElementsByTagName('Width')[0].textContent, 10);
        const height = parseInt(element.getElementsByTagName('Height')[0].textContent, 10);
        const zIndex = parseInt(element.getElementsByTagName('ZIndex')[0].textContent, 10);
        // Now we look up the feed
        const visualFeedId = element.getElementsByTagName('VisualFeedId')[0].textContent;
        const feed = feeds[visualFeedId];

        const feedAssignment: ScreenLayoutContentFeed = {
            height: height,
            id: visualFeedId,
            left: left,
            width: width,
            top: top,
            typeId: feed.typeId,
            sourceFolder: feed.sourceFolder,
            zIndex: zIndex
        };
        content.feeds.push(feedAssignment);
    });
    return content;
}

@Injectable({providedIn: 'root'})
export class ScreenLayoutContentProvider {

    public constructor(private readonly mediaPlayerFileSystem: MediaPlayerFileSystem) {
        const screenLayoutFullPath = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VideoPlaylist) + "/" + SCREEN_LAYOUT_FILENAME;

        const textContent$ = this.mediaPlayerFileSystem.watchTextContent(screenLayoutFullPath);
        this.content$ = textContent$
            .pipe(
                map(xmlText => parseScreenLayoutXml(xmlText))
            );
    }

    public readonly content$: Observable<ScreenLayoutContent>;
}