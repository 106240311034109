import { Injectable } from '@angular/core';
import { DatEvent, DBVersionChangeEvent } from './databaseInterfaces';

export enum ApplicationLogType {
	Information = 0,
	Debug = 1,
	Error = 2,
	Warning = 3,
	Fatal = 4
}

export interface LogData {
	CreatedDate: Date;
	EntryType: ApplicationLogType;
	Source: string;
	Description: string;
}

@Injectable({
    providedIn: "root"
})
export class LogDatabase {

	databaseName: string = "LogDatabase";
	databaseVersion: number = 1;
	MAX_ROWS_OF_LOGS: number = 2000;
	async openDatabase(): Promise<IDBDatabase> {
		const dbFactory: IDBFactory = (typeof(window.indexedDB) !== 'undefined')
			? window.indexedDB
			:  (<any>window).webkitIndexedDB;
		const idbOpenRequest = dbFactory.open(this.databaseName, this.databaseVersion);
		idbOpenRequest.onupgradeneeded = function (event: DBVersionChangeEvent) {
			const db = event.target.result;
			db.createObjectStore("logMessage", { autoIncrement: true });
		};

		return await new Promise(function (resolve, reject) {
			idbOpenRequest.onerror = function (event: Event) {
				console.log('window.indexedDB.open threw an error!');
				reject(event);
			};

			idbOpenRequest.onsuccess = function (event: DatEvent) {
				resolve(event.target.result);
			};
		});
	}

	async deleteLogs(logKeys: string[]): Promise<any> {
		const keysCopy = logKeys.slice();
		const db = await this.openDatabase();
		const transaction = db.transaction(["logMessage"], "readwrite");
		const objectStore = transaction.objectStore("logMessage");

		for (let key of keysCopy) {
			const deletePromise = new Promise(
				(resolve, reject) => {
					const request = objectStore.delete(key);
					request.onsuccess = function (event) {
						resolve();
					};
					request.onerror = function (event) {
						reject(event);
					};
				}
			);
			await deletePromise;
		}
	}

	async fetchLogs() : Promise<{entries: LogData[], keys: string[]}> {
		const db = await this.openDatabase();
		const transaction = db.transaction(["logMessage"]);
		const objectStore: IDBObjectStore = transaction.objectStore("logMessage");
		const logEntries: LogData[] = [];
		const logKeys: string[] = [];
		return await new Promise<{entries: LogData[], keys:string[]}>((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event: DatEvent) {
				const cursor = event.target.result;
				if (cursor) {
					logKeys.push(cursor.key);
					logEntries.push(cursor.value);
					cursor.continue();
				} else {
					resolve({
						entries: logEntries,
						keys: logKeys
					});
				}
			};
		});
	}

	async recordMessage(logData: LogData) {
		const db = await this.openDatabase();
		const transaction = db.transaction(["logMessage"], "readwrite");
		transaction.oncomplete = function (event) {
			//console.log('LogDatabase.recordMessage - transaction.oncomplete event fired');
		};
		transaction.onerror = function (event) {
			console.log('LogDatabase.recordMessage - transaction.onerror event fired');
		};

		const objectStore = transaction.objectStore('logMessage');

		const getCount = objectStore.count();

		getCount.onsuccess = (event: DatEvent) => {
			if (event.target.result < this.MAX_ROWS_OF_LOGS) {
				const insertRequest = objectStore.add(logData);
				insertRequest.onsuccess = function (event) {
					//console.log('LogDatabase.recordMessage - Succesfully added message to logs');
				};
			}
		};
	}
}
