
class Base64Array {
	/// <summary>
	/// Array which makes base64 encoding and decoding faster.
	/// </ summary>
	// Declare string of available chars inside base64.
	S:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	CA: string[] = [];
	IA: any = [];
	//---------------------------------------------------------
	// INIT: Class
	//---------------------------------------------------------

	constructor() {
		let c = '';
		for (var i = 0; i < this.S.length; i++) {
			c = this.S.charAt(i);
			this.CA[i] = c;
			this.IA[c] = i;
		}
	}
}

export function arrayBufferToArray(arrayBuffer: ArrayBuffer):number[] {
    const nativeArray = new Uint8Array(arrayBuffer);
    return [].slice.call(nativeArray);
}

export function toBase64String(b: string | number[], wrap?: boolean):string {
    /// <summary>
    /// Converts the value of an array of 8-bit unsigned integers to its equivalent
    /// System.String representation encoded with base 64 digits.
    /// </summary>
    /// <param type="byte[]" name="b">An array of 8-bit unsigned integers.</param>
    /// <param type="bool" name="wrap">Wrap base64 string with '\r\n' separator.</param>
    /// <returns type="string">
    /// The System.String representation, in base 64, of the contents of inArray.
    /// </returns>
    /// <remarks>
    /// A very fast and memory efficient class to encode and decode to and from BASE64
    /// in full accordance with RFC 2045. Based on http://migbase64.sourceforge.net/
    /// Converted to JavaScript by Evaldas Jocys [evaldas@jocys.com], http://www.jocys.com
    /// </remarks>
    let B64 = new Base64Array();
    // Check special case
    var bLen = (b) ? b.length : 0;
    if (bLen === 0) return "";
    // Length of even 24-bits.
    var eLen:number = Math.floor(bLen / 3) * 3;
    // Returned character count.
    var cCnt = ((bLen - 1) / 3 + 1) << 2;
    var dLen = cCnt + (wrap ? (cCnt - 1) / 76 << 1 : 0); // Length of returned array
    var dArr = new Array(dLen);
    // Encode even 24-bits.
    for (var s: number = 0, d: number = 0, cc: number = 0; s < eLen;) {
        // Copy next three bytes into lower 24 bits of int, paying attension to sign.
        var i = (<any>b[s++] & 0xff) << 16 | (<any>b[s++] & 0xff) << 8 | (<any>b[s++] & 0xff);
        // Encode the int into four chars.
        dArr[d++] = B64.CA[(i >>> 18) & 0x3f];
        dArr[d++] = B64.CA[(i >>> 12) & 0x3f];
        dArr[d++] = B64.CA[(i >>> 6) & 0x3f];
        dArr[d++] = B64.CA[i & 0x3f];
        // Add optional line separator as specified in RFC 2045.
        if (wrap && ++cc == 19 && d < dLen - 2) {
            dArr[d++] = '\r';
            dArr[d++] = '\n';
            cc = 0;
        }
    }
    // Pad and encode last bits if source isn't even 24 bits.
    var left = bLen - eLen; // 0 - 2.
    if (left > 0) {
        // Prepare the int.
        var j = ((<any>b[eLen] & 0xff) << 10) | (left == 2 ? ((<any>b[bLen - 1] & 0xff) << 2) : 0);
        // Set last four chars.
        dArr[dLen - 4] = B64.CA[j >> 12];
        dArr[dLen - 3] = B64.CA[(j >>> 6) & 0x3f];
        dArr[dLen - 2] = (left == 2) ? B64.CA[j & 0x3f] : '=';
        dArr[dLen - 1] = '=';
    }
    return dArr.join("");
}

export function fromBase64String(s: string, fix?: boolean): number[] {
    /// <summary>
    /// Converts the specified System.String, which encodes binary data as base 64
    /// digits, to an equivalent 8-bit unsigned integer array.
    /// </summary>
    /// <param type="string" name="s">A string.</param>
    /// <param type="bool" name="fix">Fix base64 string by removing all ilegal chars.</param>
    /// <returns type="byte[]">
    /// An array of 8-bit unsigned integers equivalent to s.
    /// </returns>
    /// <remarks>
    /// A very fast and memory efficient class to encode and decode to and from BASE64
    /// in full accordance with RFC 2045. Based on http://migbase64.sourceforge.net/
    /// Converted to JavaScript by Evaldas Jocys [evaldas@jocys.com], http://www.jocys.com
    /// </remarks>
    var B64 = new Base64Array();
    // Check special case
    if (fix) {
        // Remove illegal chars
        var regex = new RegExp("[^" + B64.S + "]", "g");
        s = s.replace(regex, "");
    }
    var sLen = s.length;
    if (sLen === 0) return new Array(0);
    // Start and end index after trimming.
    var sIx = 0, eIx = sLen - 1;
    // Get the padding count (=) (0, 1 or 2).
    var pad = s.charAt(eIx) == '=' ? (s.charAt(eIx - 1) == '=' ? 2 : 1) : 0;  // Count '=' at end.
    // Content count including possible separators.
    var cCnt = eIx - sIx + 1;
    var sepLn = (s.charAt(76) == '\r') ? (cCnt / 78) : 0;
    var sepCnt = sLen > 76 ? (sepLn << 1) : 0;
    // The number of decoded bytes.
    var len = ((cCnt - sepCnt) * 6 >> 3) - pad;
    // Preallocate byte[] of exact length.
    var bytes = new Array(len);
    // Decode all but the last 0 - 2 bytes.
    var d = 0;
    var eLen = Math.floor(len / 3) * 3;
    var i: number;
    for (var cc = 0; d < eLen;) {
        // Assemble three bytes into an var from four "valid" characters.
        i = B64.IA[s.charAt(sIx++)] << 18 |
            B64.IA[s.charAt(sIx++)] << 12 |
            B64.IA[s.charAt(sIx++)] << 6 |
            B64.IA[s.charAt(sIx++)];
        // Add the bytes
        bytes[d++] = (i >> 16);
        bytes[d++] = ((i & 0xFFFF) >> 8);
        bytes[d++] = (i & 0xFF);
        // If line separator, jump over it.
        if (sepCnt > 0 && ++cc == 19) {
            sIx += 2;
            cc = 0;
        }
    }
    if (d < len) {
        // Decode last 1-3 bytes (incl '=') into 1-3 bytes.
        i = 0;
        for (var j = 0; sIx <= (eIx - pad) ; j++) {
            i |= B64.IA[s.charAt(sIx++)] << (18 - j * 6);
        }
        for (var r = 16; d < len; r -= 8) {
            var cropBits = Math.pow(2, r + 8) - 1;
            bytes[d++] = ((i & cropBits) >> r);
        }
    }
    return bytes;
 }