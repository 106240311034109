export {}
declare global {
    interface Array<T> {
        contains(o: T): boolean;
        remove(o: T): void;
        shuffle(): Array<T>;
        pushAll(a: Array<T>): void;
        insertArray(a: Array<T>, index: number): void;
    }
}
// implementation of contains
Array.prototype.contains = function (obj) {
    return this.indexOf(obj) > -1;
};

Array.prototype.remove = function (obj) {
    var index = this.indexOf(obj);

    if (index > -1) {
        this.splice(index, 1);
    }
};

Array.prototype.shuffle = function () {
	let j: number;
	let temp: any;
    let i = this.length;
    while (--i > 0) {
        j = Math.floor(Math.random() * (i + 1));
        temp = this[j];
        this[j] = this[i];
        this[i] = temp;
    }
    return this;
};

Array.prototype.pushAll = function (secondArray) {
    Array.prototype.push.apply(this, secondArray);
};

Array.prototype.insertArray = function (array, index) {
    if (!index) {
        index = this.length - 1;
    }

    Array.prototype.splice.apply(this, [index, 0].concat(array));
};