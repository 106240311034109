import "src/extensions/dateExtensions";
import { PlaylistAssignment } from "./playlistReader";
import { TickerDisplayAssignment } from "../../ticker-feed/tickerScheduler";

type Assignment = (PlaylistAssignment | TickerDisplayAssignment);

export interface ScheduleEntry {
	EndDateTime: Date;
	StartDateTime: Date;
	Assignment: Assignment
}


function sameDayOfWeek(dayOfWeekFlag: number, day: number): number {
	return dayOfWeekFlag & day;
}

const DayOfWeekFlags = {
	Sundays: 1,
	Mondays: 2,
	Tuesdays: 4,
	Wednesdays: 8,
	Thursdays: 0x10,
	Fridays: 0x20,
	Saturdays: 0x40
};

function getDayOfWeekFlag(day: Date): number {
	switch (day.getDay()) {
		case 0:
			return DayOfWeekFlags.Sundays;
		case 1:
			return DayOfWeekFlags.Mondays;
		case 2:
			return DayOfWeekFlags.Tuesdays;
		case 3:
			return DayOfWeekFlags.Wednesdays;
		case 4:
			return DayOfWeekFlags.Thursdays;
		case 5:
			return DayOfWeekFlags.Fridays;
		case 6:
			return DayOfWeekFlags.Saturdays;
	}
}

function matchesDayOfWeek(assignment: Assignment, date: Date): boolean {
	if (!assignment.DayOfWeekFlags) {
		return true;
	}

	if (assignment.DayOfWeekFlags === "") {
		return true;
	}

	if (assignment.DayOfWeekFlags === "-1" || assignment.DayOfWeekFlags === "0") {
		return true;
	}

	if (sameDayOfWeek(parseInt(assignment.DayOfWeekFlags), getDayOfWeekFlag(date))) {
		return true;
	}

	return false;
}

function matchesDate(assignment: Assignment, date: Date): boolean {
	if (assignment.DateRangeStart !== null && assignment.DateRangeStart > date) {
		return false;
	}

	if (assignment.DateRangeEndExclusive) {
		var c = new Date(
			assignment.DateRangeEndExclusive.getFullYear(),
			assignment.DateRangeEndExclusive.getMonth(),
			assignment.DateRangeEndExclusive.getDate()
		);
		if (+c <= +date) {
			return false;
		}
	}
	return true;
}

export class ContentScheduler {

    static generateSchedule(dateStart: Date, dateEndExclusive: Date, assignments: Assignment[]): ScheduleEntry[] {
        if (!assignments) {
            throw "ContentScheduler.generateplaylistContentScheduler: parameter 3 must be an array";
        }
        if (assignments.length === 0) {
            return [];
        }

        if (typeof (dateStart) === 'string') {
            dateStart = new Date(dateStart);
        }
        if (typeof (dateEndExclusive) === 'string') {
            dateEndExclusive = new Date(dateEndExclusive);
        }

        var aggregator = new ContentScheduleAggregator();
        assignments.sort(ContentScheduler.assignmentComparator);
        for (var date = new Date(dateStart); date < dateEndExclusive; date.setDate(date.getDate() + 1)) {
            for (var i = 0; i < assignments.length; i++) {
                if (matchesDate(assignments[i], date) && matchesDayOfWeek(assignments[i], date)) {
                    var startDate = new Date(date);

                    if ((assignments[i].TimeOfDayStart) && (assignments[i].TimeOfDayStart !== "")) {
                        var timesArray = assignments[i].TimeOfDayStart.split(".")[0].split(":");

                        if (timesArray.length == 3) {
                            startDate.setHours(parseInt(timesArray[0]));
                            startDate.setMinutes(parseInt(timesArray[1]));
                            startDate.setSeconds(parseInt(timesArray[2]));
                        }
                    }

                    var endDate = new Date(date);

                    if ((assignments[i].TimeOfDayEnd) && (assignments[i].TimeOfDayEnd !== "")) {
                        var timesArrayEnd = assignments[i].TimeOfDayEnd.split(".")[0].split(":");

                        if (timesArrayEnd.length === 3) {
                            endDate.setHours(parseInt(timesArrayEnd[0]));
                            endDate.setMinutes(parseInt(timesArrayEnd[1]));
                            endDate.setSeconds(parseInt(timesArrayEnd[2]));
                        }
                    } else {
                        endDate.setDate(endDate.getDate() + 1);
                    }

                    aggregator.addEntry(assignments[i], startDate, endDate);
                }
            }
        }

        aggregator.Entries.sort(function (e1, e2) {
            if (e1.startDateTime > e2.startDateTime) {
                return 1;
            } else if (e1.startDateTime < e2.startDateTime) {
                return -1;
            } else {
                return 0;
            }
        });

        const scheduleEntries: ScheduleEntry[] = [];
        aggregator.Entries.forEach(function (currentEntry) {
            scheduleEntries.push({ EndDateTime: currentEntry.endDateTime, StartDateTime: currentEntry.startDateTime, Assignment: currentEntry.assignment });
        });

        return scheduleEntries;
    }

	private static assignmentComparator(a1: Assignment, a2: Assignment): number {
		if (a1.assignmentIndex < a2.assignmentIndex) {
			return 1;
		} else if (a1.assignmentIndex > a2.assignmentIndex) {
			return -1;
		} else {
			return 0;
		}
	}
}

declare global {
	interface Array<T> {
		concat(a: any[]): any;
	}
}

class ContentScheduleAggregator {
	Entries: Entry[] = [];
	addEntry(assignment: Assignment, startDateTime: Date, endDateTime: Date):void {
		var newEntry = new Entry(assignment, startDateTime, endDateTime);

		if (this.Entries.length === 0) {
			this.Entries.push(newEntry);
		} else {
			var overlappingRangeStart = 0;
			var overlappingRangeEndExclusive = 0;

			while ((overlappingRangeStart < this.Entries.length) && this.comesAfter(newEntry, this.Entries[overlappingRangeStart])) {
				++overlappingRangeStart;
				++overlappingRangeEndExclusive;
			}

			while ((overlappingRangeEndExclusive < this.Entries.length) && this.overLaps(newEntry, this.Entries[overlappingRangeEndExclusive])) {
				++overlappingRangeEndExclusive;
			}

			if ((overlappingRangeEndExclusive - overlappingRangeStart) < 1) {
				this.Entries.splice(overlappingRangeStart, 0, newEntry);
			} else {
				var elementsToReplace = this.Entries.slice(overlappingRangeStart, overlappingRangeEndExclusive);

				this.Entries.splice(overlappingRangeStart, overlappingRangeEndExclusive - overlappingRangeStart);

				let newElements: Entry[] = [];

				for (var i = 0; i < elementsToReplace.length; i++) {
					newElements = newElements.concat(elementsToReplace[i].sliceOut(newEntry));
				}

				newElements.push(newEntry);

				newElements.sort(function (entryA, entryB) {
					if (entryA.startDateTime > entryB.startDateTime) {
						return 1;
					} else if (entryA.startDateTime < entryB.startDateTime) {
						return -1;
					} else {
						return 0;
					}

				});

				Array.prototype.splice.apply(this.Entries, [overlappingRangeStart, 0].concat(newElements));
			}
		}
	}

	private comesBefore(currentEntry: Entry, other: Entry): boolean {
		return this.compareDateRange(currentEntry, other) === 0;
	}

	private comesAfter(currentEntry: Entry, other: Entry): boolean {
		return this.compareDateRange(currentEntry, other) === 2;
	}

	private overLaps(currentEntry: Entry, other: Entry): boolean {
		return this.compareDateRange(currentEntry, other) === 1;
	}

	private compareDateRange(currentEntry: Entry, other: Entry): number {
		if (+currentEntry.endDateTime <= +other.startDateTime) {
			return 0;
		}

		if (+other.endDateTime <= +currentEntry.startDateTime) {
			return 2;
		}

		return 1;
	}

}


class Entry {
	assignment: Assignment;
	startDateTime: Date;
	endDateTime: Date;
	constructor(assignment: Assignment, startDateTime: Date, endDateTime: Date) {
		this.assignment = assignment;
		this.startDateTime = startDateTime;
		this.endDateTime = endDateTime;
	}

	sliceOut(other: Entry): Entry[] {
		var entries: Entry[] = [];

		if (this.startDateTime < other.startDateTime) {
			entries.push(new Entry(this.assignment, this.startDateTime, other.startDateTime));
		}

		if (other.endDateTime < this.endDateTime) {
			entries.push(new Entry(this.assignment, other.endDateTime, this.endDateTime));
		}

		return entries;
	}
}