interface FontFamilyAndFilePath {
    fontFamily : string,
    fileUrl: string
}

// A feed can have a <style> element 
// to apply styles that work on all elemnets 
// in a feed
export class StyleSheetHandler {

    private readonly fontFamiliesToUrl : { [familyName:string] : string } = {};

    public constructor(private rootElement: HTMLElement) {
    }

    public getOrCreateStyleElement() : HTMLStyleElement {
        const styleElement = this.rootElement.getElementsByTagName("style");
        if(styleElement.length > 0) {
            return styleElement.item(0);
        } else {
            const newStyleElement:HTMLStyleElement = document.createElement("style");
            if(this.rootElement.childNodes.length) {
                this.rootElement.insertBefore(newStyleElement, this.rootElement.childNodes.item(0));
            } else {
                this.rootElement.appendChild(newStyleElement);
            }
            return this.getOrCreateStyleElement();
        }
    }
    
    static generateFontFaceDefinitionString(fffp: FontFamilyAndFilePath) {
        return `@font-face { font-family: ${fffp.fontFamily}; src:url("${fffp.fileUrl}"); } `;
    }

    public setFontFamily(familyName: string, url: string) {
        this.fontFamiliesToUrl[familyName] = url;
        const styleElement = this.getOrCreateStyleElement();

        const definitionStrings: string[] = [];
        for(let fontFamily in this.fontFamiliesToUrl) {
            const url = this.fontFamiliesToUrl[fontFamily];
            const definitionString = StyleSheetHandler.generateFontFaceDefinitionString({ fontFamily: fontFamily, fileUrl: url });
            definitionStrings.push(definitionString);
        }
        const styleText = definitionStrings.join(" ");
        styleElement.innerText = styleText;
    }

    public getFontFamily(url: string): string | null {
        let fontFamilyString: string = null;
        for (let fontFamily in this.fontFamiliesToUrl) {
            if (this.fontFamiliesToUrl[fontFamily] === url) {
                fontFamilyString = fontFamily;
                break;
            } 
        }
        return fontFamilyString;
    }
}