import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FileManager, fileManager, isFileSystemAvailable } from "./fileManager";
import { FileUtils } from "./fileUtils";
import { LoggerService } from "./loggerService";

export interface ScreenActivationSettings {
    communicationPortNames: string;
    displayScreenSaver: boolean;
    endTime: string;
    inputSource: number;
    screenId: string;
    screenProtocol: number;
    screenSerialPort: string;
    startTime: string;
}

export interface PlayerSettings {
    deviceId: number;
    timeZoneOffset: string; // HH:mm or HH:mm:ss
    serverAddress: string;
    serverPublicKey: string;
    deviceKey: string;
    audioVolume: number;
    automaticallyPlayOnStartup: boolean;
    automaticStartupDelaySeconds: number;
    crossFadeDuration: number;
    dailyReboot: boolean;
    dailyRebootTime: string;
    defaultSlideDuration: number;
    enableHealthMonitoring: boolean;
    healthCheckIntervalMinutes: number;
    scheduledAudio: boolean;
    screenRotation: number;
    turnScreenOnAndOff: boolean;
    screenActivationSettings: ScreenActivationSettings;
    loggingLevel: number;
}

const defaultServerAddress = "cms.datmedia.com.au";

const defaultPlayerSettings: PlayerSettings = {
    deviceId: NaN,
    timeZoneOffset: "",
    automaticStartupDelaySeconds: 0,
    automaticallyPlayOnStartup: true,
    dailyReboot: true,
    deviceKey: null,
    enableHealthMonitoring: false,
    serverPublicKey: null,
    turnScreenOnAndOff: false,
    serverAddress: defaultServerAddress,
    audioVolume: 90,
    crossFadeDuration: 900,
    dailyRebootTime: "06:00",
    defaultSlideDuration: 10,
    healthCheckIntervalMinutes: 60,
    scheduledAudio: false,
    screenRotation: 0,
    screenActivationSettings: {
        communicationPortNames: "",
        displayScreenSaver: false,
        endTime: "22:00",
        screenId: "1",
        screenProtocol: 2,
        startTime: "9:00",
        inputSource: 0,
        screenSerialPort: ""
    },
    loggingLevel: 2
};

type DeviceSettingsPropertyName = keyof(PlayerSettings) | keyof(ScreenActivationSettings);

class SafeConversions {
    static toNumber(n:any, defaultValue:number) : number {
        if(typeof(n) === 'number') {
            return n;
        }
        if(typeof(n) === 'string') {
            const parsedInt = parseInt(n, 10);
            return isNaN(parsedInt) ? defaultValue : parsedInt;
        }
        return defaultValue;
    }

    static toBoolean(n:any, defaultValue:boolean) : boolean {
        if(typeof(n) === 'boolean') {
            return n;
        }
        if(typeof(n) === 'string') {
            const loweredN = n.toLowerCase();
            if(n === 'true') {
                return true;
            }
            if(n === 'false') {
                return false;
            }
        }
        if(typeof(n) === 'number') {
            return n != 0;
        }
        return defaultValue;
    }

    static toString(n:any, defaultValue:string) : string {
        if(typeof(n) === 'string') {
            return n;
        }
        if(typeof(n) === 'undefined') {
            return defaultValue;
        }
        return `${n}`;
    }
}

namespace PlayerSettingsPersistence {
    export function ensurePlayerSettingsIsComplete(playerSettings: PlayerSettings) {
        playerSettings.serverAddress = SafeConversions.toString(
            playerSettings.serverAddress,
            defaultPlayerSettings.serverAddress
        );
        playerSettings.audioVolume = SafeConversions.toNumber(
            playerSettings.audioVolume,
            defaultPlayerSettings.audioVolume
        );
        playerSettings.turnScreenOnAndOff = SafeConversions.toBoolean(
            playerSettings.turnScreenOnAndOff,
            defaultPlayerSettings.turnScreenOnAndOff
        );
        playerSettings.crossFadeDuration = SafeConversions.toNumber(
            playerSettings.crossFadeDuration,
            defaultPlayerSettings.crossFadeDuration
        );
        playerSettings.dailyReboot = SafeConversions.toBoolean(
            playerSettings.dailyReboot,
            defaultPlayerSettings.dailyReboot
        );
        playerSettings.dailyRebootTime = SafeConversions.toString(
            playerSettings.dailyRebootTime,
            defaultPlayerSettings.dailyRebootTime
        );
        playerSettings.defaultSlideDuration = SafeConversions.toNumber(
            playerSettings.defaultSlideDuration,
            defaultPlayerSettings.defaultSlideDuration
        );
        playerSettings.healthCheckIntervalMinutes = SafeConversions.toNumber(
            playerSettings.healthCheckIntervalMinutes,
            defaultPlayerSettings.healthCheckIntervalMinutes
        );
        playerSettings.scheduledAudio = SafeConversions.toBoolean(
            playerSettings.scheduledAudio,
            defaultPlayerSettings.scheduledAudio
        );
        playerSettings.screenRotation = SafeConversions.toNumber(
            playerSettings.screenRotation,
            defaultPlayerSettings.screenRotation
        );
        playerSettings.loggingLevel = SafeConversions.toNumber(
            playerSettings.loggingLevel,
            defaultPlayerSettings.loggingLevel
        );
        if(!playerSettings.screenActivationSettings) {
            const newSettings = {};
            Object.assign(newSettings, defaultPlayerSettings.screenActivationSettings);
            playerSettings.screenActivationSettings = <ScreenActivationSettings>newSettings;
        }
        playerSettings.screenActivationSettings.communicationPortNames = SafeConversions.toString(
            playerSettings.screenActivationSettings.communicationPortNames,
            defaultPlayerSettings.screenActivationSettings.communicationPortNames
        );
        playerSettings.screenActivationSettings.displayScreenSaver = SafeConversions.toBoolean(
            playerSettings.screenActivationSettings.displayScreenSaver,
            defaultPlayerSettings.screenActivationSettings.displayScreenSaver
        );
        playerSettings.screenActivationSettings.startTime = SafeConversions.toString(
            playerSettings.screenActivationSettings.startTime,
            defaultPlayerSettings.screenActivationSettings.startTime
        );
        playerSettings.screenActivationSettings.endTime = SafeConversions.toString(
            playerSettings.screenActivationSettings.endTime,
            defaultPlayerSettings.screenActivationSettings.endTime
        );
        playerSettings.screenActivationSettings.screenId = SafeConversions.toString(
            playerSettings.screenActivationSettings.screenId,
            defaultPlayerSettings.screenActivationSettings.screenId
        );
        playerSettings.screenActivationSettings.screenProtocol = SafeConversions.toNumber(
            playerSettings.screenActivationSettings.screenProtocol,
            defaultPlayerSettings.screenActivationSettings.screenProtocol
        );
        playerSettings.timeZoneOffset = SafeConversions.toString(
            playerSettings.timeZoneOffset,
            defaultPlayerSettings.timeZoneOffset
        );
    }

    function writeTextToFile(
        fileEntry: tizen.File | FileEntry,
        text: string
    ): Promise<void> {
		return new Promise<void>(function (resolve, reject) {
            if(FileManager.isTizenFile(fileEntry)) {
                fileEntry.openStream(
                    "w",
                    (fs) => {
                        try {
                            fs.write(text);
                            resolve();
                        } catch (e) {
                            reject(e);
                        } finally {
                            fs.close();
                        }
                    },
                    reject
                );
            } else {
                fileEntry.createWriter(
                    fileWriter => {
                        const blob = new Blob(
                            [text],
                            {type : 'application/json'}
                        );
                        
                        fileWriter.write(blob);
                        console.log('Looks like we wrote to the file OK');
                        resolve();
                    },
                    reject
                )
            }
		});
    }

    export async function save(playerSettingsString: string) {
        console.log('deviceSettings.storeConfiguration invoked');
        const root = await fileManager.fileSystemReady;
        let fileEntry: tizen.File | FileEntry;
        try {
            fileEntry = await FileUtils.getTizenFile(
                root,
                fileManager.playerSettingsFile,
                {
                    create: false
                }
            );
            await FileUtils.deleteFileOrDirectory(fileEntry);
        } catch (e) {
            if (e && (e.name === "NotFoundError")) {
            } else {
                throw e;
            }
        }
        fileEntry = await FileUtils.getTizenFile(
            root,
            fileManager.playerSettingsFile,
            {
                create: true,
                exclusive: false
            }
        );
        await writeTextToFile(fileEntry, playerSettingsString);
    }

    export async function loadPlayerSettings() : Promise<PlayerSettings> {
        if(!isFileSystemAvailable()) {
            const errorMessage = "Unable to access the player settings, as there is no available file system";
            console.error(errorMessage);
            const error = new Error(errorMessage);
            error.name = "FILE_SYSTEM_UNAVAILABLE";
            throw error;
        }
        try {
            const root = await fileManager.fileSystemReady;
            const fileEntry = await FileUtils.getTizenFile(
                root,
                fileManager.playerSettingsFile,
                { 
                    create: false
                }
            );
            const text = await FileUtils.getFileEntryText(fileEntry);
            const loadedPlayerSettings:PlayerSettings = JSON.parse(text);
            PlayerSettingsPersistence.ensurePlayerSettingsIsComplete(loadedPlayerSettings);
            return loadedPlayerSettings;
        } catch (e) {
            console.error(e);
            const newSettings = <PlayerSettings>{};
            PlayerSettingsPersistence.ensurePlayerSettingsIsComplete(newSettings);
            return newSettings;
        }


    }
}

// We had race conditions when playersettings were being recorded more than once
class PlayerSettingsPersister {
    
    private pendingSettings: string;
    private isProcessing: boolean = false;
    constructor() {
    }

    public async save(playerSettings: PlayerSettings) {
        this.pendingSettings = JSON.stringify(playerSettings);
        if (!this.isProcessing) {
            this.isProcessing = true;
            try {
                while(this.pendingSettings) {
                    const toSave = this.pendingSettings;
                    this.pendingSettings = undefined;
                    await PlayerSettingsPersistence.save(toSave);    
                }
            } finally {
                this.isProcessing = false;
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class DeviceSettings {

    private readonly propertyChangedSubject: Subject<DeviceSettingsPropertyName> = new Subject<DeviceSettingsPropertyName>();
   
    private readonly propertiesChangedSubject: Subject<DeviceSettingsPropertyName[]> = new Subject<DeviceSettingsPropertyName[]>();
    private readonly settingsChangedSubject: Subject<PlayerSettings> = new Subject<PlayerSettings>();

    readonly playerSettingsLoaded: Promise<void>;
    playerSettings?: PlayerSettings;

    get playerSettingsObservable() : Observable<PlayerSettings> {
        return this.settingsChangedSubject.asObservable();
    }

    private async loadPlayerSettings() : Promise<void> {
        this.playerSettings = await PlayerSettingsPersistence.loadPlayerSettings();
        this.settingsChangedSubject.next(this.playerSettings);
    }

    private readonly persister = new PlayerSettingsPersister();

    constructor(private logger: LoggerService) {
        try {
            this.playerSettingsLoaded = this.loadPlayerSettings();
        } catch(error) {
            this.playerSettingsLoaded = Promise.reject(error);
        }
    }

    async getPlayerSettings() : Promise<PlayerSettings> {
        try {
            await this.playerSettingsLoaded;
            return this.playerSettings;    
        } catch(error) {
            const newError = new Error(`getPlayerSettings threw an error: ${error}`);
            newError.name = error.name;
            throw error;
        }
    }

    async storeConfiguration(): Promise<void> {
        await this.playerSettingsLoaded;
        this.persister.save(this.playerSettings);
        this.settingsChangedSubject.next(this.playerSettings);
    }


    async modifySettings(modify: (settings: PlayerSettings) => boolean ) : Promise<void> {
        await this.playerSettingsLoaded;
        if (modify(this.playerSettings)) {
            await this.storeConfiguration();
        };
    }

    storePlayerIdEnrol(deviceId: number): Promise<void> {
        return this.modifySettings(
            s => {
                if (s.deviceId == deviceId) {
                    return false;
                } else {
                    s.deviceId = deviceId;
                    return true;
                }
            }
        );
    }

    storeServerPublicKey(publicKey: string): Promise<void> {
        return this.modifySettings(
            s => {
                if (s.serverPublicKey == publicKey) {
                    return false;
                } else {
                    s.serverPublicKey = publicKey;
                    return true;
                }
            }
        );
    }

    async storePlayerPrivateKey(privateKey: string): Promise<void> {
        return this.modifySettings(
            s => {
                if (s.deviceKey == privateKey) {
                    return false;
                } else {
                    s.deviceKey = privateKey;
                    return true;
                }
            }
        );
    }
    
    async storeServerIpAddress(serverAddress: string): Promise<void> {
        return this.modifySettings(
            s => {
                if (s.serverAddress == serverAddress) {
                    return false;
                } else {
                    s.serverAddress = serverAddress;
                    return true;
                }
            }
        );
    }
}