import * as protobuf from "protobufjs";

function getProtobufType(protoBufferSource: string, typeName: string) : Promise<protobuf.Type> {
    return new Promise<protobuf.Type>(
        (resolve, reject) => {
            protobuf.load(
                protoBufferSource,
                (err: Error, root: protobuf.Root) => {
                    if(err) {
                        console.error(`An error occurred while loading protobuf source '${protoBufferSource}'`);
                        reject(err);
                        return;
                    }
                    try {
                        const protoType = root.lookupTypeOrEnum(typeName);
                        if(!!protoType) {
                            resolve(protoType);
                        } else {
                            reject(`Could not find the type ${typeName} in ${protoBufferSource}`);
                        }

                    } catch(error) {
                        reject(error);
                    }
                }
            );
        }
    );
}

export class ProtobufSerializer<T extends object> {
    public readonly getProtobufType : () => Promise<protobuf.Type>;

    constructor(private readonly protoBufferSource: string, private readonly typeName: string) {
        this.getProtobufType = (
            () => {
                let cachedPromise: Promise<protobuf.Type> = null;
                return () => {
                    cachedPromise = cachedPromise || getProtobufType(this.protoBufferSource, this.typeName);
                    return cachedPromise;
                };
            }
        )();
    }

    async serialize(message: T) : Promise<ArrayLike<number>> {
        const protoType = await this.getProtobufType();
        const verificationMessage = protoType.verify(message);
        if(verificationMessage) {
            console.error(`Failed to verify the message matched the protobuf source '${this.protoBufferSource}'`);
            throw(verificationMessage);
        }   
        const protobufMessage = protoType.create(message);
        return protoType.encode(protobufMessage).finish();
    }


    static asUint8Array(v: ArrayBuffer | ArrayLike<number>) : Uint8Array {
        if(v instanceof Uint8Array) {
            return v;
        } else {
            return new Uint8Array(v);
        }
    }

    async deserialize(
        values: ArrayBuffer | ArrayLike<number>,
    ) : Promise<T> {
        const protoType = await this.getProtobufType();
        const messageT:protobuf.Message<{}> = protoType.decode(
            ProtobufSerializer.asUint8Array(values)
        );
        const message:T = <T>messageT;
        return message;
    }
}