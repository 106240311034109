import { Component, OnInit } from '@angular/core';
import { DeviceSettings, PlayerSettings } from '../../../deviceSettings';
import { from, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

function convertToKeyValuePairs(playerSettings: PlayerSettings) : {key: string, value: any}[] {
    const keyValuePairs: { key: string, value: any }[] = [];
    for (let k in playerSettings) {
        if (k === 'screenActivationSettings') {
            for (let k2 in playerSettings.screenActivationSettings) {
                keyValuePairs.push({
                    key: k2,
                    value: playerSettings.screenActivationSettings[k2]
                });
            }
        } else {
            keyValuePairs.push({
                key: k,
                value: playerSettings[k]
            });
        }
    }
    return keyValuePairs;
}


@Component({
    selector: "local-settings-view",
    templateUrl: "./local-settings-view.component.html"
})
export class LocalSettingsViewComponent implements OnInit {

    keyValuePairs$: Subject<{ key: string, value: any }[]> = new Subject<{key: string, value: any}[]>();

    constructor(private deviceSettings: DeviceSettings) {
    }

    ngOnInit(): void {
        this.deviceSettings.playerSettingsLoaded.then(
            () => {
                this.keyValuePairs$.next(convertToKeyValuePairs(this.deviceSettings.playerSettings));
            }
        )
        this.deviceSettings.playerSettingsObservable.pipe(
            map(convertToKeyValuePairs)
        ).subscribe({
            next: (value) => this.keyValuePairs$.next(value)
        });
    }
}