import { DuplexChannelEventArgs } from './duplexChannelEventArgs';
import { DuplexChannelMessageEventArgs } from './duplexChannelMessageEventArgs';
import { Subscription } from 'rxjs';

// This set of classes was ported from the file
// eneter-message-6.5.0.js 
// 
// https://github.com/DATMedia/Laqorr-Media-Player/blob/master/Chrome/lib/eneter/eneter-messaging-6.5.0.js

import { OutputChannel } from './outputChannel';
import { LoggerService } from 'src/loggerService';

export class TypedResponseReceivedEventArgs {
    constructor(
        public readonly ResponseMessage: any,
        public readonly ReceivingError: any
    ) {
    }
}

export type OnResponseReceivedHandler = (responseMessage: TypedResponseReceivedEventArgs) => void;

export class DuplexTypedMessageSender {

    constructor(readonly loggerService: LoggerService) {    
    }
    onResponseReceived : OnResponseReceivedHandler = () => {};
    private myOutputChannel: OutputChannel;
    
    onConnectionClosed: (DuplexChannelEventArgs) => void = (args) => {};
     // Overrides the message handler from the base class.
    // It deserializes the received message and invokes event notifying the response message was received.
    _onResponseMessageReceived(duplexChannelMessageEventArgs : DuplexChannelMessageEventArgs)
    {
        let anObject:string|ArrayBuffer = null;
        let anError = null;
        
        try
        {
            // Deserialize incoming message.
            //anObject = mySerializer.deserialize(duplexChannelMessageEventArgs.Message);
            anObject = duplexChannelMessageEventArgs.Message;

        }
        catch (err)
        {
            anError = err;
            console.error("Failed to deserialize the response message.", err);
        }
        // Raise the event.
        const aResponseReceivedEventArgs = new TypedResponseReceivedEventArgs(anObject, anError);
        this.onResponseReceived(aResponseReceivedEventArgs);
    };


    isDuplexOutputChannelAttached()
    {
        return this.myOutputChannel !== null;
    };

    getAttachedDuplexOutputChannel() {
        return this.myOutputChannel;
    }

    channelOnConnectionClosedSubscription : Subscription;

    attachDuplexOutputChannel(outputChannel: OutputChannel) {
        this.myOutputChannel = outputChannel;

        this.channelOnConnectionClosedSubscription = this.myOutputChannel.onConnectionOpened.subscribe({
           next: (args:DuplexChannelEventArgs) => this.onConnectionClosed(args) 
        });
        this.myOutputChannel.onResponseMessageReceived = (args) => this._onResponseMessageReceived(args);
        this.myOutputChannel.openConnection();
    }

    detachDuplexOutputChannel() {
        if (this.myOutputChannel !== null)
        {
            try
            {
                this.myOutputChannel.closeConnection();
            }
            catch (err)
            {
            }

            // Unsubscribe from events.
            if(this.channelOnConnectionClosedSubscription) {
                this.channelOnConnectionClosedSubscription.unsubscribe();
                this.channelOnConnectionClosedSubscription = null;
            }

            this.myOutputChannel.onResponseMessageReceived = function(duplexChannelMessageEventArgs) {};
            this.myOutputChannel = null;
        }       
    }

    sendRequestMessage(message: ArrayBuffer | ArrayLike<number>) {
        if (this.isDuplexOutputChannelAttached() === false)
        {
            throw new Error("Failed to send the message because the output channel is not attached.");
        }

        try
        {
            this.getAttachedDuplexOutputChannel().sendMessage(message);
        }
        catch (err)
        {
            console.log("Failed to send the message.", err);
            this.loggerService.logError("duplexTypedMessageSender failed to send message");
            this.loggerService.logError(err);
            throw err;
        }
    }
}