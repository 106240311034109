export class RssFeedGetter {

    retrieve(url: string): Promise<string[]> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onload = function (e) {
                if (this.status == 200) {
                    const xml = this.response;

                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(xml, "text/xml");
                    const titles: string[] = [];
                    const items = xmlDoc.getElementsByTagName("item");
                    const itemsArray: Element[] = Array.prototype.slice.call(items);
                    itemsArray.forEach((element, index) => {
                        const title = element.getElementsByTagName("title")[0].textContent;
                        titles.push(title);
                    });

                    resolve(titles);
                } else {
                    resolve([]);
                }
            };

            xhr.onerror = function () { resolve([]); };
            xhr.send(null);
        });
    }
}
