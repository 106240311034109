// This code should be looked at to see if it can be merged with the geometry library somehow

function generateTransformationBasedOnRotation(rotation: AVPlayRotation) {
    switch(rotation) {
      case "PLAYER_DISPLAY_ROTATION_0":
        return _ => _;
      case "PLAYER_DISPLAY_ROTATION_90":
        {
          const rotate = (point) => {
            return {
              x: 0-point.y,
              y: point.x
            }
          };
          const translate = (point) => {
            return {
              x: point.x,
              y : point.y - 1080
            };
          };
          return point => rotate(translate(point));
        }
      case "PLAYER_DISPLAY_ROTATION_180":
        {
          const rotate = (point) => {
            return {
              x: 0-point.x,
              y: 0-point.y
            };
          };
          const translate = (point) => {
            return {
              x: point.x - 1920,
              y: point.y - 1080
            };
          };
          return point => rotate(translate(point));
        }
      case "PLAYER_DISPLAY_ROTATION_270":
        {
          const rotate = (point) => {
            return {
              x: 0 + point.y,
              y: 0 - point.x
            }
          };
          const translate = (point) => {
            return {
              x: point.x,
              y: point.y + 1920
            }
          };
          return point => translate(rotate(point));
        }
      default:
        return _ => _;
    }
  }

export function calculateTizenVideoDisplayRect(targetRect, rotation) {
    const points = [
      { x: targetRect.left, y: targetRect.top },
      { x: targetRect.left, y: targetRect.top + targetRect.height },
      { x: targetRect.left + targetRect.width, y: targetRect.top },
      { x: targetRect.left + targetRect.width, y: targetRect.top + targetRect.height }
    ];
    const transform = generateTransformationBasedOnRotation(rotation);
    const newPoints = points.map(transform);
    const bounds = newPoints.reduce(
        (bounds, p) => {
          return {
            minX: Math.min(bounds.minX, p.x),
            maxX: Math.max(bounds.maxX, p.x),
            minY: Math.min(bounds.minY, p.y),
            maxY: Math.max(bounds.maxY, p.y)
          }
        },
        {
          minX: Number.MAX_SAFE_INTEGER,
          maxX: Number.MIN_SAFE_INTEGER,
          minY: Number.MAX_SAFE_INTEGER,
          maxY: Number.MIN_SAFE_INTEGER
        }
    );
    return {
      left: bounds.minX,
      top: bounds.minY,
      width: bounds.maxX - bounds.minX,
      height: bounds.maxY - bounds.minY
    };
  }