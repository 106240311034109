import { Component, OnInit } from '@angular/core';
import { FileDownloadClient, FileDownloadClientStatus, FileDownloadClientState } from '../../../fileDownloadClient';
import { Observable} from 'rxjs';
import { map, share } from 'rxjs/operators';


function generateStatusText(status: FileDownloadClientStatus) {
    switch (status.state) {
        case FileDownloadClientState.IDLE:
            return "";
        case FileDownloadClientState.TRANSFERRING:
            return `Receiving file ${status.fileName}`;
        case FileDownloadClientState.COPYING:
            return `Preparing file ${status.fileName}`;
    }
}

function generateDisplayFields(status: FileDownloadClientStatus) {
    return {
        statusText: generateStatusText(status),
        displayProgress: status.state != FileDownloadClientState.IDLE,
        progressPercentage: status.state == FileDownloadClientState.IDLE
            ? undefined
            : status.amountDownloadedSoFar / status.fileSize * 100,
        progressDescription: status.state == FileDownloadClientState.IDLE
            ? ""
            : `${Math.round(status.amountDownloadedSoFar/1024)} of ${Math.round(status.fileSize/1024)} kb`
    };
}

@Component({
    templateUrl: 'file-transfer-view.component.html',
    selector: 'file-transfer-view',
    styleUrls: ['file-transfer-view.component.scss']
})
export class FileTransferViewComponent implements OnInit {
    constructor(readonly fileDownloadClient: FileDownloadClient) {
    }

    ngOnInit() {
        const statusSettings = this.fileDownloadClient.status$.pipe(
            map(generateDisplayFields),
            share()
        );
        this.statusText$ = statusSettings.pipe(map(settings => settings.statusText));
        this.displayProgress$ = statusSettings.pipe(map(s => s.displayProgress));
        this.progressDescription$ = statusSettings.pipe(map(s => s.progressDescription));
        this.progressPercentage$ = statusSettings.pipe(map(s => s.progressPercentage));
    }

    statusText$ : Observable<string>;
    displayProgress$: Observable<boolean>;
    progressDescription$: Observable<string>;
    progressPercentage$: Observable<number>;
}