import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';

export interface BuildVersionInfo {
    buildDateTime : string,
    branch: string,
    commit: string,
    versionNumber: string
};

function trimForwardSlashes(s: string) : string {
    while(s.startsWith('/')) {
        s = s.slice(1);
    }
    while(s.endsWith('/')) {
        s = s.slice(0, s.length-1);
    }
    return s;
}

@Injectable({
  "providedIn": "root"  
})
export class VersionInfoProvider {
    constructor(
        @Inject(APP_BASE_HREF)
        private readonly baseHref: string,
        private readonly http: HttpClient
    ) {
    }

    private promise: Promise<BuildVersionInfo>;


    private generateVersionInfoPath() : string {
        const baseHrefTrimmed = trimForwardSlashes(this.baseHref);
        if((baseHrefTrimmed) && (baseHrefTrimmed.length)) {
            return `/${baseHrefTrimmed}/versionInfo.json`
        } else {
            return "/versionInfo.json";
        }
    }

    public getVersionInfo() : Promise<BuildVersionInfo> {

        if(!this.promise) {
            const path = this.generateVersionInfoPath();
            this.promise = this.http.get<BuildVersionInfo>(path)
                .toPromise();
        }
        return this.promise;
    }
}