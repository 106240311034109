export class UTF8EncoderClass {
    constructor() {
        this.InitializeClass();
    }

    GetBytes(s: string): number[] {
        const bytes:number[] = [];
        let c = 0;
        for (let i = 0; i < s.length; i++) {
            c = s.charCodeAt(i);
            if (c > 0xFFFF) {
                bytes.push(0xDC00 | c & 0x3FF);
                bytes.push(0xD7C0 + (c >> 10));
            } else {
                bytes.push(c & 0xFF);
                bytes.push(c >> 8);
            }
        }
        return bytes;
    }
   
    GetString(bytes:number[]):string {
        let s = "";
        let b = 0;
        let b1 = 0;
        let b2 = 0;
        for (let i = 0; i < bytes.length; i++) {
            b1 = bytes[i]; i++;
            b2 = bytes[i];
            s += String.fromCharCode((b2 << 8) | b1);
        }
        return s;
    }

    InitializeClass(): void {
    }
}

export class StringBuilder {
    _parts:string[] = [];
    constructor(private value:string){
        this.InitializeClass();
    }

    Append(value: string, countParameter?: number): boolean {
        let results = true;
        if (typeof (value) == 'undefined') {
            results = false;
        } else {
            let count = (countParameter) ? countParameter : 1;
            for (let i = 0; i < count; i++) {
                this._parts.push(value);
            }
        }
        return results;
    }

    AppendLine(value: string): boolean {
        return this.Append(value + '\r\n');
    }

    Clear():void {
        if (this._parts.length > 0) {
            this._parts.splice(0, this._parts.length);
        }
    }
    
    IsEmpty() {
        return (this._parts.length === 0);
    }
    
    ToString(delimiter:string): string {
        return this._parts.join(delimiter || '');
    }
    
    ToArray(delimiter:string) {
        return this._parts;
    }

    InitializeClass(): void {
        if (this.value) {
            this.Append(this.value);
        }
    }
}


// A Hack to get the RSA javascript to work
(<any>window).UTF8Encoder = new UTF8EncoderClass();
//var UTF8Encoder = new UTF8EncoderClass();