import { DisplayHandler, ExecutionCompleteArgs, PreparedElement, zIndexes, ExecutionId, HtmlElementDisplay } from './displayHandler';
import { ElementFader, ElementFadeTask } from './elementFader';
import { LaqorrFileEntry, FileUtils } from 'src/fileUtils';
import { Subject } from 'rxjs';
import { BlockingCollection } from 'src/blockingCollection';
import { DeviceSettings } from 'src/deviceSettings';
import { LocalTimeZoneClock } from 'src/clock';

const maxFileSize = (4.5*1024*1024);

async function canDisplayFile(entry: LaqorrFileEntry) {
    const fileSize = await FileUtils.getFileSize(entry);
    if (fileSize < maxFileSize) {
        return {
            canDisplay: true,
            message: "This file can be displayed"
        };
    } else {
        return {
            canDisplay: false,
            message: `The image file ${entry.name} is too large to display`
        };
    }
}

export class ImageDisplayHandler implements DisplayHandler {
    preparedElements: PreparedElement<HTMLDivElement>[] = [
        {
            file: null,
            element: null,
            display: "block",
            zIndex: zIndexes.hiding
        },
        {
            file: null,
            element: null,
            display: "block",
            zIndex: zIndexes.hiding
        }
    ];

    errorElement = {
        display: <HtmlElementDisplay>"none",
        zIndex: zIndexes.hiding,
        text: "There is no error. If you can read this, there's a bug."
    };

    private currentlyDisplayedElement: PreparedElement<HTMLDivElement>;
    private pendingElement: PreparedElement<HTMLDivElement>;
    private readonly availableElements = new BlockingCollection<PreparedElement<HTMLDivElement>>();

    setNativeElements(element1: HTMLDivElement, element2: HTMLDivElement) {
        console.log('videoFeedComponent.setNativeElements - adding the elements to this.preparedElements');
        this.preparedElements[0].element = element1;
        this.preparedElements[1].element = element2;
        this.availableElements.reset(this.preparedElements);
    }

    // Don't delete this, it's invoked by the template
    getSource(item: PreparedElement<HTMLDivElement>) : string {
        return (item.file) 
            ? `url('${FileUtils.getLaqorrFileEntryUrl(item.file)}')`
            : '';
    }

    readonly playCompleteSubject = new Subject<ExecutionCompleteArgs>();
    get mediaExecutionComplete$() {
        return this.playCompleteSubject.asObservable();
    }

    constructor(
        private readonly deviceSettings: DeviceSettings,
        private readonly elementFader: ElementFader,
        private readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
    }
    
    private elementFadeTask: ElementFadeTask;

    stopAll() {
        if(this.elementFadeTask) {
            this.elementFadeTask.destroy();
            this.elementFadeTask = null;
        }
        this.preparedElements.forEach(
            image => {
                image.file = null;
                image.display = "none";
                image.zIndex = zIndexes.hiding;
            }
        );
        this.currentlyDisplayedElement = null;
        this.pendingElement = null;
        this.availableElements.reset(this.preparedElements);
    }

    private async hideElement(element: PreparedElement<HTMLDivElement>) {
        try {
            this.elementFadeTask = await this.elementFader.fade(
                element,
                () => {
                    this.availableElements.push(element);
                    this.elementFadeTask = null;
                }
            );
        }
        catch(e) {
        }
    }

    private async delayForSlideDuration() : Promise<void> {
        const slideDuration = (await this.deviceSettings.getPlayerSettings()).defaultSlideDuration * 1000;
        await new Promise(r => window.setTimeout(r, slideDuration));
    }

    private async displayError(text: string) {
        this.errorElement.display = "flex";
        this.errorElement.zIndex = zIndexes.showing;
        this.errorElement.text = text;
        await this.delayForSlideDuration();
        this.errorElement.text = "There is no error and you should not be able to read this text";
        this.errorElement.zIndex = zIndexes.hiding;
        this.errorElement.display = "none";
    }
    
    private async displayImage(mediaFile: LaqorrFileEntry) {
        this.currentlyDisplayedElement = this.pendingElement || await this.availableElements.pop();
        this.pendingElement = null;
        this.currentlyDisplayedElement.display = "block";
        this.currentlyDisplayedElement.file = mediaFile;
        this.currentlyDisplayedElement.zIndex = zIndexes.showing;
        await this.delayForSlideDuration();
        this.hideElement(this.currentlyDisplayedElement);
        this.currentlyDisplayedElement = null;
    }

    async play(mediaFile: LaqorrFileEntry, executionId: ExecutionId)  {
        let succeeded: boolean;
        const startTime = this.localTimeZoneClock.now;
        const fileCheckResult = await canDisplayFile(mediaFile);
        if(fileCheckResult.canDisplay) {
            await this.displayImage(mediaFile);
            succeeded = true;
        } else {
            await this.displayError(fileCheckResult.message);
            succeeded = false;
        }
        this.playCompleteSubject.next({
            executionId: executionId,
            mediaFile: mediaFile,
            startTime: startTime,
            succeeded: succeeded
        });
    }
    
    async prepareNext(mediaFile: LaqorrFileEntry) : Promise<void> {
        const fileCheckResult = await canDisplayFile(mediaFile);
        if(!fileCheckResult.canDisplay) {
            return;
        }
        if(!this.pendingElement) {
            this.pendingElement = await this.availableElements.pop();
        }
        this.pendingElement.display = "block";
        this.pendingElement.file = mediaFile;
        this.pendingElement.zIndex = zIndexes.pending;
    }
}