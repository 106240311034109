export interface Rectangle {
    left: number;
    top: number;
    width: number;
    height: number;
}
 export interface Point {
     x: number,
     y: number
 }

 // Cheating for now, because we know what it's going to be
 export function matrixMultiply(m1: number[][], m2: number[][]) : number[][] {
    return [
        [m1[0][0] * m2[0][0] + m1[0][1] * m2[1][0] + m1[0][2] * m2[2][0]],
        [m1[1][0] * m2[0][0] + m1[1][1] * m2[1][0] + m1[1][2] * m2[2][0]]
    ];

     const result:number[][] = [];
     for(let i = 0; i < m1[0].length; ++i) {
         result[i] = new Array<number>(m2[0].length);
         for(let j = 0; j < m2.length; ++j) {
             if(!result[j]) {
                 result[j] = [];
             }
             result[j][i] = (result[j][i] || 0) + m1[i][j] * m2[j][i];
         }
     }
     return result;
 }

 export function transformPoint(point: Point, matrix: number[][]) {
    const r = matrixMultiply(matrix, [[point.x], [point.y], [1.0]]);
    return {
        x: r[0][0],
        y: r[1][0]
    }
 };

export function rotatePoint(point: Point, origin: Point, radians: number) {
    const rotationMatrix : number[][] = [
        [Math.cos(radians), 0-Math.sin(radians), 0],
        [Math.sin(radians), Math.cos(radians), 0],
        [0, 0, 0]
    ];
    const firstTranslation : number[][] = [
        [1, 0, 0-origin.x],
        [0, 1, 0-origin.y],
        [0, 0, 1]
    ];
    const secondTranslation: number[][] = [
        [1, 0, origin.x],
        [0, 1, origin.y],
        [0, 0, 1]
    ];
    let result = transformPoint(point, firstTranslation);
    result = transformPoint(result, rotationMatrix);
    result = transformPoint(result, secondTranslation);
    return result;
}

export function degreesToRadians(degrees: number) {
   return degrees * Math.PI / 180.0;
}


export function getRectangleCenter(rectangle: Rectangle) : Point {
    return {
        x: rectangle.left + rectangle.width/2,
        y: rectangle.top + rectangle.height/2
    };
}

export function rotateRectangle(rectangle: Rectangle, degrees: number, origin?: Point) {
    origin = origin || getRectangleCenter(rectangle);
    const radians = degreesToRadians(degrees);
    const originalPoints:Point[] = [
        {
            x: rectangle.left,
            y: rectangle.top
        },
        {
            x:rectangle.left + rectangle.width,
            y:rectangle.top
        },
        {
            x:rectangle.left + rectangle.width,
            y:rectangle.top + rectangle.height
        },
        {
            x:rectangle.left,
            y:rectangle.top + rectangle.height
        }
    ];
    const newPoints = originalPoints.map(
        p => {
            const result = rotatePoint(p, origin, radians);
            return result;
        }
    );
    let boundaries = newPoints.reduce(
        (
            previousValue,
            currentValue
        ) => {
            return {
                minX: Math.min(previousValue.minX, currentValue.x),
                minY: Math.min(previousValue.minY, currentValue.y),
                maxX: Math.max(previousValue.maxX, currentValue.x),
                maxY: Math.max(previousValue.maxY, currentValue.y)
            }
        },
        { 
            minX: Number.MAX_VALUE,
            minY: Number.MAX_VALUE,
            maxX: Number.MIN_VALUE,
            maxY: Number.MIN_VALUE
        }
    );
    return {
        left: boundaries.minX,
        top: boundaries.minY,
        width: boundaries.maxX - boundaries.minX,
        height: boundaries.maxY - boundaries.minY
    };
}

export function calculateOriginalRectangleGivenTargetRectangleAndRotation(targetRectangle: Rectangle, degrees: number) : Rectangle {
    return rotateRectangle(targetRectangle, 0-degrees); 
}