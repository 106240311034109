import { FocusRelinquishedArgs, NavigatableComponent, Side } from './navigatableComponent';
import { asapScheduler } from 'rxjs';
import { Input } from '@angular/core';

export abstract class TileComponentBase<T> extends NavigatableComponent {
    protected _items: T[] = [];
    public focusedItem: T;

    public installedItemRows: T[][];

    constructor() {
        super();
    }

    protected getIndexOfItem(p: T) : [number, number] {
        for(let i = 0; i < this.installedItemRows.length; ++i) {
            for(let j = 0; j < this.installedItemRows[i].length; ++j) {
                if(p === this.installedItemRows[i][j]) {
                    return [i, j];
                }
            }
        }
        return [-1, -1];
    }

    protected getFocusedIndex() : [number, number] {
        return this.getIndexOfItem(this.focusedItem);
    }

    moveFocusLeft() {
        const [i, j] = this.getFocusedIndex();
        if(j <= 0) {
            this.relinquishFocus("Left");
        } else {
            this.focusedItem = this.installedItemRows[i][j-1];
        }
        return true;
    }

    moveFocusRight() {
        const [i, j] = this.getFocusedIndex();
        if(i < 0) {
            return false;
        }
        if(j < this.installedItemRows[i].length-1) {
            this.focusedItem = this.installedItemRows[i][j+1];
            return true;
        } else {
            console.log('Cannot move the focus right');
            return false;
        }
    }

    moveFocusUp() {
        const [i, j] = this.getFocusedIndex();
        if(i <= 0) {
            this.relinquishFocus("Top");
        } else {
            this.focusedItem = this.installedItemRows[i-1][j];
        }
        return true;
    }

    moveFocusDown() {
        const [i, j] = this.getFocusedIndex();
        if(i < 0) {
            return false;
        } else if (i < this.installedItemRows.length - 1) {
            this.focusedItem = this.installedItemRows[i+1][j];
            return true;
        } else {
            return false;
        }
    }

    itemComponentFocusRelinquished(item: T, args: FocusRelinquishedArgs) {
        console.log(`TileComponentBase itemComponentFocusRelinquished invoked.`);
        let succeeded: boolean = true;
        switch(args.fromSide) {
            case "Left" :
                succeeded = this.moveFocusLeft();
                break;
            case "Right":
                succeeded = this.moveFocusRight();
                break;
            case "Top":
                succeeded = this.moveFocusUp();
                break;
            case "Bottom":
                succeeded = this.moveFocusDown();
                break;
        }
        if(!succeeded) {
            asapScheduler.schedule(() => args.component.focus(args.fromSide));
        }
    }

    public focus(from: Side) {
        super.focus(from);

        if(this.installedItemRows.length && this.installedItemRows[0].length) {
            this.focusedItem = this.focusedItem || this.installedItemRows[0][0];
        }
    }

    abstract cloneItem(item: T) : T;

    regenerateRows() {
        this.installedItemRows = [[]];
        for(let p of (this._items || [])) {
            if(this.installedItemRows[this.installedItemRows.length-1].length == 4)
            {
                this.installedItemRows.push([]);
            }
            this.installedItemRows[this.installedItemRows.length-1].push(
                this.cloneItem(p)
            );
            this.focusedItem = this.installedItemRows[0][0];
        }
    }

    protected setItems(itemsArray: T[]) {
        if(this._items !== itemsArray) {
            console.log('TileComponentBase changing');
            this._items = itemsArray;
            this.regenerateRows();
        }
    }

    public set items(itemsArray: T[]) {
        console.log('tileComponentBase.items setter invoked');
        this.setItems(itemsArray);
    }
}