import { Injectable } from '@angular/core';
import { DatEvent, DBVersionChangeEvent } from './databaseInterfaces';
import { HtmlUtils } from './htmlUtils';

interface CurrentPlaylist {
	VideoPlaylist: string;
	AudioPlaylist: string;
}

interface MediaFileExecutionEntry {
	MediaType: number;
	FileName: string;
	ExecutionTimes: string[];
	Id: number;
	ExecutionTimeKeys: number[];
}

export interface PlayHistory {
    Entries: MediaFileExecutionEntry[],
    ExecutionKeys: number[];
	VideoPlaylist: string;
	AudioPlaylist: string;
}

export type PlaylistMediaType = "Video" | "Audio";
export type ExecutionMediaType = "Video" | "Audio";

@Injectable({
    providedIn: "root"
})
export class PlayHistoryDatabase {
	databaseName: string = "PlayHistoryDatabase";
	MAX_ROWS_OF_PLAY_HISTORY: number = 10000;
	constructor() {
	}

    openDatabase(): Promise<IDBDatabase> {
        const dbFactory: IDBFactory = (typeof(window.indexedDB) !== 'undefined')
			? window.indexedDB
			:  (<any>window).webkitIndexedDB;
		const idbOpenRequest = dbFactory.open(this.databaseName, 1);
        idbOpenRequest.onupgradeneeded = function (event: DBVersionChangeEvent) {
            console.log('window.indexedDB.open made onupgradeneeded get called');
            const db: IDBDatabase = event.target.result;

            if (!db.objectStoreNames.contains('MediaFile')) {
                db.createObjectStore("MediaFile", { autoIncrement: true });
            }

            if (!db.objectStoreNames.contains('CurrentPlaylist')) {
                db.createObjectStore("CurrentPlaylist", { autoIncrement: true });
            }

            if (!db.objectStoreNames.contains('MediaFileExecution')) {
                db.createObjectStore("MediaFileExecution", { autoIncrement: true });
            }
        };

        return new Promise(function (resolve, reject) {
            idbOpenRequest.onerror = function (event) {
                console.log('window.indexedDB.open threw an error!');
                reject(event);
            };

            idbOpenRequest.onsuccess = function (event: DatEvent) {
                resolve(event.target.result);
            };
        });
    }

    async deletePlayHistory(executionKeys: number[], mediaFileKeys: number[]): Promise<any> {
        await this.deleteExecutionByKeys(executionKeys);
        await this.deleteMediaFileByKeys(mediaFileKeys);
    }

    async deleteExecutionByKeys(executionKeys: number[]): Promise<any> {
        const executionKeysCopy = executionKeys.slice();
        const db = await this.openDatabase();
        const executionTransaction: IDBTransaction = db.transaction(["MediaFileExecution"], "readwrite");
        const executionObjectStore: IDBObjectStore = executionTransaction.objectStore("MediaFileExecution");
        for (let key of executionKeysCopy) {
			await new Promise(
				(resolve, reject) => {
					const request = executionObjectStore.delete(key);
					request.onsuccess = function (event) {
						resolve({});
					};
					request.onerror = function (event) {
						reject(event);
					};
				}
			);
		}
    }

    async deleteMediaFileByKeys(mediaFileKeys: number[]): Promise<any> {
        const mediaFileKeysCopy = mediaFileKeys.slice();
        const db = await this.openDatabase();
        const mediaFileTransaction: IDBTransaction = db.transaction(["MediaFile"], "readwrite");
        const mediaFileObjectStore: IDBObjectStore = mediaFileTransaction.objectStore("MediaFile");
        for (let key of mediaFileKeysCopy) {
			await new Promise(
				(resolve, reject) => {
					const request = mediaFileObjectStore.delete(key);
					request.onsuccess = function (event) {
						resolve({});
					};
					request.onerror = function (event) {
						reject(event);
					};
				}
			);
		}
    }

    async fetchPlayHistory(): Promise<PlayHistory> {
        const db = await this.openDatabase();
        const currentPlaylist = await this.getCurrentPlaylist(db);
        const mediaFileTransaction = db.transaction(["MediaFile"]);
        const objectStore = mediaFileTransaction.objectStore("MediaFile");
        const mediaFileEntries: MediaFileExecutionEntry[] = [];
		return await new Promise(function (resolve, reject) {
            objectStore.openCursor().onsuccess = function (event: DatEvent) {
                const cursor = event.target.result;

                if (cursor) {
                    mediaFileEntries[cursor.key] = { MediaType: cursor.value.MediaType, FileName: cursor.value.FileName, ExecutionTimes: [], Id: cursor.key, ExecutionTimeKeys: [] };
                    cursor.continue();
                }
            };

            mediaFileTransaction.oncomplete = function () {
                const mediaExecutionTransaction = db.transaction(['MediaFileExecution']);
                const mediaExecutionObjects = mediaExecutionTransaction.objectStore("MediaFileExecution");
                const mediaExecutionKeys: number[] = [];

                mediaExecutionObjects.openCursor().onsuccess = function (event: DatEvent) {
                    const cursor = event.target.result;

                    if (cursor) {
                        mediaExecutionKeys.push(cursor.key);
                        const mediaFileId = cursor.value.MediaFileId;

                        const mediaFileEntry = mediaFileEntries[mediaFileId];

                        if (mediaFileEntry) {
                            mediaFileEntry.ExecutionTimes.push(HtmlUtils.dateToJsonString(cursor.value.Time));
                            mediaFileEntry.ExecutionTimeKeys.push(cursor.key);
                        }

                        cursor.continue();
                    }
                };

                mediaExecutionTransaction.oncomplete = function () {
                    resolve(
                        {
                            Entries: mediaFileEntries.filter(function (val: any) { return val !== null; }),
                            ExecutionKeys: mediaExecutionKeys,
                            VideoPlaylist: currentPlaylist.VideoPlaylist,
                            AudioPlaylist: currentPlaylist.AudioPlaylist
                        }
                    );
                };
            };
        });
    }

    async recordPlayHistory(fileName: string, mediaType: ExecutionMediaType, feedId: string, time: Date): Promise<any> {
        const db = await this.openDatabase();
        const transaction = db.transaction(["MediaFile"], "readwrite");
        transaction.oncomplete = function (event) {
            //console.log('playHistoryDatabse.recordPlayHistory - transaction.oncomplete event fired');
        };
        transaction.onerror = function (event) {
            console.log('playHistoryDatabse.recordPlayHistory - transaction.onerror event fired');
        };

        const objectStore = transaction.objectStore('MediaFile');
        objectStore.openCursor().onsuccess = (e: DatEvent) => {
            const mediaFileCursor = e.target.result;
            if (mediaFileCursor) {
                if (mediaFileCursor.value.FileName === fileName) {
                    const executionTransaction = db.transaction(["MediaFileExecution"], "readwrite");
                    const executionObject = executionTransaction.objectStore('MediaFileExecution');
                    const count = executionObject.count();
                    count.onsuccess = (event: DatEvent) => {
                        if (event.target.result < this.MAX_ROWS_OF_PLAY_HISTORY) {
                            executionObject.add({ MediaFileId: mediaFileCursor.key, Time: time || new Date() });
                        }
                    };
                    return;
                } else {
                    mediaFileCursor.continue();
                }
            } else {
                const insertRequest = objectStore.add({ FileName: fileName, MediaType: mediaType, FeedId: feedId });
                insertRequest.onsuccess = function (event: DatEvent) {
                    const executionTransaction = db.transaction(["MediaFileExecution"], "readwrite");
                    const executionObject = executionTransaction.objectStore('MediaFileExecution');
                    executionObject.add({ MediaFileId: event.target.result, Time: time || new Date() });
                };
            }
        };
    }

    async recordCurrentPlaylist(playlist: string, mediaType: PlaylistMediaType): Promise<any> {
        console.log('playHistoryDatabase, record current playlist');
        const db = await this.openDatabase();
        const currentPlaylistTransaction = db.transaction(["CurrentPlaylist"], "readwrite");
        const currentPlaylistObject = currentPlaylistTransaction.objectStore('CurrentPlaylist');
        currentPlaylistObject.put(playlist, mediaType);
    }

	getCurrentPlaylist(db: IDBDatabase): Promise<CurrentPlaylist> {
        return new Promise(function (resolve, reject) {
            const currentPlaylistTransaction = db.transaction(["CurrentPlaylist"], "readwrite");
            const currentPlaylistObject = currentPlaylistTransaction.objectStore('CurrentPlaylist');
            let videoPlaylist = '';
            let audioPlaylist = '';
            currentPlaylistObject.openCursor().onsuccess = function (event: DatEvent) {
                const cursor = event.target.result;
                if (cursor) {
                    if (cursor.key === 'Video') {
                        videoPlaylist = cursor.value;
                    } else if (cursor.key === 'Audio') {
                        audioPlaylist = cursor.value;
                    }
                    cursor.continue();
                } else {
                    resolve({
                        VideoPlaylist: videoPlaylist,
                        AudioPlaylist: audioPlaylist
                    });
                }
            };

        });
    }

    async emptyCurrentPlaylistTable(): Promise<any> {
        const db = await this.openDatabase();
        const currentPlaylistTransaction = db.transaction(["CurrentPlaylist"], "readwrite");
        const currentPlaylistObject = currentPlaylistTransaction.objectStore('CurrentPlaylist');
        currentPlaylistObject.clear();
    }

    async emptyMediaExecutionTable(): Promise<any> {
        const db = await this.openDatabase();
        const executionTransaction = db.transaction(["MediaFileExecution"], "readwrite");
        const executionObject = executionTransaction.objectStore('MediaFileExecution');
        executionObject.clear();
    }

    async emptyMediaFileTable(): Promise<any> {
        const db = await this.openDatabase();
        const mediaFileTransaction = db.transaction(["MediaFile"], "readwrite");
        const mediaFileObjects = mediaFileTransaction.objectStore('MediaFile');
        mediaFileObjects.clear();
    }
}