import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatableComponent, Side } from 'src/navigatableComponent';
import { Location } from '@angular/common';

@Component({
    templateUrl: "./application-view.component.html",
    selector: "application-view"
})
export class ApplicationViewComponent extends NavigatableComponent implements OnInit, AfterViewInit {
    constructor(private route:ActivatedRoute, private readonly location: Location) {
        super();
    }


    id: string;

    appInfo: tizen.ApplicationInformation;

    ngOnInit() {
        super.ngOnInit();
        this.id = this.route.snapshot.paramMap.get('id');
        if(tizen)
        {
            if(tizen.application) {
                this.appInfo = tizen.application.getAppInfo(this.id);
            }
        }
    }

    ngAfterViewInit(): void {
        if(this.hasFocus) {
            this.launchButton.nativeElement.focus();
        }
    }

    displayApplicationLaunchDiagnostics: boolean;
    applicationLaunchStatus: string;

    launchApplication() {
        this.applicationLaunchStatus = "Launching application...";
        tizen.application.launch(
            this.id,
            () => this.applicationLaunchStatus = "application launch succesfull",
            (error) => this.applicationLaunchStatus = `failed: ${error.message}`
        )
    }

    public focus(from: Side) {
        super.focus(from);
        if(this.launchButton) {
            this.launchButton.nativeElement.focus();
        }
    }

    @ViewChild("launchButton") launchButton: ElementRef;

}