import { mergeAnalyzedFiles } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable, merge, of } from 'rxjs';
import { filter, tap, delay, mapTo } from 'rxjs/operators';
import { LaqorrFileEntry, FileUtils } from "./fileUtils";
import { LoggerService } from "./loggerService";


function extractFileText(fileEntry$: Observable<FileEntry>) : Observable<string> {
    return new Observable<string>(
        subscription => {
            const fileEntrySubscription = fileEntry$.subscribe({
                next: async (fileEntry: LaqorrFileEntry) => {
                    try {
                        const text = await FileUtils.getFileEntryText(fileEntry);
                        subscription.next(text);
                    } catch(e) {
                        subscription.error(e);
                    }
                }
            });
            return {
                unsubscribe: () => {
                    fileEntrySubscription.unsubscribe();
                }
            }
        }
    );
}

@Injectable({
    providedIn: "root"
})
export class MediaPlayerFileSystem {
	onFileDeleted = new Subject<LaqorrFileEntry>();
    private onFileSaved = new Subject<LaqorrFileEntry>();
	constructor(private readonly logger: LoggerService) {
	}  

    watchMediaFileArrived(
        filePath: string
    ): Observable<LaqorrFileEntry> {
        return new Observable<LaqorrFileEntry>(
            (subscriber) => {
                FileUtils.getFile(
                    filePath,
                    {
                        create: false,
                        exclusive: false
                    }
                ).then(
                    value => {
                        subscriber.next(value);
                    }
                ).catch(
                    e => {
                        console.log('Unable to read file ' + filePath + ': ' + e.message);
                    }
                );
                return this.onFileSaved
                    .pipe(
                        filter(fileEntry => FileUtils.normalizePath(fileEntry.fullPath) == FileUtils.normalizePath(filePath))
                    ).subscribe(subscriber);
            }
        );
    }; 

	watchMediaFileDeleted(filePath: string): Observable<LaqorrFileEntry> {
        return this.onFileDeleted
            .pipe(
                tap(() => console.log("file delete event")),
                filter(fileEntry => FileUtils.normalizePath(fileEntry.fullPath) == FileUtils.normalizePath(filePath))
            );
    }

    watchTextContent(filePath: string): Observable<string> {
        const fileSaved$ = this.onFileSaved
            .pipe(
                filter(fileEntry => FileUtils.pathsMatch(fileEntry, filePath)),
                delay(500), // Don't know if this is actually necessary
                extractFileText
            );
        const fileDeleted$ = this.onFileDeleted
            .pipe(
                filter(fileEntry => FileUtils.pathsMatch(fileEntry, filePath)),
                mapTo('')
            );
        const readFromExisting$ = new Observable<string>(
            observable => {
                const pushTextFromFileEntry = async () => {
                    try {
                        const fileEntry = await FileUtils.getFile(filePath, { create: false, exclusive: false});
                        const text = await FileUtils.getFileEntryText(fileEntry);
                        observable.next(text);
                    } catch(e) {
                    } finally {
                        observable.complete();
                    }
                };
                pushTextFromFileEntry();
            }
        );
        return merge(
            of(''),
            readFromExisting$,
            fileSaved$,
            fileDeleted$
        );
    }
    
	async fireFileSavedEvent(file: string | LaqorrFileEntry) {
        let getFilePromise: Promise<LaqorrFileEntry>;
        if (typeof(file) === 'string') {
            console.log('^^^^ MediaPlayerFileSystem.fireFileSavedEvent being invoked. file =  ' + file + ' ^^^^');
            getFilePromise = FileUtils.getFile(file, { create: false, exclusive: false });
        } else {    
            // Making an assumption - if it is not a string, it is a fileEntry      
            getFilePromise = Promise.resolve(file);
        }
        const fileEntry = await getFilePromise;
        this.onFileSaved.next(fileEntry);
    }
}
