import { Component, OnDestroy, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit, AfterViewInit, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FeedTypeId, ScreenLayoutContent, ScreenLayoutContentProvider, emptyContent } from './screenlayoutContentProvider';
import { Subscription, combineLatest, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { TickerFeedComponent } from '../ticker-feed/ticker-feed.component';
import { WebFeedComponent } from '../web-feed/web-feed.component';
import { ClockFeedComponent } from '../clock-feed/clock-feed.component';
import { VideoFeedComponent } from '../video-feed/video-feed.component';
import { NoContentFeedComponent } from '../no-content-feed/no-content-feed.component';
import { DeviceSettings } from 'src/deviceSettings';
import { PlayHistoryDatabase } from 'src/playHistoryDatabase';

import { Rectangle, rotateRectangle, calculateOriginalRectangleGivenTargetRectangleAndRotation, getRectangleCenter } from '../../../geometry';


@Component({
    "templateUrl": "screenlayout-content-view.component.html",
    "selector": "screenlayout-content-view"
})
export class ScreenlayoutContentViewComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    contentChangeSubscription: Subscription = null;
    content: ScreenLayoutContent = emptyContent;
    backgroundColor: string = "transparent";
    width: number;
    height: number;
    feedsContentStyle: {[key: string]: any};
    currentScreenRotation: number;
    @ViewChild(
        "feedsContainer",
        {
            read: ViewContainerRef
        }
    )
    feedsContainer: ViewContainerRef;

    constructor(
        private readonly screenLayoutContentProvider: ScreenLayoutContentProvider,
        private resolver: ComponentFactoryResolver,
        private renderer: Renderer2,
        private deviceSettings : DeviceSettings,
        private playHistoryDatabase: PlayHistoryDatabase
    ) {
        console.log('screenLayoutContentViewComponent constructor');
    }

    @Input() originalWidth: number = 600;
    @Input() originalHeight: number = 800;

    private startWatchingContent() {
        const screenRotation$ = merge(
            of(this.deviceSettings.playerSettings),
            this.deviceSettings.playerSettingsObservable
        ).pipe(
            map(playerSettings => playerSettings.screenRotation),
        );
        this.contentChangeSubscription = combineLatest(
            [
                this.screenLayoutContentProvider.content$,
                screenRotation$
            ]
        ).subscribe({
            next: (result) => {
                this.content = result[0];
                this.currentScreenRotation = result[1];
                this.applyScreenLayoutContent(this.content, this.currentScreenRotation);
            }
        });
    }

    ngAfterViewInit() {
        this.startWatchingContent();
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(('originalWidth' in changes) || ('originalHeight' in changes)) {
            this.applyScreenLayoutContent(this.content, this.currentScreenRotation); 
        }
    }

    ngOnDestroy() {
        if (this.contentChangeSubscription) {
            this.contentChangeSubscription.unsubscribe();
        }
    }

    private getComponentFactory(typeId: FeedTypeId) {
        switch(typeId) {
            case 'DATMedia.HtmlFeed':
                return this.resolver.resolveComponentFactory(WebFeedComponent);
            case 'DATMedia.ClockFeed':
                return this.resolver.resolveComponentFactory(ClockFeedComponent);
            case 'DATMedia.VideoFeed':
                return this.resolver.resolveComponentFactory(VideoFeedComponent);
            case 'DATMedia.TickerFeed':
                return this.resolver.resolveComponentFactory(TickerFeedComponent);
            case 'Internal.NoContent':
                return this.resolver.resolveComponentFactory(NoContentFeedComponent);
            default:
                return this.resolver.resolveComponentFactory(VisualFeedBaseComponent);
        }
    }

    private applyScreenLayoutContent(content:ScreenLayoutContent, screenRotation: number) {
        if(this.feedsContainer) {
            this.feedsContainer.clear();
        }
        if(isNaN(screenRotation)) {
            return;
        }
        this.playHistoryDatabase.recordCurrentPlaylist(content.key, "Video");
        this.backgroundColor = content.backgroundColor;
        const targetRectangle:Rectangle = {
            left:0,
            top:0,
            width: this.originalWidth,
            height: this.originalHeight
        };
        const originalRectangle = rotateRectangle(
            targetRectangle,
            0-screenRotation
        );
        const widthMultiplier = originalRectangle.width / content.expectedWidth;
        const heightMultiplier = originalRectangle.height / content.expectedHeight;
        for(const feed of content.feeds) {
            if (feed) {
                const componentFactory = this.getComponentFactory(feed.typeId);
                const componentRef = this.feedsContainer.createComponent(componentFactory);
                const rectBeforeRotation : Rectangle = {
                    left: originalRectangle.left + feed.left * widthMultiplier,
                    top: originalRectangle.top + feed.top * heightMultiplier,
                    height: feed.height * heightMultiplier,
                    width: feed.width * widthMultiplier
                };
                const rectAfterRotation : Rectangle = rotateRectangle(
                    rectBeforeRotation,
                    screenRotation,
                    getRectangleCenter(originalRectangle)
                );
                const nativeElement = componentRef.location.nativeElement;
                this.renderer.setStyle(nativeElement, 'background-color', this.backgroundColor);
                this.renderer.setStyle(nativeElement, 'position', 'relative'); // Required for zIndex to take effect
                if (feed.zIndex) {
                    this.renderer.setStyle(nativeElement, 'z-index', feed.zIndex);
                }
                componentRef.instance.rectangle = rectAfterRotation;
                componentRef.instance.rotation = screenRotation;
                componentRef.instance.feedId = feed.id;
                componentRef.instance.typeId = feed.typeId;
                componentRef.instance.sourceFolder = feed.sourceFolder;
                componentRef.instance.backgroundColor = this.backgroundColor;
            }
        }
    }
}