import { ClientInterface } from '../laqorrProtobuf'


export type ActivationState = ClientInterface.ActivationState;

export enum ChangeTrigger {
    ApplicationStart = 0x00,
    Manual = 0x01,
    Timer = 0x02
}

export enum FunctionalityStatus {
    NotApplicable = 0,
    Good = 1,
    Bad = 2
}

export interface ActivationInfo {
    ActivationState: ActivationState;
    ChangeTime: Date;
    ChangeTrigger: ChangeTrigger;
    NextChangeTime?: Date;
}
