import { AnimationPlayer, AnimationBuilder, animate, style } from '@angular/animations';
import { PreparedElement, zIndexes } from './displayHandler';
import { DeviceSettings } from 'src/deviceSettings';


export class ElementFadeTask {
    animationPlayer: AnimationPlayer;

    public constructor(private readonly builder: AnimationBuilder, private readonly millisecondsDuration: number) {
    }

    public start(element: PreparedElement<HTMLDivElement | HTMLVideoElement>, completionCallback: () => void) {
        const metadata = [
            style({ opacity: '1' }),
            animate(`${this.millisecondsDuration}ms`, style({ opacity: 0 })),
          ];
        const factory = this.builder.build(metadata);
        this.animationPlayer = factory.create(element.element);
        element.zIndex = zIndexes.fading;
        this.animationPlayer.onDone(
            () => {
                this.destroy();
                element.zIndex = zIndexes.hiding;
                completionCallback();
            }
        );
        this.animationPlayer.play();
    }

    public destroy() {
        if(this.animationPlayer) {
            this.animationPlayer.destroy();
            this.animationPlayer = null;    
        }
    }
}

export class ElementFader {
    constructor(private readonly builder: AnimationBuilder, private readonly deviceSettings: DeviceSettings) {
    }

    async fade(element: PreparedElement<HTMLDivElement | HTMLVideoElement>, completionCallback: ()=>void) : Promise<ElementFadeTask> {
        const crossFadeDuration = (await this.deviceSettings.getPlayerSettings()).crossFadeDuration;
        try {
            const elementFadeTask = new ElementFadeTask(this.builder, crossFadeDuration);
            elementFadeTask.start(element, completionCallback);
            return elementFadeTask;
        }
        catch(e) {
        }   
    }
}