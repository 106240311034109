import { DisplayHandler, ExecutionCompleteArgs, PreparedElement, zIndexes, ExecutionId } from './displayHandler';
import { ElementFader, ElementFadeTask } from './elementFader';
import { FileManager } from 'src/fileManager';
import { LaqorrFileEntry, FileUtils } from 'src/fileUtils';
import { Subject } from 'rxjs';
import { BlockingCollection } from 'src/blockingCollection';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalTimeZoneClock } from 'src/clock';

export class VideoElementDisplayHandler implements DisplayHandler {
    private readonly playCompleteSubject = new Subject<ExecutionCompleteArgs>();
    private readonly availableElements = new BlockingCollection<PreparedElement<HTMLVideoElement>>();

    get mediaExecutionComplete$() {
        return this.playCompleteSubject.asObservable();
    }

    public constructor(
        private readonly sanitizer: DomSanitizer, 
        private readonly elementFader: ElementFader,
        private readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
    }

    preparedElements: PreparedElement<HTMLVideoElement>[] = [
        {
            file: null,
            element: null,
            display: "block",
            zIndex: zIndexes.hiding
        },
        {
            file: null,
            element: null,
            display: "block",
            zIndex: zIndexes.hiding
        }
    ];

    setNativeElements(element1: HTMLVideoElement, element2: HTMLVideoElement) {
        this.preparedElements[0].element = element1;
        this.preparedElements[1].element = element2;
        this.availableElements.reset(this.preparedElements);
    }

    private playerVolume: number = 0;

    setPlayerVolume(playerVolume: number) {
        this.playerVolume = playerVolume;
        this.preparedElements.forEach(
            video => {
                if (video.element) {
                    video.element.volume = playerVolume / 100.0;
                }
            }
        );
    }

    // Don't delete this, it's invoked by the template
    getSource(videoElement: PreparedElement<HTMLVideoElement>) {
        const file = videoElement.file;
        return file
            ? this.sanitizer.bypassSecurityTrustResourceUrl(FileManager.isTizenFile(file) ? file.toURI() : file.toURL())
            : "";
    }

    private elementFadeTask : ElementFadeTask;
    async play(mediaFile: LaqorrFileEntry, executionId: ExecutionId)  {
        let element: PreparedElement<HTMLVideoElement>;
        if(this.pendingElement) {
            element = this.pendingElement;
            this.pendingElement = null;
        } else {
            element = await this.availableElements.pop();
        }
        const videoElement = element.element;

        const complete = async (success:boolean) => {
            videoElement.onended = null;
            videoElement.onerror = null;

            this.elementFadeTask = await this.elementFader.fade(
                element,
                () => {
                    this.elementFadeTask = null;
                    element.file = null;
                    this.availableElements.push(element);   
                }
            );
            this.playCompleteSubject.next({
                executionId: executionId,
                mediaFile: mediaFile,
                startTime: startTime,
                succeeded: success
            });
        } 


        videoElement.onended = () => complete(true);
        videoElement.onerror = () => complete(false);

        element.zIndex = zIndexes.showing;
        element.display = "block";

        if(element.file !== mediaFile) {
            element.file = mediaFile;
            videoElement.src = FileUtils.getLaqorrFileEntryUrl(mediaFile);
            videoElement.load();
        } 
        videoElement.currentTime = 0;
        videoElement.volume = this.playerVolume / 100.0;
        videoElement.currentTime = 0;
        const startTime = this.localTimeZoneClock.now;
        videoElement.play();
    }

    private pendingElement: PreparedElement<HTMLVideoElement>;

    async prepareNext(mediaFile: LaqorrFileEntry) : Promise<void> {
        this.pendingElement = this.pendingElement || await this.availableElements.pop();
        this.pendingElement.file = mediaFile;
        this.pendingElement.zIndex = zIndexes.hiding;
        this.pendingElement.display = "block";
        const videoElement = this.pendingElement.element;
        videoElement.src = FileUtils.getLaqorrFileEntryUrl(mediaFile);
        videoElement.load();
        videoElement.currentTime = 0;
    }

    stopAll() {
        if(this.elementFadeTask) {
            this.elementFadeTask.destroy();
            this.elementFadeTask = null;
        }
        this.preparedElements.forEach(
            video => {
                video.element.pause();
                video.element.onended = null;
                video.element.onerror = null;
                video.element.src = null;
                video.file = null;
                video.display = "none";
                video.zIndex = zIndexes.hiding;        
            }
        );
        this.pendingElement = null;
        // Making use of the knowledge that BlockingCollection<T>
        // forces distinct values
        this.availableElements.reset(this.preparedElements);
    }
}