import { ServerApi, FileForTransfer } from './serverApi';
import { Injectable } from '@angular/core';
import { FileHierarchyNode } from "./fileHierarchyNode";
import { fileManager } from "./fileManager";
import { FileUtils } from "./fileUtils";
import { MediaPlayerFileSystem } from "./mediaPlayerFileSystem";
import { MediaPlayerFileInfoManager } from "./mediaPlayerFileInfoManager";
import { LoggerService } from './loggerService';

interface FileToCheck {
    fileEntry: tizen.File | FileEntry
    requiredFile: FileForTransfer;
}

interface RequiredFilesAndHierarchy {
    RequiredFiles: FileForTransfer[];
    Hierarchy: FileHierarchyNode;
    FindMatchingFileEntry: (f: FileForTransfer) => tizen.File | FileEntry | void;
    isExternal: boolean;
}

interface FilesOrFoldersToDelete {
    filesToDelete: tizen.File[];
    directoriesToDelete: (tizen.File | DirectoryEntry)[];
    keep?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class FileSynchroniser {
    sizeOfOneMb: number = 1048576;
    constructor(
        private readonly serverApi: ServerApi,
        private readonly mediaPlayerFileSystem: MediaPlayerFileSystem,
        private readonly mediaPlayerFileInfoManager: MediaPlayerFileInfoManager,
        private readonly loggerService: LoggerService
    ) {
	}

    getRequiredFiles() {
        return this.serverApi.getRequiredFiles().toPromise();
    }

    generateFullFilePath(requiredFile: FileForTransfer) {
        const folderName = fileManager.getMediaPlayerFolderName(requiredFile.MediaPlayerFolder);
        return folderName + '\\' + requiredFile.RelativePathOnClient;
    }

    async synchroniseAllFiles() {
        console.log('synchroniseAllFiles invoked');
        const requiredFiles = await this.getRequiredFiles();
        const existingPrivateFileHierarchy = await fileManager.getExistingFileHierarchy();
        const synchronizationGroups: RequiredFilesAndHierarchy[] = [];
        let privateFiles: any = requiredFiles;

        synchronizationGroups.push({
            RequiredFiles: privateFiles,
            Hierarchy: existingPrivateFileHierarchy,
            FindMatchingFileEntry: (requiredFile) => {
                const fullPath = this.generateFullFilePath(requiredFile);
                console.log(`Finding a file matching the entry ${fullPath}`);
                return existingPrivateFileHierarchy.findMatchingFileEntry(fullPath);
            },
            isExternal: false
        });

        await Promise.all(
            synchronizationGroups
                .map(
                    async (group) => {
                        return await this.synchroniseGroup(group);
                    }
                )
        );
    }

    private async synchroniseGroup(requiredFilesAndHierarchy: RequiredFilesAndHierarchy) {
        console.log(`synchronizeGroup invoked`);
        const filesToCheck: FileToCheck[] = [];
        const filesToDownload: FileForTransfer[] = [];

        requiredFilesAndHierarchy
            .RequiredFiles
            .forEach(
                requiredFile => {
                    const fileEntry = requiredFilesAndHierarchy.FindMatchingFileEntry(requiredFile);
                    if (fileEntry) {
                        filesToCheck.push({
                            fileEntry: fileEntry,
                            requiredFile: requiredFile
                        });
                    } else {
                        console.log(`Unable to find a fileEntry matching ${requiredFile.RelativePathOnClient}`)
                        filesToDownload.push(requiredFile);
                    }
                }
            );

        // Now to delete the files and folders we don't need!
        // we keep all files in external sd card or usb
        let fileSystemToCleanUp: FilesOrFoldersToDelete = {
            filesToDelete: [],
            directoriesToDelete:[]
        };

        if (!requiredFilesAndHierarchy.isExternal) {
            fileSystemToCleanUp = this.scanFilesHierarchyNodeForItemsToDelete(requiredFilesAndHierarchy.Hierarchy, filesToCheck);
        }

        await this.checkFileMD5(filesToCheck, fileSystemToCleanUp.filesToDelete, filesToDownload);
        await this.deleteFilesDirectoriesAndDownloadNewFilesDirectories(
            fileSystemToCleanUp.filesToDelete,
            fileSystemToCleanUp.directoriesToDelete,
            filesToDownload);
    }

    private async notifyServerDeletedFiles(
        directoriesToDelete: (tizen.File | DirectoryEntry)[],
        filesToDelete: tizen.File[]
    ) {
        let allFilesInFolders: (tizen.File | Entry)[] = [];
        await Promise.all(directoriesToDelete.map(async (directoryToDelete) => {
            const files = await fileManager.getAllFiles(directoryToDelete);
            allFilesInFolders = [...allFilesInFolders, ...files];
        }));

        allFilesInFolders = allFilesInFolders.filter(
            f => f !== undefined && f !== null
        );

        let allFilePaths: string[] = 
            [
                ...allFilesInFolders,
                ...filesToDelete
            ].map(
                f => f.fullPath
            ).filter(
                f => (f !== undefined) && f !== null
            )

        if (allFilePaths.length) {
            this.mediaPlayerFileInfoManager.notifyDeletedFilesToServer(allFilePaths);
        }
    }

    private async deleteFilesDirectoriesAndDownloadNewFilesDirectories(
        filesToDelete: tizen.File[],
        directoriesToDelete:(tizen.File | DirectoryEntry)[],
        filesToDownload: FileForTransfer[]
    ) {
        await this.notifyServerDeletedFiles(directoriesToDelete, filesToDelete);

        for (const fileToDelete of filesToDelete) {
            console.log(`fileSynchroniser deleting ${fileToDelete.fullPath}`);
            await FileUtils.deleteFileOrDirectory(fileToDelete);
            this.mediaPlayerFileSystem.onFileDeleted.next(fileToDelete);
        }

        for (const directoryToDelete of directoriesToDelete) {
            console.log(`fileSynchroniser deleting ${directoryToDelete.fullPath}`);
            await FileUtils.deleteFileOrDirectory(directoryToDelete);
        }

        if (filesToDownload.length) {
            return this.serverApi.postSendFileRequests(filesToDownload).toPromise();
        } else {
            return Promise.resolve();
        }
    }

    private isMusicFile(fileEntry: tizen.File) {
        return fileEntry.fullPath.toLowerCase().endsWith("mp3");
    }

    private isMusicFolder(fileEntry: tizen.File | Entry) {
        const fullPath = fileEntry.fullPath.toLowerCase();
        return fullPath.indexOf("music") > -1 || fullPath.indexOf("audioadvert") > -1 || fullPath.indexOf("audiomessage") > -1;
    }

    private isTemporaryFolder(entry: tizen.File | Entry) {
        return FileUtils.pathsMatch(entry, fileManager.temporaryFiles);
    }

    private isPlayerSettingsFile(entry: tizen.File) {
        return FileUtils.pathsMatch(entry, fileManager.playerSettingsFile);
    }

    private scanFilesHierarchyNodeForItemsToDelete(fhn: FileHierarchyNode, filesToCheck: FileToCheck[]): FilesOrFoldersToDelete {
        const returnValue: FilesOrFoldersToDelete = {
            filesToDelete: [],
            directoriesToDelete: [],
            keep: false
        };

        // A nested linear search. This will perform terribly
        fhn.files.forEach((fileEntry) => {
            if (filesToCheck.some((fileToCheck) => {
                return FileUtils.pathsMatch(fileToCheck.fileEntry, fileEntry);
            })) {
                returnValue.keep = true;
            } else {
                if (!this.isMusicFile(fileEntry) && !this.isPlayerSettingsFile(fileEntry)) {
                    returnValue.filesToDelete.push(fileEntry);
                }
            }
        });
        fhn.children.forEach((childNode) => {
            const childResult = this.scanFilesHierarchyNodeForItemsToDelete(childNode, filesToCheck);
            returnValue.filesToDelete = returnValue.filesToDelete.concat(childResult.filesToDelete);
            returnValue.directoriesToDelete = returnValue.directoriesToDelete.concat(childResult.directoriesToDelete);
            returnValue.keep = returnValue.keep || childResult.keep;
            if (!(childResult.keep)) {
                if (
                    !this.isMusicFolder(childNode.directoryEntry)
                    && !this.isTemporaryFolder(childNode.directoryEntry)
                ) {
                    returnValue.directoriesToDelete.push(childNode.directoryEntry);
                }
            }
        });
        return returnValue;
    }

    private async checkFileMD5(filesToCheck: FileToCheck[], filesToDelete: (tizen.File | FileEntry)[], filesToDownload: FileForTransfer[]) {
        console.log(`fileSynchroniser.checkFileMD5. There are ${filesToCheck.length} files to check`);
        for (let fileToCheck of filesToCheck) {
            const fileSize = await FileUtils.getFileSize(fileToCheck.fileEntry);
            if (fileSize != fileToCheck.requiredFile.FileSize) {
                console.log(`The file ${fileToCheck.requiredFile.RelativePathOnClient} has a fileSize of ${fileSize} which does not match ${fileToCheck.requiredFile.FileSize}`);
                filesToDelete.push(fileToCheck.fileEntry);
                filesToDownload.push(fileToCheck.requiredFile);
            } else {
                if (fileSize >= this.sizeOfOneMb) {
                    console.log(`Not checking MD5 of file ${fileToCheck.requiredFile.RelativePathOnClient} as it is too large. It passes the check!`);
                } else {
                    const generatedMd5: string = await FileUtils.getFileMd5ByChunks(fileToCheck.fileEntry, this.loggerService);
                    if (!FileUtils.compareMd5s(generatedMd5, fileToCheck.requiredFile.MD5)) {
                        console.log(`MD5 mismatch for file ${fileToCheck.requiredFile.RelativePathOnClient}. Will redownload`);
                        filesToDelete.push(fileToCheck.fileEntry);
                        filesToDownload.push(fileToCheck.requiredFile);
                    } else {
                        console.log(`File ${fileToCheck.requiredFile.RelativePathOnClient} matches the MD5 and the length`);
                    }
                }
            }
        }
    }
}