import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RootComponent } from "../components/root/root.component";
import { DisplayComponent } from "../components/display/display.component";
import { NavigationSidebar } from "../components/navigation-sidebar/navigation-sidebar.component";
import { AppComponent } from "../components/app/app.component";
import { HomeComponent } from "../components/home/home.component";
import { TopBannerComponent } from "../components/top-banner/top-banner.component";
import { UnsupportedBrowserComponent } from "../components/unsupported-browser/unsupported-browser.component";
import { EnrolmentClientViewComponent } from "../components/enrolment-client-view/enrolment-client-view.component";
import { ClientRequestDispatcherViewComponent } from "../components/client-request-dispatcher-view/client-request-dispatcher-view.component";
import { ServerSelectorComponent } from "../components/server-selector/server-selector.component";
import { SessionManagerViewComponent } from "../components/session-manager-view/session-manager-view.component";
import { MethodCallDispatcherViewComponent } from "../components/method-call-dispatcher-view/method-call-dispatcher-view.component";
import { InstalledPackagesViewComponent } from "../components/packages/installed-packages-view/installed-packages-view.component";
import { RecentKeyPressViewComponent } from "../components/recent-key-press-view/recent-keypress-view.component";
import { PackagesPageComponent } from "../components/packages/packages-page/packages-page.component";
import { PackageTileComponent } from "../components/packages/package-tile/package-tile.component";
import { PackageViewComponent } from "../components/packages/package-view/package-view.component";
import { PagerComponent } from "../components/pager/pager.component";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerDisplayViewComponent } from "../components/logger-display-view/logger-display-view.component";
import { InstalledApplicationsViewComponent } from '../components/applications/installed-applications-view/installed-applications-view.component';
import { ApplicationsPageComponent } from '../components/applications/applications-page/applications-page.component';
import { ApplicationTileComponent } from '../components/applications/application-tile/application-tile.component';
import { ApplicationViewComponent } from '../components/applications/application-view/application-view.component';
import { LocalSettingsViewComponent } from '../components/local-settings-view/local-settings-view.component';
import { ScreenlayoutContentViewComponent } from "../components/screenlayout-content-view/screenlayout-content-view.component";
import { CustomerPageComponent } from "../components/customer-page/customer-page.component"
import { ServerReportingTimer } from "../../serverReportingTimer";
import { DeviceSettings } from "../../deviceSettings";
import { ClientSessionManager } from "../../eneter/session/clientSessionManager";
import { VisualFeedBaseComponent } from '../components/visual-feed-base/visual-feed-base.component';
import { WebFeedComponent } from '../components/web-feed/web-feed.component';
import { ScreenPowerManager } from "../../screenActivation/screenPowerManager";
import { ScreenPowerManagerWatcher } from '../../screenActivation/screenPowerManagerWatcher';
import { ClockFeedComponent } from '../components/clock-feed/clock-feed.component';
import { VideoFeedComponent } from '../components/video-feed/video-feed.component';
import { TickerFeedComponent } from '../components/ticker-feed/ticker-feed.component';
import { NoContentFeedComponent } from '../components/no-content-feed/no-content-feed.component';
import { FileTransferViewComponent } from '../components/file-transfer-view/file-transfer-view.component';
import { DailyReboot } from 'src/dailyReboot';
import { EnrolmentClient } from 'src/eneter/enrolment/enrolmentClient';
import { MethodCallDispatcher } from '../../eneter/methodCall/methodCallDispatcher';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { initializer as developerModeSettingInitializer } from '../../developerModeSettingProvider';
import { LoggerService } from '../../loggerService';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';

function registerForTizenNumericKeys() {
    if(typeof(tizen) === 'undefined') {1
        return;
    }
    if(!(tizen && tizen.tvinputdevice)) {
        return;
    }

    // Register the numeric keys
    for(let i = 0; i <= 9; ++i) {
        tizen.tvinputdevice.registerKey(`${i}`);
    }
}

registerForTizenNumericKeys();

@NgModule({
    declarations: [
        AppComponent,
        RootComponent,
        // The declarations must include all components
        // Otherwise directives like *ngFor will not work
        HomeComponent,
        DisplayComponent,
        ServerSelectorComponent,
        EnrolmentClientViewComponent,
        ClientRequestDispatcherViewComponent,
        SessionManagerViewComponent,
        MethodCallDispatcherViewComponent,
        InstalledPackagesViewComponent,
        RecentKeyPressViewComponent,
        PagerComponent,
        PackagesPageComponent,
        LoggerDisplayViewComponent,
        PackageTileComponent,
        PackageViewComponent,
        InstalledApplicationsViewComponent,
        ApplicationsPageComponent,
        ApplicationTileComponent,
        ApplicationViewComponent,
        LocalSettingsViewComponent,
        ScreenlayoutContentViewComponent,
        VisualFeedBaseComponent,
        FileTransferViewComponent,
        WebFeedComponent,
        ClockFeedComponent,
        VideoFeedComponent,
        TickerFeedComponent,
        NoContentFeedComponent,
        TopBannerComponent,
        NavigationSidebar,
        CustomerPageComponent,
        UnsupportedBrowserComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [
        { 
            provide: APP_INITIALIZER,
            useFactory: (service: ServerReportingTimer) => async () => {
                try {
                    await service.startTimer();
                } catch(error) {
                    console.error(`Exception caught invoking service.startTimer: ${error}`);
                }
            },
            deps: [ServerReportingTimer],
            multi: true 
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (settings: DeviceSettings) => async () => {
                try {
                     await settings.getPlayerSettings();
                } catch(error) {
                    console.error(`Exception caught invoking settings.getPlayerSettings(): ${error}`);
                }
            }, 
            deps: [DeviceSettings],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (screenPowerManager: ScreenPowerManager) => () => {
                try {
                     screenPowerManager.start();
                } catch(error) {
                    console.error(`Exception caught invoking screenPowerManager.start(): ${error}`);
                }
            },
            deps: [ScreenPowerManager],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (watcher: ScreenPowerManagerWatcher) => () => {
                try {
                     watcher.startWatching();
                } catch(error) {
                    console.error(`Exception caught invoking screenPowerManager.start(): ${error}`);
                }
            },
            deps: [ScreenPowerManagerWatcher],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (dailyReboot: DailyReboot) => () => dailyReboot.start(),
            deps: [DailyReboot],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (clientSessionManager: ClientSessionManager) => () => clientSessionManager.openSessionWhenReady(),
            deps: [ClientSessionManager],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (methodCallDispatcher: MethodCallDispatcher) => () => methodCallDispatcher.listenForIncomingMethodCalls(),
            deps: [MethodCallDispatcher],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (enrolmentClient: EnrolmentClient, router: Router) => async () => {
                const enrolmentStatus = await enrolmentClient.status.pipe(
                    filter(status => typeof(status.isEnrolled) !== 'undefined'),
                    first()
                ).toPromise();
                
                if(enrolmentStatus.isEnrolled) {
                    router.navigateByUrl('/display');
                } else {
                    try {
                        router.navigateByUrl('/setup');
                    } catch(error) {
                        console.error(`error thrown when navigating to '/setup': ${error}`);
                    }
                }
            },
            deps: [EnrolmentClient, Router],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (loggerService: LoggerService) => async() => {
                try {
                    if(tizen) {
                        if(tizen.systeminfo) {
                            tizen.systeminfo.getPropertyValue(
                                "DISPLAY",
                                (systemInfoDisplay: tizen.SystemInfoDisplay) => {
                                    loggerService.logDebug("*** System Display ***");
                                    loggerService.logDebug(`Physical Dimensions: (${systemInfoDisplay.physicalWidth}, ${systemInfoDisplay.physicalHeight})`);
                                    loggerService.logDebug(`Dots Per Inch Width,Height: (${systemInfoDisplay.dotsPerInchWidth}, ${systemInfoDisplay.dotsPerInchHeight})`);
                                    loggerService.logDebug(`Resolution: (${systemInfoDisplay.resolutionWidth}, ${systemInfoDisplay.resolutionHeight})`);
                                }
                            );

                            tizen.systeminfo.getPropertyValue(
                                "BUILD",
                                (systemInfoBuild) => {
                                    loggerService.logDebug("*** System Build ***");
                                    loggerService.logDebug(`model: ${systemInfoBuild.model}`);
                                    loggerService.logDebug(`manufacturer: ${systemInfoBuild.manufacturer}`);
                                    loggerService.logDebug(`buildVersion: ${systemInfoBuild.buildVersion}`);
                                }
                            )
                            return;
                        }
                    }
                    loggerService.logDebug("unable to access tizen.systeminfo");
                } catch(error) {
                    loggerService.logError(error);
                }

            },
            deps: [LoggerService],
            multi: true
        },
        developerModeSettingInitializer,
        // https://stackoverflow.com/questions/39287444/angular2-how-to-get-app-base-href-programmatically
        {
            provide: APP_BASE_HREF,
            useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        }

    ],
    bootstrap: [RootComponent]
})
export class AppModule {

}