import { Component } from '@angular/core';
import { LoggerService } from '../../../loggerService';

@Component({
    "templateUrl": "logger-display-view.component.html",
    "selector": "logger-display-view"
})
export class LoggerDisplayViewComponent {
    logs: string[] = [];

    constructor(readonly logger: LoggerService) {
        this.logs = logger.logs;
    }
}