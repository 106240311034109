// A universal resource identifier
// This class is immutable
// you cannot modify it, just create
// new instances of it that are slightly different
//
export class Uri {
    private protocol: string;
    private port: number;
    private ipAddress: string;
    private parameters: { [key:string] : string | number };
    private pathElements : string[];

    public static create(
        values: {
            protocol: string,
            port: number,
            ipAddress: string,
            path: string
        }
    ) {
        const uri = new Uri();
        uri.protocol = values.protocol;
        uri.port = values.port;
        uri.ipAddress = values.ipAddress;
        uri.pathElements = Uri.splitPathElements(values.path);
        return uri;
    }


    private clone() : Uri {
        const clonedValue = new Uri();
        clonedValue.ipAddress = this.ipAddress;
        clonedValue.parameters = this.parameters;
        clonedValue.pathElements = this.pathElements;
        clonedValue.port = this.port;
        clonedValue.protocol = this.protocol;
        return clonedValue;
    }


    public appendPathElements(path:string) {
        const uriClone = this.clone();
        uriClone.pathElements = uriClone.pathElements.slice().concat(Uri.splitPathElements(path));
        return uriClone;
    }

    public addParameter(name: string, value: string|number) {
        const target:{[key:string]:string|number} = {};
        target[name] = value;
        return this.addParameters(target);
    }

    public addParameters(p: {[key:string] : string|number}) {

        const target:{[key:string]:string|number} = {};
        Object.assign(target, p, this.parameters);
        const uriClone = this.clone();
        uriClone.parameters = target;
        return uriClone;
    }

    private static trimForwardSlashes(s:string) {
        return s.replace(/^\/+|\/+$/gm, '');
    }

    private static splitPathElements(path: string) : string[] {
        return (path || '').split('/').filter(s => s && s.length);
    }

    static inferPortFromProtocol(protocol:string) : number {
        if(protocol=== "http") {
            return 80;
        } else if (protocol === "https") {
            return 443;
        } 
        return NaN;
    }

    static parse(s:string) : Uri {
        const re = /^([^\/:]+):\/\/([^\/?:]+)(:(\d+))?([^?]*)(\?(.+))?$/g;
        const match : RegExpExecArray = re.exec(s);
        const parameters:string = match[7];
        const result = new Uri();
        result.protocol = match[1];
        result.ipAddress = match[2];
        result.port = parseInt(match[4], 10);
        // If the port is not set, use the default
        if(isNaN(result.port)) {
            result.port = Uri.inferPortFromProtocol(result.protocol);
        }
        result.pathElements = Uri.splitPathElements(match[5]);
        result.parameters = (match[7] || '')
            .split('&')
            .map(p => p.split('='))
            .reduce(
                (m, p) => { m[p[0]] = p[1]; return m; }, 
                <{[index:string]:string}>{}
            );
        return result;
    }

    toString() : string {
        const path = this
            .pathElements
            .map(pathElement => Uri.trimForwardSlashes(pathElement))
            .join('/');
        let url = `${this.protocol}://${this.ipAddress}:${this.port}/${path}`;

        if(this.parameters) {
            const parameterNames = Object.keys(this.parameters);
            if(parameterNames.length) {
                const parameterString = parameterNames
                    .map(pName => `${encodeURIComponent(pName)}=${encodeURIComponent(`${this.parameters[pName]}`)}`)
                    .join('&');
                url = `${url}?${parameterString}`;
            }
        }
        return url;
    }
}