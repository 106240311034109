// Manual copy of the items in the protoBuf folder
// attempting to match the structure of the subfolders with namespaces
//
// There may be a way to generate this automatically
//
// This file is identical in two places
//  1) repository laqorr-mediaplayer folder: Chrome/Eneter/laqorrProtobuf.ts
//  2) repository laqorr-tizen folder: src/laqorrProtobuf.ts
//
// Synchronization between these two files is currently manual

export enum MessageType {
    EnrollmentRequest = 1,
    EnrollmentResponse = 2,
    SessionRequest = 3,
    SessionResponse = 4,
    MethodCall = 5,
    MethodResponse = 6
}

export interface Message {
    MessageType : MessageType;
    MessageContents: ArrayLike<number>;
}

export interface NetworkConfiguration {
    ApiEndpoints: string[];
}

export interface PublicKey {
    Exponent: string;
    // Modulus, mispelled
    Modules: string;
}

export namespace Enrolment {
    export enum ResponseType {
        InitialResponse = 0,
        Confirmation = 1
    }

    export interface ResponseContents {
        ResponseType : ResponseType;
        Data: ArrayBuffer;
    }

    export enum RequestType {
        InitialRequest = 0,
        ConfirmationReceipt = 1
    }

    export interface RequestContents {
        RequestType : RequestType;
        Data : ArrayLike<number>;
    }

    export enum DevicePlatform {
        Windows = 0,
        Android = 1,
        Chrome = 2,
        Tizen = 3
    }

    export interface InitialRequest {
        DevicePublicKey: ArrayLike<number>;
        DevicePlatform: DevicePlatform;
        SoftwareVersion: number;
    }

    export interface InitialResponse {
        UniqueEnrollmentCode: string;
        ServerPublicKey: ArrayBuffer;
    }

    export interface EnrolmentConfirmation {
        DeviceId : number;
        EncryptedChallenge: string;
        ReportedPort: number;
        NetworkConfiguration: NetworkConfiguration;
    }

    export interface ConfirmationReceipt {
        ChallengeResponse : ArrayBuffer;
    }
}

export namespace Session {
    export interface InitiateSession {
        DeviceId: number;
        Challenge: string;
    }

    export interface SessionAcceptance {
        ChallengeResponse: string;
        EncryptedMessage: string;
    }

    export interface SessionKey {
        Key: string;
        InitializationVector: string;
    }

    export interface SessionAcceptanceContents {
        Challenge: ArrayBuffer;
        SessionKey: SessionKey;
    }

    export enum RejectionReason {
        UnrecognisedPlayerId = 1,
        FailedChallenge = 2
     }

     export interface SessionRejection {
        RejectionReason: RejectionReason;
        RejectionMessage: string;
     }

     export enum RequestType{
        InitiateSession = 1,
        ConfirmSession = 2
     }

     export interface SessionRequest {
         RequestType: RequestType;
         Data: ArrayLike<number>;
     }

     export enum ResponseType{
        SessionAcceptance = 1,
        Ping = 2,
        SessionRejection = 3
     }
     
     export interface SessionResponse {
       ResponseType: ResponseType;
       Data: ArrayLike<number>;
     }
}

export namespace MethodCall {
    export interface MethodCallException {
        Action: string;
        Message: string;
        InnerException: MethodCallException;
        Code: number;
    }

    export interface MethodInvocation {
        RequestId: number;
        MethodName: string;
        Parameters: ArrayLike<number>;
    }

    export interface MethodResponse {
        RequestId: number;
        Result: ArrayLike<number>;
        Succeeded: boolean;
        ResponseException?: MethodCallException;
    }
}

export namespace ClientInterface {
    export enum ScreenProtocol {
        // The default for the operating system. On windows, we can send SCREEN_ON and SCREEN_OFF messages
        // On android and Chrome this does nothing
        Default = 0,
        SamsungMDC = 1,
        // Just stop playing
        None = 2,
        NecLcd = 3,
        PanasonicTH_42lFE6W = 4,
        PhilipsQSeries = 5,
        PhilipsVSeries = 6,
        Philips_Version_1_88 = 7,
        Philips_Version_1_87 = 8,
        Zalcom_ZAD_600 = 9,
        Goodview = 10,
        Hyundai = 11,
        Vuno_2018 = 12
    }

    export enum LogType {
        Information = 0,
        Debug = 1,
        Error = 2,
        Warning = 3,
        Fatal = 4,
    }

    export interface ScreenActivationSettings {

        // Time of day that it starts. Should it be a string or a number in seconds?
        StartTime?: string;
        // Time of day that it ends.
        EndTime?: string;
        ScreenProtocol?: ScreenProtocol;
        // There are a different set of input sources for each protocol
        InputSource?: number;
    
        // Used for Windows and VUNO android players
        // Does not apply if using the USB RS232 adapaters
        ScreenSerialPort?: string;
        // Used when controlling a video wall
        ScreenIds?: string;
        // Used for VUNO player
        CommunicationPortNames?: string;
    
        DisplayScreenSaver?: boolean;
    }

    export interface PlayerSettings {
        TurnScreenOnAndOff?: boolean;
        ScreenActivationSettings?: ScreenActivationSettings;
        DefaultSlideDuration?: number;
        ScheduledAudio?: boolean;
        AudioVolume?: number;
        CrossFadeDuration?: number;
        ScreenRotation?: number;
        AutomaticallyPlayOnStartup?: boolean;
        DailyRebootTime?: string;
        DailyReboot?: boolean;
        EnableHealthMonitoring?: boolean;
        HealthCheckIntervalMinutes?: number;
        AutomaticStartupDelaySeconds?: number;
        LoggingLevel?: LogType;
    }

    export enum CmsFileType {
        Video = 0,
        Music = 1,
        AudioMessage = 2,
        AudioAdvert = 3,
        PlaylistFile = 4,
        SoftwareUpdate = 5,
        PlaylistMasterFile = 6,
        MediaTagFile = 7,
        ScheduleFile = 8,
        TickerContentFile = 9,
        MultiPlaylistFile = 10,
        VisualFeedContent = 11,
        MusicWhitelist = 12
    }

    export interface DownloadParameters {
        File: string;
        TotalBytes: number;
        Checksum:string;
        MediaType: CmsFileType;
    }

    export interface ReceiveBytesParameters {
        Data: Array<number>;
        File: string;
        SequenceId?: number;
        Checksum?: string;
        MediaType?: CmsFileType;
    }

    export enum MediaPlayerFolder {
        Video = 0,
        Music = 1,
        VideoPlaylist = 2,
        AudioPlaylist = 3,
        VisualFeedContent = 4,
        AudioMessage = 5,
        AudioAdvert = 6,
        MusicWhitelist = 7,
		TemporaryFiles = 8
    }

    export enum FolderQueryResultsStatus {
        Success = 0,
        NotFound = 1
    }

    export interface VirtualFolderDefinition {
        MediaPlayerFolder: MediaPlayerFolder;
        FullPath: string;
    }

    export interface QueryPath {
        MediaPlayerFolder: MediaPlayerFolder;
        SubFolder?: string;
    }

    export interface FolderItem {
        FolderName: string;
        LastModifiedDateTime?: string;
    }

    export interface FileItem {
        FileName: string;
        FileSize?: number;
        MD5?: string;
        LastModifiedDateTime?: string;
    }

    export interface DeleteFileOrFolderParameter {
        Path: string;
        DeletePhysical: boolean;
    }

    export interface FolderQueryResults {
        VirtualFolderDefinitions: VirtualFolderDefinition[];
        Status?: FolderQueryResultsStatus;
        FolderPath?: string;
        ChildFolders: FolderItem[];
        ChildFiles: FileItem[];   
    }

    export enum ActivationState {
        Unknown = 0,
        Active = 1,
        Inactive = 2,
        InvalidClock = 3,
        Configuration = 4
    }

    
    export enum ChangeTrigger{
        ApplicationStart = 0,
       Manual = 1,
       Timer = 2
    }

    export interface ActivationInfo {
        ActivationState: ActivationState;
        ChangeTrigger: ChangeTrigger;
        ChangeTime: number;
        NextChangeTime: number;
    }
}
