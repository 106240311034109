import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigatableComponent, Side } from 'src/navigatableComponent';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnrolmentClient } from 'src/eneter/enrolment/enrolmentClient';


@Component({
    templateUrl: './top-banner.component.html',
    selector: 'top-banner',
    styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent extends NavigatableComponent implements OnInit {
    constructor(readonly router: Router, readonly enrolmentClient: EnrolmentClient) {
        super();
    }

    launchPlayer() {
        this.router.navigateByUrl('/display');
    }

    @ViewChild('launchPlayerButton') launchPlayerButton: ElementRef<HTMLButtonElement>;

    public focus(from: Side) {
        super.focus(from);
        if(this.launchPlayerButton) {
            this.launchPlayerButton.nativeElement.focus();
        } else {
            this.relinquishFocus("Bottom");
        }
    }

    canLaunch$: Observable<boolean>;

    ngOnInit() {
        this.canLaunch$ = this.enrolmentClient.status.pipe(
            map(status => status.isEnrolled)
        );
    }
}