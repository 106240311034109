import { Component, OnInit } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { watchMostRecentKeydowns } from '../../../inputListener';
import { trigger, state, style, animate, transition, sequence } from '@angular/animations';


@Component({
    selector: 'recent-keypress-view',
    templateUrl: 'recent-keypress-view.component.html',
    animations: [
        trigger(
          'recentKeysViewState',
          [
            state(
              'visible', 
              style(
                {
                  opacity:1,
                  "background-color":'green'
                }
              )
            ),
            state(
              'invisible',
              style(
                {
                  opacity:0,
                  "background-color":'yellow'
                }
              )
            ),
            transition(
              'visible => invisible', [
                  animate('1s')
              ]
            ),
            transition(
              'void => visible', [
                animate('.5s')
              ]
            )
        ])
      ]
})
export class RecentKeyPressViewComponent implements OnInit
{
    ngOnInit(): void {
        this.startDisplayingMostRecentKeys();
    }

    animationStart: Date = new Date();

    displayKeyPresses = false;
    includeKeyPressesInDom = false;

    keyPresses: KeyboardEvent[] = [];

    keyPressesAnimationStarted(event) {
        this.animationStart = new Date();
        // console.log(`keyPressesAnimationStarted ${event.fromState} => ${event.toState}`);
        this.includeKeyPressesInDom = true;
      }
    
      keyPressesAnimationDone(event) {
        let duration = new Date().getTime() - this.animationStart.getTime();
        // console.log(`${duration} keyPressesAnimationDone ${event.fromState} => ${event.toState}`);
        this.includeKeyPressesInDom = this.displayKeyPresses;
      }

      private timerSubcription:Subscription;
      private makeVisibleTemporarily() {
        // console.log('makeVisibleTemporarily');
        if(this.timerSubcription) {
          this.timerSubcription.unsubscribe();
          this.timerSubcription = null;
        }
        this.timerSubcription = timer(5000)
          .subscribe({
            next: () => this.displayKeyPresses = false
          });
        // console.log('Setting includeKeyPressesInDom to true');
        this.includeKeyPressesInDom = true;
        this.displayKeyPresses = true;
        //this.changeDetectorRef.detectChanges();
      }

      private startDisplayingMostRecentKeys() {
        watchMostRecentKeydowns(8)
            .subscribe({
               next: (values) => {
                   this.keyPresses = values;
                   this.makeVisibleTemporarily();
               } 
            }); 
      }
}