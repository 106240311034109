import { Component } from '@angular/core';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { Observable, of, from } from 'rxjs';
import { delay, mergeAll } from 'rxjs/operators';

@Component({
    "templateUrl": "no-content-feed.component.html",
    "styleUrls": ["no-content-feed.component.scss"]
})
export class NoContentFeedComponent  extends VisualFeedBaseComponent {
    // Adding a slight delay to the display
    // to avoid this component displaying while
    // the file is initially loading
    public displayElements$ : Observable<boolean> = from(
        [
            of(false),
            of(true).pipe(delay(500)),
        ]
    ).pipe(
        mergeAll()
    );
}