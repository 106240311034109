import { LocalTimeZoneClock } from "./clock";

import { Subscription } from "rxjs";

export interface ITimerService {
	setNextNotification(nextNotificationDateTIme: Date, callback: (d:Date) => void ) : void;
	dispose() : void;
	getCurrentDateTime() : Date;
	getCurrentTime() : Date;
}


function max(d1: Date, d2: Date) {
	return d1.valueOf() > d2.valueOf() ? d1 : d2;
}


export class TimerService implements ITimerService {
	nextTimer: number | undefined;
    mNextNotificationTime: Date;

	public constructor(private readonly localTimeZoneClock : LocalTimeZoneClock) {
		localTimeZoneClock.$timeLeap.subscribe()
	}

	timeLeapSubscription?: Subscription;

	private reset() {
		if (typeof(this.nextTimer) == 'number') {
			clearTimeout(this.nextTimer);
			this.nextTimer = undefined;
		}
		if (this.timeLeapSubscription) {
			this.timeLeapSubscription.unsubscribe();
			this.timeLeapSubscription = undefined;
		}
	}


	private futureMillisecondsToDate(futureMilliseconds: number) : Date {
		const futureValue = this.getCurrentDateTime().valueOf() + futureMilliseconds;
		return new Date(futureValue);
	}

	setNextNotification(nextNotificationDateTime: Date | number, callback: (d: Date) => void): void {
		this.reset();

		const effectiveNextNotificationDateTime = typeof(nextNotificationDateTime) == "number"
			? this.futureMillisecondsToDate(nextNotificationDateTime)
			: nextNotificationDateTime;
		this.mNextNotificationTime = effectiveNextNotificationDateTime;

		var difference = effectiveNextNotificationDateTime.getTime() - this.getCurrentDateTime().getTime();
	
		if (difference < 0) {
			callback(this.getCurrentDateTime());
		} else {
			this.nextTimer = window.setTimeout(
				() => callback(max(effectiveNextNotificationDateTime, this.getCurrentDateTime())),
				difference
			);
		}
		this.timeLeapSubscription = this.localTimeZoneClock.$timeLeap.subscribe( {
			next: () => this.setNextNotification(effectiveNextNotificationDateTime, callback)
		});
	}

	dispose(): void {
		this.reset();
	}

	max(l: Date, r: Date): Date {
		return l > r ? l : r;
	}

	min(l: Date, r: Date): Date {
		return l > r ? r : l;
	}

	getCurrentDateTime():Date {
		return this.localTimeZoneClock.now;
	}

	getCurrentTime():Date {
		return this.getCurrentDateTime();
	}
}