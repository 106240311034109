import { Injectable } from '@angular/core';
import { ServerUpdater } from "./serverUpdater";

@Injectable({
    providedIn: "root"
})
export class ServerReportingTimer {
    
    constructor (private serverUpdater: ServerUpdater) {
    }

    private timerHandle: NodeJS.Timer = null;

    private async sendUpdateAndRestartTimeout(millisecondsInterval?: number) {
        millisecondsInterval = millisecondsInterval || await this.getDefaultReportingIntervalInMilliseconds();
        if(this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
        this.timerHandle = setTimeout(
            () => this.sendUpdateAndRestartTimeout(),
            millisecondsInterval
        );
        this.serverUpdater.sendUpdate();
    }

    // We don't want the players all reporting at the same time, because that could
    // potentially cause performance issues
    // This is why we randomize the time intervals in some circumstances
    private async randomTimeInterval() : Promise<number> {
        const milliseconds = await this.getDefaultReportingIntervalInMilliseconds();
        return Math.random() * milliseconds;
    }

    async startTimer() {
        await this.sendUpdateAndRestartTimeout(
            await this.randomTimeInterval()
        );        
    };

    private defaultReportingIntervalInMilliseconds = 1000 * 60 * 60;

    private async getDefaultReportingIntervalInMilliseconds() {
        return this.defaultReportingIntervalInMilliseconds;
    }

    public async manuallySendUpdate() {
        this.sendUpdateAndRestartTimeout(
            await this.randomTimeInterval()
        );
    };
}
