import { FileManager, fileManager } from "./fileManager";
import { LoggerService } from "./loggerService";
import { Md5Chunk } from "./md5Chunk";
export type LaqorrFileEntry = FileEntry | tizen.File;
export type LaqorrDirectoryEntry = DirectoryEntry | tizen.File;

// Contains utility methods that are exclusive to the Tizen file system
class TizenFileUtils {
    static async moveFile(fileEntry: tizen.File, destinationFolder: tizen.File, destinationName: string): Promise<tizen.File> {
        await new Promise<void>(
            (resolve, reject) => {
                fileEntry.parent.moveTo(
                    fileEntry.fullPath,
                    destinationFolder.fullPath + "/" + destinationName,
                    true,
                    resolve,
                    reject
                );
            }
        );
        return TizenFileUtils.getFile(
            destinationFolder,
            destinationName,
            {
                create: false,
                exclusive: false
            }
        );
    }
    static getFileEntryText(fileEntry: tizen.File) {
        return new Promise<string>(function(resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', fileEntry.toURI(), true);
            xhr.onload = function(e) {
                if (this.status == 0 || this.status == 200) {
                    resolve(this.response);
                } else {
                    reject({
                        message: "The get file request returned a status of " + this.status
                    });
                }
            };
            xhr.send();
        });
    }

    private static fileExists(entryList: tizen.File[], fileName: string) {
		for (let i = 0; i < entryList.length; ++i) {
			if (entryList[i].isFile && entryList[i].name === fileName) {
				return true;
			}
		}
		return false;
    }

    static getDirectory(
        rootFolder: tizen.File,
        subFolderName: string,
        options: tizen.Flags
    ): Promise<tizen.File | DirectoryEntry> {        
        if (!options.create) {
            return new Promise(
                (resolve, reject) => {
                    try {
                        resolve(rootFolder.resolve(subFolderName));
                    } catch (e) {
                        reject(e);
                    }
                }
            );
        } else if (options.exclusive) {
            return new Promise(function (resolve, reject) {
                try {
                    resolve(rootFolder.createDirectory(subFolderName));
                } catch (e) {
                    reject(e);
                }
            });
        } else {
            return new Promise(function (resolve, reject) {
                rootFolder.listFiles(
                    (files) => {
                        if (TizenFileUtils.directoryExists(files, subFolderName)) {
                            try {
                                resolve(rootFolder.resolve(subFolderName));
                            } catch (e) {
                                reject(e);
                            }
                        } else {
                            try {
                                resolve(rootFolder.createDirectory(subFolderName));
                            } catch (e) {
                                reject(e);
                            }
                        }
                    },
                    reject
                )
            });
        }
    }


    private static directoryExists(entryList: tizen.File[], folderName: string) {
		for (let i = 0; i < entryList.length; ++i) {
			if (entryList[i].isDirectory && entryList[i].name === folderName) {
				return true;
			}
		}
		return false;
    }

    public static deleteFileOrDirectory(fileOrDirectory: tizen.File) : Promise<void> {
        return new Promise(function (resolve, reject) {
            if (fileOrDirectory.isDirectory) {
                fileOrDirectory.parent.deleteDirectory(fileOrDirectory.fullPath, true, resolve, reject);
            }
            else {
                fileOrDirectory.parent.deleteFile(fileOrDirectory.fullPath, resolve, reject);
            }
        });
    }

    static getFile(rootFolder: tizen.File, fileName: string, options: Flags) : Promise<tizen.File> {
        if (!options.create) {
            return new Promise<tizen.File>(
                (resolve, reject) => {
                    try {
                        resolve(rootFolder.resolve(fileName));
                    } catch (e) {
                        reject(e);
                    }
                }
            );
        } else if (options.exclusive) {
            return new Promise(
                (resolve, reject) => {
                    try {
                        resolve(rootFolder.createFile(fileName));
                    } catch (e) {
                        reject(e);
                    }
                }
            );
        } else {
            return new Promise(
                (resolve, reject) => {
                    rootFolder.listFiles(
                        (files) => {
                            if (TizenFileUtils.fileExists(files, fileName)) {
                                try {
                                    resolve(rootFolder.resolve(fileName));
                                } catch (e) {
                                    reject(e);
                                }
                            } else {
                                try {
                                    resolve(rootFolder.createFile(fileName));
                                } catch (e) {
                                    reject(e);
                                }
                            }
                        },
                        reject
                    )
                }
            );
        }
    }

}

// Contains utility methods only for the Chrome Browser file system
class ChromeBrowserFileUtils {
    static moveFile(fileEntry: FileEntry, destinationFolder: DirectoryEntry, destinationName: string): Promise<FileEntry> {
        return new Promise<FileEntry>(
            (resolve, reject) => {
                console.log(`FileDownloadClient.completeDownload moving file to ${destinationFolder.fullPath}/${destinationName}`);
                fileEntry.moveTo(
                    destinationFolder,
                    destinationName,
                    (newEntry) => resolve(<FileEntry>newEntry),
                    reject
                );
            }
        );
    }
    static getFileEntryText(fileEntry: FileEntry) {
        return new Promise<string>(
            (resolve, reject) => {
                fileEntry.file(
                    async (file) => {
                        const value = await file.text();
                        resolve(value);
                    },
                    reject
                );
            }
        );
    }

    static getDirectory(rootFolder: DirectoryEntry, subFolderName: string, options: Flags) : Promise<DirectoryEntry> {
        return new Promise<DirectoryEntry>(
            (resolve, reject) => {
                rootFolder.getDirectory(
                    subFolderName,
                    options,
                    entry => resolve(entry),
                    reject
                );
            }
        );
    }

    static getFile(rootFolder: DirectoryEntry, fileName: string, options: Flags) {
        return new Promise<FileEntry>(
            (resolve, reject) => {
                rootFolder.getFile(
                    fileName,
                    options,
                    (success: FileEntry) => resolve(success),
                    (error) => reject(error)
                );
            }
        );
    }

    static getFileSize(fileEntry: FileEntry) : Promise<number> {
        return new Promise<number>(
            (resolve, reject) => {
                fileEntry.file(
                    file => {
                        resolve(file.size);
                    },
                    reject
                )
            }
        );
    }

    static deleteFileOrDirectory(fileOrDirectory: Entry) : Promise<void> {  
        if(fileOrDirectory.isDirectory) {
            return new Promise<void>(
                (resolve, reject) => {
                    (<DirectoryEntry>(fileOrDirectory)).removeRecursively(
                        resolve,
                        reject
                    )
                }
            );
        } else {
            return new Promise<void>(
                (resolve, reject) => {
                    fileOrDirectory.remove(
                        resolve,
                        reject
                    )
                }
            );
        }
    }
}

export class FileUtils {
    public static async getTizenDirectory(
        rootFolder: LaqorrDirectoryEntry,
        subFolderName: string,
        options: tizen.Flags
    ): Promise<LaqorrDirectoryEntry> {
        return FileManager.isTizenFile(rootFolder) 
            ? TizenFileUtils.getDirectory(rootFolder, subFolderName, options)
            : ChromeBrowserFileUtils.getDirectory(rootFolder, subFolderName, options);
    }

        // This function receives a promise for a folder entry
    // a turns this into a promise to get a subfolder entry
    public static async chainSubFolder(
        getFolderPromise: Promise<any>,
        subFolderName: string,
        options?: tizen.Flags
    ): Promise<tizen.File | DirectoryEntry> {
        options = options || { create: true, exclusive: false };
        const de = await getFolderPromise;
        if ((!subFolderName) || (subFolderName === '')) {
            return de;
        }
        else {
            return await FileUtils.getTizenDirectory(de, subFolderName, options);
        }
    }

    // This should probably go into FileManager
    public static getFeedFilePath(fileName:string, sourceFolder:string):string {
        var visualFeedContentFolderName = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VisualFeedContent);
        return visualFeedContentFolderName + "/" + sourceFolder + "/" + fileName;
    }

    public static getLaqorrFileEntryUrl(entry: LaqorrFileEntry) {
        return FileManager.isTizenFile(entry) ? entry.toURI() : entry.toURL()
    }

    // Gets a file from a feed folder
    public static async getFileUrl(fileName: string, sourceFolder:string) : Promise<string> {
        const root = await fileManager.fileSystemReady;
        try {
            if(FileManager.isTizenFile(root)) {
                return root.resolve(
                    FileUtils.getFeedFilePath(fileName, sourceFolder)
                ).toURI();
            } else {
                const fileEntry = await ChromeBrowserFileUtils.getFile(root, this.getFeedFilePath(fileName, sourceFolder), { create: false });
                return fileEntry.toURL();
            }
        } catch (e) {
            throw e;
        }
    }

    public static async getTizenFile(
        rootFolder: LaqorrDirectoryEntry,
        fileName: string,
        options: tizen.Flags
    ): Promise<tizen.File | FileEntry> {
        if(!FileManager.isTizenFile(rootFolder)) {
            return ChromeBrowserFileUtils.getFile(
                rootFolder,
                fileName,
                options
            );
        } else {
            return TizenFileUtils.getFile(
                rootFolder,
                fileName,
                options
            );
        }
    }


    public static normalizePath(path: string) : string {
        if(path.startsWith("wgt-private/")) {
            path = path.replace("wgt-private/", "");
        }
        while(path.startsWith("/")) {
            path = path.slice(1);
        }
        return path;
    }

    public static pathsMatch(p1: string | { fullPath: string}, p2: string | { fullPath: string }) : boolean {
        const s1 = FileUtils.normalizePath(
            typeof(p1) === 'string' 
            ? p1
            : p1.fullPath
        );
        const s2 = FileUtils.normalizePath(
            typeof(p2) === 'string'
            ? p2
            : p2.fullPath
        );
        return s1 === s2;
    }

        // Receives a full path, and returns a handle to the file
    // Will create all of the sub folders if necessary
    public static async getFile(filePath: string, options: tizen.Flags): Promise<LaqorrFileEntry> {
        filePath = FileUtils.normalizePath(filePath);
        if (!options.create) {
            const root = await fileManager.fileSystemReady;
            if(FileManager.isTizenFile(root))
            {
                return root.resolve(filePath);
            }
            else {
                return new Promise<FileEntry>(
                    (resolve, reject)  => {
                        root.getFile(
                            filePath,
                            {
                                create: options.create,
                                exclusive: options.exclusive
                            },
                            (entry: FileEntry) => {
                                resolve(entry);
                            },
                            (err: DOMError) => {
                                reject(err);
                            }
                        )
                    }
                );
            }
        } else {
            var separatedByFolderPath = filePath.split(/[\/\\]+/);
            var folderReady = fileManager.fileSystemReady;
            for (var i = 0; i < separatedByFolderPath.length - 1; ++i) {
                folderReady = FileUtils.chainSubFolder(folderReady, separatedByFolderPath[i]);
            }
            var fileName = separatedByFolderPath[separatedByFolderPath.length - 1];
            const directory = await folderReady;
            if(FileManager.isTizenFile(directory)) {
                return await FileUtils.getTizenFile(directory, fileName, options);
            } else {
                return new Promise<FileEntry>(
                    (resolve, reject)  => {
                        directory.getFile(
                            filePath,
                            {
                                create: options.create,
                                exclusive: options.exclusive
                            },
                            (entry: FileEntry) => {
                                resolve(entry);
                            },
                            (err: DOMError) => {
                                reject(err);
                            }
                        )
                    }
                );
            }
        }
    }

    public static getDirectory(
        rootFolder: tizen.File | DirectoryEntry,
        temporaryFolderName: string,
        create?: boolean
    ): Promise<tizen.File | DirectoryEntry> {
        const createFolder = create || false;
        return FileUtils.getTizenDirectory(
            rootFolder,
            temporaryFolderName,
            { 
                create: createFolder,
                exclusive: false 
            }
        );
    }

    public static compareMd5s(md51: string, md52: string): boolean {
        return md51.replace(/-/g, "").toUpperCase() === md52.replace(/-/g, "").toUpperCase();       
   }

   public static getFileEntryText(fileEntry: tizen.File | FileEntry): Promise<string> {
    return FileManager.isTizenFile(fileEntry)
        ? TizenFileUtils.getFileEntryText(fileEntry)
        : ChromeBrowserFileUtils.getFileEntryText(fileEntry);
    };

	public static async getFeedDirectoryEntry(sourceFolder: string): Promise<tizen.File> {
        const directoryEntry = await fileManager.getMediaPlayerFolderEntry(
            fileManager.mediaPlayerFolder.VisualFeedContent
        );
        if(!FileManager.isTizenFile(directoryEntry)) {
            console.error('getFeedDirectoryEntry not supported for the non tizen system');
            throw 'getFeedDirectoryEntry not supported for the non tizen system';
        }
        else {
            return directoryEntry.resolve(sourceFolder);
        }
    }

    public static async getFileSize(file: tizen.File | FileEntry): Promise<number> {
        return FileManager.isTizenFile(file)
            ? file.fileSize
            : await ChromeBrowserFileUtils.getFileSize(file);
    }

    public static getFileSizeAndModificationTime(file: tizen.File | Entry) : Promise<{size: number, modificationTime: Date }> {
        if(FileManager.isTizenFile(file)) {
            return new Promise(function (resolve, reject) {
                resolve({size : file.fileSize, modificationTime : file.modified});
            });    
        } else {
            return new Promise<{size:number, modificationTime: Date}>(
                (resolve, reject) =>
                {
                    file.getMetadata(
                        metadata => resolve(metadata),
                        reject
                    );
                }
            )
        }
    }

    public static async getFileMd5ByChunks(
        inputFile: string | tizen.File | FileEntry,
        loggerService: LoggerService
    ): Promise<string> {
        var getFileEntryPromise: Promise<tizen.File | FileEntry>;
        var md5fileChunk = new Md5Chunk(loggerService);
        var chunkSize = 40960;
        const fileEntry = (typeof(inputFile) === "string") 
            ? await FileUtils.getFile(inputFile, { create: false, exclusive: false })
            : inputFile;

        if(FileManager.isTizenFile(fileEntry)) {
            return new Promise<string>(function (resolve, reject) {
                const fileSize = fileEntry.fileSize;
                let offset = 0;
                let bytes: ArrayLike<number>;
                fileEntry.openStream(
                    "r",
                    (fs) => {
                        try {
                            do {
                                bytes = fs.readBytes(chunkSize);
                                offset += bytes.length;
                                md5fileChunk.receiveBits(bytes);
    
                                if (offset >= fileSize) {
                                    resolve(md5fileChunk.complete());
                                    return;
                                }
                            } while (offset < fileSize);
                        } catch (e) {
                            reject(e);
                        } finally {
                            fs.close();
                        }
                    },
                    reject
                );
            });
        } else {
            return new Promise<string>(
                (resolve, reject) => {
                    fileEntry.file(
                        async (file) => {
                            const readableStream = file.stream();
                            const reader = readableStream.getReader();
                            let result = await reader.read();
                            while(!result.done) {
                                md5fileChunk.receiveBits(result.value);
                                result = await reader.read();
                            }
                            resolve(md5fileChunk.complete());
                        },
                        reject
                    )
                }
            );
        }
		
    }

    public static readDirectoryEntries(directory: tizen.File | DirectoryEntry): Promise<tizen.File[] | Entry[]> {
        if(FileManager.isTizenFile(directory)) {
            return new Promise<tizen.File[]>(
                (resolve, reject) => {
                directory.listFiles(
                    (files) => {
                        resolve(files);
                   },
                   reject
                )
            });    
        } else {
            const directoryReader = directory.createReader();
            return new Promise<Entry[]>(
                (resolve, reject) => {
                    directoryReader.readEntries(
                        (entries:Entry[]) => {
                            resolve(entries);
                        },
                        reject
                    )
                }
            );
        }
    }

    public static deleteFileOrDirectory(fileOrDirectory: tizen.File | Entry) : Promise<void> {
        return FileManager.isTizenFile(fileOrDirectory)
        ? TizenFileUtils.deleteFileOrDirectory(fileOrDirectory)
        : ChromeBrowserFileUtils.deleteFileOrDirectory(fileOrDirectory);
    }

    public static moveFile(fileEntry: tizen.File | FileEntry, destinationFolder: tizen.File | DirectoryEntry, destinationName: string) : Promise<tizen.File | FileEntry> {
        if(FileManager.isTizenFile(fileEntry)) {
            if(FileManager.isTizenFile(destinationFolder)) {
                return TizenFileUtils.moveFile(fileEntry, destinationFolder, destinationName);
            }
        } else if(!FileManager.isTizenFile(destinationFolder)) {
            return ChromeBrowserFileUtils.moveFile(fileEntry, destinationFolder, destinationName);
        } else {
            throw new Error(`moveFile invoked with an invalid combination of Tizen and ChromeBrowser file system types`);
        }
    }
}


