import { DeviceSettings } from "./deviceSettings";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface DeviceApiEndpoint {
    uri: string;
}

export interface SocketEndpoint {
    uri: string;
    protocol: string;
}

export interface LaqorrEndpoints {
    deviceApiEndpoints: DeviceApiEndpoint[];
    socketEndpoints: SocketEndpoint[];
}

const noEndpoints : LaqorrEndpoints = {
    deviceApiEndpoints: [],
    socketEndpoints: []
}

@Injectable({
    providedIn: 'root'
})
export class ServerEndpointProvider {
    private readonly subject = new BehaviorSubject<LaqorrEndpoints>(noEndpoints);
    constructor(private readonly deviceSettings : DeviceSettings, readonly httpClient: HttpClient) {
        try {
            this.fetchValues();
        } catch(error) {
            console.error(`error thrown from ServerEnpointProvider constructor. ${error}`);
        }
    }

    get serverAddress() {
        return this.deviceSettings.playerSettings.serverAddress;
    }

    async setServerAddress(address: string) {
        this.subject.next(noEndpoints);
        await this.deviceSettings.storeServerIpAddress(address);
        this.fetchValues();
    }

    private static generateEndpointUrl(laqorrServerAddress: string) {
        if(!/^https?:\/\//.test(laqorrServerAddress)) {
            laqorrServerAddress = `${ServerEndpointProvider.defaultScheme}://${laqorrServerAddress}`;
        }
        return `${laqorrServerAddress}/.well-known/laqorr-endpoints`;
    }

    static readonly defaultScheme = "https";

    private retryTimerHandle: number;

    private clearRetryTimer() {
        if(this.retryTimerHandle) {
            window.clearTimeout(this.retryTimerHandle);
            this.retryTimerHandle = undefined;
        }
    }

    private startRetryTimer() {
        window.setTimeout(
            () => {
                this.fetchValues();
            },
            60000
        );
    }

    private async fetchValues() {
        this.clearRetryTimer();
        await this.deviceSettings.getPlayerSettings();
        const endpointUrl = ServerEndpointProvider.generateEndpointUrl(this.deviceSettings.playerSettings.serverAddress);
        const observableHttpsResult: Observable<LaqorrEndpoints> = this.httpClient.get<LaqorrEndpoints>(endpointUrl);
        observableHttpsResult.subscribe({
            next: endpoints => {
                this.subject.next(endpoints);
            },
            error: (err) => {
                console.error(`Failed to retreive the endpoints from ${endpointUrl}`, err);
                this.subject.next(noEndpoints);
                this.startRetryTimer();
            }
        });
    }
 
    get endpoints() : Observable<LaqorrEndpoints> {
        return this.subject.asObservable();
    }

    get endpointsAvailable(): Observable<boolean> {
        return this.subject.pipe(map(endpoints => endpoints != noEndpoints));
    }
}