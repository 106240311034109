export function calculateFontSize(
    context: CanvasRenderingContext2D,
    maxWidth:number,
    height:number, 
    fontFamilyName: string, 
    fontStyle:string,
    text:string
): number {
    let heightMax: number = height;
    context.font = `${fontStyle}${heightMax}px ${fontFamilyName}`;
    let trialWidth = context.measureText(text).width;

    if (trialWidth > maxWidth) {
        let heightMin: number = 0.0;
        while (heightMax - heightMin > 1.0) {
            let attempt: number = (heightMin + heightMax) / 2.0;
            context.font = `${attempt}px ${fontFamilyName}`;
            let attemptWidth = context.measureText(text).width;
            if (attemptWidth > maxWidth) {
                heightMax = attempt;
            } else {
                heightMin = attempt;
            }
        }
        heightMax = heightMin;
    }
    return heightMax;
}