// This is the interface via which the

import { ClientRequestDispatcher } from '../clientRequestDispatcher';
import { EnrolmentClient } from '../enrolment/enrolmentClient';
import { ClientCryptoService } from '../clientCryptoService'
import { Observable, combineLatest } from 'rxjs';
import { filter, mergeMap, share, map } from 'rxjs/operators';
import * as LaqorrProtobuf from '../../laqorrProtobuf';
import * as TextUtils from "../../textUtils";
import { DeviceSettings } from 'src/deviceSettings';
import { Serializers } from "../serializers";
import { FiniteStateMachineStateManager } from "../../finiteStateMachineStateManager";
import { Injectable } from '@angular/core';


export enum SessionStatusIdentifier {
    disconnected,
    awaitingServerResponse,
    failed,
    connected,
    awaitingDependencies
}

export interface SessionManagerStatus {
    description: string;
    canOpen: boolean;
    identifier: SessionStatusIdentifier;
}

// ISessionState objects can interact with
interface ISessionStateEnvironment {
    // Set the timer for the inital server response
    // to a session open request
    startSessionResponseTimer() : void;
    stopSessionResponseTimer() : void;
    startRetryTimer() : number;
    stopRetryTimer() : void;
    startPingWaitTimer() : number;
    stopPingWaitTimer();

    clientCryptoService: ClientCryptoService;
    dropConnection() : void;
    lastActivityTime : Date;

    sessionRequestDispatcher: SessionRequestDispatcher;
    deviceSettings: DeviceSettings;
}

interface ISessionState {
    readonly status: SessionManagerStatus;
    connectionClosed() : ISessionState;
    connectionError() : ISessionState;
    sessionRejected() : ISessionState;
    acceptanceMessageReceived(acceptance: LaqorrProtobuf.Session.SessionAcceptance) : Promise<ISessionState>;
    sessionOpenRequestSent() : ISessionState;
    sessionResponseTimerFired() : ISessionState;
    pingReceived() : ISessionState;
    pingWaitTimerFired(pingTimerId: number) : ISessionState;
    retryTimerFired(retryTimerId: number) : Promise<ISessionState>;
    manualCloseRequested() : ISessionState;
    openSessionRequested() : Promise<ISessionState>;
    dependenciesAreAvailable() : Promise<ISessionState>;
    dependenciesAreUnavailable() : Promise<ISessionState>;
}

abstract class AbstractSessionState implements ISessionState {
    constructor(protected environment : ISessionStateEnvironment) {
    }

    abstract get status() : SessionManagerStatus;

    acceptanceMessageReceived(acceptance: LaqorrProtobuf.Session.SessionAcceptance) : Promise<ISessionState> {
        console.log('Unexpectedly received a session acceptance message');
        return Promise.resolve(this);
    }

    openingSession() : ISessionState {
        return this;
    }

    connectionClosed() : ISessionState {
        return this;
    }

    connectionError() : ISessionState {
        return this;
    }

    sessionOpenRequestSent() : ISessionState {
        return this;
    }

    sessionResponseTimerFired() : ISessionState {
        return this;
    }

    retryTimerFired(retryTimerId: number) : Promise<ISessionState> {
        return Promise.resolve(this);
    }

    manualCloseRequested() : ISessionState {
        return this;
    }

    sessionRejected() : ISessionState {
        return this;
    }

    openSessionRequested() : Promise<ISessionState> {
        return Promise.resolve(this);
    }

    pingReceived() : ISessionState {
        return this;
    }

    pingWaitTimerFired(pingTimerId: number) : ISessionState {
        return this;
    }

    static randomBytes(count: number): Uint8Array {
        const nativeArr = new Uint8Array(count);
		const crypto = window.crypto;
		crypto.getRandomValues(nativeArr);
		return [].slice.call(nativeArr);
    }

    protected async beginOpenSession() : Promise<ISessionState> {
        const challenge = AbstractSessionState.randomBytes(8);
        const challengeEncrypted:number[] = this.environment.clientCryptoService.encryptUsingServerPublicKey(challenge);

        try {
            this.environment.startSessionResponseTimer();
           await this.environment.sessionRequestDispatcher.sendInitiateSession({
                Challenge: TextUtils.toBase64String(challengeEncrypted),
                DeviceId: this.environment.deviceSettings.playerSettings.deviceId             
            });
        }
        catch(error) {
            return new SessionState_Failed(
                this.environment,
                "An error occurred when sending the intial session request",
                this.environment.startRetryTimer()
            );
        }
        return new SessionState_AwaitingServerResponse(this.environment, challenge);
    }

    dependenciesAreAvailable() : Promise<ISessionState> {
        return Promise.resolve(this);
    }

    dependenciesAreUnavailable() : Promise<ISessionState> {
        return Promise.resolve(new SessionState_AwaitingDependencies(this.environment));
    }
}

class SessionState_Disconnected extends AbstractSessionState {

    async openSessionRequested() : Promise<ISessionState> {
        return super.beginOpenSession()
    }

    get status() : SessionManagerStatus {
        return {
            description: "No active session (disconnected)",
            canOpen: true,
            identifier: SessionStatusIdentifier.disconnected
        };
    }
}

class SessionState_AwaitingServerResponse extends AbstractSessionState {

    constructor(context: ISessionStateEnvironment, private readonly challenge: Uint8Array) {
        super(context);
    }

    get status(): SessionManagerStatus {
        return {
            description: "Waiting for session acceptance from server",
            canOpen: false,
            identifier: SessionStatusIdentifier.awaitingServerResponse
        };
    }

    async acceptanceMessageReceived(acceptance: LaqorrProtobuf.Session.SessionAcceptance) : Promise<ISessionState> {
        this.environment.stopSessionResponseTimer();
        const decryptChallenge = this.environment.clientCryptoService.decryptUsingDeviceKey(
            TextUtils.fromBase64String(acceptance.ChallengeResponse)
        );
        if(!decryptChallenge.every((v, n) => v === this.challenge[n])) {
            throw "returned challenge is not the same";
        }
        const decryptedContents = this.environment.clientCryptoService.decryptUsingDeviceKey(
            TextUtils.fromBase64String(acceptance.EncryptedMessage)
        );
        const sessionAcceptedContents = await Serializers.Session.SessionAcceptanceContents.deserialize(decryptedContents);
        this.environment.clientCryptoService.sessionKey = sessionAcceptedContents.SessionKey;
        const encryptedChallengeResponse = this.environment.clientCryptoService
            .encryptUsingSessionKey(
                TextUtils.arrayBufferToArray(sessionAcceptedContents.Challenge)
            );
        this.environment.sessionRequestDispatcher.sendSessionConfirmation(encryptedChallengeResponse);
        
        return new SessionState_Connected(
            this.environment,
            this.environment.startPingWaitTimer()
        );
    }

    sessionResponseTimerFired() : ISessionState {
        return new SessionState_Failed(
            this.environment, 
            "A timeout occurred while waiting for the session response",
            this.environment.startRetryTimer()
        );
    }

    connectionClosed() : ISessionState {
        return new SessionState_Failed(
            this.environment,
            "The connection was closed while waiting for the session response",
            this.environment.startRetryTimer()
        );
    }

    connectionError() : ISessionState {
        return new SessionState_Failed(
            this.environment,
            "A connection error occurred while waiting for the server response",
            this.environment.startRetryTimer()
        );
    }

    sessionRejected() : ISessionState {
        return new SessionState_Failed(
            this.environment,
            "Was able to communicate with the server, but it rejected the session request",
            this.environment.startRetryTimer()
        );
    }

    dependenciesAreUnavailable() : Promise<ISessionState> {
        return Promise.resolve(new SessionState_AwaitingDependencies(this.environment));
    }
}

class SessionState_Failed extends AbstractSessionState {
    constructor(environment: ISessionStateEnvironment, readonly description: string, readonly retryTimerId: number) {
        super(environment);
    }

    get status(): SessionManagerStatus {
        return {
            description: this.description,
            canOpen: true,
            identifier: SessionStatusIdentifier.failed
        };
    }

    async openSessionRequested() : Promise<ISessionState> {
        return super.beginOpenSession()
    }

    retryTimerFired(retryTimerId: number) : Promise<ISessionState> {
        if(retryTimerId == this.retryTimerId) {
            this.environment.stopRetryTimer();    
            return this.beginOpenSession();        
        } else {
            return Promise.resolve(this);
        }
    }

    dependenciesAreUnavailable() : Promise<ISessionState> {
        this.environment.stopRetryTimer(); 
        return Promise.resolve(new SessionState_AwaitingDependencies(this.environment));
    }
}

class SessionState_Connected extends AbstractSessionState {

    constructor(environment: ISessionStateEnvironment, private readonly awaitingPingTimerId: number) {
        super(environment)
    }

    get status(): SessionManagerStatus {
        return {
            description: "There is an open session with the server. Can now receive push messages",
            canOpen: false,
            identifier: SessionStatusIdentifier.connected
        };
    }

    connectionClosed() : ISessionState {
        return new SessionState_Failed(
            this.environment,
            "The connection to the server was closed while there was a session",
            this.environment.startRetryTimer()
        );
    }

    manualCloseRequested() : ISessionState {
        return new SessionState_Disconnected(this.environment);
    }

    connectionError() : ISessionState {
        ;
        return new SessionState_Failed(
            this.environment,
            "Unable to connect to the server",
            this.environment.startRetryTimer()
        );
    }

    sessionRejected() : ISessionState {
        // Not sure how this could have fired!
        return new SessionState_Failed(
            this.environment,
            "The session connection request was rejected by the server",
            this.environment.startRetryTimer()
        );
    }

    dependenciesAreUnavailable() : Promise<ISessionState> {
        return Promise.resolve(new SessionState_AwaitingDependencies(this.environment));
    }

    pingWaitTimerFired(pingTimerId: number): ISessionState {
        if (this.awaitingPingTimerId == pingTimerId) {
            this.environment.dropConnection();
            return new SessionState_Failed(
                this.environment,
                "Did not receive a ping message from the server within the expected interval. Closed the session",
                this.environment.startRetryTimer()
            );
        } else {
            return this;
        }
    }

    pingReceived(): ISessionState {
        return new SessionState_Connected(this.environment, this.environment.startPingWaitTimer())
    }
}

class SessionState_AwaitingDependencies extends AbstractSessionState {
    get status(): SessionManagerStatus {
        return {
            description: "Waiting for dependencies to be available",
            canOpen: false,
            identifier: SessionStatusIdentifier.awaitingDependencies
        };
    }
    dependenciesAreAvailable() : Promise<ISessionState> {
        return super.beginOpenSession();
    }
    dependenciesAreUnavailable(): Promise<ISessionState> {
        return Promise.resolve<ISessionState>(this);
    }
}

class SessionRequestDispatcher {

    readonly responseReceived: Observable<LaqorrProtobuf.Session.SessionResponse>;

    constructor(private readonly clientRequestDispatcher: ClientRequestDispatcher) {
        this.responseReceived = this.clientRequestDispatcher.messageReceived
            .pipe(
                filter(message => message.MessageType == LaqorrProtobuf.MessageType.SessionResponse),
                mergeMap(message => Serializers.Session.SessionResponse.deserialize(message.MessageContents)),
                share()
            );
    }

    closeConnection() {
        this.clientRequestDispatcher.closeConnection();
    }

    private async send(request: LaqorrProtobuf.Session.SessionRequest) : Promise<void> {    
        return this.clientRequestDispatcher.sendMessage({
            MessageType : LaqorrProtobuf.MessageType.SessionRequest,
            MessageContents: await Serializers.Session.SessionRequest.serialize(request)           
        });
    }

    async sendInitiateSession(request: LaqorrProtobuf.Session.InitiateSession) : Promise<void> {
        return this.send({
            RequestType: LaqorrProtobuf.Session.RequestType.InitiateSession,
            Data: await Serializers.Session.InitiateSession.serialize(request)
        });
    }

    async sendSessionConfirmation(data: number[]) : Promise<void> {
        return this.send({
            RequestType: LaqorrProtobuf.Session.RequestType.ConfirmSession,
            Data: new Uint8Array(data)
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class ClientSessionManager implements ISessionStateEnvironment {

    readonly sessionRequestDispatcher: SessionRequestDispatcher;
    private readonly stateManager: FiniteStateMachineStateManager<ISessionState>;

    private transition(t : (s: ISessionState) => ISessionState | Promise<ISessionState>) {
        this.stateManager.transition(t);
    }

    get status() : Observable<SessionManagerStatus> {
        return this.stateManager.currentState.pipe(
            map(s => s.status)
        );
    }
    
    constructor(
        private readonly clientRequestDispatcher : ClientRequestDispatcher,
        private readonly enrolmentClient : EnrolmentClient,
        public readonly clientCryptoService : ClientCryptoService,
        public readonly deviceSettings : DeviceSettings
    ) {
        console.log('clientSessionManager constructor invoked');
        this.sessionRequestDispatcher = new SessionRequestDispatcher(clientRequestDispatcher);
        this.stateManager = new FiniteStateMachineStateManager<ISessionState>(new SessionState_AwaitingDependencies(this));
    }

    public openSessionWhenReady() {
        combineLatest(
            [
                this.clientRequestDispatcher.status,
                this.enrolmentClient.status
            ]
        ).pipe(
            map(([clientRequestDispatcherState, enrolmentClientState]) => {
                return clientRequestDispatcherState.isAvailable && enrolmentClientState.isEnrolled;
            })
        ).subscribe(
            (available) => this.transition(s => available ? s.dependenciesAreAvailable() : s.dependenciesAreUnavailable())
        );
        this.sessionRequestDispatcher.responseReceived.subscribe({
            next: async (response) => {
                switch(response.ResponseType) {
                    case LaqorrProtobuf.Session.ResponseType.SessionAcceptance:
                        this.handleSessionAcceptance(
                            await Serializers.Session.SessionAcceptance.deserialize(response.Data)
                        );
                        break;
                    case LaqorrProtobuf.Session.ResponseType.SessionRejection:
                        this.handleSessionRejection(
                            await Serializers.Session.SessionRejection.deserialize(response.Data)
                        );
                        break;
                    case LaqorrProtobuf.Session.ResponseType.Ping:
                        this.handlePing();
                        break;
                    default:
                        console.error(`Received an unexpected session response of type ${response.ResponseType}`);
                }
            }
        });
        this.clientRequestDispatcher.connectionClosed.subscribe(
            () => this.transition(s => s.connectionClosed())
        );
    }

    public get lastActivityTime() : Date {
        return this.clientRequestDispatcher.lastCommunicationTime;
    }

    private sessionResponseTimeout() {
        this.stopSessionResponseTimer();
        this.transition(s => s.sessionResponseTimerFired());
    }

    private retryTimerFired(id: number) {
        this.transition(s => s.retryTimerFired(id));
    }

    timerHandles : {
        retrySession? : number;
        awaitServerResponse? : number;
        ping? : number;
    } = {};
    startSessionResponseTimer() : void {
        this.stopSessionResponseTimer();
        this.timerHandles.awaitServerResponse = window.setTimeout(
            () => this.sessionResponseTimeout(),
            10000);
    }
    stopSessionResponseTimer() : void {
        if(this.timerHandles.awaitServerResponse) {
            window.clearTimeout(this.timerHandles.awaitServerResponse);
            this.timerHandles.awaitServerResponse = null;
        }
    }

    startRetryTimer() : number {
        if(this.timerHandles.retrySession) {
            window.clearTimeout(this.timerHandles.retrySession);
            this.timerHandles.retrySession= null;
        }
        const id = this.generateTimerId();
        this.timerHandles.retrySession = window.setTimeout(() => {
            this.retryTimerFired(id);
        }, 60000);
        return id;
    }

    stopRetryTimer() : void {
        if(this.timerHandles.retrySession) {
          window.clearTimeout(this.timerHandles.retrySession);
          this.timerHandles.retrySession= null;
      }      
    }

    private readonly generateTimerId : () => number = (
        () =>
        {
            let seed = 1;
            return () => ++seed;
        }
    )();

    startPingWaitTimer(): number {
        if (this.timerHandles.ping) {
            window.clearTimeout(this.timerHandles.ping);
        }
        const id = this.generateTimerId();
        this.timerHandles.ping = window.setTimeout(
            () => {
                this.timerHandles.ping = null;
                this.transition(s => s.pingWaitTimerFired(id));
            },
            2 * 60 * 1000 // The server sends a ping message every 60 seconds. If we haven't gotten one in 120 seconds assume the connection has been lost
        );
        return id;
    }

    stopPingWaitTimer() {
        if(this.timerHandles.ping) {
            window.clearTimeout(this.timerHandles.ping);
            this.timerHandles.ping = null;
        }
    }

    private async handleSessionAcceptance(sessionAcceptance: LaqorrProtobuf.Session.SessionAcceptance) {
        this.transition(async s => await s.acceptanceMessageReceived(sessionAcceptance));
    }

    private handleSessionRejection(sessionRejection: LaqorrProtobuf.Session.SessionRejection) {
        console.log('Handle SessionRejection invoked. Must handle it');      
    }

    handlePing() {
        this.transition(async s => s.pingReceived());
    }

    public dropConnection(): void {
        this.clientCryptoService.sessionKey = null;
        this.clientRequestDispatcher.closeConnection();
    }

    // This method is invoked from the "Open Session" button 
    // when viewing all of the controls
    //
    public async openSession() {
        this.transition(async s => await s.openSessionRequested());
    }
}