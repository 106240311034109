import { concat, Observable, merge, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';

export interface FileAvailabilityItem {
	File: FileEntry | tizen.File;
    IsReady: boolean;
    Index: number
}

function joinPaths(p1: string, p2:string) {
    if(p1[0] != '/') {
        p1 = `/${p1}`;
    }
    if(p1[p1.length-1] != '/') {
        p1 = `${p1}/`;
    }
    return `${p1}${p2}`;
}

export function watchFileSetForAvailabilityChanges(
    filesToWatch: string[],
    mediaPlayerFileSystem: MediaPlayerFileSystem,
    folder: string
) : Observable<FileAvailabilityItem[]> {
    const pathsToWatch = filesToWatch.map(file => joinPaths(folder, file));
    const mediaFilesArrived = pathsToWatch.map(
        (file, index) =>
            mediaPlayerFileSystem.watchMediaFileArrived(
                file
            ).pipe(
                map(
                    fileEntry => <FileAvailabilityItem>{
                        File: fileEntry,
                        Index: index,
                        IsReady: true
                    }
                )
            )
    );
    const mediaFilesDeleted = pathsToWatch.map(
        (file, index) =>
            mediaPlayerFileSystem.watchMediaFileDeleted(
                file
            ).pipe(
                map(
                    fileEntry => <FileAvailabilityItem>{
                        File: fileEntry,
                        Index: index,
                        IsReady: false
                    }
                )
            )
    );
    const fileAvailabilityItems = filesToWatch
        .map(
            (file, index) => {
                return {
                    File: null,
                    Index: index,
                    IsReady: false
                };
            }
        );
    return concat(
        of<FileAvailabilityItem[]>([]),
        merge(...mediaFilesArrived, ...mediaFilesDeleted)
            .pipe(
                tap((item:FileAvailabilityItem) => fileAvailabilityItems[item.Index] = item),
                map(_ => fileAvailabilityItems.slice())
            )
    )
}


