import { Injectable } from '@angular/core';
import { DropConnection } from './dropConnection';
import { Logger } from './logger';

@Injectable({
    providedIn: "root"
})
export class DeviceReboot {
    constructor(
        private readonly dropConnection: DropConnection,
        private logger: Logger
    ) {
    }

    public async run() : Promise<void> {
        if (!b2bapis) {
            this.logger.error('DeviceReboot', `Unable to restart the device: typeof (b2bapis) === ${typeof(b2bapis)}`);
            throw new Error('Unable to restart the device as there is no available API to call');
            return;
        }
        const b2bcontrol = b2bapis.b2bcontrol;
        if (!b2bcontrol) {
            this.logger.error('DeviceReboot', `Unable to restart the device: typeof (b2bapis.b2bcontrol) === ${typeof(b2bapis)}`);
            return;
        }
        await this.dropConnection.run();
        b2bapis.b2bcontrol.rebootDevice(
            (val) => {
                this.logger.debug('DeviceReboot', `rebootDevice called the success function ${val}`);
            },
            (error) => {
                this.logger.error(
                    'DeviceReboot',
                    "[rebootDevice] code :" + error.code + " error name: " + error.name + "  message " + error.message
                );
            }
        );
    }
}