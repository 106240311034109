import { Injectable } from '@angular/core';
import { ServerApi, PlayerVirtualPath } from './serverApi';
import { fileManager } from "./fileManager";

interface VirtualFolderDefinition {
    mediaPlayerFolder?: number;
    fullPath?: string;
}

@Injectable({
    providedIn: "root"
})
export class MediaPlayerFileInfoManager {
    virtualFolderDefinitions: VirtualFolderDefinition[];
    constructor(private serverApi: ServerApi) {
        this.virtualFolderDefinitions = this.getVirtualFolderDefinitions();
    }

    private getFileInfos(paths: string[]): PlayerVirtualPath[] {
        const fileInfos: PlayerVirtualPath[] = [];
        for (let path of paths) {
            fileInfos.push(this.getFileInfo(path));
        }
        return fileInfos;
    }

    notifyDeletedFilesToServer(paths:string[]) {
        const fileInfos = this.getFileInfos(paths);
        this.serverApi.fileHasBeenDeleted(fileInfos).subscribe();
    }

    private getFileInfo(path: string): PlayerVirtualPath {
        for (let virtualFolderDefinition of this.virtualFolderDefinitions) {
            if (path.startsWith(virtualFolderDefinition.fullPath)) {
                return {
                    MediaFolder: virtualFolderDefinition.mediaPlayerFolder,
                    RelativePath: path.replace(virtualFolderDefinition.fullPath, "")
                };
            }
        }
        return null;
    }

    private getVirtualFolderDefinitions(): VirtualFolderDefinition[] {
        const virtualFolderDefinitions: VirtualFolderDefinition[] = [];
        for (const key in fileManager.mediaPlayerFolder) {
            const virtualFolderDefinition: VirtualFolderDefinition = {};
            virtualFolderDefinition.mediaPlayerFolder = fileManager.mediaPlayerFolder[key];
            virtualFolderDefinition.fullPath = "wgt-private/" + key + "/";
            virtualFolderDefinitions.push(virtualFolderDefinition);
        }
        return virtualFolderDefinitions;
    }
}