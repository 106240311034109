import { StringBuilder } from "./rsaEncryption/TextEncoding";
import * as TextUtils from "../textUtils";
import * as LaqorrProtobuf from '../laqorrProtobuf';
import { DeviceSettings } from "../deviceSettings";

function ConverRsaParametersToXml(parameters: RSAParameters, includePrivateParameters: boolean) {
    var builder = new StringBuilder("");
    builder.Append("<RSAKeyValue>");
    builder.Append("<Modulus>" + TextUtils.toBase64String(parameters.Modulus) + "</Modulus>");
    builder.Append("<Exponent>" + TextUtils.toBase64String(parameters.Exponent) + "</Exponent>");
    if (includePrivateParameters) {
        builder.Append("<P>" + TextUtils.toBase64String(parameters.P) + "</P>");
        builder.Append("<Q>" + TextUtils.toBase64String(parameters.Q) + "</Q>");
        builder.Append("<DP>" + TextUtils.toBase64String(parameters.DP) + "</DP>");
        builder.Append("<DQ>" + TextUtils.toBase64String(parameters.DQ) + "</DQ>");
        builder.Append("<InverseQ>" + TextUtils.toBase64String(parameters.InverseQ) + "</InverseQ>");
        builder.Append("<D>" + TextUtils.toBase64String(parameters.D) + "</D>");
    }
    builder.Append("</RSAKeyValue>");
    return builder.ToString("");
}

function ConvertPublicKeyToXml(publicKey : LaqorrProtobuf.PublicKey) {
    var builder = new StringBuilder("");
    builder.Append("<RSAKeyValue>");
    builder.Append("<Modulus>" + publicKey.Modules + "</Modulus>");
    builder.Append("<Exponent>" + publicKey.Exponent + "</Exponent>");
    builder.Append("</RSAKeyValue>");
    return builder.ToString("");
};

function getXmlValue(xmlString, tagString) {
    var tag = new RegExp("<" + tagString + ">(.*?)</" + tagString + ">", "gi");
    var tagMatch = xmlString.match(tag);
    if (!tagMatch) return null;
    var base64 = tagMatch[0].replace(tag, "$1");
    var bytes = TextUtils.fromBase64String(base64);
    return bytes;
}


function ExtractParametersFromXmlString(xmlString : string) {
    const parameters = new RSAParameters();
    var tagSpace = new RegExp("\\s", "gi");
    xmlString = xmlString.replace(tagSpace, "");
    parameters.Exponent = getXmlValue(xmlString, "Exponent");
    parameters.Modulus = getXmlValue(xmlString, "Modulus");
    parameters.D = getXmlValue(xmlString, "D");
    parameters.DP = getXmlValue(xmlString, "DP");
    parameters.DQ = getXmlValue(xmlString, "DQ");
    parameters.InverseQ = getXmlValue(xmlString, "InverseQ");
    parameters.P = getXmlValue(xmlString, "P");
    parameters.Q = getXmlValue(xmlString, "Q");
    return parameters;
};

export class KeyStore {
    constructor(private readonly deviceSettings: DeviceSettings) {
    }

    private _deviceKey: RSAParameters;
    get deviceKey() : RSAParameters {
        if(!(this._deviceKey)) {
            const deviceKeyXmlString = this.deviceSettings.playerSettings.deviceKey;
            if(deviceKeyXmlString) {
                this._deviceKey = ExtractParametersFromXmlString(deviceKeyXmlString);
            }            
        }
        return this._deviceKey;
    }

    async setDeviceKey(rsaParameters: RSAParameters) {
        this._deviceKey = rsaParameters;
        await this.deviceSettings.storePlayerPrivateKey(ConverRsaParametersToXml(rsaParameters, true));
    }

    private _serverPublicKey: RSAParameters;

    get serverPublicKey() : RSAParameters {
        if(!(this._serverPublicKey)) {
            const xmlString = this.deviceSettings.playerSettings.serverPublicKey;
            if(xmlString) {
                this._serverPublicKey = ExtractParametersFromXmlString(xmlString);
            }
        }
        return this._serverPublicKey;
    }

    async setServerPublicKey(publicKey : LaqorrProtobuf.PublicKey) {
        await this.deviceSettings.storeServerPublicKey(ConvertPublicKeyToXml(publicKey));
        this._serverPublicKey = null;
    }
}