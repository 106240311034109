import { Component } from  '@angular/core';
import { NavigatableComponent, Side } from 'src/navigatableComponent';

@Component({
    templateUrl: './unsupported-browser.component.html',
    styleUrls: ['./unsupported-browser.component.scss'],
    selector: 'unsupported-browser'
}) 
export class UnsupportedBrowserComponent extends NavigatableComponent {
    focus(from: Side) {
        console.log('UnsupportedBrowserComponent.focus invoked');
    }
}