import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { Injectable, Provider, APP_INITIALIZER } from "@angular/core";
import { listenForEasterEgg } from "./inputListener";

@Injectable({
    providedIn: 'root'
})
export class DeveloperModeSetting {
    public value: boolean = false;
}


export const initializer: Provider = {
    provide: APP_INITIALIZER,
    deps: [DeveloperModeSetting],
    useFactory: (developerModeSetting: DeveloperModeSetting) => () => {
        console.log('developerModeSettingFactory invoked');
        listenForEasterEgg("1", "3", "9", "7")
            .subscribe({
                next: () => developerModeSetting.value = !developerModeSetting.value
            });
    },
    multi: true
}


