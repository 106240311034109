import { Injectable } from '@angular/core';
import { ClientRequestDispatcher } from './eneter/clientRequestDispatcher';
import { Logger } from './logger';

@Injectable({
    providedIn: "root"
})
export class DropConnection {
    constructor(
        private readonly clientRequestDispatcher: ClientRequestDispatcher,
        private logger: Logger
    ) {
    }

    async run() {
        await new Promise<void>(
            resolve => {
                let subscriptionIsInitialized = false;
                const subscription = this.clientRequestDispatcher.status.subscribe({
                    next: (status) => {
                        if(status.canClose) {
                            try {
                                this.clientRequestDispatcher.closeConnection();
                            } catch(e) {}
                        } else if (status.isClosed) {
                            if(subscriptionIsInitialized)
                            {
                                subscription.unsubscribe();
                            }
                            else
                            {
                                window.setTimeout(
                                    () => {
                                        subscription.unsubscribe();
                                    }, 
                                    0
                                );
                            }
                            resolve();
                        }
                    }
                });
                subscriptionIsInitialized = true;

            }
        );
    }
}