import { Component } from '@angular/core';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { fileManager } from 'src/fileManager';
import { Rectangle, rotateRectangle } from '../../../geometry';

function generateRotateTransformDirective(degrees: number) {
    const radians = (degrees%360) * Math.PI / 180.0;
    return `rotate(${radians}rad)`;
}

@Component({
    "templateUrl": "visual-feed-base.component.html",
    "selector": "visual-feed-base"
})
export class VisualFeedBaseComponent {
    private _rectangle: Rectangle = {
        left: 0,
        top: 0,
        height: 0,
        width: 0
    };
    public get rectangle() : Rectangle {
        return this._rectangle;
    }

    public set rectangle(value: Rectangle) {
        this._rectangle = value; 
    }

    public feedId: string;
    public typeId: string;
    public sourceFolder: string;
    public backgroundColor: string;
    public rotation: number;

    constructor(public mediaPlayerFileSystem: MediaPlayerFileSystem) {
    }

    protected getFeedFilePath(fileName: string) {
        const visualFeedContentFolderName = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VisualFeedContent);
        return `/${visualFeedContentFolderName}/${this.sourceFolder}/${fileName}`;
    }

    protected generateSourceRectanglePriorToRotation() {
        const targetRectangle: Rectangle = {
            left:0,
            top:0,
            width:this.rectangle.width,
            height: this.rectangle.height
        };
        return rotateRectangle(targetRectangle, 0-this.rotation);
    }

    private generateDefaultRotationAndSizingStyles() {

        const sourceRectangle = this.generateSourceRectanglePriorToRotation();
        return {
            position: 'absolute',
            left: `${sourceRectangle.left}px`,
            height: `${sourceRectangle.height}px`,
            width: `${sourceRectangle.width}px`,
            top: `${sourceRectangle.top}px`,
            transform: generateRotateTransformDirective(this.rotation)
        };
    }

    private _positionAndRotation: {[key:string]:string} = null;
    public get positionAndRotation() {
        if(!this._positionAndRotation) {
            this._positionAndRotation = this.generateDefaultRotationAndSizingStyles();
        }
        return this._positionAndRotation;
    }
}