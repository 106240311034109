
export class HtmlUtils {
    public static getTime(input: string) : Date {
        if(!input){
            return null;
        }
    
        const times = input.split(":");

        if(times.length !== 2){
            return null;
        }
    
        const hours = parseFloat(times[0]);
        const minutes = parseFloat(times[1]);

        if(isNaN(hours) || isNaN(minutes) ){
            return  null;
        }
    
        if(hours%1 !== 0 || minutes%1 !== 0){
            return null;
        }
    
        if(hours > 23 || minutes > 59){
            return null;
        }
    
        const now = new Date();
        now.setHours(hours, minutes, 0, 0);
        return now;
    }

    public static dateToJsonString(d: Date) {
        if(!d) {
            return '';
        }
        return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`;
    }

    public static generateUniqueId = (
        () => {
            let seed: number = 0;
            return () => "element_" + (++seed);    
        }
    )();

    public static divSizeChanged(element: HTMLElement, callback: any) {
        if (element.style.position !== "relative") {
            element.style.position = "relative";
        }

        const div = document.createElement("div");
        div.className ="resize";
        div.style.cssText = "position:absolute; width:auto; height:auto; top:0; right:0; bottom:0; left:0; margin:0; padding:0; overflow:hidden; visibility:hidden; z-index:-1";
        element.appendChild(div);

        const iframe1 = document.createElement("iframe");
        iframe1.style.cssText = "width:100%; height:0; border:0; visibility:visible; margin:0";
        div.appendChild(iframe1);

        const iframe2 = document.createElement("iframe");
        iframe2.style.cssText = "width:0; height:100%; border:0; visibility:visible; margin:0";
        div.appendChild(iframe2);

        element.dataset.mresizeW = `${element.offsetWidth}`;
        element.dataset.mresizeH = `${element.offsetHeight}`;
        element.dataset.mresizeThrottle = "100";
        const iframes: HTMLIFrameElement[] = Array.prototype.slice.call(element.querySelectorAll(".resize iframe"));
        let mresizeT: NodeJS.Timer = null;
        iframes.forEach((iframe, index) => {
            (iframe.contentWindow || iframe).addEventListener("resize", () => {
                const d = element.dataset;
                if (d.mresizeW !== `${element.offsetWidth}` || d.mresizeH !== `${element.offsetHeight}`) {
                    if (mresizeT) clearTimeout(mresizeT);
                    mresizeT = setTimeout(() => {
                        callback();
                        d.mresizeW = `${element.offsetWidth}`;
                        d.mresizeH = `${element.offsetHeight}`;
                    }, parseInt(d.throttle));
                }
            }) 
        });
    }
}
