import { ClientRequestDispatcher } from '../clientRequestDispatcher';
import { ClientCryptoService } from "../clientCryptoService";
import { Serializers } from "../serializers";
import * as LaqorrProtobuf from "../../laqorrProtobuf";
import { Subscription, Observable, Subject } from 'rxjs';
import { filter, mergeMap, share, map, tap } from 'rxjs/operators';
import { fromBase64String } from "../../textUtils";
import { DeviceSettings } from "../../deviceSettings";
import { DropConnection } from "../../dropConnection";
import { FiniteStateMachineStateManager } from "../../finiteStateMachineStateManager";
import { Injectable } from '@angular/core';
import { PlayHistoryDatabase } from 'src/playHistoryDatabase';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { fileManager } from "src/fileManager";
import { FileUtils } from 'src/fileUtils';
import { SCREEN_LAYOUT_FILENAME } from 'src/angular/components/screenlayout-content-view/screenlayoutContentProvider';
import { LoggerService } from 'src/loggerService';

const INITIAL_REQUEST_TIMEOUT = 10000;


async function cleanOldUserData(playHistoryDatabase: PlayHistoryDatabase, mediaPlayerFileSystem: MediaPlayerFileSystem) {
    try {
        try {
            const videoFolderEntry = await fileManager.getMediaPlayerFolderEntry(fileManager.mediaPlayerFolder.Video);
            await FileUtils.deleteFileOrDirectory(videoFolderEntry);
        } catch (e) {
        }
        try {
            const visualFeedFolderEntry = await fileManager.getMediaPlayerFolderEntry(fileManager.mediaPlayerFolder.VisualFeedContent);
            await FileUtils.deleteFileOrDirectory(visualFeedFolderEntry);
        } catch (e) {
        }
        try {
            const screenLayoutFullPath = fileManager.getMediaPlayerFolderName(fileManager.mediaPlayerFolder.VideoPlaylist) + "/" + SCREEN_LAYOUT_FILENAME;
            const fileEntry = await FileUtils.getFile(screenLayoutFullPath, { create: false, exclusive: false });
            await FileUtils.deleteFileOrDirectory(fileEntry);
            mediaPlayerFileSystem.onFileDeleted.next(fileEntry);
        } catch (e) {
        }
        playHistoryDatabase.emptyCurrentPlaylistTable();
        playHistoryDatabase.emptyMediaExecutionTable();
        playHistoryDatabase.emptyMediaFileTable();
    } catch (error) {
        console.error(error);
    }
}


interface EnrolmentRequestSender {
    sendInitialRequest(initialRequest: LaqorrProtobuf.Enrolment.InitialRequest) : Promise<void>;
    sendConfirmationReceipt(confirmationReceipt: LaqorrProtobuf.Enrolment.ConfirmationReceipt) : Promise<void>;
}

class EnrolmentRequestDispatcher implements EnrolmentRequestSender {
    constructor(
        private readonly clientRequestDispatcher: ClientRequestDispatcher,
        private readonly loggerService: LoggerService
    ) {
        this.responseReceived = this.clientRequestDispatcher.messageReceived
            .pipe(
                filter(message => message.MessageType == LaqorrProtobuf.MessageType.EnrollmentResponse),
                mergeMap(message => Serializers.Enrolment.ResponseContents.deserialize(message.MessageContents)),
                share()
            );
    }

    readonly responseReceived: Observable<LaqorrProtobuf.Enrolment.ResponseContents>;

    get connectionClosed() {
        return this.clientRequestDispatcher.connectionClosed;
    }

    get connectionError() {
        return this.clientRequestDispatcher.connectionError;
    }
    
    private async send(request: LaqorrProtobuf.Enrolment.RequestContents) : Promise<void> { 
        try {
            return this.clientRequestDispatcher.sendMessage({
                MessageType : LaqorrProtobuf.MessageType.EnrollmentRequest,
                MessageContents: await Serializers.Enrolment.RequestContents.serialize(request)           
            });
        } catch(error) {
            this.loggerService.logError('Error thrown from EnrolmentRequestDispatcher.send');
            this.loggerService.logError(error);
            throw error;
        }

    }

    async sendInitialRequest(initialRequest: LaqorrProtobuf.Enrolment.InitialRequest) : Promise<void> {
         try {
            return this.send({
                RequestType: LaqorrProtobuf.Enrolment.RequestType.InitialRequest,
                Data: await Serializers.Enrolment.InitialRequest.serialize(initialRequest)
            });
        } catch(error) {
            this.loggerService.logError('EnrolmentRequestDispatcher.sendInitialRequest error');
            this.loggerService.logError(error);
        }

    }

    async sendConfirmationReceipt(confirmationReceipt: LaqorrProtobuf.Enrolment.ConfirmationReceipt) : Promise<void> {
        return this.send({
            RequestType : LaqorrProtobuf.Enrolment.RequestType.ConfirmationReceipt,
            Data : await Serializers.Enrolment.ConfirmationReceipt.serialize(confirmationReceipt)
        });
    }
}

interface DeviceEnrolledEventArgs {
    deviceId: number;
}

interface EnrolmentClientStateContext {
    requestSender : EnrolmentRequestSender,
    cryptoService : ClientCryptoService,
    deviceEnrolled : Subject<DeviceEnrolledEventArgs>,
    deviceSettings: DeviceSettings,
    playHistoryDatabase: PlayHistoryDatabase,
    mediaPlayerFileSystem: MediaPlayerFileSystem,
    forceDropConnection() : Promise<void>;
    setTimeout(milliseconds:number) : number;
    clearTimeout(timeoutHandle: number) : void;
}


export interface EnrolmentClientStatus {
    description : string,
    uniqueCode? : string,
    canEnrol : boolean,
    canUnenrol : boolean,
    isEnrolled : boolean,
    isError: boolean
};

abstract class EnrolmentClientState {
    constructor(
        protected readonly context : EnrolmentClientStateContext,
    ) {
    }

    abstract get status() : EnrolmentClientStatus;

    confirmationReceived(message: LaqorrProtobuf.Enrolment.EnrolmentConfirmation): Promise<EnrolmentClientState> {
        console.log('Received an unexpected message of type LaqorrProtobuf.Enrolment.Confirmation');
        return Promise.resolve(this);
    }
    initialResponseReceived(message: LaqorrProtobuf.Enrolment.InitialResponse) : Promise<EnrolmentClientState> {
        console.log('Received an unexpected message of type LaqorrProtobuf.Enrolment.InitialResponse');
        return Promise.resolve(this);
    }
    beginEnrolment() : Promise<EnrolmentClientState> {
        throw "Begin enrolment invoked, but the enrolment client is already in the middle of the enrolment process";
    }
    unenrol() : Promise<EnrolmentClientState> {
        throw "Not able to unenrol in the current state";
    }

    connectionClosed() : EnrolmentClientState {
        return this;
    }

    connectionError() : EnrolmentClientState {
        return this;
    }

    onTimeout(timeoutHandle: number) : EnrolmentClientState {
        return this;
    }
}

class EnrolmentClientUninitializedState extends EnrolmentClientState {
    get status() : EnrolmentClientStatus {
        return {
            canEnrol : false,
            canUnenrol : false,
            description : `The enrolment client is not yet initialized`,
            isEnrolled: undefined,
            isError: false
        };
    }
}

class EnrolmentClientEnrolledState extends EnrolmentClientState {
    beginEnrolment() : Promise<EnrolmentClientState> {
        throw "Begin enrolment invoked, but already enrolled";
    }

    async unenrol() : Promise<EnrolmentClientState> {
        try {
            await this.context.deviceSettings.storePlayerIdEnrol(NaN);
        } catch(error) {

        }
        try {
            await this.context.forceDropConnection();
        }
        catch(error) {
        }

        try {
            await cleanOldUserData(this.context.playHistoryDatabase, this.context.mediaPlayerFileSystem);
        } catch(error) {
        }

        return new EnrolmentClientUnenrolledState(this.context);
    }

    get status() : EnrolmentClientStatus {
        return {
            canEnrol : false,
            canUnenrol : true,
            description : `Player is Enrolled with public id ${this.context.deviceSettings.playerSettings.deviceId}`,
            isEnrolled: true,
            isError: false
        }
    }
}


class EnrolmentClientAwaitingConfirmation extends EnrolmentClientState {
    constructor(private readonly uniqueCode: string, context: EnrolmentClientStateContext) {
        super(context);
    }

    async confirmationReceived(message: LaqorrProtobuf.Enrolment.EnrolmentConfirmation) : Promise<EnrolmentClientState> {
        console.log(`Enrolment confirmation received. DeviceId = ${message.DeviceId}`);

        const decrypted = this.context.cryptoService.decryptUsingDeviceKey(
            fromBase64String(message.EncryptedChallenge)
        );
 
        const reEncrypted = this.context.cryptoService.encryptUsingServerPublicKey(decrypted);
        const reEncryptedAsUint8Array = new Uint8Array(reEncrypted);

        await this.context.requestSender.sendConfirmationReceipt({
            ChallengeResponse: reEncryptedAsUint8Array
        });

        // Must store the device id and network configuration
        await this.context.deviceSettings.storePlayerIdEnrol(message.DeviceId);
        this.context.deviceEnrolled.next(
            {
                deviceId: message.DeviceId
            }
        );
        return new EnrolmentClientEnrolledState(this.context);
    }

    connectionClosed() : EnrolmentClientState {
        return new EnrolmentClientUnenrolledState(
            this.context,
            "The connection to the server has been closed. Enrolment was unsuccessful"
        );
    }

    connectionError() : EnrolmentClientState {
        return new EnrolmentClientUnenrolledState(
            this.context,
            "Failed to connect to the server. Enrolment was unsuccessful"
        );
    }

    get status() : EnrolmentClientStatus {
        return {
            canEnrol : false,
            canUnenrol : false,
            description : `Waiting for enrolment confirmation (which should arrive when the code is entered into the server)`,
            uniqueCode: this.uniqueCode,
            isEnrolled: false,
            isError: false
        };
    }
}

class EnrolmentClientAwaitingInitialResponse extends EnrolmentClientState {
    constructor(context: EnrolmentClientStateContext, readonly pendingTimeoutHandle: number) {
        super(context);
    }

    async initialResponseReceived(message: LaqorrProtobuf.Enrolment.InitialResponse) : Promise<EnrolmentClientState> {
        this.context.clearTimeout(this.pendingTimeoutHandle);
        await this.context.cryptoService.setServerPublicKey(
            await Serializers.PublicKey.deserialize(message.ServerPublicKey)
        );
        return new EnrolmentClientAwaitingConfirmation(message.UniqueEnrollmentCode, this.context);
    }

    get status() : EnrolmentClientStatus {
        return {
            canUnenrol : false,
            canEnrol : false,
            description : "Waiting for first server response",
            isEnrolled : false,
            isError: false
        };
    }

    onTimeout(timeoutHandle: number) : EnrolmentClientState {
        if(timeoutHandle === this.pendingTimeoutHandle) {
            return new EnrolmentClientUnenrolledState(
                this.context,
                "A timeout occurred while waiting for the first response. Enrolment was unsuccsessful"
            );
        } else {
            return this;
        }
    }

    connectionClosed() : EnrolmentClientState {
        return new EnrolmentClientUnenrolledState(
            this.context,
            "The connection to the server has been lost. Enrolment was unsuccsessful"
        );
    }

    connectionError() : EnrolmentClientState {
        return new EnrolmentClientUnenrolledState(
            this.context,
            "Failed to connect to the server. Enrolment was unsuccessful"
        );
    }
}

class EnrolmentClientUnenrolledState extends EnrolmentClientState {
    constructor(context: EnrolmentClientStateContext, private message?: string) {
        super(context);
    }
    async beginEnrolment() : Promise<EnrolmentClientState> {
        try {
            await this.context.cryptoService.generateNewDeviceKey();
        }
        catch(exception) {
            console.log(`${exception}`);
            return new EnrolmentClientCatstrophicErrorState(this.context, exception);
        }
        const timeoutId = this.context.setTimeout(INITIAL_REQUEST_TIMEOUT);
        await this.context.requestSender.sendInitialRequest({
            DevicePlatform: LaqorrProtobuf.Enrolment.DevicePlatform.Tizen,
            DevicePublicKey: await Serializers.PublicKey.serialize(this.context.cryptoService.devicePublicKey),
            SoftwareVersion : 12 // Todo: get this from the version json file that gets written with the build
        });
        return new EnrolmentClientAwaitingInitialResponse(
            this.context,
            timeoutId
        );
    }
    get status() : EnrolmentClientStatus {
        return {
            canUnenrol: false,
            canEnrol: true,
            isEnrolled: false,
            description: this.message || "Player is not enrolled",
            isError: false
        };
    }
}

class EnrolmentClientCatstrophicErrorState extends EnrolmentClientState {
    readonly error: any;

    constructor(context: EnrolmentClientStateContext, error: any) {
        super(context);
        this.error = error;
    }

    getErrorMessage() : string {
        if(typeof(this.error) === 'string') {
            return this.error;
        } else if (
            (typeof(this.error) === 'object') && (typeof(this.error.message) === 'string')
        ) {
            return this.error.message;
        } else {
            return `${this.error}`;
        }
    }

    get status() : EnrolmentClientStatus {
        return {
            canUnenrol: false,
            canEnrol: false,
            isEnrolled: false,
            description: this.getErrorMessage(),
            isError: true
        };
    }
}

@Injectable({
    providedIn: "root"
})
export class EnrolmentClient {
    private stateManager: FiniteStateMachineStateManager<EnrolmentClientState>;
    private async transition(t : (state: EnrolmentClientState) => EnrolmentClientState | Promise<EnrolmentClientState>) {
        try {
            this.stateManager.transition(t);
        } catch(error) {
            this.loggerService.logError('error thrown from EnrolmentClient.transition');
            this.loggerService.logError(error);
        }
    }

    get status() : Observable<EnrolmentClientStatus> {
        return this.stateManager.currentState.pipe(
            map(s => s.status)
        );
    }

    private listenForIncomingMessages(enrolmentRequestDispatcher : EnrolmentRequestDispatcher) : Subscription {
        return enrolmentRequestDispatcher.responseReceived
            .subscribe({
                next: async response => {
                    switch(response.ResponseType) {
                        case LaqorrProtobuf.Enrolment.ResponseType.InitialResponse:
                            this.initialResponseReceived(
                                await Serializers.Enrolment.InitialResponse.deserialize(response.Data)
                            );
                            break;
                        case LaqorrProtobuf.Enrolment.ResponseType.Confirmation:
                            this.confirmationReceived(
                                await Serializers.Enrolment.EnrolmentConfirmation.deserialize(response.Data)
                            );
                            break;
                        default: 
                            console.error(`Received an unexpected enrolment response type of ${response.ResponseType}`);
                    }
                }
            });
    }

    private readonly deviceEnrolledSubject = new Subject<DeviceEnrolledEventArgs>();

    public get deviceEnrolled(): Observable<DeviceEnrolledEventArgs> {
        return this.deviceEnrolledSubject;
    }

    public constructor(
        clientRequestDispatcher : ClientRequestDispatcher,
        dropConnection: DropConnection,
        private readonly deviceSettings: DeviceSettings,
        private readonly clientCryptoService: ClientCryptoService,
        playHistoryDatabase: PlayHistoryDatabase,
        mediaPlayerFileSystem: MediaPlayerFileSystem,
        private readonly loggerService: LoggerService
    ) {
        const enrolmentRequestDispatcher = new EnrolmentRequestDispatcher(clientRequestDispatcher, loggerService);
        this.listenForIncomingMessages(enrolmentRequestDispatcher);
        enrolmentRequestDispatcher.connectionClosed.subscribe({
            next: () => {
                this.transition(s => s.connectionClosed());
            }
        });
        enrolmentRequestDispatcher.connectionError.subscribe({
            next: () => {
                this.transition(s => s.connectionError());
            }
        })
        
        const context:EnrolmentClientStateContext = {
            cryptoService : this.clientCryptoService,
            requestSender: enrolmentRequestDispatcher,
            deviceEnrolled: this.deviceEnrolledSubject,
            deviceSettings: deviceSettings,
            forceDropConnection: () => dropConnection.run(),
            playHistoryDatabase: playHistoryDatabase,
            mediaPlayerFileSystem: mediaPlayerFileSystem,
            setTimeout: (milliseconds:number) => this.setTimeout(milliseconds),
            clearTimeout: (timeoutHandle: number) => this.clearTimeout(timeoutHandle)
        };

        this.stateManager = new FiniteStateMachineStateManager<EnrolmentClientState>(
            new EnrolmentClientUninitializedState(context)
        );
        this.deviceSettings.getPlayerSettings()
            .then(
                settings =>
                    this.stateManager.transition(
                        () =>
                            !!deviceSettings.playerSettings.deviceId
                                ? new EnrolmentClientEnrolledState(context)
                                : new EnrolmentClientUnenrolledState(context)
                    )
            ).catch(
                (error) => {
                    console.error(`Error when fetching the device settings ${error}`);
                    this.stateManager.transition(
                        (currentState) =>
                            new EnrolmentClientCatstrophicErrorState(context, error)
                    );
                }
            )
    }

    private async initialResponseReceived(message: LaqorrProtobuf.Enrolment.InitialResponse) {
        this.transition(
            state => state.initialResponseReceived(message)
        );
    }

    private async confirmationReceived(message: LaqorrProtobuf.Enrolment.EnrolmentConfirmation) {
        this.transition(
            state => state.confirmationReceived(message)
        );
    }

    public async beginEnrolment() : Promise<void> {
        this.transition(
            state => state.beginEnrolment()
        );
    }

    public unenrol() {
        this.transition(state => state.unenrol());
    }

    private pendingTimeoutHandle: number = 0;
    private setTimeout(milliseconds: number) {
        if(this.pendingTimeoutHandle) {
            window.clearTimeout(this.pendingTimeoutHandle)
        }
        const timeoutHandle = window.setTimeout(
            () => {
                if(timeoutHandle === this.pendingTimeoutHandle) {
                    this.transition(state => state.onTimeout(timeoutHandle));
                }
            },
            milliseconds
        )
        this.pendingTimeoutHandle = timeoutHandle;
        return this.pendingTimeoutHandle;
    }

    private clearTimeout(timeoutHandle: number) {
        window.clearTimeout(timeoutHandle);
        if(this.pendingTimeoutHandle === timeoutHandle) {
            this.pendingTimeoutHandle = 0;
        }
    }
}