import * as TextUtils from "../textUtils";
import * as LaqorrProtobuf from '../laqorrProtobuf';
import { DeviceSettings } from "../deviceSettings";
import { KeyStore } from './keyStore';
import { Injectable } from '@angular/core';

@Injectable({
    "providedIn": "root"
})
export class ClientCryptoService {
    private readonly keyStore: KeyStore;

    constructor(readonly deviceSettings: DeviceSettings) {
        this.keyStore = new KeyStore(deviceSettings);
    }

    async setServerPublicKey(publicKey: LaqorrProtobuf.PublicKey) : Promise<void> {
        await this.keyStore.setServerPublicKey(publicKey);
    }

    async generateNewDeviceKey() {
        const rsa = new RSACryptoServiceProvider();
        await this.keyStore.setDeviceKey(rsa.ExportParameters(true));
    }

    get devicePublicKey() : LaqorrProtobuf.PublicKey {
        const deviceKey = this.keyStore.deviceKey;
        return {
            Exponent: TextUtils.toBase64String(deviceKey.Exponent),
            Modules: TextUtils.toBase64String(deviceKey.Modulus)
        };
    }

    decryptUsingDeviceKey(message: number[]) : number[] {
        const serverCryptoServiceProvider = new RSACryptoServiceProvider();
        serverCryptoServiceProvider.ImportParameters(this.keyStore.deviceKey);
        return serverCryptoServiceProvider.Decrypt(message, true);
    }

    encryptUsingServerPublicKey(message: number[] | Uint8Array) : number[] {
        const serverCryptoServiceProvider = new RSACryptoServiceProvider();
        serverCryptoServiceProvider.ImportParameters(this.keyStore.serverPublicKey);
        return serverCryptoServiceProvider.Encrypt(message, true);
    }

    sessionKey: LaqorrProtobuf.Session.SessionKey;

    private static crypt(challenge: ArrayLike<number>, cryptor: Cryptor) {
        const inputBuffer = new Byte(challenge.length);
        BufferBlockCopy(challenge, 0, inputBuffer, 0, inputBuffer.length);
        const stream = new IOMemoryStream();
        const mode = CryptoStreamMode.Write;
        const cryptoStream = new CryptographyCryptoStream(stream, cryptor, mode);
        cryptoStream.Write(inputBuffer, 0, inputBuffer.length);
        cryptoStream.FlushFinalBlock();
        var outputBuffer = stream.ToArray();
        stream.Close();
        cryptoStream.Close();
        return outputBuffer;
    }

    encryptUsingSessionKey(challenge: ArrayLike<number>) : number[] {
        const cipher = new CryptographyRijndaelManaged();
        const cryptor = cipher.CreateEncryptor(
            TextUtils.fromBase64String(this.sessionKey.Key),
            TextUtils.fromBase64String(this.sessionKey.InitializationVector)
        );
        return ClientCryptoService.crypt(challenge, cryptor);
    };

    decryptUsingSessionKey(challenge: ArrayLike<number>) : number[] {
        const cipher = new CryptographyRijndaelManaged();
        const cryptor = cipher.CreateDecryptor(
            TextUtils.fromBase64String(this.sessionKey.Key),
            TextUtils.fromBase64String(this.sessionKey.InitializationVector)
        );
        return ClientCryptoService.crypt(challenge, cryptor);
    }
}