import { Injectable } from '@angular/core';
import { ServerEndpointProvider, DeviceApiEndpoint } from './serverEndpointProvider';
import { Uri } from './uri';
import { HttpClient } from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as LaqorrProtobuf from "./laqorrProtobuf";
import { DeviceSettings } from './deviceSettings';
import { ApplicationLogType } from "./logDatabase";
import { HtmlUtils } from "./htmlUtils";
import { ClientInterface } from './laqorrProtobuf'

type ActivationState = ClientInterface.ActivationState;

export enum MediaPlayerComponent
{
    Player = 0,
    Controller = 1,
    FileDownloader = 2,
    ScheduledTaskProcessor = 3
}

export interface MediaPlayerProcessHealth {
    MediaPlayerProcess: MediaPlayerComponent;
    IsRunning: boolean;
}

export interface DateTimeRange {
    StartDateTime: Date;
    EndDateTime: Date;
}

enum MediaType {
    Video,
    Audio
}

export interface MediaFileExecutions {
    MediaType:MediaType;
    FileName:string;
    ExecutionTimes: Date[];
}

export enum ChangeTrigger {
    ApplicationStart = 0x00,
    Manual = 0x01,
    Timer = 0x02
}

export interface ActivationInfo {
    ActivationState: ActivationState;
    ChangeTime: Date;
    ChangeTrigger: ChangeTrigger;
    NextChangeTime: Date;
}

export enum FunctionalityStatus {
    NotApplicable = 0, // This was supposed to be "NotApplicable"
    Good = 1,
    Bad = 2
}

interface ApplicationLogEntry {
    Source: string;
    Description: string;
    EntryType: ApplicationLogType;
    CreatedDate: string|Date;
}

interface LogBundle {
    PlayerId: number;
    Entries: ApplicationLogEntry[];
}

export interface PlayerVirtualPath {
    MediaFolder: number;
    RelativePath: string;
}

export interface FileForTransfer {
    FileType: LaqorrProtobuf.ClientInterface.CmsFileType;
    FileSize: number;
    MD5: string;
    ServerPath: string;
    MediaPlayerFolder: number;
    RelativePathOnClient: string;
}

export interface PlayerStatusAndHistory {
    PlayerId:number;
    IpAddress:string;
    FreeDiskSpace:number;
    DiskSpacePercentageFree:number;
    CurrentSoftwareVersion:string;
    CurrentVideoPlaylist:string;
    CurrentAudioPlaylist: string;
    PlayerLocalTime: string; // Will be parsed by the server as a date
    ProcessHealth: MediaPlayerProcessHealth[];
    UptimeHistory?: DateTimeRange[];
    ExecutionHistory?: MediaFileExecutions[];
    OsVersionString?:string;
    Model?:string;
    MachineName?:string;
    ActivationInfo: {
        ActivationState: ActivationState;
        ChangeTime: string;
        ChangeTrigger: ChangeTrigger;
        NextChangeTime: string;
    };
    GoogleCloudMessagingRegistrationId?:string;
    ScreenCommunicationState?: FunctionalityStatus;
    ScreenMetrics: { Width: number, Height: number }[]
}

export interface PlayerClockInfo {
    UtcTime: string;
    LocalOffset: string;
}

@Injectable({
    providedIn: "root"
})
export class ServerApi {
    private deviceApiEndpoints: DeviceApiEndpoint[] = [];
    
    constructor(private readonly serverEndpointProvider: ServerEndpointProvider, private readonly deviceSettings: DeviceSettings, private readonly httpClient: HttpClient) {
        serverEndpointProvider
            .endpoints
            .subscribe(ep => this.deviceApiEndpoints = ep.deviceApiEndpoints);
    }

    updateStatusAndHistory(statusAndHistory: PlayerStatusAndHistory) {
        return this.postJson("StatusAndHistory", {}, statusAndHistory);
    }

    private static tryPerformActionOnEndpoints<T>(
        transformUri: (uri:Uri) => Uri,
        processRequest: (uri:string) => Observable<T>,
        apiEndpoints: DeviceApiEndpoint[]
    ) : Observable<T> {
        if(!apiEndpoints.length) {
            return throwError("Failed to call the method on all endpoints");
        }
        const endpointUri = Uri.parse(apiEndpoints[0].uri);
        const transformedUri = transformUri(endpointUri);
        return processRequest(transformedUri.toString())
            .pipe(
                catchError(
                    (e) => this.tryPerformActionOnEndpoints(
                        transformUri,
                        processRequest,
                        apiEndpoints.slice(1)
                    )
                )
            );
    }

    private performApiAction<T>(
        transformUri: (uri:Uri) => Uri,
        processRequest: (uri:string) => Observable<T>
    ) {
        return ServerApi.tryPerformActionOnEndpoints<T>(
            transformUri,
            processRequest,
            this.deviceApiEndpoints
        );
    }

    private static formatToLocalIsoDateString(key: string, value:any) {
        if(this[key] instanceof Date) {
            const d = <Date>(this[key]);
            const formattedDate = HtmlUtils.dateToJsonString(d);
            return formattedDate;
        } else {
            return value;
        }
    }

    private postJson(path: string, parameters:{[key:string]:string|number},  data: any) {
        const transformUri = (uri: Uri) => uri.appendPathElements(path).addParameters(parameters);
        const postData = JSON.stringify(
            data,
            ServerApi.formatToLocalIsoDateString
        );
        const processRequest = (uri:string) => {
            return this.httpClient.post(
                uri,
                postData,
                {
                    headers: {
                        "content-type": "application/json"
                    }
                }
            );
        };
        return this.performApiAction<{}>(
            transformUri,
            processRequest
        );
    }

    private getJson<T>(path: string, parameters:{[key:string]:string|number}) {
        const transformUri = (uri: Uri) => uri.appendPathElements(path).addParameters(parameters);
        const processRequest = (uri:string) => {
            return this.httpClient.get<T>(uri);
        };
        return this.performApiAction<T>(
            transformUri,
            processRequest
        );
    }

    getRequiredFiles() {
        return this.getJson("RequiredFiles", { playerId: this.deviceSettings.playerSettings.deviceId });
    }

    getPlayerClockInfo() : Observable<PlayerClockInfo> {
        return this.getJson<PlayerClockInfo>("Clock", { playerId: this.deviceSettings.playerSettings.deviceId });
    }

    postSendFileRequests(filesForTransfer: FileForTransfer[]) {
        return this.postJson('requiredFiles/Send', { playerId: this.deviceSettings.playerSettings.deviceId }, filesForTransfer);
    }

    fileHasBeenDeleted(fileInfos: PlayerVirtualPath[]) {
        return this.postJson('File/FileDeletedFromPlayer', { playerId: this.deviceSettings.playerSettings.deviceId}, fileInfos);
    }

    postPlayerLogs(logBundle: LogBundle) {
        logBundle.Entries = logBundle.Entries.map((entry: ApplicationLogEntry) => {
            if (entry.CreatedDate) {
                entry.CreatedDate = HtmlUtils.dateToJsonString(<Date>entry.CreatedDate);
            }
            return entry;
        });
        return this.postJson("PlayerLogs", {}, logBundle);
    }

    available: Observable<boolean> = this.serverEndpointProvider.endpointsAvailable;
}