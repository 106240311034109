import moment from 'moment';

class SegmentMatchResults {
        success: boolean;
        index: number;
        length: number;
        convert: (date: Date) => string;
        measurementText: string;
        constructor(success: boolean, index?: number, length?: number, convert?: (date: Date) => string, measurementText?: string) {
                this.success = success;
                this.index = index;
                this.length = length;
                this.convert = convert;
                this.measurementText = measurementText;
        }
}

interface SegmentFormatter {
        match(input: string): SegmentMatchResults;
}

class DateTimeTokenSegmentFormatter implements SegmentFormatter {
        token: string;
        convert: (date: Date) => string;
        measurementText: string;
        constructor(token: string, convert: (date: Date) => string, measurementText: string) {
                this.token = token;
                this.convert = convert;
                this.measurementText = measurementText;
        }

        public match(input: string): SegmentMatchResults {
                const tokenIndex: number = input.indexOf(this.token);
                if (tokenIndex != -1)
                {
                        return new SegmentMatchResults(true,
                                tokenIndex,
                                this.token.length,
                                this.convert,
                                this.measurementText
                        );
                } else {
                        return new SegmentMatchResults(false);
                }
        }
}

class EscapeStringSegmentFormatter implements SegmentFormatter {
        private static regexp: RegExp = new RegExp('\\[(.*)\\]');

        public match(input: string): SegmentMatchResults {
                const regexpMatch: RegExpMatchArray = EscapeStringSegmentFormatter.regexp.exec(input);
                if (regexpMatch) {
                        return new SegmentMatchResults(true, 
                                regexpMatch.index,
                                regexpMatch[0].length, 
                                (d) => regexpMatch[1],
                                regexpMatch[1]
                        );  
                } else {
                        return new SegmentMatchResults(false);
                }
        }
}

class Generator {
        convert: (date: Date) => string;
        measurementText: string;
        constructor(convert: (date: Date) => string, measurementText: string) {
                this.convert = convert;
                this.measurementText = measurementText;
        }
}

export class DateTimeFormatter {
        // Note that the order of these is important!
        // For example "MMMM" must precede "MMM",
        // so that the shortened one does not take away
        // from the longer one
        //
        private static segmentFormatters: SegmentFormatter[] = <SegmentFormatter[]>[
                new EscapeStringSegmentFormatter(),
                new DateTimeTokenSegmentFormatter(
                        "MMMM",
                        (date: Date) => moment(date).format("MMMM"),
                        "September"),
                new DateTimeTokenSegmentFormatter(
                        "MMM",
                        (date: Date) => moment(date).format("MMM"),
                        "May"),
                new DateTimeTokenSegmentFormatter(
                        "MM",
                        (date: Date) => moment(date).format("MM"),
                        "10"),
                new DateTimeTokenSegmentFormatter(
                        "M",
                        (date: Date) => moment(date).format("M"),
                        "10"),
                new DateTimeTokenSegmentFormatter(
                        "DD",
                        (date: Date) => moment(date).format("DD"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "D",
                        (date: Date) => moment(date).format("D"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "dddd",
                        (date: Date) => moment(date).format("dddd"),
                        "Wednesday"),
                new DateTimeTokenSegmentFormatter(
                        "A",
                        (date: Date) => moment(date).format("A"),
                        "AM"),
                new DateTimeTokenSegmentFormatter(
                        "a",
                        (date: Date) => moment(date).format("a"),
                        "am"),
                new DateTimeTokenSegmentFormatter(
                        "HH",
                        (date: Date) => moment(date).format("HH"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "H",
                        (date: Date) => moment(date).format("H"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "hh",
                        (date: Date) => moment(date).format("hh"),
                        "10"),
                new DateTimeTokenSegmentFormatter(
                        "h",
                        (date: Date) => moment(date).format("h"),
                        "12"),
                new DateTimeTokenSegmentFormatter(
                        "mm",
                        (date: Date) => moment(date).format("mm"),
                        "00"),
                new DateTimeTokenSegmentFormatter(
                        "m",
                        (date: Date) => moment(date).format("m"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "ss",
                        (date: Date) => moment(date).format("ss"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "s",
                        (date: Date) => moment(date).format("s"),
                        "20"),
                new DateTimeTokenSegmentFormatter(
                        "YYYY",
                        (date: Date) => moment(date).format("YYYY"),
                        "2000"),
                new DateTimeTokenSegmentFormatter(
                        "YY",
                        (date: Date) => moment(date).format("YY"),
                        "00")
        ];

        private static constructGenerators(format: string): Generator[] {
                if (!format)
                {
                        return <Generator[]>[];
                }
                for (const formattingToken of DateTimeFormatter.segmentFormatters)
                {
                        const matchResults: SegmentMatchResults = formattingToken.match(format);
                        if (matchResults.success) {
                                const textBefore: string = format.substring(0, matchResults.index);
                                const textAfter: string = format.substring(matchResults.index + matchResults.length);
                                const generators: Generator[] = [];
                                generators.push(new Generator(matchResults.convert, matchResults.measurementText));
                                return DateTimeFormatter.constructGenerators(textBefore)
                                        .concat(generators)
                                        .concat(DateTimeFormatter.constructGenerators(textAfter));
                        }
                }
                const generators: Generator[] = [];
                generators.push(new Generator(d => format, format));
                return generators;
        }

        private static generateConversionFunction(format: string): (date: Date) => string
        {
                const generators: Generator[] = DateTimeFormatter.constructGenerators(format);
                return (d) => generators.map((value: Generator, index: number) => value.convert(d)).join("");

        }

        public static format(date: Date, format: string): string {
                return DateTimeFormatter.generateConversionFunction(format)(date);
        }

	public static constructMeasurementText(format: string): string {
                const generators: Generator[] = DateTimeFormatter.constructGenerators(format);
                return generators.map((value: Generator, index: number) => value.measurementText).join("");
        }
}
